import { Switch } from '@chakra-ui/react';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { MBox, MFlex } from '~app/components/Monetize';
import { QuoteBasicContactSelect } from '~app/components/Quotes/QuoteBasicContactSelect';
import { QuoteBasicInfoLine } from '~app/components/Quotes/QuoteBasicInfoLine';
import { useIsTenantEsignConfigured } from '~app/hooks/useIsTenantEsignConfigured';
import {
  ContactTypes,
  IContactRespSchema,
  IQuoteRequestSchema,
} from '~app/types';
import { LegalEntityAddressFormatEnum } from '~app/types/legalEntityTypes';
import { useQuoteContext } from '../../Quote/quoteContext';

interface QuoteBasicContactsProps {}

export const QuoteBasicContacts: FC<
  QuoteBasicContactsProps
> = ({}: QuoteBasicContactsProps) => {
  const {
    quoteContacts,
    quoteData: { quote },

    handleSubmitButtonWithoutDirtyCheck,
    isReadOnly,
  } = useQuoteContext();
  const isTenantEsignConfigured = useIsTenantEsignConfigured();

  const {
    externalContacts,
    internalContacts,
    externalAccountContacts,
    internalAccountContacts,
    handleChangeContact,
    addressDataForm,
    addressSource,
    isDisabled,
    isDisabledPrimaryBilling,
  } = quoteContacts;
  const shippingAddressId = addressDataForm.watch('shippingAddressId');

  const billingContact = externalContacts.find((contact) => contact.billing);
  const primaryContact = externalContacts.find((contact) => contact.primary);
  const esignContact = externalContacts.find((contact) => contact.esign);
  const esignInternalContact = internalContacts.find(
    (contact) => contact.esign,
  );

  const addressFormat = LegalEntityAddressFormatEnum.US_FORMAT;
  const { control, watch } = useFormContext<IQuoteRequestSchema>();
  const requiresEsignWatch = watch('requiresEsign');

  const handleChangeContactAndEvaluate = async (
    contact: IContactRespSchema,
    type: ContactTypes,
    isInternal: boolean,
  ) => {
    handleChangeContact(contact, type, isInternal);
  };

  return (
    <MBox>
      <QuoteBasicInfoLine
        title="Primary Contact"
        showRequired={!primaryContact}
      >
        <MBox mr={isDisabledPrimaryBilling ? 0 : '-2'}>
          <QuoteBasicContactSelect
            value={primaryContact?.contact}
            onChange={(contact) =>
              handleChangeContactAndEvaluate(contact, 'primary', false)
            }
            contacts={externalAccountContacts}
            isDisabled={isDisabledPrimaryBilling}
            isReadOnly={isDisabledPrimaryBilling}
            accountId={quote?.accountId}
          />
        </MBox>
      </QuoteBasicInfoLine>

      <QuoteBasicInfoLine
        title="Billing Contact"
        showRequired={!billingContact}
      >
        <MBox mr={isDisabledPrimaryBilling ? 0 : '-2'}>
          <QuoteBasicContactSelect
            value={billingContact?.contact}
            onChange={(contact) =>
              handleChangeContactAndEvaluate(contact, 'billing', false)
            }
            contacts={externalAccountContacts}
            isDisabled={isDisabledPrimaryBilling}
            isReadOnly={isDisabledPrimaryBilling}
            accountId={quote?.accountId}
          />
        </MBox>
      </QuoteBasicInfoLine>

      {isTenantEsignConfigured && (
        <QuoteBasicInfoLine title="eSign">
          <MFlex>
            <Controller
              name="requiresEsign"
              control={control}
              render={({ field: { value, onChange, ...rest } }) => (
                <Switch
                  isReadOnly={isReadOnly}
                  isDisabled={isReadOnly}
                  isChecked={value}
                  onChange={(e) => {
                    onChange(e.target.checked);
                    handleSubmitButtonWithoutDirtyCheck();
                  }}
                  {...rest}
                />
              )}
            />
          </MFlex>
        </QuoteBasicInfoLine>
      )}
      {requiresEsignWatch && (
        <>
          <QuoteBasicInfoLine
            title="eSign Internal Contact"
            showRequired={!esignContact}
          >
            <MBox mr={isDisabled ? 0 : '-2'}>
              <QuoteBasicContactSelect
                value={esignInternalContact?.contact}
                onChange={(contact) =>
                  handleChangeContactAndEvaluate(contact, 'esign', true)
                }
                contacts={internalAccountContacts}
                isDisabled={isDisabled}
                isReadOnly={isDisabled}
                accountId={quote?.accountId}
                defaultValues={{ internal: true, esigner: true }}
              />
            </MBox>
          </QuoteBasicInfoLine>
          <QuoteBasicInfoLine
            title="eSign Customer Contact"
            showRequired={!esignContact}
          >
            <MBox mr={isDisabled ? 0 : '-2'}>
              <QuoteBasicContactSelect
                value={esignContact?.contact}
                onChange={(contact) =>
                  handleChangeContactAndEvaluate(contact, 'esign', false)
                }
                contacts={externalAccountContacts}
                isDisabled={isDisabled}
                isReadOnly={isDisabled}
                accountId={quote?.accountId}
              />
            </MBox>
          </QuoteBasicInfoLine>
        </>
      )}
    </MBox>
  );
};
