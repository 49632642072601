import { boolean, nativeEnum, number, object, string, z } from 'zod';
import { getRequiredMessage } from '~app/utils/messages';
import { AccountSchema } from './accountTypes';
import { CustomFieldRecordSchema } from './customFieldsTypes';

export enum CreditTypeEnum {
  SERVICE = 'SERVICE',
  PREPAID = 'PREPAID',
  CREDIT_NOTE = 'CREDIT_NOTE',
}
export const CreditTypeEnumZ = nativeEnum(CreditTypeEnum);

export enum CreditStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  CANCELED = 'CANCELED',
  APPLIED = 'APPLIED',
  VOID = 'VOID',
}
export const CreditStatusEnumZ = nativeEnum(CreditStatusEnum);

// PUT /api/accounts/:accountId/billGroup/:billgroupId/credits
// POST /api/accounts/:accountId/billGroup/:billgroupId/credits/:creditId
export const UpsertCreditSchema = object({
  id: string().optional(),
  billGroupId: string().nonempty('Bill Group is required'),
  status: CreditStatusEnumZ,
  type: CreditTypeEnumZ,
  currency: string().nonempty(''),
  amount: z
    .union([z.string(), z.number()])
    .refine(
      (val) => val !== null && val !== undefined && val !== '',
      getRequiredMessage('Amount'),
    )
    .refine(
      (val) => {
        if (val || val === 0) {
          return !Number.isNaN(Number(val)) && Number(val) > 0;
        }
        return true;
      },
      {
        message: 'Amount must be greater than 0.',
      },
    )
    .transform((val) => Number(val)),
  refundable: boolean(),
  expirationDate: string().nullish(),
  reason: string().nonempty("Reason for credit can't be empty"),
  name: string(),
  customFields: CustomFieldRecordSchema.nullish(),
});
export type IUpsertCreditSchema = z.infer<typeof UpsertCreditSchema>;

// GET /api/accounts/:accountId/credits
export const GetCreditSchema = object({
  id: string(),
  accountId: string(),
  amount: number().positive(),
  amountApplied: number(),
  amountRemaining: number(),
  appliedDate: string().nullish(), // Only applicable to InvoiceCreditDto (which should likely be a different type here)
  billGroupId: string(),
  creditNoteId: string().nullable(),
  currency: string(),
  expirationDate: string().nullable(),
  prepaidInvoiceItemId: string().nullable(),
  reason: string(),
  refundable: boolean(),
  status: CreditStatusEnumZ,
  type: CreditTypeEnumZ,
  name: string().nullable(),
  modifyDate: string().optional(),
  accountSummary: AccountSchema,
  customFields: CustomFieldRecordSchema.nullable(),
  voidReason: string().optional(),
});
export type IGetCreditSchema = z.infer<typeof GetCreditSchema>;

export const VoidCreditSchema = object({
  reason: string().min(1, getRequiredMessage('Reason')).max(255),
});
export type IVoidCreditSchema = z.infer<typeof VoidCreditSchema>;
