import { SortOrder, TDataTablePager } from '~types';

export interface ApiListPageable {
  pageNumber: number;
  pageSize: number;
}
export interface ApiListResponse<T = any> {
  pageable?: ApiListPageable;
  totalElements: number;
  totalPages: number;
  content: T[];
}

export interface ApiListInfiniteScrollResponse<T = any>
  extends Omit<ApiListResponse<T>, 'pageable'> {
  pageable?: Omit<ApiListPageable, 'pageNumber'> & { nextPageToken?: string };
}

export interface GetListApiFilter {
  [key: string]:
    | number // number
    | string // string
    | { contains: string } // GET /api/accounts?name=contains:mustafa
    | { in: string[] } // GET /api/accounts?id=in:A1001,A1002,A1003
    | { between: { gte?: number; lte?: number; bt?: string } } // GET amount=lte:100&amount=gte:1000
    | { ne: string } // GET amount=ne:string
    | { eq: string };
}

export const DEFAULT_API_RESPONSE: ApiListResponse = {
  content: [],
  totalElements: 0,
  totalPages: 0,
};

export const DEFAULT_ROWS = 20;
/**
 * Default pager for data table
 * it is preferred to use this as the foundation and override specific properties as needed
 *
 * 20 rows per page
 * sort by createDate Descending (newest first)
 * If sorting on a string, like name, make sure to override the sortOrder
 */
export const DEFAULT_PAGER: TDataTablePager = {
  first: 0,
  rows: DEFAULT_ROWS,
  page: 0,
  sortField: 'createDate',
  sortOrder: -1,
} as const;

export type GetListApiConfig = {
  /** @deprecated - this property is not used for API requests */
  first?: number;
  /** Used to determine pageSize */
  rows?: number;
  /** Used to determine current page, starting at 0 */
  page?: number;
  /** Used for "load more" based pagination, used on usage service */
  nextPageToken?: string;
  /** Field to sort by */
  sortField?: string;
  /** Sort direction to apply to sort field. -1 for Descending, 1 for Ascending */
  sortOrder?: SortOrder;
  multiSortMeta?: any[];
};

export interface GenericApiResponse {
  message: string;
  status: number;
}
