import { createContext, useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { activeToastState } from '~app/store/global.store';
import { ToastItem, ToastSeverity } from '~types';

export interface AddToastItem extends ToastItem {
  timeTaken?: number;
}

const defaultContext = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addToast: (newToast: AddToastItem) => {},
};

const ToastContext = createContext(defaultContext);

export const useToast = () => useContext(ToastContext);

export const useToastProvider = () => {
  const setToast = useSetRecoilState(activeToastState);
  const addToast = (newToast: AddToastItem) => {
    const { timeTaken, ...rest } = newToast;
    const defaultTimeTakenExpiration = 5000;

    if (!timeTaken || (timeTaken && timeTaken > defaultTimeTakenExpiration)) {
      const defaultVal: ToastItem = {
        life: 5000,
      };
      setToast({ ...defaultVal, ...rest });
    }
  };

  return { addToast };
};

export const ToastProvider = ({ children }: { children: any }) => {
  const data = useToastProvider();
  return <ToastContext.Provider value={data}>{children}</ToastContext.Provider>;
};

export const useCommonToast = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { addToast } = useToast();

  // Check if it has showToast
  const hasShowToastParam = searchParams.has('showToast');

  useEffect(() => {
    if (hasShowToastParam) {
      const summary = searchParams.get('summary')!;
      const detail = searchParams.get('detail')!;
      const severity = searchParams.get('severity')! as ToastSeverity;

      addToast({
        summary,
        detail,
        severity,
      });
      ['showToast', 'summary', 'detail', 'severity'].map((param) =>
        searchParams.delete(param),
      );
      setSearchParams(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
