import {
  Box,
  Tag,
  TagCloseButton,
  TagLabel,
  Tooltip,
  useOutsideClick,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import React, {
  FunctionComponent as FC,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { apiGet, handleApiErrorToast } from '~app/api/axios';
import { composeGetQuery } from '~app/api/utils';
import { useToast } from '~app/services/toast';
import { shortenText } from '~app/utils';
import { ApiListResponse, DEFAULT_PAGER, TDataTablePager } from '~types';
import { MButton } from '..';
import MSearchInput from '../MSearchInput';
import { MFlex } from '../chakra';
import { MInputSearchTagCheckBox } from './components/MInputSearchTagCheckBox';

const pager: TDataTablePager = {
  ...DEFAULT_PAGER,
  sortField: 'name',
  sortOrder: 1,
};

interface MInputSearchTagProps {
  value: any[];
  endpoint: string;
  labelField: string;
  selectionLimit?: number;
  checkboxDisplay?: boolean;
  displayAvatar?: boolean;
  displayValue?: boolean;
  disabled?: boolean;
  disableItemCheckbox?: (item: any) => boolean;
  placeholder?: string;
  displayContentStyleProps?: any;
  openOnPageLoad?: boolean;
  onChange?: (e: any[]) => void;
  transformDataFromApi?: (data: any[]) => any[];
  onApplyButtonClick?: (e: any[]) => void;
  onRemoveTag: (e: any[]) => void;
}

const MInputSearchTag: FC<MInputSearchTagProps> = React.forwardRef<
  any,
  MInputSearchTagProps
>(
  (
    {
      value,
      endpoint,
      onChange,
      labelField,
      selectionLimit = -1,
      checkboxDisplay,
      displayAvatar,
      displayValue = true,
      disabled,
      disableItemCheckbox,
      placeholder = 'Search',
      displayContentStyleProps,
      openOnPageLoad,
      transformDataFromApi,
      onApplyButtonClick,
      onRemoveTag,
      ...rest
    }: MInputSearchTagProps,
    ref,
  ) => {
    const inputRef = useRef<any>(null);

    const [query, setQuery] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<any[]>([]);
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const [focused, setFocused] = React.useState(false);

    const [selectedData, setSelectedData] = useState<any[]>([]);

    const { addToast } = useToast();
    useOutsideClick({
      ref: inputRef,
      handler: () => setIsMenuOpen(false),
    });

    const searchDataByQuery = async (q: string) => {
      try {
        setLoading(true);
        const params = composeGetQuery(pager, {
          name: `contains:${q}`,
        });
        const result = await apiGet<ApiListResponse<any>>(endpoint, {
          params,
        });

        setData(
          transformDataFromApi
            ? transformDataFromApi(result.data.content)
            : result.data.content,
        );
      } catch (error) {
        handleApiErrorToast(error, {
          summary: 'Error while fetching query data.',
        });
      } finally {
        setLoading(false);
      }
    };

    const debouncedEventHandler = useMemo(
      () => debounce(searchDataByQuery, 300),
      [],
    );

    const handleQuery = (value: string): void => {
      setQuery(value);
      debouncedEventHandler(value);
    };

    const handleFocus = () => {
      setFocused(true);
      setIsMenuOpen(true);
    };

    const handleBlur = () => setFocused(false);

    const handleItem = (item: any) => {
      setIsMenuOpen(false);
      setQuery('');

      if (onChange) {
        let tempSelectedData: any[];
        const isIdAlreadyExist = selectedData.some(
          (tag: any) => tag.id === item.id,
        );

        if (isIdAlreadyExist) {
          addToast({
            summary: 'Duplicate Selection',
            detail: `${item.name} was already selected.`,
            severity: 'warning',
          });
        } else {
          tempSelectedData = [...selectedData, item];
          setSelectedData(tempSelectedData);
          onChange(tempSelectedData);
        }
      }
      searchDataByQuery('');
    };

    const handleRemove = (item: any) => {
      const selectedTag = selectedData.filter((tag) => tag.id !== item.id);
      setSelectedData(selectedTag);
      onRemoveTag(selectedTag);
    };

    useEffect(() => {
      searchDataByQuery('');
    }, []);

    useEffect(() => {
      if (value?.length) {
        setSelectedData(value);
      }
    }, [value]);

    useEffect(() => {
      if (openOnPageLoad) {
        handleFocus();
      }
    }, [openOnPageLoad]);

    const handleCheckboxCheck = (item: any, event?: any) => {
      const isTagExist = selectedData.findIndex(
        (tag: any) => tag.id === item.id,
      );
      if (isTagExist !== -1) {
        const newTag = [...selectedData];
        newTag.splice(isTagExist, 1);
        setSelectedData(newTag);
        onChange && onChange(newTag);
      } else {
        const tempSelectedData = [...selectedData, item];
        setSelectedData(tempSelectedData);
        onChange && onChange(tempSelectedData);
      }
    };

    const handleApplyBtn = () => {
      onApplyButtonClick && onApplyButtonClick(selectedData);
      setIsMenuOpen(false);
    };

    return (
      <Box ref={inputRef} w="full" position="relative">
        <MSearchInput
          isDisabled={selectedData?.length === selectionLimit || disabled}
          placeholder={placeholder}
          value={query}
          onChange={handleQuery}
          onFocus={handleFocus}
          onBlur={handleBlur}
          count={data.length}
          {...rest}
        />
        {isMenuOpen && (
          <Box
            data-testid="input-searchTag"
            position="absolute"
            zIndex="100"
            top="auto"
            left={0}
            border={data?.length ? '1px solid' : 'none'}
            borderColor="tGray.back"
            borderRadius="3"
            p={data?.length ? '1.5' : '0'}
            mt="2"
            w="full"
            bgColor="white"
            boxShadow="t1"
            {...displayContentStyleProps}
          >
            <Box
              maxH="400px"
              overflow="auto"
              className="custom-scroll-bar-v1"
              overflowY="auto"
            >
              {checkboxDisplay ? (
                <>
                  <MInputSearchTagCheckBox
                    data={data}
                    selectedData={selectedData}
                    displayAvatar={displayAvatar || false}
                    labelField={labelField}
                    handleCheckboxCheck={handleCheckboxCheck}
                    handleDisableCheckbox={disableItemCheckbox}
                  />
                </>
              ) : (
                data.map((i: any) => (
                  <TypeAheadActionItem onClick={() => handleItem(i)} key={i.id}>
                    {i.name}
                  </TypeAheadActionItem>
                ))
              )}
            </Box>
            {checkboxDisplay && !!data?.length && (
              <MButton
                data-testid="searchTag-apply-btn"
                variant="primary"
                w="full"
                mt={2}
                onClick={handleApplyBtn}
                isDisabled={!selectedData?.length}
              >
                Apply
              </MButton>
            )}
          </Box>
        )}

        <Wrap spacing={2} mt={2}>
          {displayValue &&
            value?.length > 0 &&
            value.map((item, index) => (
              <WrapItem key={index}>
                <Tag
                  h={8}
                  size="md"
                  borderWidth="1px"
                  borderStyle="solid"
                  borderColor="tGray.lightPurple"
                  borderRadius="62px"
                  variant="solid"
                  colorScheme="tGray.support"
                  color="tPurple.base"
                  fontWeight="bold"
                >
                  <Tooltip label={item[labelField]} placement="bottom-end">
                    <MFlex alignItems="center">
                      <TagLabel ml={1}>
                        {shortenText('last', 40, item[labelField], 75)}
                      </TagLabel>
                    </MFlex>
                  </Tooltip>
                  {!disabled && (
                    <TagCloseButton onClick={() => handleRemove(item)} />
                  )}
                </Tag>
              </WrapItem>
            ))}
        </Wrap>
      </Box>
    );
  },
);

const TypeAheadActionItem = ({ children, ...rest }: any) => (
  <Box
    color="tPurple.dark"
    fontWeight="bold"
    fontSize="sm"
    p="2"
    borderRadius="2"
    cursor="pointer"
    _hover={{ background: 'tBlue.hover' }}
    {...rest}
  >
    {children}
  </Box>
);

export default MInputSearchTag;
