import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Flex,
  Icon,
  Popover,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  Tooltip,
  useOutsideClick,
} from '@chakra-ui/react';
import React, { FC, useState } from 'react';
import { MdArrowDropDown, MdArrowDropUp, MdFilterList } from 'react-icons/md';
import MButton from './MButton';
import MCustomBadge from './MCustomBadge';
import MCustomIconButton from './MCustomIconButton';
import MText from './MText';

interface MFilterCardProps {
  onClose?: null | ((applyFilters: boolean) => void);
  onResetFilter?: null | (() => void);
  children?: any | null;
  renderTrigger?:
    | null
    | (({ onOpen }: { onOpen: () => void }) => React.ReactElement);
  filtersApplied?: number;
  filterTooltip?: string;
  popoverProps?: PopoverProps;
  isInternalFilterUnchanged: boolean;
}

const MFilterCard: FC<MFilterCardProps> = React.forwardRef<
  any,
  MFilterCardProps
>(
  (
    {
      onClose,
      onResetFilter,
      children,
      renderTrigger,
      filtersApplied,
      filterTooltip = 'Filter',
      popoverProps,
      isInternalFilterUnchanged,
    }: MFilterCardProps,
    ref,
  ) => {
    const applyButtonRef = React.useRef<HTMLButtonElement>(null);
    const contentRef = React.useRef<HTMLElement>(null);
    const filterContainerRef = React.useRef<HTMLDivElement>(null);

    const initialFocusRef = !isInternalFilterUnchanged
      ? applyButtonRef
      : contentRef;

    const [isOpen, setIsOpen] = useState(false);
    useOutsideClick({
      ref: filterContainerRef,
      handler: () => setIsOpen(false),
    });

    const handleOpen = () => {
      setIsOpen(!isOpen);
      if (isOpen) {
        initialFocusRef?.current?.focus();
      }
    };

    const handleResetFilter = () => {
      setIsOpen(false);
      onResetFilter && onResetFilter();
    };

    const handleApplyFilter = () => {
      setIsOpen(false);
      onClose && onClose(true);
    };

    const handleClose = () => {
      setIsOpen(false);
      onClose && onClose(false);
    };

    return (
      <Box ref={filterContainerRef} position="relative" maxWidth="300px">
        <Popover
          initialFocusRef={initialFocusRef}
          autoFocus
          placement="bottom-end"
          trigger="click"
          offset={[0, 0]}
          isOpen={isOpen}
          onOpen={handleOpen}
          onClose={handleClose}
          {...popoverProps}
        >
          <PopoverTrigger>
            {renderTrigger ? (
              renderTrigger({ onOpen: handleOpen })
            ) : (
              <MCustomBadge
                text={(filtersApplied || '') as string}
                tooltipLabel="Reset All Filters"
                onClickReset={handleResetFilter}
              >
                <Tooltip label={filterTooltip} placement="bottom-end">
                  <MCustomIconButton
                    data-testid="filter-card-button"
                    containerSize="8"
                    btnSize="6"
                    onClick={handleOpen}
                    icon={MdFilterList}
                    variant="icon"
                    iconColor="tPurple.base"
                    mb="0"
                  />
                </Tooltip>
              </MCustomBadge>
            )}
          </PopoverTrigger>
          <PopoverContent
            border="1px solid"
            borderColor="tGray.back"
            borderRadius="2"
            filter="drop-shadow(0px 4px 8px rgba(24, 36, 60, 0.15))"
            width="300px"
            position="relative"
            ref={contentRef}
          >
            <Box
              p={4}
              maxH="50vh"
              overflowY="auto"
              mb="64px"
              className="custom-scroll-bar-v1"
            >
              <MText
                color="tPurple.base"
                fontSize="lg"
                fontWeight="bold"
                mb="2"
              >
                Filter
              </MText>
              <Accordion allowMultiple defaultIndex={[0]} variant="default">
                {children}
              </Accordion>
            </Box>
            <Flex
              width="100%"
              padding={4}
              margin="auto"
              position="fixed"
              borderTop="1px solid"
              borderTopColor="tGray.back"
              style={{ bottom: 0 }}
              justifyContent="space-between"
            >
              <Button variant="cancel" mr="2" onClick={handleResetFilter}>
                Reset All
              </Button>
              <Button
                ref={applyButtonRef}
                variant="primary"
                onClick={handleApplyFilter}
                isDisabled={isInternalFilterUnchanged}
              >
                Apply
              </Button>
            </Flex>
          </PopoverContent>
        </Popover>
      </Box>
    );
  },
);

export const FilterCardAccordionItem = ({
  title,
  filterApplied = false,
  handleClearFilter,
  children,
}: {
  title: string;
  filterApplied?: boolean;
  handleClearFilter: () => void;
  children: any;
}) => (
  <AccordionItem border="none">
    {({ isExpanded }: { isExpanded: boolean }) => (
      <>
        <AccordionButton
          data-testid={`${title}-accordion-button`}
          p="0"
          _hover={{ background: 'none' }}
          my="2"
          justifyContent="space-between"
        >
          <Box flex="1" textAlign="left">
            <MText fontWeight="bold">{title}</MText>
          </Box>
          <Flex alignItems="center">
            {filterApplied && (
              <MButton
                as="span"
                variant="tertiary"
                size="xs"
                fontSize="xxs"
                _hover={{ background: 'none' }}
                h="4"
                onClick={(event) => {
                  event.stopPropagation();
                  handleClearFilter?.();
                }}
              >
                Clear
              </MButton>
            )}
            <Icon as={isExpanded ? MdArrowDropUp : MdArrowDropDown} />
          </Flex>
        </AccordionButton>

        <AccordionPanel p="0" m="0">
          {children}
        </AccordionPanel>
      </>
    )}
  </AccordionItem>
);

export const FilterCardCheckGroup = ({
  items,
  value,
  isRadio = false,
  returnObject = false,
  objectId = 'id',
  itemLabel = 'label',
  itemValue = 'value',
  onChange,
}: {
  items: any;
  value: any[] | any;
  // work as radio not checkboxes
  isRadio?: boolean;
  // whether to send object on onChange
  returnObject?: boolean;
  // when returnObject is true, objectId indicated the uniqueness of each object
  objectId?: string;
  itemLabel?: string;
  itemValue?: string;
  onChange: (val: any) => void;
}) => {
  const internalItemValue = itemValue || 'value';

  const isSelected = (item: any) => {
    if (isRadio) {
      return returnObject
        ? compareItems(item, value)
        : valueOfItem(item) === value;
    }
    return Array.isArray(value) && value.includes(valueOfItem(item));
  };

  const compareItems = (item1: any, item2: any) =>
    item1 &&
    item2 &&
    (returnObject
      ? item1[objectId] === item2[objectId]
      : item1[internalItemValue] === item2[internalItemValue]);

  const valueOfItem = (item: any) =>
    returnObject ? item : item[internalItemValue];

  const handleChange = (item: any) => {
    if (isRadio) {
      // Allows de-selection of radio button
      onChange(isSelected(item) ? {} : valueOfItem(item));
    } else {
      const selected = isSelected(item);
      const cVal = value || [];
      const newValue = valueOfItem(item);
      const selectingAll: boolean = newValue === '';
      let newItems = [];

      if (selected) {
        newItems = cVal.filter((val: any) => newValue !== val);
      } else {
        newItems = cVal
          .concat(newValue)
          // All option should not be selected with other filters.
          // Filter it out here or the other options depending on what is seleted.
          .filter((v: string) => (selectingAll ? !v : v));
      }

      onChange(newItems);
    }
  };

  return (
    <>
      {items.map((item: any, index: any) => (
        <Box key={index}>
          <Checkbox
            my={1}
            isChecked={(isSelected(item) as any) || false}
            onChange={() => handleChange(item)}
          >
            <MText ml="1" fontWeight="medium">
              {item[itemLabel]}
            </MText>
          </Checkbox>
        </Box>
      ))}
    </>
  );
};

export const FilterCardCheckGroupTest = ({
  items,
  onChangeCheckBox,
  onResetFilter,
}: {
  items: any[];
  onChangeCheckBox: (val: any) => void;
  onResetFilter: (val: any) => void;
}) => (
  <>
    {items.map((item, index) => (
      <Box key={index}>
        <Checkbox
          my={1}
          isChecked={item.checked}
          onChange={() => onChangeCheckBox(item)}
        >
          <MText ml="1" fontWeight="medium">
            {item.label}
          </MText>
        </Checkbox>
      </Box>
    ))}
  </>
);

export default MFilterCard;
