import { BoxProps, CenterProps } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { Accept, ErrorCode, FileRejection, useDropzone } from 'react-dropzone';
import { MdInfo } from 'react-icons/md';
import {
  MBox,
  MCenter,
  MFlex,
  MIcon,
  MLink,
  MText,
} from '~app/components/Monetize';
import { ACCEPT_FILES } from '~app/constants/misc';

type AcceptFileType = keyof typeof ACCEPT_FILES;
interface MFileDragDropUploadProps extends BoxProps {
  accept?: AcceptFileType[];
  label?: string;
  subtitle?: string;
  innerContainerProps?: CenterProps;
  onFileUpload: (file: File) => void;
  maxFileSize?: number; // Max File size in MB, undefined for unlimited file size
  renderBoxContent?: ({
    fileError,
    open,
  }: {
    fileError?: ErrorCode | null | string;
    open: () => void;
  }) => React.ReactElement;
  resetErrorState?: boolean;
  uploadLoading?: boolean;
  uploadProgressPercentage?: number;
}

export const MFileDragDropUpload = ({
  accept = ['*'],
  label = 'Drag your file here',
  subtitle,
  onFileUpload,
  innerContainerProps,
  maxFileSize = 10,
  renderBoxContent,
  uploadLoading,
  uploadProgressPercentage,
  resetErrorState = false,
  ...restProps
}: MFileDragDropUploadProps) => {
  const [fileError, setFileError] = useState<ErrorCode | null | string>(null);

  useEffect(() => {
    resetErrorState && !!fileError && setFileError(null);
  }, [resetErrorState]);

  const onDrop = useCallback(
    async (files: File[], rejectedFiles: FileRejection[]) => {
      if (files.length) {
        setFileError(null);
        onFileUpload(files[0]);
      } else if (rejectedFiles.length) {
        setFileError(rejectedFiles[0].errors[0].code);
      }
    },
    [onFileUpload],
  );

  const acceptedTypes = accept.map((acc) => ACCEPT_FILES[acc]);

  const _accept: Accept = acceptedTypes.reduce((acc, key) => {
    key.accepted.forEach((it: any) => (acc[it] = []));
    return acc;
  }, {} as Accept);

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    accept: _accept,
    multiple: false,
    maxSize: maxFileSize * 1000000,
    onDrop,
  });

  const renderError = () => {
    if (!fileError) {
      return null;
    }
    return (
      <>
        {fileError === ErrorCode.FileTooLarge && (
          <MFlex alignItems="center" mt={2}>
            <MIcon as={MdInfo} mr={2} color="tRed.base" />
            <MText fontSize="xs" color="tRed.base" as="span">
              The file exceeds the maximum allowed size of {maxFileSize}MB
            </MText>
          </MFlex>
        )}
        {fileError === ErrorCode.FileInvalidType && (
          <MFlex alignItems="center" mt={2}>
            <MIcon as={MdInfo} mr={2} color="tRed.base" />
            <MText fontSize="xs" color="tRed.base" as="span">
              Invalid File Format. Supported type:{' '}
              {acceptedTypes.map((ac) => ac.label).join(', ')}
            </MText>
          </MFlex>
        )}
      </>
    );
  };
  const showDnDText = !renderBoxContent || (!!renderBoxContent && isDragActive);
  const overlayBoxProps: CenterProps = {};
  if (!!renderBoxContent && isDragActive) {
    overlayBoxProps.opacity = 0.8;
    overlayBoxProps.top = '0';
    overlayBoxProps.position = 'absolute';
    overlayBoxProps.bg = 'white';
    overlayBoxProps.width = '100%';
    overlayBoxProps.height = '100%';
  }
  return (
    <>
      <MBox
        borderRadius="3px"
        backgroundColor={isDragActive ? 'tBlue.hover' : 'transparent'}
        borderStyle="dashed"
        borderWidth="1px"
        borderColor={fileError ? 'tRed.base' : 'tGray.lightPurple'}
        position="relative"
        {...getRootProps({
          className: 'dropzone',
          onClick: (event) => event.stopPropagation(),
        })}
        {...restProps}
      >
        <input {...getInputProps()} />
        {!!renderBoxContent && renderBoxContent({ open, fileError })}

        {showDnDText && (
          <MCenter
            display="flex"
            flexDirection="column"
            {...overlayBoxProps}
            {...innerContainerProps}
          >
            <MBox fontSize="xs">
              {label} or{' '}
              <MLink textDecor="underline" fontSize="sm" onClick={open}>
                Browse
              </MLink>
            </MBox>

            {!!subtitle && (
              <MBox fontSize="xs" color="tGray.acGray">
                {subtitle}
              </MBox>
            )}

            <MBox>
              {!!maxFileSize && (
                <MText fontSize="xs" color="tGray.acGray" as="span" pr="2">
                  Max file size: {maxFileSize}MB.
                </MText>
              )}
              <MText fontSize="xs" color="tGray.acGray" as="span">
                Supported type: {acceptedTypes.map((ac) => ac.label).join(', ')}
              </MText>
            </MBox>
            {!renderBoxContent && renderError()}
          </MCenter>
        )}
      </MBox>

      {renderBoxContent && renderError()}
    </>
  );
};
