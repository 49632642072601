import { Button, ButtonProps, Icon, IconProps } from '@chakra-ui/react';
import React, { ElementType } from 'react';

export interface MCustomIconButtonProps extends ButtonProps {
  containerSize?: number | string;
  btnSize?: number | string;
  icon: ElementType;
  iconProps?: Omit<IconProps, 'css'>;
  iconStyle?: any;
  isLoading?: boolean;
  iconColor?: string;
  iconColorHover?: string;
}

const MCustomIconButton = React.forwardRef<any, MCustomIconButtonProps>(
  (
    {
      btnSize = 4,
      containerSize = 5,
      icon,
      iconProps,
      iconStyle,
      isLoading = false,
      iconColor = 'tPurple.base',
      iconColorHover = '',
      ...rest
    }: MCustomIconButtonProps,
    ref,
  ) => {
    return (
      <Button
        ref={ref}
        role="button"
        variant="icon"
        w={containerSize}
        h={containerSize}
        isLoading={isLoading}
        padding="0"
        minWidth="initial"
        _hover={{ backgroundColor: rest.isDisabled ? 'none' : 'tBlue.hover' }}
        {...rest}
      >
        <Icon
          as={icon}
          w={btnSize}
          h={btnSize}
          color={iconColor}
          style={iconStyle}
          _hover={{
            color: rest.isDisabled ? 'none' : iconColorHover,
          }}
          {...iconProps}
        />
      </Button>
    );
  },
);

export default MCustomIconButton;
