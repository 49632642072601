import { useEffect } from 'react';
import { handleApiErrorToast } from '~app/api/axios';
import {
  MBox,
  MPageContainer,
  MSettingsPageHeader,
  MSkeleton,
  MVStack,
} from '~app/components/Monetize';
import { useQuoteSettings } from '~app/hooks';
import { useDocumentHead } from '~app/services/documentHead';
import {
  IQuoteSettingsConfigSchema,
  QuoteSettingsDefaultAddressSourceEnum,
} from '~app/types';
import DefaultQuoteDocuments from './components/DefaultQuoteDocuments';
import { DefaultSigningContacts } from './components/DefaultSigningContacts';
import NetTerms from './components/NetTerms';
import { QuoteSettingApprovals } from './components/QuoteSettingApprovals';
import { QuoteSettingEndAction } from './components/QuoteSettingEndAction';
import { QuoteSettingGeneral } from './components/QuoteSettingGeneral';
import { QuoteSettingProcessing } from './components/QuoteSettingProcessing';

const QuoteSettings = () => {
  const { setDocTitle } = useDocumentHead();
  const { fetchLoading, quoteSettings, doSaveQuoteSettings, submitLoading } =
    useQuoteSettings();
  useEffect(() => {
    setDocTitle('Settings', 'Quote Settings');
  }, []);

  /**
   * Handle save and show error message if there is an error
   * Returns boolean if the save was unsuccessful
   */
  const handleSave = async (
    payload: IQuoteSettingsConfigSchema,
  ): Promise<void> => {
    try {
      await doSaveQuoteSettings({
        ...payload,
        defaultAddressSource:
          payload.defaultAddressSource ||
          QuoteSettingsDefaultAddressSourceEnum.CONTACT,
      });
    } catch (ex) {
      handleApiErrorToast(ex);
    }
  };

  return (
    <MPageContainer data-testid="quote-settings-list">
      <MSettingsPageHeader title="Quote Settings" />

      <MBox w={'100%'} mt={0} overflow="auto" mb="6">
        {fetchLoading && (
          <MVStack maxW="600px" spacing={4}>
            <MSkeleton height={50} width="100%" />
            <MSkeleton height={50} width="100%" />
            <MSkeleton height={50} width="100%" />
          </MVStack>
        )}
        {!fetchLoading && quoteSettings && (
          <MVStack maxW="600px" spacing={4}>
            <QuoteSettingGeneral
              quoteSettings={quoteSettings}
              isLoading={fetchLoading}
              onSubmit={(data) => handleSave({ ...quoteSettings, ...data })}
            />

            <DefaultQuoteDocuments
              isLoading={fetchLoading}
              defaultQuoteDocuments={quoteSettings?.defaultQuoteDocuments ?? []}
            />

            <DefaultSigningContacts />

            <NetTerms
              netTerms={quoteSettings?.netTerms!}
              onSave={(netTerms) => handleSave({ ...quoteSettings, netTerms })}
            />

            <QuoteSettingApprovals
              approvalData={{
                quoteApprovalNotes: quoteSettings?.quoteApprovalNotes,
              }}
              onSubmit={(quoteApproval) =>
                handleSave({ ...quoteSettings, ...quoteApproval })
              }
            />

            <QuoteSettingProcessing
              value={{
                pauseAtAccepted: quoteSettings.pauseAtAccepted,
                allowQuoteLevelOverrideOfStartDateSource:
                  quoteSettings?.allowQuoteLevelOverrideOfStartDateSource,
                defaultStartDateSource: quoteSettings?.defaultStartDateSource,
              }}
              isLoading={fetchLoading}
              onSubmit={(data) => handleSave({ ...quoteSettings, ...data })}
            />

            <QuoteSettingEndAction
              value={{
                contractRenewalTerms: quoteSettings?.contractRenewalTerms,
              }}
              isLoading={fetchLoading}
              onSubmit={(data: any) =>
                handleSave({
                  ...quoteSettings!,
                  ...data,
                })
              }
            />
          </MVStack>
        )}
      </MBox>
    </MPageContainer>
  );
};
export default QuoteSettings;
