import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosHeaders } from 'axios';
import { addDays } from 'date-fns/addDays';
import { formatISO } from 'date-fns/formatISO';
import { parseISO } from 'date-fns/parseISO';
import api, {
  apiDelete,
  apiGet,
  apiPost,
  apiPut,
  apiUpload,
  useAxios,
} from '~api/axios';
import { useFlags } from '~app/services/launchDarkly';
import { sortByProductObjType, sortByProductType } from '~app/utils';
import { orderObjectsBy } from '~app/utils/misc';
import { sortQuoteItems } from '~app/utils/quotes';
import {
  ApiListResponse,
  CollaborationAccessEnum,
  ContractEndActionEnum,
  GenericApiResponse,
  GetListApiConfig,
  GetListApiFilter,
  IBillingScheduleRespSchemaUI,
  IContract,
  IContractWithQuotes,
  IExtendedExpirationDateReqSchema,
  INewQuoteTypeReqSchema,
  IOpportunity,
  IOpportunityReqSchema,
  IOpportunityWithQuotes,
  IQuoteBasicRespSchema,
  IQuoteBillingScheduleRespSchema,
  IQuoteConditionalTermSchema,
  IQuoteContactAddressDataSchema,
  IQuoteContacts,
  IQuoteContactsRequest,
  IQuoteDiscount,
  IQuoteDiscounts,
  IQuoteOfferingRemoveReqSchema,
  IQuoteOfferingReqSchema,
  IQuoteOfferingRespSchema,
  IQuotePrice,
  IQuoteRequestSchema,
  IQuoteRespSchema,
  IQuoteReviewReq,
  IQuoteSigningOrderResp,
  IQuoteTemplateConfigSchema,
  IStorage,
  ISubscriptionListPricingResponseSchema,
  ISubscriptionsFormReq,
  OpportunityReqSchema,
  ProductTypeEnum,
  QuoteAmendmentVersionEnum,
  QuoteCancelationReasonEnum,
  QuoteItemAmendmentStatusEnum,
  QuoteOfferingGroupRemovalModeEnum,
  QuoteOfferingRemovalScopeEnum,
  ReOrderRequestTypes,
  SigningOrderEnum,
  StorageTypeEnum,
} from '~types';
import { ApiQueryItem } from './queryUtils';
import { updateListCacheWithUpdatedItem } from './queryUtilsHelpers';
import {
  asQueryUtil,
  composeGetQuery,
  getQuoteUpdateRequestFromQuote,
} from './utils';

export const cpqServiceQueryKeys = {
  base: ['cpq'] as const,
  contracts: () => [...cpqServiceQueryKeys.base, 'contracts'] as const,
  contractList: () => [...cpqServiceQueryKeys.contracts(), 'list'] as const,
  contractById: (id: string) =>
    [...cpqServiceQueryKeys.contracts(), id] as const,
  billingScheduleByContractId: (id: string) =>
    [...cpqServiceQueryKeys.contracts(), id, 'billing-schedule'] as const,
  opportunities: () => [...cpqServiceQueryKeys.base, 'opportunities'] as const,
  opportunityList: () =>
    [...cpqServiceQueryKeys.opportunities(), 'list'] as const,
  opportunityById: (id: string) =>
    [...cpqServiceQueryKeys.opportunities(), id] as const,
  quotes: () => [...cpqServiceQueryKeys.base, 'quotes'] as const,
  quotesById: (id: string) => [...cpqServiceQueryKeys.quotes(), id] as const,
  quotesBasic: () => [...cpqServiceQueryKeys.base, 'quotes-basic'] as const,
  quotesBasicList: () =>
    [...cpqServiceQueryKeys.quotesBasic(), 'list'] as const,
  quoteBillingSchedule: (quoteId: string) =>
    [...cpqServiceQueryKeys.quotesById(quoteId), 'billing-schedule'] as const,
  displayConfig: (quoteId: string) =>
    [...cpqServiceQueryKeys.quotesById(quoteId), 'display-config'] as const,
  quoteStorage: (quoteId: string) =>
    [...cpqServiceQueryKeys.quotes(), quoteId, 'storages'] as const,
  quoteStorageById: (quoteId: string, storageId: string) =>
    [...cpqServiceQueryKeys.quoteStorage(quoteId), storageId] as const,
  quotePrices: (quoteId: string) =>
    [...cpqServiceQueryKeys.quotes(), quoteId, 'prices'] as const,
  quoteDiscounts: (quoteId: string) =>
    [...cpqServiceQueryKeys.quotes(), quoteId, 'discounts'] as const,
  quoteTemplateHtml: (quoteId: string) =>
    [...cpqServiceQueryKeys.quotes(), quoteId, 'html'] as const,
};

const quotesKeys: ApiQueryItem = {
  byId: {
    endpoint: (id: string) => `/api/quotes/${id}`,
    queryKey: (id: string) => cpqServiceQueryKeys.quotesById(id),
  },
  create: {
    endpoint: () => `/api/quotes`,
    invalidateKeys: [cpqServiceQueryKeys.quotesBasicList()],
    setDataKey: (id: string) => cpqServiceQueryKeys.quotesById(id),
  },
  update: {
    endpoint: (id: string) => `/api/quotes/${id}`,
    invalidateKeys: [cpqServiceQueryKeys.quotesBasicList()],
    setDataKey: (id: string) => cpqServiceQueryKeys.quotesById(id),
  },
};

/** Quote list has a reduced payload for increased performance */
const quotesBasicKeys: ApiQueryItem = {
  list: {
    endpoint: `/api/quotes`,
    queryKey: cpqServiceQueryKeys.quotesBasicList(),
  },
};

export const CPQ_SERVICE_API = asQueryUtil({
  cpqServiceQuotes: quotesKeys,
  cpqServiceBasicQuotes: quotesBasicKeys,
});

export function useGetQuoteList(
  {
    config,
    filters,
    accountId,
  }: {
    config: GetListApiConfig;
    filters?: GetListApiFilter;
    accountId?: string;
  },
  options: {
    enabled?: boolean;
    onSuccess?: (data: ApiListResponse<IQuoteBasicRespSchema>) => void;
  } = {},
) {
  const params = composeGetQuery(config, filters);
  if (accountId) {
    params.accountId = accountId;
  }
  return useQuery<ApiListResponse<IQuoteBasicRespSchema>>(
    [...cpqServiceQueryKeys.quotesBasicList(), params],
    {
      queryFn: () =>
        apiGet<ApiListResponse<IQuoteBasicRespSchema>>('/api/quotes', {
          params,
        }).then((res) => res.data),
      ...options,
    },
  );
}

export async function doDeleteQuote(quoteId: string): Promise<any> {
  const response = await apiDelete<any>(`/api/quotes/${quoteId}`);
  return response.data;
}

// Ends

// subscriptions
export async function doGetSubscriptionPrices(
  signal: AbortSignal,
  payload: ISubscriptionsFormReq,
): Promise<ISubscriptionListPricingResponseSchema> {
  const res = await apiPost<ISubscriptionListPricingResponseSchema>(
    `/api/subscriptions/pricing`,
    payload,
    {
      signal,
    },
  );
  return res.data;
}

// quote apis
export async function doCreateQuote(
  payload: IQuoteRequestSchema,
): Promise<IQuoteRespSchema> {
  const res = await apiPost<any>(`/api/quotes`, payload);
  return res.data;
}

export async function doGetQuote(quoteId: string): Promise<IQuoteRespSchema> {
  const res = await api.get<IQuoteRespSchema>(`/api/quotes/${quoteId}`);
  return sortQuoteItems(res.data);
}

export async function doReviewQuote(
  quoteId: string,
  data?: IQuoteReviewReq,
): Promise<IQuoteRespSchema> {
  const res = await apiPost<IQuoteRespSchema>(
    `/api/quotes/${quoteId}/review`,
    data || {},
  );
  return sortQuoteItems(res.data);
}

export async function doUpdateQuote(
  quoteId: string,
  payload: IQuoteRequestSchema,
): Promise<IQuoteRespSchema> {
  const res = await apiPut<IQuoteRespSchema>(`/api/quotes/${quoteId}`, payload);
  return sortQuoteItems(res.data);
}
export async function doUpdateQuoteCollaborationAccess(
  quoteId: string,
  payload: { collaborationAccess: CollaborationAccessEnum },
) {
  await apiPut(`/api/quotes/${quoteId}/collaborationAccess`, payload);
}

export async function doEvaluateQuoteRules(
  quoteId: string,
): Promise<IQuoteRespSchema> {
  const res = await apiPost<IQuoteRespSchema>(
    `/api/quotes/${quoteId}/evaluateRules`,
  );
  return sortQuoteItems(res.data);
}

export async function doSendQuote(quoteId: string): Promise<IQuoteRespSchema> {
  const res = await apiPost<IQuoteRespSchema>(`/api/quotes/${quoteId}/send`);
  return sortQuoteItems(res.data);
}

export async function doEditQuote(quoteId: string): Promise<IQuoteRespSchema> {
  const res = await apiPost<IQuoteRespSchema>(`/api/quotes/${quoteId}/edit`);
  return sortQuoteItems(res.data);
}

export async function doRecreateQuote(
  quoteId: string,
): Promise<IQuoteRespSchema> {
  const res = await apiPost<IQuoteRespSchema>(
    `/api/quotes/${quoteId}/recreate`,
  );
  return sortQuoteItems(res.data);
}

export async function doReorderQuoteOfferings(
  quoteId: string,
  payload: ReOrderRequestTypes,
): Promise<IQuoteRespSchema> {
  const res = await apiPut<IQuoteRespSchema>(
    `/api/quotes/${quoteId}/quoteOfferings/reorder`,
    payload,
  );
  return sortQuoteItems(res.data);
}

export function useChangeQuoteOwner(
  options: {
    onSuccess?: (data: IQuoteRespSchema) => void;
    onError?: (data: unknown) => void;
  } = {},
) {
  const { onSuccess, ...restOptions } = options;
  return useMutation<
    IQuoteRespSchema,
    unknown,
    {
      quoteId: string;
      ownerId: string;
    }
  >(
    ({ quoteId, ownerId }) =>
      apiPost<IQuoteRespSchema>(
        `/api/quotes/${quoteId}/changeOwner/${ownerId}`,
      ).then((res) => sortQuoteItems(res.data)),
    {
      onSuccess: (data, { quoteId, ownerId }) => {
        onSuccess && onSuccess(data);
      },
      ...restOptions,
    },
  );
}

export function useGetQuoteBillingSchedule(
  quoteId: string,
  version: 'V1' | 'V2',
  options?: { onError?: (error: any) => void },
) {
  const url =
    version === 'V1'
      ? `/api/quotes/${quoteId}/billingSchedule`
      : `/api/quotes/${quoteId}/billingSchedule/diff`;
  return useQuery(
    [...cpqServiceQueryKeys.quoteBillingSchedule(quoteId), version],
    {
      queryFn: () =>
        apiGet<IQuoteBillingScheduleRespSchema>(url).then((res) => res.data),
      enabled: !!quoteId,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}

// Quotes Api

export async function doGetQuotesByAccount(
  accountId: string,
  config: GetListApiConfig,
  filters?: GetListApiFilter,
) {
  const params = composeGetQuery(config, filters);
  const res = await apiGet<ApiListResponse<IQuoteRespSchema>>(
    `/api/accounts/${accountId}/quotes`,
    { params },
  );
  return res.data;
}

export async function getContactByQuote(
  quoteId: string,
): Promise<IQuoteContacts> {
  const res = await apiGet<IQuoteContacts>(`/api/quotes/${quoteId}/contacts`);
  return res.data;
}

/** TODO: deprecate in favor of useUpdateContact from accountService and extend to handle both actions */
export async function updateContactByQuote(
  quoteId: string,
  payload: IQuoteContactsRequest,
): Promise<IQuoteContacts> {
  const res = await apiPut<IQuoteContacts>(
    `/api/quotes/${quoteId}/contacts`,
    payload,
  );
  return res.data;
}

export async function updateQuoteAddress(
  quoteId: string,
  payload: IQuoteContactAddressDataSchema,
): Promise<IQuoteContactAddressDataSchema> {
  const res = await apiPut<IQuoteContactAddressDataSchema>(
    `/api/quotes/${quoteId}/addresses`,
    payload,
  );
  return res.data;
}

export async function doChangeQuoteSigningOrder(
  quoteId: string,
  signingOrder: SigningOrderEnum,
): Promise<IQuoteSigningOrderResp> {
  const res = await apiPut<IQuoteSigningOrderResp>(
    `/api/quotes/${quoteId}/signingConfig`,
    {
      signingOrder,
    },
  );
  return res.data;
}

export async function doManuallyAcceptQuoteV2(
  quoteId: string,
  data: FormData,
): Promise<IQuoteRespSchema> {
  const res = await apiPost<IQuoteRespSchema>(
    `/api/v2/quotes/${quoteId}/accept`,
    data,
    {
      headers: new AxiosHeaders({
        'Content-Type': 'multipart/form-data',
      }),
    },
  );
  return sortQuoteItems(res.data);
}

export async function doProcessQuote(
  quoteId: string,
): Promise<IQuoteRespSchema> {
  await apiPost<IQuoteRespSchema>(`/api/quotes/${quoteId}/process`);
  return doGetQuote(quoteId);
}

export async function doUpdateDelayedBilling(
  quoteId: string,
  startDate: string,
): Promise<IQuoteRespSchema> {
  const res = await apiPost<IQuoteRespSchema>(
    `/api/quotes/${quoteId}/delayedBilling`,
    {
      startDate,
    },
  );
  return sortQuoteItems(res.data);
}

export async function doCancelQuoteService(
  quote: IQuoteBasicRespSchema & Partial<IQuoteRespSchema>,
  reason: QuoteCancelationReasonEnum,
): Promise<IQuoteRespSchema> {
  const res = await apiPost<IQuoteRespSchema>(
    `/api/quotes/${quote.id}/cancel`,
    quote,
    {
      params: { reason },
    },
  );
  return sortQuoteItems(res.data);
}

export async function doRetractQuoteService(
  quoteId: string,
): Promise<IQuoteRespSchema> {
  const res = await apiPost<IQuoteRespSchema>(`/api/quotes/${quoteId}/retract`);
  return sortQuoteItems(res.data);
}

export async function doArchiveQuoteService(quoteId: string) {
  await apiPut(`/api/quotes/${quoteId}/archive`);
}
/**
 * Allows adding multiple quote offerings (serially) to a quote and returns the result from the last call
 */
export function useBulkAddOfferingsToQuote(
  options: {
    onSuccess?: (data: IQuoteRespSchema) => void;
    onError?: (data: unknown) => void;
  } = {},
) {
  const { onSuccess, ...restOptions } = options;
  return useMutation<
    IQuoteRespSchema,
    unknown,
    {
      quoteId: string;
      quoteOfferings: IQuoteOfferingReqSchema[];
    }
  >(
    ({ quoteId, quoteOfferings }) => {
      return (async () => {
        let latestQuote: IQuoteRespSchema | null = null;
        for (const quoteOffering of quoteOfferings) {
          latestQuote = await apiPost(
            `/api/quotes/${quoteId}/quoteOfferings`,
            quoteOffering,
          ).then((res) => res.data);
        }
        if (!latestQuote) {
          throw new Error('Invalid payload');
        }
        return sortQuoteItems(latestQuote);
      })();
    },
    {
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
      ...restOptions,
    },
  );
}

export async function doCreateQuoteOffering(
  quoteId: string,
  payload: IQuoteOfferingReqSchema,
): Promise<IQuoteOfferingRespSchema> {
  const res = await apiPost<IQuoteOfferingRespSchema>(
    `/api/quotes/${quoteId}/quoteOfferings`,
    payload,
  );
  // Ensure quoteItems are sorted by product type so components do not need to worry about sorting
  res.data.items = sortByProductType(res.data.items);
  return res.data;
}

export async function doUpdateQuoteOffering(
  quoteId: string,
  quoteOfferingId: string,
  payload: IQuoteOfferingReqSchema,
): Promise<IQuoteOfferingRespSchema> {
  const res = await apiPut<IQuoteOfferingRespSchema>(
    `/api/quotes/${quoteId}/quoteOfferings/${quoteOfferingId}`,
    payload,
  );
  // Ensure quoteItems are sorted by product type so components do not need to worry about sorting
  res.data.items = sortByProductType(res.data.items);
  return res.data;
}

export async function doDeleteQuoteOffering(
  quoteId: string,
  quoteOfferingId: string,
): Promise<GenericApiResponse> {
  const res = await apiDelete<GenericApiResponse>(
    `/api/quotes/${quoteId}/quoteOfferings/${quoteOfferingId}`,
  );
  return res.data;
}

export async function doRemoveQuoteOffering(
  quoteId: string,
  payload: IQuoteOfferingRemoveReqSchema,
): Promise<IQuoteRespSchema> {
  const res = await apiPost<IQuoteRespSchema>(
    `/api/quotes/${quoteId}/quoteOfferings/remove`,
    payload,
  );
  return sortQuoteItems(res.data);
}

export async function doRevertQuoteOffering(
  quoteId: string,
  quoteOfferingId: string,
): Promise<IQuoteRespSchema> {
  const res = await apiPost<IQuoteRespSchema>(
    `/api/quotes/${quoteId}/quoteOfferings/${quoteOfferingId}/revert`,
  );
  return sortQuoteItems(res.data);
}

export const doUploadQuoteDocument = async (
  quoteId: string,
  file: File,
  type = StorageTypeEnum.MSA,
) => {
  const formData = new FormData();
  formData.append('file', file);

  const res = await apiUpload<IStorage>(
    `/api/quotes/${quoteId}/upload?type=${type}`,
    formData,
  );
  return res.data;
};

export function useGetQuoteDisplayConfig(
  quoteId: string,
  options: {
    enabled?: boolean;
    onSuccess?: (data: IQuoteTemplateConfigSchema) => void;
  } = {},
) {
  return useQuery([...cpqServiceQueryKeys.displayConfig(quoteId)], {
    queryFn: () =>
      apiGet<IQuoteTemplateConfigSchema>(
        `/api/quotes/${quoteId}/displayConfig`,
      ).then((res) => res.data),
    refetchOnWindowFocus: false,
    ...options,
  });
}

export function useUpdateQuoteDisplayConfig(
  options: {
    onSuccess?: (data: IQuoteTemplateConfigSchema) => void;
    onError?: (data: unknown) => void;
  } = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;
  return useMutation<
    IQuoteTemplateConfigSchema,
    unknown,
    {
      quoteId: string;
      payload: IQuoteTemplateConfigSchema;
    }
  >(
    ({ quoteId, payload }) =>
      apiPut<IQuoteTemplateConfigSchema>(
        `/api/quotes/${quoteId}/displayConfig`,
        {
          defaultTemplate: 'QUOTE_DEFAULT_1', // FALLBACK TO DEFAULT TEMPLATE UNTIL BE HAS THIS DATA
          ...payload,
        },
      ).then((res) => res.data),
    {
      onSuccess: (data, { quoteId }) => {
        queryClient.setQueryData(
          [...cpqServiceQueryKeys.displayConfig(quoteId)],
          data,
        );
        onSuccess && onSuccess(data);
      },
      ...restOptions,
    },
  );
}

export function useExtendExpirationDate(
  options: {
    onSuccess?: (data: IQuoteRespSchema) => void;
    onError?: (data: unknown) => void;
  } = {},
) {
  return useMutation<
    IQuoteRespSchema,
    unknown,
    {
      quoteId: string;
      payload: IExtendedExpirationDateReqSchema;
    }
  >(
    ({ quoteId, payload }) =>
      apiPut<IQuoteRespSchema>(
        `/api/quotes/${quoteId}/extendExpirationDate`,
        undefined,
        { params: payload },
      ).then((res) => sortQuoteItems(res.data)),
    {
      ...options,
    },
  );
}

export function useUpdateNewQuoteType(
  options: {
    onSuccess?: (data: IQuoteRespSchema) => void;
    onError?: (data: unknown) => void;
  } = {},
) {
  return useMutation<
    IQuoteRespSchema,
    unknown,
    {
      quoteId: string;
      payload: INewQuoteTypeReqSchema;
    }
  >(
    ({ quoteId, payload }) =>
      apiPut<IQuoteRespSchema>(
        `/api/quotes/${quoteId}/newQuoteType`,
        payload,
      ).then((res) => sortQuoteItems(res.data)),
    {
      ...options,
    },
  );
}

// OPPORTUNITIES
export function useGetOpportunities({
  config,
  filters,
  url = '/api/opportunities',
}: {
  config: GetListApiConfig;
  filters?: GetListApiFilter;
  url?: string;
}) {
  const params = composeGetQuery(config, filters);
  return useQuery<ApiListResponse<IOpportunity>>(
    [...cpqServiceQueryKeys.opportunityList(), url, params],
    {
      queryFn: () =>
        apiGet<ApiListResponse<IOpportunity>>(url, {
          params,
        }).then((res) => res.data),
    },
  );
}

export function useGetOpportunityById(
  opportunityId: string,
  options: {
    enabled?: boolean;
    onSuccess?: (data: IOpportunityWithQuotes) => void;
    onError?: (data: unknown) => void;
  } = {},
) {
  return useQuery<IOpportunityWithQuotes>(
    cpqServiceQueryKeys.opportunityById(opportunityId),
    {
      queryFn: () =>
        apiGet<IOpportunityWithQuotes>(`/api/opportunities/${opportunityId}`)
          .then((res) => res.data)
          .then((opportunity) => ({
            ...opportunity,
            quotes: orderObjectsBy(
              opportunity.quotes || [],
              ['modifyDate'],
              ['desc', 'desc'],
            ),
          })),
      ...options,
    },
  );
}

/**
 * Update customId on Opportunity
 */
export function useLinkOpportunityToCrm(
  options: {
    onError?: (err: unknown) => void;
    onSuccess?: (data: IOpportunityWithQuotes) => void;
  } = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;
  return useMutation<
    IOpportunityWithQuotes,
    unknown,
    { opportunityId: string; customId: string | null }
  >({
    mutationFn: ({ opportunityId, customId }) =>
      apiPut<IOpportunityWithQuotes>(`/api/opportunities/${opportunityId}`, {
        customId,
      }).then((res) => res.data),
    onSuccess: (data) => {
      queryClient.setQueryData(
        [...cpqServiceQueryKeys.opportunityById(data.id)],
        data,
      );
      data.quotes?.map((quote) =>
        queryClient.invalidateQueries([
          ...cpqServiceQueryKeys.quotesById(quote.id),
        ]),
      );
      onSuccess && onSuccess(data);
    },
    ...restOptions,
  });
}

// /api/opportunities/customId/{id}
export function getOpportunityByCustomId(customId: string) {
  return apiGet<IOpportunityWithQuotes>(
    `/api/opportunities/customId/${customId}`,
  ).then((res) => res.data);
}

export const doGetOrCreateOpportunity = async (
  customId: string,
  createPayload: any,
) => {
  return getOpportunityByCustomId(customId).catch((ex) =>
    apiPost(
      `/api/opportunities`,
      OpportunityReqSchema.parse(createPayload),
    ).then((res) => res.data),
  );
};

/**
 * If an opportunity with the customId exists, then use it, otherwise create a new opportunity
 */
export function useGetOrCreateOpportunityWithCrmLink(
  options: {
    onSuccess?: (data: IOpportunityWithQuotes) => void;
    onError?: (data: unknown) => void;
  } = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;
  return useMutation<
    IOpportunityWithQuotes,
    unknown,
    {
      customId: string;
      createPayload: IOpportunityReqSchema;
    }
  >(
    ({ createPayload, customId }) => {
      return getOpportunityByCustomId(customId)
        .then((opportunity) => opportunity)
        .catch((ex) =>
          apiPost(
            `/api/opportunities`,
            OpportunityReqSchema.parse(createPayload),
          ).then((res) => res.data),
        );
    },
    {
      onSuccess: (data, opportunity) => {
        queryClient.invalidateQueries(cpqServiceQueryKeys.opportunityList());
        queryClient.setQueryData(
          cpqServiceQueryKeys.opportunityById(data.id),
          opportunity,
        );
        onSuccess && onSuccess(data);
      },
      ...restOptions,
    },
  );
}

/**
 * Create a new opportunity
 */
export function useCreateOpportunity(
  options: {
    onSuccess?: (data: IOpportunityWithQuotes) => void;
    onError?: (data: unknown) => void;
  } = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;
  return useMutation<IOpportunityWithQuotes, unknown, IOpportunityReqSchema>(
    (opportunity) =>
      apiPost(
        `/api/opportunities`,
        OpportunityReqSchema.parse(opportunity),
      ).then((res) => res.data),
    {
      onSuccess: (data, opportunity) => {
        queryClient.invalidateQueries(cpqServiceQueryKeys.opportunityList());
        queryClient.setQueryData(
          cpqServiceQueryKeys.opportunityById(data.id),
          opportunity,
        );
        onSuccess && onSuccess(data);
      },
      ...restOptions,
    },
  );
}

export function useUpdateOpportunityQuote(
  options: {
    onSuccess?: (data: undefined) => void; //TODO: Should update types in future when BE finally return some content
    onError?: (data: unknown) => void;
  } = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;
  return useMutation<
    undefined,
    unknown,
    {
      opportunityId: string;
      quoteId: string;
    }
  >(
    ({ opportunityId, quoteId }) =>
      apiPut(
        `/api/opportunities/${opportunityId}/quotes/${quoteId}/setPrimary`,
      ).then((res) => res.data),
    {
      onSuccess: (data, { opportunityId }) => {
        queryClient.invalidateQueries(cpqServiceQueryKeys.opportunityList());
        queryClient.invalidateQueries(
          cpqServiceQueryKeys.opportunityById(opportunityId),
        );
        onSuccess && onSuccess(data);
      },
      ...restOptions,
    },
  );
}

// CONTRACTS
export function useGetContracts<
  T = ApiListResponse<IContract>,
  SelectData = ApiListResponse<IContract>,
>(
  {
    config,
    filters,
    url = '/api/contracts',
  }: {
    config: GetListApiConfig;
    filters?: GetListApiFilter;
    url?: string;
  },
  options: UseQueryOptions<T, unknown, SelectData> = {},
) {
  const params = composeGetQuery(config, filters);
  return useQuery<T, unknown, SelectData>(
    [...cpqServiceQueryKeys.contractList(), url, params],
    {
      queryFn: () =>
        apiGet<T>(url, {
          params,
        }).then((res) => res.data),
      ...options,
    },
  );
}

export function useUpdateContractById(
  options: Partial<
    UseMutationOptions<
      IContractWithQuotes,
      unknown,
      { id: string; payload: { endAction: ContractEndActionEnum } }
    >
  > = {},
) {
  const { onSuccess, ...restOptions } = options;
  const queryClient = useQueryClient();

  return useMutation<
    IContractWithQuotes,
    unknown,
    { id: string; payload: { endAction: ContractEndActionEnum } }
  >(
    ({ id, payload }) =>
      apiPut<IContractWithQuotes>(`/api/contracts/${id}`, payload).then(
        (res) => res.data,
      ),
    {
      onSuccess: (data, variables, context) => {
        updateListCacheWithUpdatedItem(
          queryClient,
          [...cpqServiceQueryKeys.contractList()],
          data,
          true,
        );
        queryClient.invalidateQueries([
          ...cpqServiceQueryKeys.contractById(data.id),
        ]);
        onSuccess?.(data, variables, context);
      },
      ...restOptions,
    },
  );
}
export async function doGetContractsByAccount(
  accountId: string,
  config: GetListApiConfig,
  filters?: GetListApiFilter,
): Promise<ApiListResponse<IContract>> {
  const params = composeGetQuery(config, filters);
  const res = await apiGet<ApiListResponse<IContract>>(
    `/api/accounts/${accountId}/contracts`,
    { params },
  );
  return res.data;
}

export async function doGetContract(
  contractId: string,
): Promise<IContractWithQuotes> {
  const res = await apiGet<IContractWithQuotes>(`/api/contracts/${contractId}`);
  return res.data;
}

export function useGetContractById(
  contractId: string,
  options: {
    enabled?: boolean;
    onSuccess?: (data: IContractWithQuotes) => void;
    onError?: (data: unknown) => void;
  } = {},
) {
  return useQuery<IContractWithQuotes>(
    cpqServiceQueryKeys.contractById(contractId),
    {
      queryFn: () =>
        apiGet<IContractWithQuotes>(`/api/contracts/${contractId}`).then(
          (res) => res.data,
        ),
      ...options,
    },
  );
}

export async function doAmendContract(
  contractId: string,
  opportunity?: Partial<Pick<IOpportunity, 'id' | 'name' | 'customId'>>,
  amendmentDate?: string,
) {
  // optionally allow providing existing fields on the opportunity that will be created
  const payload =
    opportunity &&
    (opportunity.id || (opportunity.customId && opportunity.name))
      ? { opportunity }
      : {};
  const res = await apiPost<IQuoteRespSchema>(
    `/api/contracts/${contractId}/amend`,
    payload,
    { params: { amendmentDate } },
  );
  return sortQuoteItems(res.data);
}

export async function doGetBillingScheduleByContractId(
  contractId: string,
): Promise<IBillingScheduleRespSchemaUI> {
  const res = await apiGet<IBillingScheduleRespSchemaUI>(
    `/api/contracts/${contractId}/billingSchedule`,
  );
  return {
    ...res.data,
    usageProductExists: res.data.periods.some(({ items }) =>
      items.some(({ productType }) => productType === ProductTypeEnum.USAGE),
    ),
    periods: res.data.periods.map((data, index) => {
      const periodUId = data.invoiceId
        ? data.invoiceId
        : `${data.invoiceDate}${index}`;
      return {
        ...data,
        uid: periodUId,
        items: data.items.map((item, index) => ({
          ...item,
          uid: `${periodUId}${item.pricePerUnit}${index}`,
        })),
        usageProductExists: data.items.some(
          ({ productType }) => productType === ProductTypeEnum.USAGE,
        ),
      };
    }),
  };
}

/**
 * Performs multiple API requests to cancel a contract
 * Amend contract as of specified date
 */
export function useCancelContract() {
  const queryClient = useQueryClient();

  return useMutation<
    IQuoteRespSchema,
    unknown,
    { contractId: string; amendmentEffectiveDate: string; ownerId: string }
  >(async ({ amendmentEffectiveDate, contractId, ownerId }) => {
    // Firstly Amend the contract to get the amended quote response
    let quote = await doAmendContract(
      contractId,
      undefined,
      amendmentEffectiveDate,
    );
    queryClient.setQueryData(cpqServiceQueryKeys.quotesById(quote.id), quote);

    // Update the quote description to reflect the cancellation
    quote = await doUpdateQuote(
      quote.id,
      getQuoteUpdateRequestFromQuote(quote, {
        description: `Cancel Contract for ${quote.accountName}`,
      }),
    );
    queryClient.setQueryData(cpqServiceQueryKeys.quotesById(quote.id), quote);

    if (quote.amendmentVersion === QuoteAmendmentVersionEnum.v1) {
      // Delete all offerings on the quote
      for (const { id: quoteOfferingId } of quote.quoteOfferings) {
        await doDeleteQuoteOffering(quote.id, quoteOfferingId);
      }
    } else if (quote.amendmentVersion === QuoteAmendmentVersionEnum.v2) {
      for (const quoteOffering of quote.quoteOfferings) {
        // skip child offerings
        if (quoteOffering.parentQuoteOfferingId) {
          continue;
        }

        // Skip onetime offerings that were already part of the amendment
        const isOnetimeOfferingOnAmendmentThatAlreadyExists =
          quoteOffering.items.every(
            ({ productType, amendmentStatus }) =>
              (productType === ProductTypeEnum.ONETIME ||
                productType === ProductTypeEnum.ONETIME_PREPAID_CREDIT) &&
              amendmentStatus !== QuoteItemAmendmentStatusEnum.ADDED,
          );
        if (isOnetimeOfferingOnAmendmentThatAlreadyExists) {
          continue;
        }

        // Offering is eligible for cancellation
        await doRemoveQuoteOffering(quote.id, {
          removal_scope: QuoteOfferingRemovalScopeEnum.GROUP,
          group_removal_mode: QuoteOfferingGroupRemovalModeEnum.TARGET_DATE,
          group_id: quoteOffering.id,
          end_date: formatISO(addDays(parseISO(amendmentEffectiveDate), -1), {
            representation: 'date',
          }),
        });
      }
    }

    // Fetch the latest state of the quote and add to query cache
    quote = await doGetQuote(quote.id);
    queryClient.setQueryData(cpqServiceQueryKeys.quotesById(quote.id), quote);

    return quote;
  });
}

export async function doRenewContract(
  contractId: string,
  opportunity?: Partial<Pick<IOpportunity, 'id' | 'name' | 'customId'>>,
) {
  // optionally allow providing existing fields on the opportunity that will be created
  const payload =
    opportunity &&
    (opportunity.id || (opportunity.customId && opportunity.name))
      ? { opportunity }
      : {};
  const res = await apiPost<IQuoteRespSchema>(
    `/api/contracts/${contractId}/renew`,
    payload,
  );
  return sortQuoteItems(res.data);
}

export function useChangeContractOwner(
  options: {
    onSuccess?: (data: IContractWithQuotes) => void;
    onError?: (data: unknown) => void;
  } = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;
  return useMutation<
    IContractWithQuotes,
    unknown,
    {
      contractId: string;
      userId: string;
    }
  >(
    ({ contractId, userId }) =>
      apiPost(`/api/contracts/${contractId}/changeOwner/${userId}`).then(
        (res) => res.data,
      ),
    {
      onSuccess: (data, { contractId }) => {
        queryClient.setQueryData(
          cpqServiceQueryKeys.contractById(contractId),
          data,
        );
        updateListCacheWithUpdatedItem(
          queryClient,
          cpqServiceQueryKeys.contractList(),
          data,
        );

        onSuccess && onSuccess(data);
      },
      ...restOptions,
    },
  );
}

// Storages
export const doGetQuoteStorageById = async (
  quoteId: string,
  storageId: string,
  params?: any,
) => {
  const res = await apiGet<IStorage>(
    `/api/quotes/${quoteId}/storages/${storageId}`,
    {
      params,
    },
  );
  return res.data;
};

export function useGetQuoteStorageById(
  {
    quoteId,
    storageId,
    params,
  }: {
    quoteId: string;
    storageId: string;
    params?: any;
  },
  options: {
    enabled?: boolean;
    onSuccess?: (data: IStorage) => void;
    onError?: (error: unknown) => void;
  } = {},
) {
  return useQuery(
    [...cpqServiceQueryKeys.quoteStorageById(quoteId, storageId), params],
    {
      queryFn: () =>
        apiGet<IStorage>(`/api/quotes/${quoteId}/storages/${storageId}`, {
          params,
        }).then((res) => res.data),
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 10, // 10 minutes
      staleTime: 1000 * 60 * 5, // 5 minutes
      ...options,
    },
  );
}

export const doDeleteQuoteStorageById = async (
  quoteId?: string,
  storageId?: string,
  params?: { type: StorageTypeEnum },
) => {
  await apiDelete<void>(`/api/quotes/${quoteId}/storages/${storageId}`, {
    params,
  });
};

export const doPrintQuoteToPdf = async (quoteId: string, useV2: boolean) => {
  const url = useV2
    ? `/api/v2/quotes/${quoteId}/print`
    : `/api/quotes/${quoteId}/print`;
  const res = await apiGet<ArrayBuffer>(url, {
    responseType: 'arraybuffer',
    headers: new AxiosHeaders({
      accept: 'application/pdf',
    }),
  });
  return res.data;
};

export const usePrintQuoteToHtml = (
  {
    quoteId,
    displayConfig,
    lastModifiedTimestamps = [],
  }: {
    quoteId: string;
    /** Used for caching response and to trigger re-fetch */
    displayConfig: IQuoteTemplateConfigSchema;
    /** Used for caching response */
    lastModifiedTimestamps?: string[];
  },
  options: {
    enabled?: boolean;
    onSuccess?: (data: string) => void;
    onError?: (error: unknown) => void;
  } = {},
) => {
  const { useQuotePdfV2 } = useFlags();
  const url = useQuotePdfV2
    ? `/api/v2/quotes/${quoteId}/print`
    : `/api/quotes/${quoteId}/print`;
  return useQuery(
    [
      ...cpqServiceQueryKeys.quoteTemplateHtml(quoteId),
      displayConfig,
      ...lastModifiedTimestamps,
    ],
    {
      queryFn: () =>
        apiGet<string>(url, {
          responseType: 'text',
          headers: new AxiosHeaders({
            accept: 'text/html',
          }),
        }).then((res) => res.data),
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
      cacheTime: 1000 * 60 * 10, // 10 minutes
      staleTime: 1000 * 60 * 5, // 5 minutes
      ...options,
    },
  );
};

export const doCloneQuote = async (quoteId: string) => {
  const res = await apiPost<IQuoteRespSchema>(`/api/quotes/${quoteId}/copy`);
  return res.data;
};

export const getQuoteDiscounts = (quoteId: string) => {
  // FIXME: this should not use a hook outside of react component
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [{ data, loading }, run] = useAxios<{
    discounts: IQuoteDiscount[];
    total: number;
  }>(`/api/quotes/${quoteId}/discounts`);

  return { data, loading, run };
};

export const doGetQuotePrices = async (quoteId: string) => {
  const res = await apiGet<{ quoteOfferingPrices: IQuotePrice[] }>(
    `/api/quotes/${quoteId}/prices`,
  );
  return res.data.quoteOfferingPrices;
};

export function useGetQuoteDiscounts(
  {
    quoteId,
    queryKeys = [],
  }: {
    quoteId: string;
    /** Optional additional query keys to add for caching */
    queryKeys?: unknown[];
  },
  options: {
    enabled?: boolean;
    onSuccess?: (data: IQuoteDiscounts) => void;
  } = {},
) {
  return useQuery<IQuoteDiscounts>(
    [...cpqServiceQueryKeys.quoteDiscounts(quoteId), ...queryKeys],
    {
      queryFn: () =>
        apiGet<IQuoteDiscounts>(`/api/quotes/${quoteId}/discounts`).then(
          (res) => res.data,
        ),
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5, // 5 minutes
      ...options,
    },
  );
}

export function useGetQuotePrices(
  quoteId: string,
  options: {
    enabled?: boolean;
    onSuccess?: (data: IQuotePrice[]) => void;
  } = {},
) {
  return useQuery([...cpqServiceQueryKeys.quotePrices(quoteId)], {
    queryFn: ({}) =>
      apiGet<{ quoteOfferingPrices: IQuotePrice[] }>(
        `/api/quotes/${quoteId}/prices`,
        { params: { applyDisplayConfiguration: true } },
      ).then((res) =>
        (res.data?.quoteOfferingPrices || []).map(
          (quotePrice: IQuotePrice): IQuotePrice => {
            return {
              ...quotePrice,
              schedule: quotePrice.schedule.map((scheduleItem) => {
                return {
                  ...scheduleItem,
                  productPrices: sortByProductObjType(
                    scheduleItem.productPrices,
                    'description',
                  ),
                };
              }),
            };
          },
        ),
      ),
    refetchOnWindowFocus: false,
    ...options,
  });
}

export const doUpdateQuoteConditionalTerm = async (
  quoteId: string,
  conTermId: string,
  payload: { terms: string },
) => {
  const resp = await apiPut<IQuoteConditionalTermSchema>(
    `/api/quotes/${quoteId}/conditionalTerms/${conTermId}`,
    payload,
  );
  return resp.data;
};

export const doRevertQuoteConditionalTerm = async (
  quoteId: string,
  conTermId: string,
) => {
  const resp = await apiPut<IQuoteConditionalTermSchema>(
    `/api/quotes/${quoteId}/conditionalTerms/${conTermId}/revert`,
  );
  return resp.data;
};
