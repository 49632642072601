import {
  MAccordion,
  MAccordionCustomButton,
  MBox,
  MFormField,
  MRadio,
  MRadioGroup,
  MText,
} from '~app/components/Monetize';
import { MSettingAccordionItem } from '../../../../../components/Monetize/MSettingAccordionItem';
import { useFlags } from '../../../../../services/launchDarkly';
import { IQuoteSettingsConfigSchema } from '../../../../../types';
import { AddressSourceSetting } from './AddressSourceSetting';
import { ContractSettingSection } from './ContractSettingSection';
import { SettingsToggle } from './SettingsToggle';

interface QuoteSettingGeneralProps {
  quoteSettings: IQuoteSettingsConfigSchema;
  onSubmit: (data: Partial<IQuoteSettingsConfigSchema>) => Promise<void>;
  isLoading: boolean;
}

export const QuoteSettingGeneral = ({
  quoteSettings,
  onSubmit,
  isLoading,
}: QuoteSettingGeneralProps) => {
  const { enableAccountBasedAddresses } = useFlags();

  return (
    <MAccordion allowMultiple data-testid="quote-setting-general" w="100%">
      <MSettingAccordionItem
        renderAccordionButton={({ isExpanded }: { isExpanded: boolean }) => (
          <MAccordionCustomButton isExpanded={isExpanded} label="General" />
        )}
      >
        <MBox mt="4">
          <ContractSettingSection label="Length">
            <MText mb="2">
              Select the contract length unit of measurement:
            </MText>
            <MFormField onClick={(ev) => ev.stopPropagation()} w="auto">
              <MRadioGroup
                onChange={(val: string) =>
                  onSubmit({
                    customContractLength: {
                      allowCustomLength: val === 'true' ? true : false,
                    },
                  })
                }
                display="flex"
                flexDirection="column"
                rowGap={2}
                value={
                  quoteSettings?.customContractLength.allowCustomLength
                    ? 'true'
                    : 'false'
                }
              >
                <MRadio value="true">
                  Any number of months (e.g. 15 months)
                </MRadio>
                <MRadio value="false">
                  Whole years only (1 year, 2 year, etc.)
                </MRadio>
              </MRadioGroup>
            </MFormField>
          </ContractSettingSection>

          <ContractSettingSection>
            {enableAccountBasedAddresses && (
              <AddressSourceSetting
                isLoading={isLoading}
                value={quoteSettings?.defaultAddressSource}
                onChange={(defaultAddressSource) =>
                  onSubmit({
                    defaultAddressSource,
                  })
                }
              />
            )}
          </ContractSettingSection>

          <ContractSettingSection showDivider={false}>
            <SettingsToggle
              name="allowPONumberOnQuotes"
              heading="Allow Purchase Order (PO) Number"
              subheading="Allow users to add a PO number on quote."
              fetchLoading={isLoading}
              value={quoteSettings?.allowPONumberOnQuotes!}
              onChange={(allowPONumberOnQuotes) =>
                onSubmit({
                  allowPONumberOnQuotes,
                })
              }
            />
          </ContractSettingSection>
        </MBox>
      </MSettingAccordionItem>
    </MAccordion>
  );
};
