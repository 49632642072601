// eslint-disable-next-line no-restricted-imports
import {
  useLDClient,
  useFlags as useLDFlags,
} from 'launchdarkly-react-client-sdk';
import { ENVIRONMENT } from '~app/config';
import { ITenant, IUser, Maybe, UserRoleEnum } from '~app/types';
import { logger } from './logger';

export type QuoteTemplates = {
  QUOTE_AFFINITY: boolean;
  QUOTE_AFFINITY_MARCH_2024: boolean;
  QUOTE_DEFAULT_1: boolean;
  QUOTE_DEFAULT_2: boolean;
  QUOTE_DEFAULT_3_EXCL_VAT: boolean;
  QUOTE_MATERIALIZE: boolean;
  QUOTE_MIDDESK: boolean;
  QUOTE_AFFINITY_MARCH_2024_TEST_V2: boolean;
  QUOTE_DEFAULT_1_TEST_V2: boolean;
  QUOTE_DEFAULT_2_TEST_V2: boolean;
  QUOTE_DEFAULT_3_EXCL_VAT_TEST_V2: boolean;
  QUOTE_MATERIALIZE_TEST_V2: boolean;
  QUOTE_AFFINITY_TEST_V2: boolean;
  QUOTE_MIDDESK_TEST_V2: boolean;
  QUOTE_PLOTLINE: boolean;
  QUOTE_DANFOSS_EU: boolean;
  QUOTE_DANFOSS_US: boolean;
};

export type OfferingDropdownSortingType =
  | 'groupByType'
  | 'alphabetical'
  | 'groupByTypeNoHeader'
  | 'customId';

export type FeatureFlag = {
  allowTenantDeletion: boolean;
  teleportSalesDemoTempDependentPricingConfig?: {
    derivedQuantity?: Record<
      string,
      {
        sourceProduct: string;
        quantityMultiplier: number;
      }
    >;
  };
  salesDemoMedinsightTempFormulaCustomPricing?: {
    formulaCustomQty?: {
      description?: string;
      dependentProduct: `prod_${string}`;
      formulaProduct: `prod_${string}`;
      offering: `offr_${string}`;
    };
  };
  salesDemoMedinsightTempGuidedQuotingSpecialConfig?: {
    defaultSelectedOfferings?: Record<
      string,
      Record<string, { offeringIds: string[] }>
    >;
    quantityMapping?: { questionId: string; productIds: string[] };
    defaultQuantities?: Record<string, number>;
    lockQuantityEdit?: { productIds: string[] };
    preSelectOptionalProducts?: { productIds: string[] };
  };
  billRun: boolean;
  quoteAcceptanceAllowedRoles: readonly ['ALL'] | readonly UserRoleEnum[];
  newApplicationOfPaymentCredit: boolean;
  showSettingFirstInvoiceCheckbox: boolean;
  customRates: boolean;
  scheduledChanges: boolean;
  createProductionTenant: boolean;
  metabaseReporting: boolean;
  revenueRecognition: boolean;
  accountingSettings: boolean;
  quoteOfferingDateAndScheduledChangeRate: boolean;
  pdfCoverFooter: boolean;
  customFields: boolean;
  crmFieldMapping: boolean;
  hideBillingSchedule: boolean;
  hideAutoRenewal: boolean;
  totalExclVat: boolean;
  preventContactCreate: boolean;
  preventContactEdit: boolean;
  conditionalQuoteTerms: boolean;
  percentOfTotal: boolean;
  allowInvoiceDelayInPast: boolean;
  showCalendarMonthProration: boolean;
  disableProrationMethod: boolean;
  quoteTemplates: QuoteTemplates;
  subscriptionFrequencyAdditionalOptions: boolean;
  showQuoteOfferingDescription: boolean;
  crmFieldMappingSfdc: boolean;
  lockPriceModelUpdateOnQuote: boolean;
  taxAnrok: boolean;
  taxAvalara: boolean;
  disableInactiveProductBanner: boolean;
  quoteInternalExternalView: boolean;
  showQuotableField: boolean;
  hideMarkAsRenewal: boolean;
  /**
   * -1 indicates no limit
   * any other positive number indicates the limit where electronic payment is allowed
   */
  anonymousInvoiceAllowPaymentAmount: -1 | number;
  allowMinCommitAdditionalFrequencies: boolean;
  onetimeInvoice: boolean;
  netsuite: boolean;
  enableAccountBasedAddresses: boolean;
  offeringDropdownSorting: OfferingDropdownSortingType;
  dunningEnabled: boolean;
  dunningEmailTemplates: { label: string; value: string }[];
  allowRefunds: boolean;
  isAppDocsSearch: boolean;
  globalAccountSearch: boolean;
  allowPartialPayment: boolean;
  allowChangingQuoteOfferingStartAndEndDate: boolean;
  allowTimezoneModification: boolean;
  showCreditAndRebill: boolean;
  allowOptionalProducts: boolean;
  allowManualPaymentDateModification: boolean;
  allowBillGroupCreation: boolean;
  enableUsBankTransfer: boolean;
  useQuotePdfV2: boolean;
  useQuoteEditV2: boolean;
  useWhatsChanged: boolean;
  useDelayedBilling: boolean;
  guidedOnboardingEnabled: boolean;
  showPricingLevelConfigure: boolean;
  guidedSelling: boolean;
  guidedSellingViaRulesService: boolean;
  allowNetsuiteFieldMapping: boolean;
  guidedQuoteOfferingSelection: boolean;
  allowBillingScheduleV2: boolean;
  showUsageTabOnAccount: boolean;
  showSubscriptionOnAccount: boolean;
  invoiceEmailFromCustomizationEnabled: boolean;
  lockFinalizedInvoiceData: boolean;
  allowCreditAndCreditNoteVoid: boolean;
  enableBillingSettings: boolean;
};

export const ENABLE_LAUNCHDARKLY = !ENVIRONMENT.IS_LOCAL_DEV;

/**
 * Initialize LaunchDarkly context
 * For dev, localhost, and preview environments we use one shared context
 *
 * @param ldClient
 * @param currentTenantUser
 * @param currentTenant
 */
export const initLaunchDarklyContext = async (
  ldClient: ReturnType<typeof useLDClient>,
  currentTenantUser: Maybe<IUser>,
  currentTenant: Maybe<ITenant>,
) => {
  try {
    if (
      !ENABLE_LAUNCHDARKLY ||
      !currentTenantUser ||
      !ldClient ||
      !currentTenant
    ) {
      return;
    }
    // Shared context for CI / Playwright to limit usage
    if (ENVIRONMENT.IS_PLAYWRIGHT) {
      await ldClient.identify({
        kind: 'user',
        key: 'SHARED_DEV_USER',
        anonymous: false,
        name: 'SHARED_DEV_USER',
        email: 'everyone@monetizenow.io',
      });
    } else if (ENVIRONMENT.IS_DEV) {
      // Dev we limit usage for user but allow targeting tenants
      await ldClient.identify({
        kind: 'tenant',
        key: currentTenant.id,
        anonymous: false,
        name: `${currentTenant.id} ${currentTenant.name} [${
          currentTenant.testTenant ? 'TEST' : 'LIVE'
        }]`,
        tenantId: currentTenant.id,
        tenantName: currentTenant.name,
        testTenant: currentTenant.testTenant,
        host: ENVIRONMENT.HOST_TYPE,
      });
    } else {
      // In staging/production we allow targeting users and tenants
      await ldClient.identify({
        kind: 'multi',
        user: {
          key: currentTenantUser.id,
          anonymous: false,
          name: currentTenantUser.name,
          email: currentTenantUser.email,
        },
        tenant: {
          key: currentTenant.id,
          anonymous: false,
          name: `${currentTenant.id} ${currentTenant.name} [${
            currentTenant.testTenant ? 'TEST' : 'LIVE'
          }]`,
          tenantId: currentTenant.id,
          tenantName: currentTenant.name,
          testTenant: currentTenant.testTenant,
          host: ENVIRONMENT.HOST_TYPE,
        },
      });
    }
    logger.info('Initialized LaunchDarkly context', ldClient.getContext());
  } catch (ex) {
    logger.warn('Error initializing LaunchDarkly context', ex);
  }
};

/**
 * Used for invoice share links
 * We know the tenantId but not the user
 */
export const initLaunchDarklyContextAnonymous = (
  ldClient: ReturnType<typeof useLDClient>,
  tenantId: string,
  shareId?: Maybe<string>,
) => {
  try {
    if (ENABLE_LAUNCHDARKLY && ldClient && tenantId) {
      ldClient.identify({
        kind: 'multi',
        user: { kind: 'user', key: 'invoice_share', name: 'invoice_share' },
        tenant: {
          key: tenantId,
          anonymous: false,
          tenantId: tenantId,
        },
      });
    }
  } catch (ex) {
    logger.warn('Error initializing LaunchDarkly context', ex);
  }
};

/** Used for localhost environments */
const DEFAULT_FEATURE_FLAGS: FeatureFlag = {
  allowTenantDeletion: true,
  teleportSalesDemoTempDependentPricingConfig: {
    derivedQuantity: {
      prod_5iURYkr2SVpvS: {
        quantityMultiplier: 10,
        sourceProduct: 'prod_f9Y1aNWQtRq7i',
      },
      prod_D5IqfwNLElAvu: {
        quantityMultiplier: 1000,
        sourceProduct: 'prod_f9Y1aNWQtRq7i',
      },
    },
  },
  salesDemoMedinsightTempFormulaCustomPricing: {
    formulaCustomQty: {
      dependentProduct: 'prod_tL0D4CZ0QUBfG',
      description: '((Membership Population Amount / 2) * (qty - 1)) / qty',
      formulaProduct: 'prod_3twFfpiKpQqqO',
      offering: 'offr_OC28aly73pO14',
    },
  },
  salesDemoMedinsightTempGuidedQuotingSpecialConfig: {
    defaultSelectedOfferings: {
      ques_uUvLlnkAynax7: {
        Core: {
          offeringIds: [
            'offr_JsXtIENgpiQoa',
            'offr_OC28aly73pO14',
            'offr_VJZkS206scU1d',
          ],
        },
        Standalone: {
          offeringIds: ['offr_qdYAMc9JhZ3aR'],
        },
      },
      ques_Z8gkYbECUZ1fo: {
        Y: {
          offeringIds: ['offr_mIC6V8ifmUs03'],
        },
      },
    },
    defaultQuantities: {
      prod_3twFfpiKpQqqO: 1,
    },
    lockQuantityEdit: {
      productIds: [
        'prod_xuf8uPhrBdcvE',
        'prod_tL0D4CZ0QUBfG',
        'prod_ZgEv8CGFIo6EU',
        'prod_zKZLyGa4WngDv',
        'prod_tKClThJcDbsgj',
        'prod_o5SVgxHVYc7xH',
        'prod_hNFRPktsigZiV',
        'prod_QZ7TKi3AmHHNf',
        'prod_rqOGTfuWVfIzh',
        'prod_5CPXp2OoeIwFo',
        'prod_BdUDEPZJsEhEN',
      ],
    },
    preSelectOptionalProducts: {
      productIds: [
        'prod_ZgEv8CGFIo6EU',
        'prod_zKZLyGa4WngDv',
        'prod_hNFRPktsigZiV',
        'prod_3twFfpiKpQqqO',
        'prod_5CPXp2OoeIwFo',
      ],
    },
    quantityMapping: {
      productIds: [
        'prod_xuf8uPhrBdcvE',
        'prod_tL0D4CZ0QUBfG',
        'prod_ZgEv8CGFIo6EU',
        'prod_zKZLyGa4WngDv',
        'prod_tKClThJcDbsgj',
        'prod_o5SVgxHVYc7xH',
        'prod_hNFRPktsigZiV',
        'prod_QZ7TKi3AmHHNf',
        'prod_rqOGTfuWVfIzh',
        'prod_5CPXp2OoeIwFo',
        'prod_BdUDEPZJsEhEN',
      ],
      questionId: 'ques_zY0yksASM3civ',
    },
  },
  billRun: true,
  quoteAcceptanceAllowedRoles: ['ALL'],
  newApplicationOfPaymentCredit: true,
  showSettingFirstInvoiceCheckbox: true,
  customRates: true,
  scheduledChanges: true,
  createProductionTenant: false,
  metabaseReporting: true,
  revenueRecognition: true,
  accountingSettings: true,
  /**
   * NOTE: This was modified - it only controls the scheduled change effective date for amendments
   * scheduled change rates are no longer included in this flag
   */
  quoteOfferingDateAndScheduledChangeRate: false,
  pdfCoverFooter: true,
  customFields: true,
  crmFieldMapping: true,
  hideBillingSchedule: false,
  hideAutoRenewal: false,
  totalExclVat: false,
  preventContactCreate: false,
  preventContactEdit: false,
  conditionalQuoteTerms: true,
  percentOfTotal: true,
  allowInvoiceDelayInPast: true,
  showCalendarMonthProration: false,
  disableProrationMethod: false,
  quoteTemplates: {
    QUOTE_AFFINITY: true,
    QUOTE_AFFINITY_MARCH_2024: true,
    QUOTE_DEFAULT_1: true,
    QUOTE_DEFAULT_2: true,
    QUOTE_DEFAULT_3_EXCL_VAT: true,
    QUOTE_MATERIALIZE: true,
    QUOTE_MIDDESK: true,
    QUOTE_AFFINITY_MARCH_2024_TEST_V2: true,
    QUOTE_DEFAULT_1_TEST_V2: true,
    QUOTE_DEFAULT_2_TEST_V2: true,
    QUOTE_DEFAULT_3_EXCL_VAT_TEST_V2: true,
    QUOTE_MATERIALIZE_TEST_V2: true,
    QUOTE_AFFINITY_TEST_V2: true,
    QUOTE_MIDDESK_TEST_V2: true,
    QUOTE_PLOTLINE: true,
    QUOTE_DANFOSS_EU: true,
    QUOTE_DANFOSS_US: true,
  },
  subscriptionFrequencyAdditionalOptions: true,
  showQuoteOfferingDescription: true,
  crmFieldMappingSfdc: true,
  lockPriceModelUpdateOnQuote: false,
  taxAnrok: true,
  taxAvalara: true,
  disableInactiveProductBanner: false,
  quoteInternalExternalView: true,
  showQuotableField: true,
  anonymousInvoiceAllowPaymentAmount: -1,
  hideMarkAsRenewal: false,
  allowMinCommitAdditionalFrequencies: true,
  onetimeInvoice: true,
  netsuite: true,
  enableAccountBasedAddresses: true,
  offeringDropdownSorting: 'groupByType',
  dunningEnabled: true,
  // TODO: replace with universal list instead of customer specific
  dunningEmailTemplates: [
    {
      label: 'Affinity Reminder Template',
      value: 'd-e7a983bd771343ada7429b7c6a695e8c',
    },
    {
      label: 'Affinity 0-21 Day Template',
      value: 'd-8172169478fc46df871468bacabe2284',
    },
    {
      label: 'Affinity 30-45 Day Template',
      value: 'd-f71639b166174aefb8f7143f845ce8a6',
    },
    {
      label: 'Affinity 45 Days Past Due Template',
      value: 'd-0ae393bc247a44a29528d33bcd661688',
    },
  ],
  allowRefunds: true,
  isAppDocsSearch: true,
  globalAccountSearch: true,
  allowPartialPayment: true,
  allowChangingQuoteOfferingStartAndEndDate: true,
  allowTimezoneModification: true,
  showCreditAndRebill: true,
  allowManualPaymentDateModification: true,
  allowOptionalProducts: true,
  allowBillGroupCreation: false,
  enableUsBankTransfer: true,
  useQuotePdfV2: false,
  useQuoteEditV2: true,
  useWhatsChanged: true,
  useDelayedBilling: true,
  guidedOnboardingEnabled: true,
  showPricingLevelConfigure: true,
  guidedSelling: true,
  guidedSellingViaRulesService: true,
  allowNetsuiteFieldMapping: true,
  guidedQuoteOfferingSelection: true,
  allowBillingScheduleV2: true,
  showUsageTabOnAccount: true,
  showSubscriptionOnAccount: true,
  invoiceEmailFromCustomizationEnabled: true,
  lockFinalizedInvoiceData: true,
  allowCreditAndCreditNoteVoid: true,
  enableBillingSettings: false,
};
/**
 * Use this hook in any components that require feature flags
 */
export const useFlags = (): FeatureFlag => {
  const featureFlags: FeatureFlag = useLDFlags();

  if (ENABLE_LAUNCHDARKLY) {
    return featureFlags;
  }

  return DEFAULT_FEATURE_FLAGS;
};

export const LDProviderConfig = {
  clientSideID: ENVIRONMENT.LAUNCH_DARKLY_CLIENT_ID,
  context: { kind: 'user', key: 'anonymous', anonymous: true },
  reactOptions: {
    useCamelCaseFlagKeys: true,
  },
};
