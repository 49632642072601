import { useDisclosure } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import pick from 'lodash/pick';
import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MdInfo, MdLock, MdOutlineConstruction } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { apiGetAllList, handleApiErrorToast } from '~app/api/axios';
import {
  useCreateEntity,
  useGetById,
  useGetListData,
  useUpdateEntity,
} from '~app/api/queryUtils';
import { useRevRecSettings } from '~app/api/revenueService';
import { CustomFieldDataForm } from '~app/components/CustomFields/CustomFieldDataForm';
import {
  MBox,
  MButton,
  MCheckbox,
  MCustomMultiSelect,
  MCustomSelect,
  MDivider,
  MFlex,
  MFormField,
  MGrid,
  MGridItem,
  MIcon,
  MInput,
  MLockedTextOrContent,
  MPageContainer,
  MPageHeader,
  MPageLoader,
  MText,
  MTooltip,
} from '~app/components/Monetize';
import { useCustomSelectValues } from '~app/components/Monetize/MCustomSelect/useCustomSelectValue';
import { PRODUCTS, ROUTES } from '~app/constants';
import {
  CUSTOM_ID_PLACEHOLDER,
  SKU_PLACEHOLDER,
  STATUS_PLACEHOLDER,
} from '~app/constants/placeholders';
import { PRODUCT_LIST } from '~app/constants/routes';
import { useDebounce } from '~app/hooks/useDebounce';
import { useHandleApiError } from '~app/hooks/useHandleApiError';
import { useCtrlEnterHotkey } from '~app/hooks/useHotkeys';
import { useSettingsReadOnlyRule } from '~app/hooks/useSettingsReadOnlyRule';
import { logFormError } from '~app/services/logger';
import {
  CustomFieldEntityEnum,
  DEFAULT_PAGER,
  IProductResSchema,
  IProductSchema,
  IUsageType,
  PrePaidCreditConfigTypeEnum,
  ProductSchema,
  ProductStatusEnum,
  ProductTypeEnum,
  RecognitionMethodEnum,
  UsageTypeStatusEnum,
} from '~app/types';
import { nullifyEmptyStrings } from '~app/utils/misc';
import { UsageTypeForm } from '../UsageTypes/UsageTypeForm';
import { UsageTypeView } from '../UsageTypes/UsageTypeView';
import ExtraConfigurationTitle from './ExtraConfigurationTitle';

const COMING_SOON_OPTIONS = new Set([
  RecognitionMethodEnum.EVENLY_MONTHLY_IN_ARREARS,
  RecognitionMethodEnum.EVENLY_MONTHLY_12_MONTHS,
  RecognitionMethodEnum.EVENLY_MONTHLY_3_MONTHS,
]);

const renderOptionsWithComingSoon = ({
  title,
  item,
  isSubtitle,
  isSelected,
  isHighlight,
}: {
  title: string;
  item: any;
  isSubtitle: boolean;
  isSelected: boolean;
  isHighlight: boolean;
}) => {
  if (COMING_SOON_OPTIONS.has(item.value)) {
    return (
      <MTooltip label="Coming Soon" placement="bottom-start">
        <MFlex
          position="relative"
          alignItems="center"
          w="full"
          justifyContent="space-between"
        >
          <MText
            color="tGray.disabledText"
            isTruncated
            noOfLines={1}
            fontSize={isSubtitle ? '12px' : 'sm'}
            mb="-2px"
            mt="0px"
          >
            {title}
          </MText>
          <MIcon as={MdOutlineConstruction} color="tPurple.lightGrayPurple" />
        </MFlex>
      </MTooltip>
    );
  }
  return (
    <MText
      color="inherit"
      isTruncated
      noOfLines={1}
      fontSize={isSubtitle ? '12px' : 'sm'}
      mt="0px"
    >
      {title}
    </MText>
  );
};

function getRecognitionMethod(productType?: ProductTypeEnum) {
  switch (productType) {
    case ProductTypeEnum.ONETIME:
    case ProductTypeEnum.MIN_COMMIT:
    case ProductTypeEnum.USAGE:
    case ProductTypeEnum.ONETIME_PREPAID_CREDIT:
      // Tf one of the above types, the recognition method must be locked to immediate
      return RecognitionMethodEnum.IMMEDIATE;
    case ProductTypeEnum.ADVANCE:
    default:
      return RecognitionMethodEnum.EVENLY_MONTHLY_IN_ADVANCE;
  }
}

const renderPrePaidCreditScopeItemContent = ({
  title,
  item,
  isSubtitle,
  isSelected,
  isHighlight,
}: {
  title: string;
  item: any;
  isSubtitle: boolean;
  isSelected: boolean;
  isHighlight: boolean;
}) => {
  if (
    [
      PrePaidCreditConfigTypeEnum.ALL_PRODUCTS,
      PrePaidCreditConfigTypeEnum.SUBSCRIPTION_PRODUCTS_ONLY,
      PrePaidCreditConfigTypeEnum.SPECIFIC_PRODUCTS,
    ].includes(item.value)
  ) {
    return (
      <MTooltip label="Coming Soon" placement="bottom-start">
        <MFlex
          position="relative"
          alignItems="center"
          w="full"
          justifyContent="space-between"
        >
          <MText
            color="inherit"
            isTruncated
            noOfLines={1}
            fontSize={isSubtitle ? '12px' : 'sm'}
            mb="-2px"
            mt="0px"
            fontWeight={isSelected || isHighlight ? 'bold' : 'light'}
          >
            {title}
          </MText>
          <MIcon as={MdOutlineConstruction} color="inherit" />
        </MFlex>
      </MTooltip>
    );
  }
  return (
    <MText
      color="inherit"
      isTruncated
      noOfLines={1}
      fontSize={isSubtitle ? '12px' : 'sm'}
      mb="-2px"
      mt="0px"
      fontWeight={isSelected || isHighlight ? 'bold' : 'light'}
    >
      {title}
    </MText>
  );
};

export const ProductForm: FC = () => {
  const { productId = '' } = useParams();
  const navigate = useNavigate();
  const { handleApiError } = useHandleApiError();

  const { isInitialLoading: isProductLoading, data: product } =
    useGetById<IProductResSchema>('productCatalogProducts', productId!, {
      enabled: !!productId,
      select: (data) => {
        // Ensure recognition method is set
        if (data) {
          data.recognitionMethod =
            data.recognitionMethod || getRecognitionMethod(data.productType);
        }
        return data;
      },
      onSuccess: (fetchedProduct) => {
        // only reset form if data actually changed
        if (!product || fetchedProduct !== product) {
          setIsProductLocked(!!fetchedProduct.locked);
          const formData = pick(
            fetchedProduct,
            Object.keys(ProductSchema.shape),
          );

          reset({
            ...formData,
            usageTypeIds: fetchedProduct?.usageTypes
              ? fetchedProduct?.usageTypes.map((usageType) => usageType.id)
              : null,
          });
        }
      },
      onError: (err) => handleApiError(err, ROUTES.PRODUCT_LIST),
      refetchOnWindowFocus: false,
    });

  const { data: recRevSettings, isLoading: isLoadingRevRecSettings } =
    useRevRecSettings();

  const [isProductLocked, setIsProductLocked] = useState<boolean>(
    () => !!product?.locked,
  );

  const { mutate: doCreateProduct, isLoading: isCreateProductLoading } =
    useCreateEntity('productCatalogProducts', {
      onSuccess: () => navigate(PRODUCT_LIST),
      onError: (error) => handleApiErrorToast(error),
    });

  const { mutate: doUpdateProduct, isLoading: isUpdateProductLoading } =
    useUpdateEntity('productCatalogProducts', {
      onSuccess: () => navigate(PRODUCT_LIST),
      onError: (error) => handleApiErrorToast(error),
    });
  const [query, setQuery] = useState<string>('');
  const debouncedQuery = useDebounce(query);

  const { data: usageTypes, isLoading: usageTypesLoading } = useGetListData<
    IUsageType,
    any[]
  >(
    'productCatalogUsageTypes',
    {
      config: { ...DEFAULT_PAGER, sortField: 'name', sortOrder: 1, rows: 200 },
      filters: {
        status: { ne: UsageTypeStatusEnum.INACTIVE },
        name: { contains: debouncedQuery },
      },
    },
    {
      select: (data) => {
        return data.content;
      },
      onError: (error) => {
        handleApiErrorToast(error);
      },
    },
  );

  const onChangeQuery = (q: string): void => {
    if (q !== query) {
      setQuery(q);
    }
  };

  const isDataLoading =
    (productId && isProductLoading) || isLoadingRevRecSettings;

  const isSaveLoading =
    isDataLoading || isCreateProductLoading || isUpdateProductLoading;

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    watch,
    setError,
    formState: { errors, isDirty, isValid },
  } = useForm<IProductSchema>({
    resolver: zodResolver(ProductSchema),
    mode: 'onChange',
    defaultValues: {
      status: ProductStatusEnum.ACTIVE,
      productType: ProductTypeEnum.ADVANCE,
      recognitionMethod: getRecognitionMethod(product?.productType),
      customFields: product?.customFields || {},
      taxExempt: false,
      customId: '',
      description: '',
      financeId: '',
      name: '',
      sku: '',
      taxItemCode: '',
      oneTimePrepaidCreditConfig: null,
      ...product,
      usageTypeIds: product?.usageTypes
        ? product?.usageTypes.map((usageType) => usageType.id)
        : null,
    },
  });

  const selectedProductType = watch('productType');
  const {
    productType,
    recognitionMethod,
    usageTypeIds,
    oneTimePrepaidCreditConfig,
  } = getValues();

  const {
    isOpen: isCreateModalOpen,
    onOpen: onOpenCreateModal,
    onClose: onCloseCreateModal,
  } = useDisclosure();

  const onCloseModal = (id?: string) => {
    onCloseCreateModal();
    id &&
      setValue('usageTypeIds', [id], {
        shouldDirty: true,
        shouldValidate: true,
      });
  };

  const onChangeProductType = (e: any) => {
    setValue('recognitionMethod', getRecognitionMethod(e));

    if (e !== ProductTypeEnum.USAGE) {
      setValue('usageTypeIds', null);
    }

    if (e === ProductTypeEnum.ONETIME_PREPAID_CREDIT) {
      setValue('oneTimePrepaidCreditConfig', {
        type: PrePaidCreditConfigTypeEnum.USAGE_PRODUCTS,
      });
    } else {
      setValue('oneTimePrepaidCreditConfig', null);
    }
  };

  useCtrlEnterHotkey(() => {
    // Usage and product forms use the same hotkeys. Don't trigger product submit if modal is open.
    if (!isCreateModalOpen && isDirty && isValid) {
      handleSubmit(onSubmit)();
    }
  });

  const onSubmit = async (data: IProductSchema) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { usageTypeIds, productType } = data;
    if (productType === ProductTypeEnum.USAGE && !usageTypeIds) {
      setError('usageTypeIds', { message: "Usage type can't be empty." });
      return;
    }
    if (productType !== ProductTypeEnum.USAGE) {
      delete data.usageTypeIds;
    }

    if (!productId) {
      doCreateProduct(nullifyEmptyStrings(data));
    } else {
      doUpdateProduct({
        id: productId,
        payload: { id: productId, ...nullifyEmptyStrings(data) },
      });
    }
  };

  const { isReadOnly, inputVariant } = useSettingsReadOnlyRule();
  const headerTitle = isReadOnly
    ? 'View Product'
    : !productId
    ? 'New Product'
    : 'Edit Product';

  const watchUsageTypeIds = watch('usageTypeIds');
  const {
    internalValue: internalUsageTypeIdsValue,
    isLoading: isLoadingUsageTypeIdsValue,
    placeholder: placeholderUsageTypeIds,
    onInternalValueChange: onUsageTypeIdsChange,
  } = useCustomSelectValues<IUsageType>({
    value: watchUsageTypeIds || [],
    setValue: (values) =>
      setValue('usageTypeIds', values, {
        shouldDirty: true,
        shouldValidate: true,
      }),
    getByIds: async (ids: string[]) =>
      ids.length > 0
        ? apiGetAllList<IUsageType>('/api/usageTypes', {
            filters: { id: { in: ids } },
          })
        : [],
    label: 'Usage types',
  });

  const lockedIcon = (
    <MFlex>
      <MTooltip
        mt={-1}
        ml={10}
        label="Some of the information in this product cannot be edited
because this product is currently being used."
        shouldWrapChildren
        placement="bottom-start"
      >
        <span>
          <MIcon
            w={4}
            h={6}
            ml="3"
            as={MdLock}
            fontSize="xl"
            alignSelf="center"
            style={{
              cursor: 'pointer',
            }}
          />
        </span>
      </MTooltip>
    </MFlex>
  );

  const isFormValid =
    productType === ProductTypeEnum.USAGE
      ? isValid && !!usageTypeIds?.length
      : isValid;

  const isReadOnlyProps = {
    isDisabled: isReadOnly || isSaveLoading,
    isReadOnly: isReadOnly,
    variant: isReadOnly ? 'readonly' : 'primary',
  };

  return (
    <MPageContainer alignItems="stretch" data-testid="product-form">
      <MPageHeader
        hasBackButton
        backButtonLink={ROUTES.PRODUCT_LIST}
        title={headerTitle}
        status={
          product?.status
            ? PRODUCTS.PRODUCT_STATUS_ENUM_DISPLAY[product?.status]
            : ''
        }
        copyUrl
        id={product?.id}
        tag={
          isProductLocked && product?.status === ProductStatusEnum.ACTIVE
            ? lockedIcon
            : ''
        }
      >
        {!isReadOnly && (
          <MButton
            form="product-form"
            variant="primary"
            isLoading={isSaveLoading}
            isDisabled={isSaveLoading || !isDirty || !isFormValid}
            type="submit"
            onClick={handleSubmit(onSubmit, logFormError)}
          >
            Save
          </MButton>
        )}
      </MPageHeader>

      {isDataLoading ? (
        <MFlex justify="center" grow={1} height={'100%'}>
          <MPageLoader height="auto" />
        </MFlex>
      ) : (
        <MBox width="100%" py={4}>
          <form
            id="product-form"
            onSubmit={handleSubmit(onSubmit, logFormError)}
            style={{ maxWidth: '725px', margin: '0 auto' }}
          >
            <MGrid templateColumns="repeat(12, 1fr)" rowGap={4} columnGap={8}>
              <MGridItem colSpan={6}>
                <MFormField error={errors.name} label="Product Name" isRequired>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <MInput
                        maxLength={80}
                        {...field}
                        isDisabled={isSaveLoading}
                        isReadOnly={isReadOnly}
                        variant={inputVariant}
                        placeholder="Enter Product Name"
                      />
                    )}
                  />
                </MFormField>
              </MGridItem>

              <MGridItem colSpan={12}>
                <MFormField
                  error={errors.description}
                  label="Description"
                  isRequired
                >
                  <Controller
                    name="description"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <MInput
                        maxLength={80}
                        {...field}
                        isDisabled={isSaveLoading}
                        isReadOnly={isReadOnly}
                        variant={inputVariant}
                        placeholder="Product Description"
                      />
                    )}
                  />
                </MFormField>
              </MGridItem>

              <MGridItem colSpan={4}>
                <MFormField error={errors.status} label="Status" isRequired>
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <MCustomSelect
                        placeholder={STATUS_PLACEHOLDER}
                        items={PRODUCTS.ProductStatusSelectOptions}
                        isDisabled={isSaveLoading}
                        isReadOnly={isReadOnly}
                        variant={inputVariant}
                        {...field}
                      />
                    )}
                  />
                </MFormField>
              </MGridItem>

              <MGridItem colSpan={4}>
                <MFormField
                  error={errors.productType}
                  label="Product Type"
                  isRequired
                >
                  <MLockedTextOrContent
                    text={PRODUCTS.PRODUCT_TYPE_DISPLAY[productType]}
                    h={8}
                    isLocked={isProductLocked}
                  >
                    <Controller
                      name="productType"
                      control={control}
                      render={({ field: { onChange, ...rest } }) => (
                        <MCustomSelect
                          placeholder="Select a type"
                          items={PRODUCTS.ProductTypeSelectOptions}
                          onChange={(event) => {
                            onChange(event);
                            onChangeProductType(event);
                          }}
                          isDisabled={isSaveLoading}
                          isReadOnly={isReadOnly}
                          variant={inputVariant}
                          {...rest}
                        />
                      )}
                    />
                  </MLockedTextOrContent>
                </MFormField>
              </MGridItem>

              <MGridItem colSpan={4}>
                {recRevSettings?.enabled && (
                  <MFormField
                    error={errors.productType}
                    label="Recognition Method"
                    isRequired
                  >
                    <MLockedTextOrContent
                      text={
                        PRODUCTS.RECOGNITION_METHOD_DISPLAY[
                          recognitionMethod || 'IMMEDIATE'
                        ]
                      }
                      h={8}
                      // If product is locked and field would be disabled based on product type, show as read-only
                      isLocked={
                        isProductLocked &&
                        productType !== ProductTypeEnum.ADVANCE
                      }
                    >
                      <Controller
                        name="recognitionMethod"
                        control={control}
                        render={({ field }) => (
                          <MCustomSelect
                            placeholder="Recognition Method"
                            items={PRODUCTS.RecognitionMethodOptions}
                            renderItemContent={renderOptionsWithComingSoon}
                            disabledItems={[
                              RecognitionMethodEnum.EVENLY_MONTHLY_IN_ARREARS,
                              RecognitionMethodEnum.EVENLY_MONTHLY_12_MONTHS,
                              RecognitionMethodEnum.EVENLY_MONTHLY_3_MONTHS,
                            ]}
                            isDisabled={
                              isSaveLoading ||
                              productType !== ProductTypeEnum.ADVANCE
                            }
                            isReadOnly={isReadOnly}
                            {...field}
                          />
                        )}
                      />
                    </MLockedTextOrContent>
                  </MFormField>
                )}
              </MGridItem>

              <MGridItem colSpan={6}>
                <MFormField error={errors.customId} label="Custom ID">
                  <Controller
                    name="customId"
                    control={control}
                    render={({ field: { value, ...rest } }) => (
                      <MInput
                        placeholder={CUSTOM_ID_PLACEHOLDER}
                        isDisabled={isSaveLoading}
                        isReadOnly={isReadOnly}
                        variant={inputVariant}
                        value={value || ''}
                        {...rest}
                      />
                    )}
                  />
                </MFormField>
              </MGridItem>

              <MGridItem colSpan={6}>
                <MFormField error={errors.sku} label="SKU">
                  <Controller
                    name="sku"
                    control={control}
                    render={({ field: { value, ...rest } }) => (
                      <MInput
                        placeholder={SKU_PLACEHOLDER}
                        isDisabled={isSaveLoading}
                        isReadOnly={isReadOnly}
                        variant={inputVariant}
                        value={value || ''}
                        {...rest}
                      />
                    )}
                  />
                </MFormField>
              </MGridItem>

              <MGridItem colSpan={6}>
                <MFormField error={errors.financeId} label="Finance ID">
                  <Controller
                    name="financeId"
                    control={control}
                    render={({ field: { value, ...rest } }) => (
                      <MInput
                        placeholder="GL Account in finance system"
                        isDisabled={isSaveLoading}
                        isReadOnly={isReadOnly}
                        variant={inputVariant}
                        value={value || ''}
                        {...rest}
                      />
                    )}
                  />
                </MFormField>
              </MGridItem>

              <MGridItem colSpan={12}>
                <MDivider />
              </MGridItem>

              <MGridItem colSpan={4}>
                <MFormField error={errors.taxItemCode} label="Tax Item Code">
                  <Controller
                    name="taxItemCode"
                    control={control}
                    render={({ field: { value, ...rest } }) => (
                      <MInput
                        placeholder="Tax Item Code"
                        isDisabled={isSaveLoading}
                        isReadOnly={isReadOnly}
                        variant={inputVariant}
                        value={value || ''}
                        {...rest}
                      />
                    )}
                  />
                </MFormField>
              </MGridItem>
              <MGridItem colSpan={8}>
                <MFormField error={errors.taxExempt} label="" skipLabel>
                  <Controller
                    name="taxExempt"
                    control={control}
                    render={({ field: { value, ...rest } }) => (
                      <MCheckbox
                        marginTop={2}
                        minH={8}
                        isChecked={!!value}
                        {...rest}
                        {...isReadOnlyProps}
                      >
                        <MFlex alignItems="center" position="relative">
                          Tax Exempt
                          <MTooltip
                            label="If selected, this product will not be sent to the tax provider during tax calculation."
                            placement="bottom-start"
                          >
                            <MFlex alignItems="center">
                              <MIcon as={MdInfo} color="tPurple.base" ml="1" />
                            </MFlex>
                          </MTooltip>
                        </MFlex>
                      </MCheckbox>
                    )}
                  />
                </MFormField>
              </MGridItem>

              {selectedProductType === ProductTypeEnum.USAGE && (
                <>
                  <ExtraConfigurationTitle title="Usage Configuration" />

                  {!isProductLocked ? (
                    <MGridItem colSpan={6}>
                      <MFormField
                        error={errors.usageTypeIds}
                        label="Usage Type"
                        isRequired={
                          selectedProductType === ProductTypeEnum.USAGE
                        }
                      >
                        <Controller
                          name="usageTypeIds"
                          control={control}
                          render={({ field }) => (
                            <MCustomMultiSelect
                              {...field}
                              itemTitle="name"
                              itemValue="id"
                              items={usageTypes}
                              prependItems={[
                                {
                                  item: PRODUCTS.NEW_USAGE_TYPE_OPTION,
                                  isAction: true,
                                  hasDivider: true,
                                  onClick: ({ onClose }) => {
                                    onClose && onClose();
                                    onOpenCreateModal();
                                  },
                                },
                              ]}
                              loading={
                                usageTypesLoading || isLoadingUsageTypeIdsValue
                              }
                              showQueryInput
                              useExternalQuery
                              multiple
                              externalQuery={query}
                              onChangeQuery={onChangeQuery}
                              isDisabled={isSaveLoading}
                              isReadOnly={isReadOnly}
                              variant={inputVariant}
                              returnItem
                              value={internalUsageTypeIdsValue}
                              closeButtonText="Apply"
                              placeholder={placeholderUsageTypeIds}
                              onChange={(val) => {
                                onUsageTypeIdsChange(val as any);
                              }}
                              inputProps={{
                                _placeholder: { color: 'tPurple.dark' },
                                _disabled: {
                                  _placeholder: {
                                    color: 'tGray.darkPurple',
                                  },
                                },
                              }}
                            />
                          )}
                        />
                      </MFormField>
                    </MGridItem>
                  ) : (
                    <MGridItem colSpan={9}>
                      <UsageTypeView usageTypes={product?.usageTypes} />
                    </MGridItem>
                  )}
                </>
              )}

              {selectedProductType ===
                ProductTypeEnum.ONETIME_PREPAID_CREDIT && (
                <>
                  <ExtraConfigurationTitle title="Pre-Paid Credit Configuration"></ExtraConfigurationTitle>

                  <MGridItem colSpan={6}>
                    <MFormField
                      error={errors.oneTimePrepaidCreditConfig?.type}
                      label="Credit Application"
                      isRequired={
                        selectedProductType ===
                        ProductTypeEnum.ONETIME_PREPAID_CREDIT
                      }
                    >
                      <MLockedTextOrContent
                        text={
                          PRODUCTS.PRE_PAID_CREDIT_CONFIG_TYPE_DISPLAY[
                            oneTimePrepaidCreditConfig?.type!
                          ]
                        }
                        h={8}
                        isLocked={isProductLocked}
                      >
                        <Controller
                          name="oneTimePrepaidCreditConfig.type"
                          control={control}
                          render={({ field: { onChange, ...rest } }) => (
                            <MCustomSelect
                              items={
                                PRODUCTS.PrePaidCreditConfigTypeSelectOptions
                              }
                              renderItemContent={
                                renderPrePaidCreditScopeItemContent
                              }
                              isDisabled={isSaveLoading}
                              isReadOnly={isReadOnly}
                              disabledItems={[
                                PrePaidCreditConfigTypeEnum.ALL_PRODUCTS,
                                PrePaidCreditConfigTypeEnum.SPECIFIC_PRODUCTS,
                                PrePaidCreditConfigTypeEnum.SUBSCRIPTION_PRODUCTS_ONLY,
                              ]}
                              {...rest}
                              onChange={onChange}
                            />
                          )}
                        />
                      </MLockedTextOrContent>
                    </MFormField>
                  </MGridItem>
                </>
              )}
              <MGridItem colSpan={12}>
                <Controller
                  name="customFields"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <CustomFieldDataForm
                      entity={CustomFieldEntityEnum.PRODUCT}
                      value={value || {}}
                      setValue={(val) => {
                        onChange(val);
                      }}
                      fieldColSpan={4}
                      mode="accordion"
                      {...isReadOnlyProps}
                      showDivider
                    />
                  )}
                />
              </MGridItem>
            </MGrid>
          </form>
        </MBox>
      )}
      {selectedProductType === ProductTypeEnum.USAGE && (
        <UsageTypeForm isOpen={isCreateModalOpen} onClose={onCloseModal} />
      )}
    </MPageContainer>
  );
};
