import { zodResolver } from '@hookform/resolvers/zod';
import isNaN from 'lodash/isNaN';
import isNumber from 'lodash/isNumber';
import { ResolverOptions } from 'react-hook-form';
import { z } from 'zod';
import { getRequiredMessage } from '~app/utils/messages';
import { IAccountDetails } from './accountDetailsTypes';
import { AddressSchema } from './addressTypes';
import { QuoteContacts } from './contactTypes';
import {
  ContractEndActionEnum,
  ContractEndActionEnumZ,
  RenewalTermLengthEnum,
  RenewalTermLengthEnumZ,
} from './contractTypes';
import { CustomFieldRecordSchema } from './customFieldsTypes';
import { QuoteItemCustomPriceSchema } from './customPriceType';
import {
  AmountUnitTypeEnum,
  DiscountDurationEnumZ,
  DiscountSchema,
  DiscountStatusEnumZ,
  DiscountTypeEnumZ,
  QuoteItemDiscountSchema,
} from './discountTypes';
import { LegalEntitySnapshot } from './legalEntityTypes';
import {
  BaseResponseSchema,
  MCustomNumberTypeNullish,
  MCustomNumberTypeRequired,
  Maybe,
} from './miscTypes';
import { NetTermsEnumZ } from './netTermsType';
import { OfferingResSchema, OfferingTypesEnumZ } from './offeringTypes';
import { OpportunityStatusEnumZ } from './opportunitySharedTypes';
import { PriceUpliftConfigResSchema } from './priceUpliftConfig';
import {
  ProductSchema,
  ProductTypeEnum,
  ProductTypeEnumZ,
} from './productTypes';
import { QuoteSettingsDefaultAddressSourceEnumZ } from './quoteSettingsAddressTypes';
import { QuoteStartDateSourceEnumZ } from './quoteSettingsTypes';
import {
  IRateResSchema,
  PriceDisplayEnumZ,
  RateBillingFrequencyEnumZ,
  RateResBaseSchema,
} from './rateTypes';
import { RuleValidationErrorLevelEnumZ } from './ruleTypes';

export interface QuoteItemDiscount {
  createDate: string;
  createdBy: string;
  discountAmount: number;
  discountAmountOrPercent: number;
  discountId: string;
  discountName: string;
  discountType: AmountUnitTypeEnum;
  lastModifiedBy: string;
  modifyDate: string;
}

export const QuoteItemSchema = z.object({
  id: z.string().optional().nullable(),
  quoteOffering: z.any().optional().nullable(),
  productId: z.string().nullish(),
  quoteOfferingId: z.string().nullish(),
  name: z.string().nullish(),
  sku: z.string().optional().nullable(),
  subscriptionItemId: z.string().optional().nullable(),
  endDate: z.string().optional().nullable(),
  description: z.string().optional().nullable(),
  isAddon: z.boolean().optional().nullable(),
  addonId: z.string().optional().nullable(),
  quantity: z
    .union([z.string(), z.number()])
    .refine(
      (val) => val !== null && val !== undefined && val !== '',
      getRequiredMessage('Quantity'),
    )
    .transform((val) => Number(val)),
  debitProrationMultiplier: z.number().optional().nullable(),
  debit: z.number().optional().nullable(),
  creditProrationMultiplier: z.number().optional().nullable(),
  credit: z.number().optional().nullable(),
  amount: z.number().optional(),
  amountWithoutDiscount: z.number().optional(),
  customDiscountId: z.string().optional().nullable(),
  customDiscountType: DiscountTypeEnumZ.optional().nullable(),
  customDiscountAmountOrPercent: z
    .union([z.string().nullish(), z.number().nullish()])
    .transform((val) => (val === null ? null : Number(val))),
  customDiscountName: z.string().nullish(),
  customDiscountDescription: z.string().nullish(),
  discounts: z.array(QuoteItemDiscountSchema).optional(),
  discountAmount: z.number().nullish(),
  discountPercent: z.number().nullish(),
  approved: z.boolean().optional().nullable(),
  amendmentStatus: z.string().optional().nullable(),
  previousItemId: z.string().optional().nullable(),
  previousRateId: z.string().optional().nullable(),
  previousAmount: z.number().optional().nullable(),
  previousQuantity: z.number().optional().nullable(),
  previousDiscountId: z.string().optional().nullable(),
  previousDiscountType: DiscountTypeEnumZ.optional().nullable(),
  previousDiscountAmountOrPercent: z.number().optional().nullable(),
  productName: z.string().nullish(),
  productType: z.string().nullish(),
  unitPrice: z.number().nullish(),
  unitPriceAfterDiscount: z.number().nullish(),
});

export type IQuoteItemSchema = z.infer<typeof QuoteItemSchema>;
export type IQuoteItem = IQuoteItemSchema;
export interface ICurrency {
  id: number;
  sortName: string;
  fullName: string;
}

export enum QuoteAmendmentVersionEnum {
  v1 = 'amendment_v1',
  v2 = 'amendment_v2',
}
export const QuoteAmendmentVersionEnumZ = z.nativeEnum(
  QuoteAmendmentVersionEnum,
);
export enum QuoteItemAmendmentStatusEnum {
  ADDED = 'ADDED',
  NO_CHANGE = 'NO_CHANGE',
  REMOVED = 'REMOVED',
  UPDATED = 'UPDATED',
}

export const QuoteItemAmendmentStatusEnumZ = z.nativeEnum(
  QuoteItemAmendmentStatusEnum,
);

export enum QuoteStatusEnum {
  DRAFT = 'DRAFT',
  REVIEW = 'REVIEW',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  SENT = 'SENT',
  ACCEPTED = 'ACCEPTED',
  PROCESSED = 'PROCESSED',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
  ARCHIVED = 'ARCHIVED',
}
export const QuoteStatusEnumZ = z.nativeEnum(QuoteStatusEnum);

// Quote statuses that have an API endpoint to transition to them
export type QuoteStatusTransitions =
  | QuoteStatusEnum.DRAFT
  | QuoteStatusEnum.REVIEW
  | QuoteStatusEnum.SENT
  | QuoteStatusEnum.ACCEPTED;

export enum QuoteTypeEnum {
  NEW = 'NEW',
  AMENDMENT = 'AMENDMENT',
  RENEWAL = 'RENEWAL',
}
export const QuoteTypeEnumZ = z.nativeEnum(QuoteTypeEnum);
export enum QuoteCancelationReasonEnum {
  EXPIRED = 'EXPIRED',
  CUSTOMER_DECLINED = 'CUSTOMER_DECLINED',
  DUPLICATE = 'DUPLICATE',
  BY_USER = 'BY_USER',
}
export const QuoteCancelationReasonEnumZ = z.nativeEnum(
  QuoteCancelationReasonEnum,
);

export enum NewQuoteTypeEnum {
  NET_NEW = 'NET_NEW',
  MANUAL_RENEWAL = 'MANUAL_RENEWAL',
}

export const NewQuoteTypeEnumZ = z.nativeEnum(NewQuoteTypeEnum);
export const QuoteItemOptionsSchema = z.object({
  showPrices: z.boolean(),
  displayUnitPriceFrequency: z.number().min(1).max(60).nullish(),
});
export type IQuoteItemOptionsSchema = z.infer<typeof QuoteItemOptionsSchema>;

export enum CollaborationAccessEnum {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
  NONE = 'NONE',
}
export const CollaborationAccessEnumZ = z.nativeEnum(CollaborationAccessEnum);

export enum CollaborationAccessLevelEnum {
  WRITE = 'WRITE',
  READ = 'READ',
}
export const CollaborationAccessLevelEnumZ = z.nativeEnum(
  CollaborationAccessLevelEnum,
);

export enum SigningOrderEnum {
  INTERNAL_FIRST = 'INTERNAL_FIRST',
  EXTERNAL_FIRST = 'EXTERNAL_FIRST',
  INSTANTANEOUS = 'INSTANTANEOUS',
}
export const SigningOrderEnumZ = z.nativeEnum(SigningOrderEnum);

export const QuoteOfferingSchema = z.object({
  id: z.string().optional(),
  name: z.string().nullish(),
  offeringId: z.string().nonempty({ message: 'offeringId is required!' }),
  rateId: z.string().nonempty({ message: 'rateId is required!' }).nullish(),
  productId: z.string().nullish(),
  items: z.array(QuoteItemSchema),
  subscriptionId: z.string().nullish(),
  description: z.string().nullish(),
  debitProrationMultiplier: z.number().optional(),
  debit: z.number().optional(),
  creditProrationMultiplier: z.number().optional(),
  credit: z.number().optional(),
  amount: z.number().optional(),
  amountWithoutDiscount: z.number().optional(),
  discounts: z.array(DiscountSchema).optional(),
  discountIds: z.array(z.string()).optional().nullable(),
  discountType: DiscountTypeEnumZ.optional(),
  discountAmountOrPercent: z.number().optional(),
  discount: z.number().optional(),
  discountAmount: z.number().optional(),
  discountPercent: z.number().optional(),
  previousRateId: z.string().nullish(),
  previousAmount: z.number().nullish(),
  previousDiscountId: z.string().optional(),
  previousDiscountType: DiscountTypeEnumZ.optional(),
  previousDiscountAmountOrPercent: z.number().optional(),
  products: z.array(ProductSchema).optional(),
  rates: z.array(RateResBaseSchema).optional(),
  amendmentStatus: z.string().optional().nullable(),
  type: OfferingTypesEnumZ.optional(),
  startDate: z.string(),
  endDate: z.string(),
  parentQuoteOfferingId: z.string().nullish(),
});
export type IQuoteOffering = z.infer<typeof QuoteOfferingSchema>;

export const OpportunitySchema = z.object({
  id: z.string().nonempty({ message: 'opportunityId is required!' }),
  name: z.string().optional().nullable(),
  customId: z.string().optional().nullable(),
  primaryQuoteId: z.string().optional().nullable(),
  url: z.string().optional().nullable(),
});
export type IOpportunitySchema = z.infer<typeof OpportunitySchema>;

// from salesforce service route '/:opportunityId/quote/link'
export interface OpportunityResponseSchema {
  opportunityId: string;
  customOpportunityId: string;
  opportunityName: string;
  quoteId: string;
}

export const QuoteContractRenewalTerms = z
  .object({
    contractEndAction: ContractEndActionEnumZ,
    autoRenewalNoticePeriod: z
      .union([z.number(), z.string()])
      .refine(
        (val) => val !== null && val !== undefined && val !== '',
        getRequiredMessage('Notice Period'),
      )
      .transform((val) => Number(val))
      .refine((val) => val <= 28, 'Must be less than or equal to 28')
      .nullish(),
    renewalTermLength: z
      .object({
        type: RenewalTermLengthEnumZ,
        months: z
          .union([z.string(), z.number()])
          .transform((val) => Number(val))
          .nullish(),
      })
      .nullish()
      .superRefine((data, ctx) => {
        if (data?.type === RenewalTermLengthEnum.FixedMonths && !data?.months) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: getRequiredMessage('Months'),
            path: ['months'],
          });
        }

        if (
          data?.type === RenewalTermLengthEnum.FixedMonths &&
          data.months &&
          (data.months > 120 || data.months < 1)
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'The Custom Length must be between 1 to 120',
            path: ['months'],
          });
        }
      }),
  })
  .superRefine((data, ctx) => {
    if (
      data.contractEndAction === ContractEndActionEnum.RENEW &&
      !data.autoRenewalNoticePeriod
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: getRequiredMessage('Notice Period'),
        path: ['autoRenewalNoticePeriod'],
      });
    }
  });

export type IQuoteContractRenewalTerms = z.infer<
  typeof QuoteContractRenewalTerms
>;

// Quote Request schema will be used for both PUT/POST since they are identical
// !!NOTE!! IF any fields are added here that don't exist on QuoteRespSchema, update getQuoteRequestFromQuoteResponse()
// from POST /api/quote/
// forom PUT /api/quote/{quoteId}
export const QuoteRequestSchema = z.object({
  customId: z.string().nullish(),
  description: z
    .string({ required_error: getRequiredMessage('Quote Name') })
    .min(2, { message: 'Quote Name must contain at least 2 character(s)' }),
  opportunityId: z.string().nullish(),
  accountId: z.string().nullish(),
  collaborationAccess: CollaborationAccessEnumZ,
  billGroupId: z.string().nullish(),
  expirationDate: z.string().nullish(),
  currency: z.string({ required_error: getRequiredMessage('Currency') }),
  netTerms: NetTermsEnumZ,
  contractStartDate: z.string().nullish(),
  contractAmendmentDate: z.string().nullish(),
  contractLength: z.number().nullish(),
  contractTerms: z.string().nullish(),
  documentUrl: z.string().nullish(),
  /** @deprecated - will be replaced with contractRenewalTerms.contractEndAction */
  autoRenewContract: z.boolean(),
  requiresEsign: z.boolean(),
  legalEntityId: z.string({
    required_error: getRequiredMessage('Legal Entity'),
  }),
  customFields: CustomFieldRecordSchema,
  contractRenewalTerms: QuoteContractRenewalTerms.nullish(),
  migrated: z.boolean().nullish(),
  // FIXME: these are not actual properties on the quote request - BE ignores them if sent
  // but we have code that relies on them (incorrectly) in QuoteDocumentsBody
  documentLocation: z.string().nullish(),
  sowDocumentStorageId: z.string().nullish(),
  purchaseOrderNumber: z.string().optional().nullable(),
  coverDocumentStorageId: z.string().nullish(),
  footerDocumentStorageId: z.string().nullish(),
  startDateSource: QuoteStartDateSourceEnumZ.nullish(),
});

export type IQuoteRequestSchema = z.infer<typeof QuoteRequestSchema>;
export interface IQuoteRequestSchemaExtended extends IQuoteRequestSchema {
  account: IAccountDetails | '';
  quoteType: QuoteTypeEnum | '';
  contractId: string;
}

export const getQuoteRequestSchema =
  (quote?: IQuoteRespSchema | null) =>
  (
    data: IQuoteRequestSchema,
    context: object | undefined,
    options: ResolverOptions<IQuoteRequestSchema>,
  ) => {
    const modifiedReqSchema = QuoteRequestSchema.superRefine(
      ({ contractLength }, ctx) => {
        if (
          quote?.type === QuoteTypeEnum.AMENDMENT &&
          quote?.amendmentVersion !== QuoteAmendmentVersionEnum.v2 &&
          Number(contractLength) < Number(quote?.previousContractLength)
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `Contract length cannot be shortened in an amendment`,
            path: ['contractLength'],
          });
        }
      },
    );
    return zodResolver(modifiedReqSchema)(data, context, options);
  };

export const OpportunityQuoteRespSchema = z.object({
  id: z.string(),
  name: z.string(),
  modifyDate: z.string().optional(),
  type: QuoteTypeEnumZ,
  status: QuoteStatusEnumZ,
  total: z.number(),
  currency: z.string(),
});
export type IOpportunityQuote = z.infer<typeof OpportunityQuoteRespSchema>;

// from GET /api/quotes/{quoteId}:
export const GetQuoteOpportunitySchema = z.object({
  id: z.string(),
  accountName: z.string().nullish(),
  status: OpportunityStatusEnumZ,
  amount: z.number().nullish(),
  customId: z.string().nullish(),
  name: z.string(),
  owner: z.string(),
  primaryQuoteId: z.string().nullish(),
  quotes: OpportunityQuoteRespSchema.array().nullish(),
  url: z.string().nullish(),
});
export type IGetQuoteOpportunitySchema = z.infer<
  typeof GetQuoteOpportunitySchema
>;

export const RevenueMetricsSchema = z.object({
  arr: z.number(),
  mrr: z.number(),
  incrementalMrr: z.number(),
  incrementalArr: z.number(),
  percentage: z.number(),
  previousArr: z.number(),
  previousMrr: z.number(),
});

export type IRevenueMetrics = z.infer<typeof RevenueMetricsSchema>;

export const RevenueMetricsQuoteItemSchema = RevenueMetricsSchema.pick({
  arr: true,
  mrr: true,
  incrementalMrr: true,
  incrementalArr: true,
});

export type IRevenueMetricsQuoteItem = z.infer<
  typeof RevenueMetricsQuoteItemSchema
>;

// from GET /api/quotes/{quoteId}:
export const QuoteItemRespSchema = BaseResponseSchema.extend({
  addonId: z.string().nullable(),
  amendmentStatus: QuoteItemAmendmentStatusEnumZ,
  // This is FE status to overwrite the BE amendmentStatus. This is used to provide user experience on amend V2
  amendmentStatusOverwrite: QuoteItemAmendmentStatusEnumZ.nullish(),
  amount: z.number(),
  amountWithoutDiscount: z.number(),
  credit: z.number(),
  creditProrationMultiplier: z.number(),
  customDiscountAmountOrPercent: z.number().nullable(),
  customDiscountName: z.string().nullish(),
  customDiscountDescription: z.string().nullish(),
  customDiscountId: z.string().nullable(),
  customDiscountType: DiscountTypeEnumZ.nullable(),
  customId: z.string().nullable(),
  customPriceId: z.string().nullable(),
  customPrice: z
    .object({
      createdBy: z.string(),
      createDate: z.string(),
      lastModifiedBy: z.string(),
      modifyDate: z.string(),
      id: z.string(),
      amount: z.number(),
    })
    .nullable(),
  debit: z.number(),
  debitProrationMultiplier: z.number(),
  description: z.string().nullable(),
  discountAmount: z.number(),
  discountPercent: z.number(),
  discounts: z.array(QuoteItemDiscountSchema),
  endDate: z.string().nullable(),
  isAddon: z.boolean().nullable(),
  options: QuoteItemOptionsSchema.nullish(),
  previousAmount: z.number().nullable(),
  previousCustomDiscountAmountOrPercent: z.number().nullable(),
  previousCustomDiscountId: z.string().nullable(),
  previousCustomDiscountType: DiscountTypeEnumZ.nullable(),
  previousItemId: z.string().nullable(),
  previousQuantity: z.number().nullable(),
  productId: z.string(),
  productName: z.string(),
  productType: ProductTypeEnumZ,
  quantity: z.number(),
  quoteOfferingId: z.string(),
  revenueMetrics: RevenueMetricsQuoteItemSchema,
  sku: z.string().nullable(),
  subscriptionItemId: z.string().nullable(),
  unitPrice: z.number(),
  unitPriceAfterDiscount: z.number(),
  displayUnitPrice: z.number(),
  isMandatory: z.boolean(),
  isSelected: z.boolean().nullable(),
});

export type IQuoteItemRespSchema = z.infer<typeof QuoteItemRespSchema>;

export enum ApprovalStatusEnum {
  NO_ACTION = 'NO_ACTION',
  DECLINED = 'DECLINED',
  APPROVED = 'APPROVED',
}
export const ApprovalStatusEnumZ = z.nativeEnum(ApprovalStatusEnum);

export enum ApprovalStatusUIEnum {
  SENT = 'SENT',
  SUBMITTED = 'SUBMITTED',
  ACCEPTED = 'ACCEPTED',
  MANUALLY_ACCEPTED = 'MANUALLY_ACCEPTED',
  NOT_REQUIRED = 'NOT_REQUIRED',
  PROCESSED = 'PROCESSED',
}
export const ApprovalStatusUIEnumZ = z.nativeEnum(ApprovalStatusUIEnum);

// from GET /api/quotes/{quoteId}:
export const ApprovalRespSchema = BaseResponseSchema.extend({
  ruleId: z.string(),
  userId: z.string(),
  username: z.string(),
  teamId: z.string(),
  teamName: z.string(),
  name: z.string(),
  description: z.string(),
  rank: z.number(),
  status: ApprovalStatusEnumZ,
  declineReason: z.string(),
  approvalTimestamp: z.string(),
  skipped: z.boolean(),
  quoteId: z.string(),
  mandatory: z.boolean(),
  currentUserApprover: z.boolean(),
});
export type IApprovalRespSchema = z.infer<typeof ApprovalRespSchema>;

const QuoteValidationSchema = z.object({
  errorLevel: RuleValidationErrorLevelEnumZ,
  message: z.string(),
  ruleDescription: z.string(),
  ruleId: z.string(),
});
export type IQuoteValidation = z.infer<typeof QuoteValidationSchema>;

export const ApprovalSchemaUI = ApprovalRespSchema.extend({
  status: z.union([ApprovalStatusEnumZ, ApprovalStatusUIEnumZ]),
});
export type IApprovalSchemaUI = z.infer<typeof ApprovalSchemaUI>;

// from GET /api/quotes/{quoteId}:
export const QuoteDiscountRespSchema = BaseResponseSchema.extend({
  name: z.string(),
  description: z.string(),
  discountType: DiscountTypeEnumZ,
  status: DiscountStatusEnumZ,
  discountCode: z.string(),
  quoteId: z.string(),
  discountAmount: z.number(),
  currency: z.string(),
  durationType: DiscountDurationEnumZ,
  durationMonths: z.number(),
  maxUses: z.number(),
  remainingUses: z.number(),
  startDate: z.string(),
  endDate: z.string(),
  autoRemove: z.boolean(),
  accountId: z.string(),
  scopeProductId: z.string(),
  scopeOfferingId: z.string(),
  locked: z.boolean(),
});

export type IQuoteDiscountRespSchema = z.infer<typeof QuoteDiscountRespSchema>;

export const QuoteOfferingOptionsSchema = z.object({
  priceDisplay: PriceDisplayEnumZ.nullish(),
});

// from GET /api/quotes/{quoteId}:
export const QuoteOfferingRespSchema = BaseResponseSchema.extend({
  quoteId: z.string(),
  offeringId: z.string(),
  offeringName: z.string(),
  offeringDescription: z.string().nullable(),
  rateId: z.string(),
  accountSpecificRate: z.boolean(),
  startDate: z.string(),
  endDate: z.string(),
  parentQuoteOfferingId: z.string().nullable(),
  rateName: z.string(),
  rateDescription: z.string().nullable(),
  billingFrequency: RateBillingFrequencyEnumZ,
  billingFrequencyInMonths: z.number(),
  subscriptionId: z.string().nullable(),
  description: z.string().nullable(),
  debitProrationMultiplier: z.number(),
  debit: z.number(),
  creditProrationMultiplier: z.number(),
  credit: z.number(),
  amountWithoutDiscount: z.number(),
  amount: z.number(),
  discountAmount: z.number(),
  discountPercent: z.number(),
  previousId: z.string().nullable(),
  previousRateId: z.string().nullable(),
  previousAmount: z.number().nullable(),
  items: z.array(QuoteItemRespSchema),
  discounts: z.array(QuoteDiscountRespSchema),
  revenueMetrics: RevenueMetricsQuoteItemSchema,
  locked: z.boolean(),
  priceUpliftConfigurationId: z.string().nullable(),
  options: QuoteOfferingOptionsSchema.nullish(),
});

export type IQuoteOfferingRespSchema = z.infer<typeof QuoteOfferingRespSchema>;

export enum QuoteConditionalTermStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export const QuoteConditionalTermStatusEnumZ = z.nativeEnum(
  QuoteConditionalTermStatusEnum,
);

export const QuoteConditionalTermSchema = BaseResponseSchema.extend({
  ruleId: z.string(),
  quoteId: z.string(),
  terms: z.string(),
  displayOrder: z.number(),
  editableOnQuote: z.boolean(),
  ruleName: z.string(),
  status: QuoteConditionalTermStatusEnumZ,
  edited: z.boolean(),
});

export type IQuoteConditionalTermSchema = z.infer<
  typeof QuoteConditionalTermSchema
>;

export const QuoteIncrementalPricesSchema = z.object({
  absolute: z.object({
    amount: z.number(),
    discountAmount: z.number(),
    amountAfterDiscount: z.number(),
  }),
  prior: z.object({
    amount: z.number(),
    discountAmount: z.number(),
    amountAfterDiscount: z.number(),
  }),
  bySubscriptionBreakdown: z.any(),
  incremental: z.object({
    amount: z.number(),
    discountAmount: z.number(),
    amountAfterDiscount: z.number(),
  }),
});
// from GET /api/quotes/{quoteId}:
export const QuoteRespSchema = BaseResponseSchema.extend({
  fromCompany: LegalEntitySnapshot,
  acceptedAt: z.string().nullable(),
  customId: z.string().nullable(),
  type: QuoteTypeEnumZ,
  number: z.string().nullable(),
  description: z.string(),
  opportunity: GetQuoteOpportunitySchema.nullable(),
  accountName: z.string(),
  orderId: z.string().nullable(),
  owner: z.string(),
  ownerId: z.string().optional(),
  ownerName: z.string(),
  accountId: z.string(),
  contractId: z.string().nullable(),
  originalQuoteId: z.string().nullable(),
  previousQuoteId: z.string().nullable(),
  contacts: QuoteContacts,
  collaborationAccess: CollaborationAccessEnumZ,
  accessLevel: CollaborationAccessLevelEnumZ.nullable(),
  billGroupId: z.string().nullable(),
  expirationDate: z.string(),
  approvalSubmittedAt: z.string().nullable(),
  approvalGrantedAt: z.string().nullable(),
  credit: z.number().nullable(),
  amount: z.number().nullable(),
  amountWithoutTax: z.number().nullable(),
  discountAmount: z.number().nullable(),
  discountPercent: z.number().nullable(),
  amountWithoutDiscount: z.number().nullable(),
  /** MSA - If storage */
  documentLocation: z.string().nullable(),
  /** MSA - If link (url) */
  documentUrl: z.string().nullable(),
  /** SOW */
  sowDocumentStorageId: z.string().optional().nullable(),
  country: z.string().nullable(),
  currency: z.string(),
  netTerms: NetTermsEnumZ,
  status: QuoteStatusEnumZ,
  cancellationReason: QuoteCancelationReasonEnumZ.nullable(),
  contractStartDate: z.string(),
  contractAmendmentDate: z.string().nullable(),
  contractEndDate: z.string(),
  contractLength: z.number(),
  previousContractLength: z.number(),
  contractTerms: z.string().nullable(),
  approvals: z.array(ApprovalRespSchema),
  quoteOfferings: z.array(QuoteOfferingRespSchema),
  noteIds: z.array(z.string()),
  manualAcceptanceReason: z.string().nullable(),
  /** User uploaded signed quote document, or Docusign */
  signedDocumentStorageId: z.string().nullable(),
  revenueMetrics: RevenueMetricsSchema,
  autoRenewContract: z.boolean(),
  requiresEsign: z.boolean(),
  newQuoteType: NewQuoteTypeEnumZ.nullable(),
  sentDate: z.string().nullable(),
  declinedDate: z.string().nullable(),
  purchaseOrderNumber: z.string().optional().nullable(),
  signingOrder: SigningOrderEnumZ,
  validations: z.array(QuoteValidationSchema),
  /** PDf Cover and Footer */
  coverDocumentStorageId: z.string().optional().nullable(),
  footerDocumentStorageId: z.string().optional().nullable(),
  legalEntityId: z.string(),
  customFields: CustomFieldRecordSchema,
  migrated: z.boolean(),
  conditionalTerms: z.array(QuoteConditionalTermSchema).nullable(),
  contractRenewalTerms: QuoteContractRenewalTerms,
  priceUpliftConfigurations: z
    .record(z.string(), PriceUpliftConfigResSchema)
    .nullish(),

  addressSource: QuoteSettingsDefaultAddressSourceEnumZ,
  shippingAddress: AddressSchema.nullish(),
  billingAddress: AddressSchema.nullish(),
  amendmentVersion: QuoteAmendmentVersionEnumZ,
  incrementalPrices: QuoteIncrementalPricesSchema.nullish(),
  startDateSource: QuoteStartDateSourceEnumZ.nullish(),
});
export type IQuoteRespSchema = z.infer<typeof QuoteRespSchema>;

export const QuoteContactAddressDataSchema = z.object({
  addressSource: QuoteSettingsDefaultAddressSourceEnumZ,
  shippingAddressId: z.string().nullish(),
  billingAddressId: z.string().nullish(),
});
export type IQuoteContactAddressDataSchema = z.infer<
  typeof QuoteContactAddressDataSchema
>;

// from GET /api/quotes:
// provides a summary of each quote for use in list views
export const QuoteBasicRespSchema = QuoteRespSchema.pick({
  accountName: true,
  amount: true,
  companyName: true,
  contractAmount: true,
  description: true,
  id: true,
  modifyDate: true,
  status: true,
  type: true,
  ownerName: true,
  ownerId: true,
  billGroupId: true,
  migrated: true,
});
export type IQuoteBasicRespSchema = z.infer<typeof QuoteBasicRespSchema>;

// QuoteOffering Request schema will be used for both PUT/POST since they are identical
// from POST /api/quotes/{quoteId}/quoteOfferings/
// from PUT /api/quotes/{quoteId}/quoteOfferings/{quoteOfferingId}
/**
 * 👉 NOTE: If fields are added or removed that are modified on the quote edit page
 * 👉 you may need to make changes to src/utils/quotes.tsx
 * If field, should not be used to detect a change and make API request on quote page, add to QUOTE_ITEM_FIELDS_COMPARISON_IGNORE
 * If field is complex and request and response have different representation, add an entry to quoteOfferingComparison.item.comparisonFns
 * Otherwise, no action is required
 */
export const QuoteItemReqSchema = z.object({
  id: z.string().nullish(),
  customId: z.string().nullish(),
  productId: z.string(),
  sku: z.string().nullish(),
  description: z
    .string()
    .max(220, 'Product description may not exceed 220 character(s)')
    .nullish(),
  quantity: MCustomNumberTypeRequired('Quantity cannot be empty'),
  customDiscountType: DiscountTypeEnumZ.nullish(),
  customDiscountAmountOrPercent: MCustomNumberTypeNullish,
  customDiscountName: z.string().nullish(),
  customDiscountDescription: z.string().nullish(),
  customPrice: QuoteItemCustomPriceSchema.nullish(),
  options: QuoteItemOptionsSchema.nullish(),
  isSelected: z.boolean().nullish(),
});

export type IQuoteItemReqSchema = z.infer<typeof QuoteItemReqSchema>;
export type IQuoteItemReqSchemaUI = IQuoteItemReqSchema & {
  productType: ProductTypeEnum;
};

export const QuoteOfferingScheduleSchema = z.object({
  startDate: z.string(),
  endDate: z.string().nullish(),
  parentQuoteOfferingId: z.string(),
});

export type IQuoteOfferingScheduleSchema = z.infer<
  typeof QuoteOfferingScheduleSchema
>;

/**
 * 👉 NOTE: If fields are added or removed that are modified on the quote edit page
 * 👉 you may need to make changes to src/utils/quotes.tsx
 * If field, should not be used to detect a change and make API request on quote page, add to QUOTE_OFFERING_FIELDS_COMPARISON_IGNORE
 * If field is complex and request and response have different representation, add an entry to quoteOfferingComparison.offering.comparisonFns
 * Otherwise, no action is required
 */
export const QuoteOfferingReqSchema = z.object({
  offeringId: z.string().nonempty({ message: 'offeringId is required!' }),
  rateId: z.string().nullish(),
  subscriptionId: z.string().nullish(),
  description: z.string().nullish(),
  items: z.array(QuoteItemReqSchema),
  discountIds: z.array(z.string()).nullish(),
  schedule: QuoteOfferingScheduleSchema.nullish(),
  startDate: z.string().nullish(),
  endDate: z.string().nullish(),
  options: QuoteOfferingOptionsSchema.nullish(),
});

export const BulkQuoteOfferingReqSchema = z.object({
  offerings: z.array(OfferingResSchema),
  billingFrequency: z.string(),
  rateId: z.string().nullish(),
  bulkRate: z.array(
    z.object({
      offeringId: z.string(),
      rateId: z.string().refine((data) => !!data, {
        message: 'Rate ID is required',
      }),
    }),
  ),
  customBillingFrequency: z.number().nullish(),
});

export type IQuoteOfferingReqSchema = z.infer<typeof QuoteOfferingReqSchema>;
export type IBulkQuoteOfferingReqSchema = z.infer<
  typeof BulkQuoteOfferingReqSchema
>;

export const getQuoteOfferingReqSchema =
  (offeringRate: IRateResSchema | null) =>
  (
    data: IQuoteOfferingReqSchema,
    context: object | undefined,
    options: ResolverOptions<IQuoteOfferingReqSchema>,
  ) => {
    const modifiedReqSchema = QuoteOfferingReqSchema.superRefine(
      ({ items }, ctx) => {
        items.map(({ quantity, productId }, index) => {
          const inputName =
            `items.${index}.quantity` as keyof IQuoteOfferingReqSchema;

          const min =
            offeringRate?.productPriceRanges[productId!]?.min &&
            isNumber(offeringRate.productPriceRanges[productId!].min) &&
            !isNaN(offeringRate.productPriceRanges[productId!].min)
              ? offeringRate?.productPriceRanges[productId!].min
              : 0;

          const max =
            offeringRate?.productPriceRanges[productId!]?.max &&
            isNumber(offeringRate.productPriceRanges[productId!].max) &&
            !isNaN(offeringRate.productPriceRanges[productId!].max)
              ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
                offeringRate?.productPriceRanges[productId!].max!
              : Infinity;

          const parsedQuantity =
            isNumber(quantity) && !isNaN(quantity) ? +quantity : 0;

          if (parsedQuantity < min) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: `Quantity is lower than set price tiers (min ${min})`,
              path: [inputName],
            });
          }

          if (parsedQuantity > max) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: `Quantity is higher than set price tiers (max ${max})`,
              path: [inputName],
            });
          }
        });
      },
    );
    return zodResolver(modifiedReqSchema)(data, context, options);
  };

export interface IQuoteDiscount {
  name: string;
  description: string;
  percentageAmount: number;
  amount: string;
  type: AmountUnitTypeEnum;
}

export interface IQuoteDiscounts {
  discounts: IQuoteDiscount[];
  total: number;
}
export const IncrementalPeriodSchema = z.object({
  billDate: z.string(),
  billableAmount: z.number(),
  creditableAmount: z.number(),
});

// GET /api/quotes/quote_59Sax3UtRtVw1tY0/billingSchedule
export const QuoteBillingScheduleRespSchema = z.object({
  contractAmount: z.number(),
  billingFrequency: RateBillingFrequencyEnumZ,
  billingFrequencyInMonths: z.number().nullish(),
  periods: z.array(
    z.object({
      incrementalLabel: z.string().optional(),
      startDate: z.string(),
      amount: z.number(),
    }),
  ),
  incrementalPeriod: IncrementalPeriodSchema.nullable(),
});

export type IQuoteBillingScheduleRespSchema = z.infer<
  typeof QuoteBillingScheduleRespSchema
>;

export type IIncrementalPeriodSchema = z.infer<typeof IncrementalPeriodSchema>;

export interface QuoteOfferingWithChildren {
  quoteOffering: IQuoteOfferingRespSchema;
  children: IQuoteOfferingRespSchema[];
  id: number;
}

export interface IQuotePriceScheduleProductPrice {
  prices: {
    amount: number;
    discount: number;
    from: number;
    net: number;
    to: number | null;
  }[];
  product: {
    id: string;
    name: string;
    description: Maybe<string>;
    productType: ProductTypeEnum;
  };
}
export interface IQuotePriceSchedule {
  endDate: string;
  startDate: string;
  productPrices: IQuotePriceScheduleProductPrice[];
  quoteOfferingId: string;
  rate: {
    id: string;
    name: string;
    description: Maybe<string>;
  };
}
export interface IQuotePrice {
  offering: {
    id: string;
    name: string;
    description: Maybe<string>;
  };
  quoteOfferingId: string;
  schedule: IQuotePriceSchedule[];
}

export const QuoteReviewSubmitSchema = z.object({
  notes: z
    .string()
    .max(500, 'Approval notes must contain at most 500 character(s)'),
});

export type IQuoteReviewSubmitSchema = z.infer<typeof QuoteReviewSubmitSchema>;

export interface IQuoteReviewSubmit {
  notes: string;
}
export interface IAllowCustomContractRes {
  allowCustomLength: boolean;
}

export enum InactiveItemTypeEnumUI {
  OFFERING = 'OFFERING',
  RATE = 'RATE',
}

export const NewQuoteTypeReqSchema = z
  .object({
    type: NewQuoteTypeEnumZ,
    previousArr: z.union([z.string(), z.number()]).nullish(),
  })
  .refine(
    ({ type, previousArr }) =>
      type === NewQuoteTypeEnum.MANUAL_RENEWAL && !!previousArr,
    {
      message: getRequiredMessage('Previous ARR'),
      path: ['previousArr'],
    },
  );

export type INewQuoteTypeReqSchema = z.infer<typeof NewQuoteTypeReqSchema>;

export const ExtendedExpirationDateReqSchema = z.object({
  extendedDate: z.string().nonempty(getRequiredMessage('Extended Date')),
});

export type IExtendedExpirationDateReqSchema = z.infer<
  typeof ExtendedExpirationDateReqSchema
>;

export type IQuoteReviewReq = {
  note?: string;
};

export const QuoteAddPoNumberSchema = z.object({
  poNumber: z.string().max(40).optional(),
});
export type IQuoteAddPoNumberSchema = z.infer<typeof QuoteAddPoNumberSchema>;

export const IQuoteSigningOrderReq = {
  signingOrder: SigningOrderEnum,
};

export type IQuoteSigningOrderResp = { message: string; status: number };

export const ContactLengthPopoverSchema = z.object({
  contractLength: z.number().int().nonnegative().nullable(),
});
export type IContactLengthPopoverSchema = z.infer<
  typeof ContactLengthPopoverSchema
>;

export enum QuoteOfferingAmendActionOptionEnum {
  AS_OF = 'AS_OF',
  START_OF_CONTRACT = 'START_OF_CONTRACT',
}
export const QuoteOfferingAmendActionOptionEnumZ = z.nativeEnum(
  QuoteOfferingAmendActionOptionEnum,
);
export const QuoteOfferingAmendActionSchema = z.object({
  option: QuoteOfferingAmendActionOptionEnumZ,
  date: z.string().nullish(),
});
export type IQuoteOfferingAmendActionSchema = z.infer<
  typeof QuoteOfferingAmendActionSchema
>;

export enum QuoteOfferingDeleteActionOptionEnum {
  AS_OF = 'AS_OF',
  START_OF_CONTRACT = 'START_OF_CONTRACT',
}
export const QuoteOfferingDeleteActionOptionEnumZ = z.nativeEnum(
  QuoteOfferingDeleteActionOptionEnum,
);
export const QuoteOfferingDeleteActionSchema = z.object({
  option: QuoteOfferingDeleteActionOptionEnumZ,
  date: z.string().nullish(),
});

export type IQuoteOfferingDeleteActionSchema = z.infer<
  typeof QuoteOfferingDeleteActionSchema
>;

export interface QuoteOfferingProps {
  index: number;
  quoteOffering?: IQuoteOfferingRespSchema | null;
  parentQuoteOffering?: IQuoteOfferingRespSchema | null;
  /**
   * For scheduled changes, this is the previous scheduled offering or the parent if it is the first scheduled change
   * This is used to know any data points, such as quantity, for the immediate prior offering
   * This will only be set for offerings that are scheduled changes
   */
  priorScheduledQuoteOffering?: IQuoteOfferingRespSchema | null;
  /**
   * For scheduled changes, this is used to calculate valid date ranges for the effective date on amendments
   */
  nextScheduledQuoteOffering?: IQuoteOfferingRespSchema | null;
  childQuoteOfferings?: IQuoteOfferingRespSchema[];
  isChildOffering?: boolean;
  quotePrices: IQuotePrice[];
  isRootOfferingOpen?: boolean;
  onChange: OnQuoteOfferingChange;
  quoteOfferings?: QuoteOfferingWithChildren[];
  setParentScheduleChangeActiveProp?: (val: boolean) => void;
}

export type QuoteOfferingState = {
  newlyAddedOffering: boolean;
  isNewQuoteOfferingForm: boolean;
  isLastSegment: boolean;
  isRemoved: boolean;
  isAmendment: boolean;
  isRenewal: boolean;
  isDraft: boolean;
  isLocked: boolean;
  isReadOnlyAndNotEmptyOffering: boolean;
  allowPriceCustomization: boolean;
  gridColumns: string;
  isQuoteOfferingDiscountAvailable: boolean;
  bgColor: string;
  bgColorV2: string;
  isOfferingUnselectable: boolean;
  isOfferingOnetime: boolean;
  isLastSegmentNoChange: boolean;
  isNoOfferingChanged: boolean;
  quoteOfferingGroupStartDate?: string;
  quoteOfferingGroupEndDate?: string;
  isUsingDelayedBilling: boolean;
};

export type EffectiveDateType = number | 'CUSTOM';

export type ScheduleChangeState = {
  availableMonthSelectOptions: {
    title: string;
    value: EffectiveDateType;
  }[];
  monthInterval: number;
  scheduleChangeInitDate: Date;
};

export type UseQuoteOfferingAmendV2Return = {
  isQOUpdated: boolean;
  isQOAddedToAmendment: boolean;
  showRevertToOriginal: boolean;
  isAmendV2Locked: boolean;
  handleRevertOriginal: () => void;
  handleUnlock: () => void;
  isEndingBeforeContractEnd: boolean;
  offeringEndDate: string;
  isOfferingEndBeforeContract: boolean;
};

export enum QuoteOfferingRemovalScopeEnum {
  GROUP = 'group',
  TARGET = 'target',
}
export const QuoteOfferingRemovalScopeEnumZ = z.nativeEnum(
  QuoteOfferingRemovalScopeEnum,
);
export enum QuoteOfferingGroupRemovalModeEnum {
  CONTRACT = 'contract',
  TARGET_DATE = 'target_date',
}
export const QuoteOfferingGroupRemovalModeEnumZ = z.nativeEnum(
  QuoteOfferingGroupRemovalModeEnum,
);
// https://monetizenow.atlassian.net/browse/BP-9942
export const QuoteOfferingRemoveReqSchema = z.object({
  removal_scope: QuoteOfferingRemovalScopeEnumZ,
  target_id: z.string().nullish(),
  group_removal_mode: QuoteOfferingGroupRemovalModeEnumZ.nullish(),
  group_id: z.string().nullish(),
  end_date: z.string().nullish(),
});
export type IQuoteOfferingRemoveReqSchema = z.infer<
  typeof QuoteOfferingRemoveReqSchema
>;

export type OnQuoteOfferingChangeAction =
  | 'ADD'
  | 'DELETE'
  | 'UPDATE'
  | 'REPLACE';

export type OnQuoteOfferingChange = (
  action: OnQuoteOfferingChangeAction,
  /**
   * One or more quote offerings to work with
   * Delete is the only use-case where there are multiple offerings
   */
  quoteOfferings?: Maybe<IQuoteOfferingRespSchema | IQuoteOfferingRespSchema[]>,
  oldQuoteOfferingId?: string,
  skipFetch?: boolean,
) => Promise<void>;

export const ContactLengthPeriodPopoverSchema = z.object({
  contractLength: z.number().int().nonnegative().nullable(),
  contractStartDate: z.string().nullish(),
  startDateSource: QuoteStartDateSourceEnumZ.nullish(),
});
export type IContactLengthPeriodPopoverSchema = z.infer<
  typeof ContactLengthPeriodPopoverSchema
>;
