import { useDisclosure } from '@chakra-ui/react';
import React, { FunctionComponent as FC, useEffect, useState } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MdOutlineTaskAlt } from 'react-icons/md';
import { v4 as uuidv4 } from 'uuid';
import {
  approvalsQueryKeys,
  doQuoteApprove,
  doQuoteRetract,
} from '~app/api/approvalService';
import { handleApiErrorToast } from '~app/api/axios';
import { APPROVAL } from '~app/constants';
import {
  QUOTE_ACCEPTED_STATUSES,
  QUOTE_APPROVED_STATUSES,
  QUOTE_SENT_STATUSES,
  QUOTE_SUBMITTED_STATUSES,
} from '~app/constants/quotes';
import { useQuoteSettings } from '~app/hooks';
import { useConfirmModal } from '~app/services/confirmModal';
import {
  ApprovalStatusUIEnum,
  IApprovalRespSchema,
  IApprovalSchemaUI,
  IQuoteRespSchema,
  QuoteStatusEnum,
} from '~app/types';
import { sortQuoteApprovals } from '~app/utils/quotes';
import { MFlex, MHStack, MIcon, MText } from '../Monetize';
import { ApprovalRejectModal } from './ApprovalRejectModal';
import Step from './Step';

const baseZIndex = 100;
interface ApprovalStepsProps {
  quote: IQuoteRespSchema;
  approvals: IApprovalRespSchema[] | null;
  // this prop is being made optional for the interim in order to support consumers
  // of this component that are not yet on React Query, it will eventually be removed
  reloadData?: () => void;
}

export const SEND_STEP = {
  id: uuidv4(),
  description: '',
  username: '',
  status: ApprovalStatusUIEnum.SENT,
} as IApprovalSchemaUI;

const SUBMITTED_STEP = {
  id: uuidv4(),
  description: '',
  username: '',
  status: ApprovalStatusUIEnum.SUBMITTED,
} as IApprovalSchemaUI;

const SIGNED_STEP = {
  id: uuidv4(),
  description: '',
  username: '',
  status: ApprovalStatusUIEnum.ACCEPTED,
} as IApprovalSchemaUI;

const MANUAL_ACCEPTANCE_STEP = {
  id: uuidv4(),
  description: '',
  username: '',
  status: ApprovalStatusUIEnum.MANUALLY_ACCEPTED,
  skipped: true,
} as IApprovalSchemaUI;

const APPROVAL_NOT_REQUIRED = {
  id: uuidv4(),
  description: '',
  username: '',
  status: ApprovalStatusUIEnum.NOT_REQUIRED,
  skipped: true,
} as IApprovalSchemaUI;

export const ApprovalSteps: FC<ApprovalStepsProps> = React.forwardRef<
  any,
  ApprovalStepsProps
>((props: ApprovalStepsProps, ref) => {
  const { quote, reloadData } = props;
  const [internalApprovals, setInternalApprovals] = useState<
    IApprovalRespSchema[]
  >(props.approvals || []);
  const {
    showConfirmModal,
    setModalLoading: setSaveLoading,
    closeModal,
  } = useConfirmModal();
  const [currentApproval, setCurrentApproval] = useState<any>(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [lastRejectedIndex, setLastRejectIndex] = useState(-1);
  const {
    isOpen: isRejectModalOpen,
    onOpen: onOpenRejectModal,
    onClose: onCloseRejectModal,
  } = useDisclosure();
  const { quoteSettings } = useQuoteSettings();

  const isQuoteProcessed = quote.status === QuoteStatusEnum.PROCESSED;
  const isQuoteSubmitted = QUOTE_SUBMITTED_STATUSES.has(quote.status);
  const isQuoteSent = QUOTE_SENT_STATUSES.has(quote.status as QuoteStatusEnum);
  const isQuoteAccepted = QUOTE_ACCEPTED_STATUSES.has(
    quote.status as QuoteStatusEnum,
  );
  const isQuoteApproved = QUOTE_APPROVED_STATUSES.has(
    quote.status as QuoteStatusEnum,
  );
  const shouldShowApprovalNotRequired =
    isQuoteApproved && props.approvals?.length === 0;

  const queryClient = useQueryClient();

  useEffect(() => {
    setInternalApprovals(sortQuoteApprovals(props.approvals || []));
  }, [props.approvals]);

  useEffect(() => {
    if (!isQuoteSubmitted) {
      // Before quote is submitted.
      setCurrentIndex(-1);
      setLastRejectIndex(internalApprovals.length);
      return;
    }

    let i = 0;
    for (i = 0; i < internalApprovals.length; i += 1) {
      if (
        !internalApprovals[i].skipped &&
        internalApprovals[i].status !== APPROVAL.APPROVAL_STATUS.APPROVED
      ) {
        break;
      }
    }
    setCurrentIndex(i);

    i = 0;
    for (i = 0; i < internalApprovals.length; i += 1) {
      if (internalApprovals[i].status === APPROVAL.APPROVAL_STATUS.DECLINED) {
        break;
      }
    }

    setLastRejectIndex(i);
  }, [internalApprovals, isQuoteSubmitted]);

  const { mutate: approveQuote, isLoading: isSaveLoading } = useMutation(
    ({ approvalId }: { approvalId: string }) => doQuoteApprove(approvalId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([...approvalsQueryKeys.dashboard()]);
        closeModal && closeModal();
      },
      onError: (error) => {
        handleApiErrorToast(error);
      },
    },
  );
  const { mutate: retractQuote, isLoading: isRetractLoading } = useMutation(
    ({ approvalId }: { approvalId: string }) => doQuoteRetract(approvalId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([...approvalsQueryKeys.dashboard()]);
        closeModal && closeModal();
      },
      onError: (error) => {
        handleApiErrorToast(error);
      },
    },
  );

  useEffect(() => {
    setSaveLoading && setSaveLoading(isSaveLoading || isRetractLoading);
  }, [isSaveLoading, isRetractLoading]);

  const onApprove = (approval: IApprovalRespSchema) => {
    const onYes = async () => {
      // this function and use of reloadData to be removed once all consumers of this component are on React Query
      setSaveLoading(true);
      try {
        await doQuoteApprove(approval.id);
      } catch (err) {
        // pass
      }
      setSaveLoading(false);

      reloadData && reloadData();
      closeModal();
    };

    // called only if the component is being used in a parent with React Query:
    const onYesWithCache = async () => {
      approveQuote({ approvalId: approval.id });
    };

    showConfirmModal({
      title: `Are you sure you want to approve this quote?`,
      description:
        'Once approved, the process will continue to the next person and they will be notified, or if this is the last step the quote will be fully approved.',
      onYes: reloadData ? onYes : onYesWithCache,
    });
  };
  const onReject = (approval: IApprovalRespSchema) => {
    setCurrentApproval(approval);
    onOpenRejectModal();
  };
  const onRetract = (approval: IApprovalRespSchema) => {
    const onYes = async () => {
      setSaveLoading(true);
      try {
        await doQuoteRetract(approval.id);
      } catch (err) {
        // pass
      }
      setSaveLoading(false);

      reloadData && reloadData();
      closeModal();
    };

    // called only if the component is being used in a parent with React Query:
    const onYesWithCache = async () => {
      retractQuote({ approvalId: approval.id });
    };

    showConfirmModal({
      title: `Are you sure you want to retract this Quote?`,
      description:
        'Retracting a Quote will invalidate the eSign Document, you can always resend the quote for signature.',
      onYes: reloadData ? onYes : onYesWithCache,
      yesBtnProps: {
        variant: 'delete',
      },
      noBtnProps: {
        variant: 'cancel',
      },
      yesButton: 'Delete',
      noButton: 'Cancel',
    });
  };

  let isNextStepSent = false;
  if (internalApprovals.length > 0) {
    const lastApproval = internalApprovals[internalApprovals.length - 1];

    isNextStepSent =
      lastApproval.skipped ||
      lastApproval.status === APPROVAL.APPROVAL_STATUS.APPROVED;
  } else {
    isNextStepSent = isQuoteSubmitted;
  }

  let isNextStepAccepted = isQuoteSent;

  return (
    <MHStack
      spacing={2}
      shouldWrapChildren
      flexWrap="wrap"
      position="relative"
      zIndex="5"
    >
      <Step
        key="submitted"
        quote={quote}
        isFirst
        isLast={false}
        approval={SUBMITTED_STEP}
        isCurrentIndex={true}
        isAfterRejected={false}
        width={80}
        extraStatus={isQuoteSubmitted ? 'DONE' : 'PENDING'}
        containerStyles={{ zIndex: baseZIndex }}
      />
      {internalApprovals.map((approval, index) => (
        <Step
          key={index}
          quote={quote}
          isFirst={false}
          isLast={false}
          approval={approval as IApprovalSchemaUI}
          onApprove={() => onApprove(approval)}
          onReject={() => onReject(approval)}
          onRetract={() => onRetract(approval)}
          isCurrentIndex={currentIndex === index}
          isAfterRejected={lastRejectedIndex < index}
          containerStyles={{ zIndex: baseZIndex - index - 1 }}
        />
      ))}
      {shouldShowApprovalNotRequired && (
        <Step
          key="approval-not-required"
          quote={quote}
          isFirst={false}
          isLast={false}
          approval={APPROVAL_NOT_REQUIRED}
          isCurrentIndex={false}
          isAfterRejected={false}
          width={40}
          extraStatus={'DONE'}
          ContentComponent={
            <MFlex alignItems="center" mx="-3">
              <MIcon as={MdOutlineTaskAlt} color="white" w={6} h={6} ml="1" />
              <MText
                fontSize="xxs"
                color="white"
                w="60px"
                ml="1"
                fontStyle="italic"
              >
                No Approvals Needed
              </MText>
            </MFlex>
          }
          containerStyles={{
            zIndex: baseZIndex - internalApprovals.length - 1,
          }}
        />
      )}
      <Step
        key="sent"
        quote={quote}
        isFirst={false}
        isLast={false}
        approval={SEND_STEP}
        isCurrentIndex={isNextStepSent}
        isAfterRejected={lastRejectedIndex < internalApprovals.length}
        width={80}
        extraStatus={isQuoteProcessed || isQuoteSent ? 'DONE' : 'PENDING'}
        containerStyles={{ zIndex: baseZIndex - internalApprovals.length - 2 }}
      />
      <Step
        key="signed"
        quote={quote}
        isFirst={false}
        isLast={!quoteSettings?.pauseAtAccepted}
        approval={
          quote.manualAcceptanceReason ? MANUAL_ACCEPTANCE_STEP : SIGNED_STEP
        }
        isCurrentIndex={isNextStepAccepted}
        isAfterRejected={lastRejectedIndex < internalApprovals.length}
        width={quoteSettings?.pauseAtAccepted ? 80 : 110}
        extraStatus={isQuoteAccepted ? 'DONE' : 'PENDING'}
        containerStyles={{ zIndex: baseZIndex - internalApprovals.length - 3 }}
      />
      {quoteSettings?.pauseAtAccepted && (
        <Step
          key="processed"
          quote={quote}
          isFirst={false}
          isLast
          approval={
            {
              id: uuidv4(),
              description: '',
              username: '',
              name: isQuoteProcessed ? 'Processed' : 'Process',
              status: ApprovalStatusUIEnum.PROCESSED,
              skipped: true,
            } as IApprovalSchemaUI
          }
          isCurrentIndex={isNextStepAccepted}
          isAfterRejected={lastRejectedIndex < internalApprovals.length}
          width={110}
          extraStatus={isQuoteProcessed ? 'DONE' : 'PENDING'}
          containerStyles={{
            zIndex: baseZIndex - internalApprovals.length - 4,
          }}
        />
      )}

      <ApprovalRejectModal
        quote={quote}
        isOpen={isRejectModalOpen}
        onClose={onCloseRejectModal}
        reloadData={reloadData}
        approval={currentApproval}
      />
    </MHStack>
  );
});
