import { BoxProps, FlexProps } from '@chakra-ui/react';
import React, { FunctionComponent as FC, ReactNode } from 'react';
import { MBox, MFlex } from './chakra';

interface MRowWithLineSeparatorProps extends FlexProps {
  leftLabel: ReactNode | JSX.Element;
  rightLabel: ReactNode | JSX.Element;
  lineProps?: BoxProps;
}

const MRowWithLineSeparator: FC<MRowWithLineSeparatorProps> = React.forwardRef<
  any,
  MRowWithLineSeparatorProps
>(
  (
    { leftLabel, rightLabel, lineProps, ...rest }: MRowWithLineSeparatorProps,
    ref,
  ) => {
    return (
      <MFlex
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        my={2}
        {...rest}
      >
        <MBox>{leftLabel}</MBox>
        <MBox
          h="1px"
          background="tGray.lightPurple"
          mx="4"
          flexGrow={1}
          {...lineProps}
        />
        <MBox>{rightLabel}</MBox>
      </MFlex>
    );
  },
);

export default MRowWithLineSeparator;
