import { useDisclosure } from '@chakra-ui/react';
import { FunctionComponent as FC, forwardRef, useState } from 'react';
import { handleApiErrorToast } from '~app/api/axios';
import { useBulkAddOfferingsToQuote } from '~app/api/cpqService';
import {
  MBox,
  MButton,
  MCenterModal,
  MCustomSelect,
  MFlex,
  MFormField,
  MStack,
  MText,
  MTooltip,
} from '~app/components/Monetize';
import { MainSearchInput } from '~app/components/Monetize/MCustomSelect/components/MainSearchInput';
import { QuoteStatusEnumDisplay } from '~app/constants/quotes';
import { useQuote } from '~app/hooks';
import { formatCurrency } from '~app/utils';
import { toDateTimeShort } from '~app/utils/dates';
import {
  CollaborationAccessEnum,
  IQuoteBasicRespSchema,
  IQuoteRespSchema,
} from '~types';
import { getQuoteOfferingsToImport } from '../quoteUtils';

export type ImportQuoteOfferingsModalProps = {
  quote: IQuoteRespSchema;
  onImported: (quote: IQuoteRespSchema) => void;
};

/**
 * Allow importing quote offerings from one quote and adding them to another quote.
 */
export const ImportQuoteOfferingsModal: FC<ImportQuoteOfferingsModalProps> = (
  props: ImportQuoteOfferingsModalProps,
) => {
  const { quote, onImported } = props;

  const { fetchQuote } = useQuote();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [additionalSearchParams] = useState({
    sort: 'modifyDate:desc',
    // status: 'ne:CANCELLED', // This would be ideal, but produces a 500 error
    status: 'in:ACCEPTED,APPROVED,DENIED,DRAFT,PROCESSED,REVIEW,SENT',
    amount: 'gt:0',
    id: `ne:${quote.id}`,
  });
  const [loading, setLoading] = useState(false);
  const [quoteIdToImport, setQuoteIdToImport] = useState<string>('');
  const { mutateAsync: saveQuoteOfferings } = useBulkAddOfferingsToQuote();

  const importQuoteOffering = async () => {
    if (!quoteIdToImport) {
      return;
    }
    setLoading(true);
    try {
      await saveQuoteOfferings({
        quoteId: quote.id,
        quoteOfferings: await getQuoteOfferingsToImport(
          quoteIdToImport,
          quote.accountId,
        ),
      });
      setQuoteIdToImport('');
      onImported((await fetchQuote(quote.id))!);
    } catch (ex) {
      handleApiErrorToast(ex);
    } finally {
      setLoading(false);
    }
  };

  function handleCancel() {
    onClose();
    setLoading(false);
    setQuoteIdToImport('');
  }

  return (
    <>
      <MButton variant="tertiary" onClick={onOpen}>
        Import Offerings
      </MButton>
      <MCenterModal
        size="sm"
        isOpen={isOpen}
        onClose={handleCancel}
        modalTitle="Import Offerings"
        renderFooter={() => (
          <MStack
            spacing={4}
            direction="row"
            align="center"
            justify="right"
            flex={1}
          >
            <MButton variant="secondary" onClick={handleCancel} minW="auto">
              Cancel
            </MButton>
            <MButton
              variant="primary"
              isLoading={loading}
              isDisabled={loading || !quoteIdToImport}
              onClick={importQuoteOffering}
              minW="auto"
            >
              Import
            </MButton>
          </MStack>
        )}
      >
        <MFormField label="Import offerings from">
          <MCustomSelect
            useMainInputAsSearchInput
            itemTitle="description"
            itemValue="id"
            displayAvatar
            endpoint={`/api/quotes?&collaborationAccess=in:${CollaborationAccessEnum.VIEW},${CollaborationAccessEnum.EDIT}`}
            itemSearch="description"
            additionalSearchParams={additionalSearchParams}
            onChange={(id: any) => setQuoteIdToImport(id || '')}
            renderItemContent={({ title, item, isHighlight, isSelected }) => (
              <MTooltip
                label={<ItemTooltipLabel quote={item} />}
                placement="bottom-end"
                w="100%"
              >
                <MText
                  w="100%"
                  color="inherit"
                  isTruncated
                  noOfLines={1}
                  fontSize="sm"
                  mb="-2px"
                  mt="0px"
                >
                  {title}
                </MText>
              </MTooltip>
            )}
            MainInputComponent={MainSearchInput}
            value={quoteIdToImport}
          />
        </MFormField>
      </MCenterModal>
    </>
  );
};

const ItemTooltipLabel = forwardRef<any, { quote: IQuoteBasicRespSchema }>(
  ({ quote }: { quote: IQuoteBasicRespSchema }, ref) => {
    return (
      <MFlex ref={ref} flexDirection="column">
        <MText color="inherit" fontSize={12}>
          Account: <MBox as="span">{quote.accountName}</MBox>
        </MText>
        <MText color="inherit" fontSize={12}>
          Status: <MBox as="span">{QuoteStatusEnumDisplay[quote.status]}</MBox>
        </MText>
        <MText color="inherit" fontSize={12}>
          Amount: <MBox as="span">{formatCurrency(quote.amount)}</MBox>
        </MText>
        <MText color="inherit" fontSize={12}>
          Last Modified:{' '}
          <MBox as="span">{toDateTimeShort(quote.modifyDate)}</MBox>
        </MText>
      </MFlex>
    );
  },
);
