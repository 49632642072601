import { BoxProps } from '@chakra-ui/react';
import { FunctionComponent as FC } from 'react';
import { MBox, MText } from '../Monetize';

interface BillingInfoProps extends BoxProps {
  title: string;
  fullName?: string; // full name is optional for (From)
  accountName: string;
  addressLine1: string;
  addressLine2: string;
  otherAddress: string;
  phone?: string;
  email: string;
}

export const BillingInfo: FC<BillingInfoProps> = (props: BillingInfoProps) => {
  const {
    title,
    fullName,
    accountName,
    addressLine1,
    addressLine2,
    otherAddress,
    phone,
    email,
    ...rest
  } = props;
  return (
    <MBox minW={50} {...rest}>
      <MText fontWeight="bold" mt={2}>
        {title}
      </MText>

      <MText data-testid={`${title}-fullName`} noOfLines={3}>
        {fullName}
      </MText>
      <MText data-testid={`${title}-companyName`} noOfLines={3}>
        {accountName}
      </MText>
      <MText data-testid={`${title}-addressLine1`} noOfLines={3}>
        {addressLine1}
      </MText>
      <MText data-testid={`${title}-addressLine2`} noOfLines={3}>
        {addressLine2}
      </MText>
      <MText data-testid={`${title}-otherAddress`} noOfLines={3}>
        {otherAddress}
      </MText>
      <MText data-testid={`${title}-phone`} noOfLines={3}>
        {phone}
      </MText>
      <MText data-testid={`${title}-email`} noOfLines={3}>
        {email}
      </MText>
    </MBox>
  );
};
