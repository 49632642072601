import {
  Box,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  useStyleConfig,
  Wrap,
} from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import React, { FC, FocusEvent } from 'react';
import { MdArrowDropDown, MdClose } from 'react-icons/md';
import { truncateMiddle } from '~app/utils';
import MCustomIconButton from '../../MCustomIconButton';
import { MainInputProps } from './MainInput';

export const MainSearchInputV2: FC<MainInputProps> = React.forwardRef<
  any,
  MainInputProps
>(
  (
    {
      getTitleFromValue,
      disabled,
      isReadOnly,
      onOpen,
      onClose,
      loading,
      value,
      onToggleOpen,
      externalLoading,
      placeholder,
      isOpen,
      items,
      cursor,
      setCursor,
      handleChange,
      showContentInInput,
      renderContentInInput,
      inputProps,
      renderRightElement,
      clearable,
      onClear,
      onChange,
      queryResultCount,
      query,
      onQueryKeyDown,
      onQueryChange,
      isSubtitleItem,
      isTruncatedInputTitle,
    },
    ref,
  ) => {
    const styles = useStyleConfig('Input', { variant: 'primary' });
    const onBlurInput = (ev: FocusEvent<any>) => {
      // Don't close popover if focus is within the popover content
      if (
        ev.relatedTarget?.hasAttribute('data-custom-select-item') ||
        ev.relatedTarget?.classList?.contains('chakra-popover__content')
      ) {
        return;
      }
      onClose();
    };

    const getBgColor = () => {
      if (disabled) {
        return 'tGray.back';
      }
      if (isOpen) {
        return 'tBlue.hover';
      }
      return 'tWhite.base';
    };
    const getBorderColor = () =>
      isOpen ? 'tBlue.lightShade' : 'tGray.lightPurple';

    const rightElement = renderRightElement && renderRightElement({ value });

    const handleOnClear = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      if (onClear) {
        onClear();
      } else {
        onChange && onChange('' as any);
      }
    };
    const showClearBtn = clearable && !isEmpty(value) && !disabled;
    let rightMargin = rightElement ? rightElement.width : 8;
    if (showClearBtn) {
      rightMargin += 4;
    }

    let inputValue = '';
    if (isOpen) {
      inputValue = query;
    } else if (!isOpen) {
      const title = getTitleFromValue(value) || '';
      inputValue = isTruncatedInputTitle ? truncateMiddle(title) : title;
    }

    if (!showContentInInput) {
      return (
        <InputGroup>
          <Input
            type="text"
            ref={ref}
            placeholder={placeholder}
            onChange={(e: any) => {
              !isOpen && onOpen(); // open if required
              onQueryChange(e.target.value);
            }}
            value={inputValue}
            onClick={() => !disabled && onOpen()}
            onBlur={onBlurInput}
            isDisabled={disabled}
            isReadOnly={isReadOnly}
            variant={isReadOnly ? 'readonly' : 'primary'}
            bg={getBgColor()}
            borderColor={isOpen ? 'tBlue.lightShade' : 'tGray.lightPurple'}
            color={isOpen ? 'tIndigo.base' : 'tPurple.dark'}
            tabIndex={isOpen ? -1 : 0}
            pr={`${rightMargin * 4}px`}
            {...inputProps}
          />
          {!isReadOnly && (
            <InputRightElement width={`${rightMargin * 4}px`}>
              {rightElement?.content}
              {showClearBtn && (
                <MCustomIconButton
                  icon={MdClose}
                  bg="none"
                  color="tPurple.dark"
                  _hover={{ bg: 'none' }}
                  ml="2"
                  btnSize={4}
                  padding={0}
                  containerSize={4}
                  onClick={handleOnClear}
                />
              )}
              {loading || externalLoading ? (
                <Spinner size="xs" />
              ) : (
                <Icon
                  as={MdArrowDropDown}
                  fontSize="20"
                  onClick={!disabled ? onToggleOpen : () => {}}
                  zIndex="1"
                />
              )}
            </InputRightElement>
          )}
        </InputGroup>
      );
    }
    return (
      <InputGroup
        __css={(styles as any).field}
        bg={getBgColor()}
        borderColor={getBorderColor()}
        display="flex"
        flexWrap="wrap"
        height="auto"
        position="relative"
        alignItems="center"
        px="1"
      >
        <Wrap spacing={0.5}>
          {showContentInInput &&
            renderContentInInput &&
            renderContentInInput({ onToggleOpen })}
          <Flex alignItems="center" flex="1" display="grid">
            <Input
              type="text"
              ref={ref}
              placeholder={placeholder}
              onChange={() => {}}
              value={inputValue}
              onFocus={() => !disabled && onOpen()}
              onBlur={onBlurInput}
              isDisabled={disabled}
              tabIndex={isOpen ? -1 : 0}
              border="none"
              _focus={{ border: 'none' }}
              width="auto"
              bg="none"
              _placeholder={{
                color: 'tGray.darkGrayPurple',
              }}
              {...inputProps}
            />
          </Flex>
        </Wrap>
        <Box
          onClick={!disabled ? onToggleOpen : () => {}}
          position="absolute"
          right="0"
          bottom="1"
        >
          {loading || externalLoading ? (
            <Spinner size="xs" />
          ) : (
            <Icon as={MdArrowDropDown} fontSize="20" zIndex="1" />
          )}
        </Box>
      </InputGroup>
    );
  },
);
