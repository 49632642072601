import { z } from 'zod';
import { getRequiredMessage } from '~app/utils/messages';

export type IEventType = {
  description: string;
  key: string;
};
export interface IEventCategory {
  category: string;
  types: IEventType[];
}

export const IWebhookEventSchema = z.object({
  eventType: z.string(),
});

export type IWebhookEvent = z.infer<typeof IWebhookEventSchema>;

export const IWebhookEventSchemas = z
  .array(IWebhookEventSchema)
  .nonempty({ message: 'At least 1 event should be selected.' });

export interface IWebhookResSchema {
  createdTime: string;
  description: string;
  enabled: boolean;
  locked: boolean;
  secret: string;
  tenantId: string;
  updatedTime: string;
  url: string;
  webhookId: string;
  events: z.infer<typeof IWebhookEventSchemas>;
}

export const WebhookReqSchema = z.object({
  url: z
    .string()
    .nonempty(getRequiredMessage('URL'))
    .url()
    .refine(
      (val) => {
        return /^https?:\/\//.test(val);
      },
      { message: 'URL should start with http:// or https://' },
    ),
  events: IWebhookEventSchemas,
  enabled: z.boolean().optional(),
  description: z.string().nonempty(getRequiredMessage('Description')),
});

export type IWebhookReqSchema = z.infer<typeof WebhookReqSchema>;
