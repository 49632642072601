import { BoxProps, Icon, IconProps } from '@chakra-ui/react';
import { FC } from 'react';
import { AiOutlineLink } from 'react-icons/ai';
import { MFlex, MIDCopyBox } from '.';
import { MClipboardCopyBox } from './MClipboardCopyBox';
import { MIDCopyBoxProps } from './MIDCopyBox';

interface MStatusIDBoxProps extends BoxProps {
  id?: string;
  /** passing true defaults to current page's URL */
  copyUrl?: string | boolean;
  /** Allows a custom copy id component */
  customIdCopyBox?: React.ReactNode;
  /** Whether to show copy icon beside the id */
  displayIcon?: boolean;
  iconProps?: IconProps;
  idCopyProps?: Partial<MIDCopyBoxProps>;
}

export const MStatusIDBox: FC<MStatusIDBoxProps> = ({
  id,
  copyUrl,
  customIdCopyBox,
  displayIcon = true,
  iconProps,
  idCopyProps,
  ...rest
}: MStatusIDBoxProps) => {
  return (
    <MFlex alignItems="center" columnGap={0} {...rest}>
      {id && (
        <MIDCopyBox
          copyValue={id}
          mr={1}
          displayIcon={displayIcon}
          {...idCopyProps}
        />
      )}
      {id && copyUrl && !customIdCopyBox && (
        //id and/or status serve as a loading signal. If either exist show the copy url icon
        <MClipboardCopyBox
          copyValue={
            typeof copyUrl === 'string' ? copyUrl : window.location.href
          }
          tooltipMessage="Copy URL"
          renderContent={() => {
            return (
              <Icon
                verticalAlign="middle"
                as={AiOutlineLink}
                boxSize={4}
                color="tPurple.dark"
                cursor="pointer"
                {...iconProps}
              />
            );
          }}
        />
      )}
      {customIdCopyBox}
    </MFlex>
  );
};
