import { Icon, Link } from '@chakra-ui/react';
import { IoMdSwap } from 'react-icons/io';
import {
  MButton,
  MDivider,
  MFlex,
  MLink,
  MText,
} from '~app/components/Monetize';
import { IQuoteRespSchema, QuoteTypeEnum } from '~app/types';
import { formatCurrency } from '~app/utils';
import { useQuoteContext } from '../../routes/Quotes/Quote/quoteContext';
import { useFlags } from '../../services/launchDarkly';
import { MLastSavedStatus } from '../Monetize/MLastSavedStatus';
import { QuoteAnalyticsV2 } from './quoteAnalytics/QuoteAnalyticsV2';

const QuoteHeaderSummaryItem = ({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}) => {
  return (
    <MFlex alignItems="center">
      <MText fontWeight="bold" mr="2">
        {label}:{' '}
      </MText>
      {children}
    </MFlex>
  );
};

export const QuoteHeaderSummary = () => {
  const { quoteInternalExternalView } = useFlags();
  const {
    isReadOnly,
    isError,
    quoteData,
    quoteStateData,
    manualRenewalFormMethods,
    isInternalView,
    isQuoteSaving,
    setIsInternalView,
  } = useQuoteContext();
  const { quote, setQuote } = quoteData;
  const { offeringLoadingState } = quoteStateData;
  const anyOfferingLoading = Object.values(offeringLoadingState).some(
    (item) => item.isLoading,
  );

  if (!quote) {
    return null;
  }

  return (
    <MFlex alignItems="center">
      {!isReadOnly && quote.modifyDate && isInternalView && (
        <>
          <MLastSavedStatus
            isLoading={quoteData.saving || anyOfferingLoading || isQuoteSaving}
            isError={isError}
          >
            <MDivider
              orientation="vertical"
              mx="3"
              borderColor="tBlack.200"
              height="20px"
            />
          </MLastSavedStatus>
        </>
      )}

      {quoteInternalExternalView && (
        <>
          <MButton
            size="sm"
            variant="tertiary"
            minW="128px"
            onClick={() => setIsInternalView(!isInternalView)}
          >
            {isInternalView ? 'Internal View' : 'External View'}
            <Icon as={IoMdSwap} ml="1" />
          </MButton>
          <MDivider
            orientation="vertical"
            mx="3"
            borderColor="tBlack.200"
            height="20px"
          />
        </>
      )}

      <QuoteHeaderSummaryItem label="ARR">
        <QuoteAnalyticsV2
          quote={quote as IQuoteRespSchema}
          setQuote={setQuote}
          manualRenewalFormMethods={manualRenewalFormMethods}
          renderTrigger={({ onOpen }) => (
            <MLink fontSize="sm" fontWeight="normal" as={Link} onClick={onOpen}>
              {formatCurrency(quote?.revenueMetrics.arr, {
                currency: quote?.currency,
              })}
            </MLink>
          )}
        />
      </QuoteHeaderSummaryItem>

      <MDivider
        orientation="vertical"
        mx="3"
        borderColor="tBlack.200"
        height="20px"
      />
      <QuoteHeaderSummaryItem
        label={quote.type === QuoteTypeEnum.AMENDMENT ? 'iARR' : 'TCV'}
      >
        <QuoteAnalyticsV2
          quote={quote as IQuoteRespSchema}
          setQuote={setQuote}
          manualRenewalFormMethods={manualRenewalFormMethods}
          renderTrigger={({ onOpen }) => (
            <MLink fontSize="sm" fontWeight="normal" as={Link} onClick={onOpen}>
              {formatCurrency(
                quote.type === QuoteTypeEnum.AMENDMENT
                  ? quote?.revenueMetrics.incrementalArr
                  : quote?.amount,
                { currency: quote?.currency },
              )}
            </MLink>
          )}
        />
      </QuoteHeaderSummaryItem>
    </MFlex>
  );
};
