import lodashGet from 'lodash/get';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import {
  DEFAULT_PAGER,
  GetListApiConfig,
  GetListApiFilter,
  IQuoteRequestSchema,
  IQuoteRespSchema,
} from '~types';
import { ApiQueryItem } from './queryUtils';

/**
 * Helper function to allow type inference of any arbitrary object key
 *
 * @param queryUtilObj This is an object with arbitrary (but well-named) keys that are of type ApiQueryItem
 *  The keys you choose should be globally unique across all services and should not conflict with any other keys
 * @returns
 */
export const asQueryUtil = <T>(queryUtilObj: {
  [K in keyof T]: ApiQueryItem;
}) => queryUtilObj;

export type ComposeQueryType = {
  currentPage?: number;
  pageSize?: number;
  sort?: string;
  [key: string]: any;
};

export const composeFiltersQuery = (filters: GetListApiFilter) => {
  const result: any = {};
  Object.keys(filters).forEach((key) => {
    const filter = filters[key] as any;

    // Contains
    if (filter && typeof filter.contains !== 'undefined') {
      if (filter.contains) {
        result[key] = `contains:${filter.contains}`;
      }

      // In
    } else if (filter && typeof filter.in !== 'undefined') {
      if (Array.isArray(filter.in) && filter.in.length > 0) {
        result[key] = `in:${filter.in.join(',')}`;
      }

      // Between
    } else if (filter && typeof filter.between !== 'undefined') {
      const res: string[] = [];
      if (typeof filter.between.bt !== 'undefined') {
        // ie. bt:100,200
        res.push(`bt:${filter.between.bt}`);
      }
      if (typeof filter.between.gt !== 'undefined') {
        res.push(`gt:${filter.between.gt}`);
      }
      if (typeof filter.between.gte !== 'undefined') {
        res.push(`gte:${filter.between.gte}`);
      }
      if (typeof filter.between.lt !== 'undefined') {
        res.push(`lt:${filter.between.lt}`);
      }
      if (typeof filter.between.lte !== 'undefined') {
        res.push(`lte:${filter.between.lte}`);
      }
      // backend does not support multiple conditions for the same field
      // so we cannot send '&contractAmount[]=bt:0,100' but instead '&contractAmount=bt:0,100'
      result[key] = res.length === 1 ? res[0] : res;
    } else if (filter && typeof filter.ne !== 'undefined') {
      result[key] = `ne:${filter.ne}`;
    } else if (filters[key] !== '' && typeof filters[key] !== 'object') {
      result[key] = filters[key];
    }
  });
  return result;
};

export const composeGetQuery = (
  config: GetListApiConfig = {},
  filters?: GetListApiFilter,
) => {
  let result: ComposeQueryType = {};

  if (config.nextPageToken) {
    result.nextPageToken = config.nextPageToken;
  }

  let currentPage = 0;
  if (!(filters?.query || filters?.fullName) && config.page) {
    currentPage = config.page;
  }

  result.currentPage = currentPage;
  result.pageSize = config.rows || DEFAULT_PAGER.rows;

  // sorting
  // passing config.sortOrder === null will not cause any sort value to be automatically appended to the query
  // this is needed for cases where we have a mix of sortFields with different sortOrders, like "defaultCurrency:desc,code:asc"
  // note that not setting config.sortOrder will still result in a sort order being appended (since it will be undefined, not null)
  // See https://monetizenow.atlassian.net/wiki/spaces/PE/pages/676069377/API+Development+Guidelines
  if (config.sortField) {
    let sort = config.sortField;
    if (config.sortOrder !== null) {
      sort = `${sort}:${
        config.sortOrder && config.sortOrder > 0 ? 'asc' : 'desc'
      }`;
    }

    result.sort = sort;
  }

  // result.sort = `${config.sortField}`;

  if (filters) {
    result = {
      ...result,
      ...composeFiltersQuery(filters),
    };
  }

  return result;
};

export const ensureArray = <T>(value: T | T[]): T[] => {
  if (Array.isArray(value)) {
    return value;
  }
  if (isNil(value)) {
    return [];
  }
  return [value];
};

/**
 * Given a quote, prepare a QuoteRequest payload with the provided data updates
 */
export const getQuoteUpdateRequestFromQuote = (
  quote: IQuoteRespSchema,
  dataUpdates: Partial<IQuoteRequestSchema>,
) => {
  const quoteResponse: IQuoteRequestSchema = {
    accountId: quote.accountId,
    autoRenewContract: quote.autoRenewContract,
    billGroupId: quote.billGroupId,
    collaborationAccess: quote.collaborationAccess,
    contractAmendmentDate: quote.contractAmendmentDate,
    contractLength: quote.contractLength,
    contractRenewalTerms: quote.contractRenewalTerms,
    contractStartDate: quote.contractStartDate,
    contractTerms: quote.contractTerms,
    currency: quote.currency,
    customFields: quote.customFields,
    customId: quote.customId,
    description: quote.description,
    documentUrl: quote.documentUrl,
    expirationDate: quote.expirationDate,
    legalEntityId: quote.legalEntityId,
    netTerms: quote.netTerms,
    opportunityId: quote.opportunity?.id,
    requiresEsign: quote.requiresEsign,
    startDateSource: quote.startDateSource,
    ...dataUpdates,
  };
  return quoteResponse;
};

export function flattenObjects<T extends object>(
  records: T[],
): Record<string, string>[] {
  return records.map((record) => flattenObject(record));
}

export function flattenObject<T extends object>(
  record: T,
  parentKey = '',
  collectorObj = {},
): Record<string, string> {
  return Object.keys(record).reduce((obj, field) => {
    const value = lodashGet(record, field);
    const key = parentKey ? `${parentKey}.${field}` : field;
    if (value && !Array.isArray(value) && isObject(value)) {
      flattenObject(value, key, collectorObj);
    } else {
      (obj as any)[key] = value;
    }
    return obj;
  }, collectorObj);
}
