import { Flex, FlexProps, Icon, Text } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { FunctionComponent as FC, ReactNode, useRef, useState } from 'react';
import { MdAutorenew, MdCheckCircle, MdError } from 'react-icons/md';
import { useNonInitialEffect } from '../../hooks/useNonInitialEffect';

const DURATION_MS = 3000;

interface MLastSavedStatusProps extends FlexProps {
  isLoading: boolean;
  isError: boolean;
  children?: ReactNode;
}

export const MLastSavedStatus: FC<MLastSavedStatusProps> = ({
  isLoading,
  isError,
  children,
  ...rest
}: MLastSavedStatusProps) => {
  const timeoutRef = useRef<any>(null);
  const [isVisible, setIsVisible] = useState(false);

  useNonInitialEffect(() => {
    // Show when loading starts or stops
    setIsVisible(true);
    // clear any other timeouts
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    // if success, then clear after {DURATION_MS} seconds
    if (!isLoading && !isError) {
      timeoutRef.current = setTimeout(() => {
        setIsVisible(false);
      }, DURATION_MS);
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isLoading, isError]);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1 }}
        >
          <Flex alignItems="center" {...rest}>
            {isLoading && (
              <>
                <Icon as={MdAutorenew} h={4} w={4} color="tGray.darkPurple" />
                <Text
                  ml={1}
                  color="tGray.darkPurple"
                  fontSize={'sm'}
                  fontWeight="normal"
                >
                  Saving..
                </Text>
              </>
            )}

            {!isLoading && isError && (
              <>
                <Icon as={MdError} h={4} w={4} color="tRed.base" />
                <Text
                  ml={1}
                  color="tRed.base"
                  fontSize={'sm'}
                  fontWeight="normal"
                >
                  Could not save changes
                </Text>
              </>
            )}

            {!isLoading && !isError && (
              <>
                <Icon as={MdCheckCircle} color="tGreen.approval" h={4} w={4} />
                <Text
                  ml={1}
                  color="tGreen.approval"
                  fontSize={'sm'}
                  fontWeight="normal"
                >
                  Saved
                </Text>
              </>
            )}
            {children}
          </Flex>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
