import { TextProps } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import { QUOTE_MATERIALIZE } from '~app/constants/quotes';
import {
  IQuoteItemRespSchema,
  IQuoteOfferingReqSchema,
  IQuotePrice,
  IQuoteRespSchema,
  IQuoteTemplateConfigSchema,
  IRateResSchema,
  ProductTypeEnum,
} from '~app/types';
import {
  getPricePerUnitWithRateFrequencyShortCode,
  getUnitPriceAfterDiscount,
  getUnitPriceBeforeDiscount,
} from '~app/utils/quotes';
import { MBox, MFlex, MGrid, MText } from '../Monetize';
import { MTextProps } from '../Monetize/MText';
import CustomPriceInput from './CustomPriceInput';

type UnitPriceProps = {
  item: IQuoteItemRespSchema;
  beforeDiscountTextProps?: TextProps;
  amountTextProps?: TextProps;
  quote: IQuoteRespSchema;
  errors?: any;
  control?: Control<IQuoteOfferingReqSchema>;
  index?: number;
  handleQuoteOfferingWithoutDirtyCheck?: () => void;
  setValue?: UseFormSetValue<IQuoteOfferingReqSchema>;
  isCustomPrice?: boolean;
  showEnable?: boolean;
  onClick?: () => void;
  styles?: MTextProps;
  offeringRate?: IRateResSchema | null;
  productType?: ProductTypeEnum;
  isDisabled?: boolean;
  /** Used to get the usage price before/after discount */
  quotePrices: IQuotePrice[];
  displayConfig?: IQuoteTemplateConfigSchema | null;
  parentQuoteOfferingId?: string;
};

const getShouldHidePPU = (
  item: IQuoteItemRespSchema,
  displayConfig?: IQuoteTemplateConfigSchema | null,
) => {
  return (
    ['Capacity Usage', 'On-Demand Usage'].includes(item.productName) &&
    displayConfig?.defaultTemplate === QUOTE_MATERIALIZE
  );
};
const UnitPrice: FC<UnitPriceProps> = ({
  item,
  beforeDiscountTextProps,
  amountTextProps,
  quote,
  isCustomPrice = false,
  showEnable = false,
  onClick,
  errors,
  control,
  index,
  handleQuoteOfferingWithoutDirtyCheck,
  setValue,
  styles,
  isDisabled = false,
  offeringRate,
  productType,
  quotePrices,
  displayConfig,
  parentQuoteOfferingId,
  ...rest
}: UnitPriceProps) => {
  const unitPriceBeforeDiscount = getUnitPriceBeforeDiscount(
    item,
    quotePrices,
    quote.currency,
    offeringRate?.id!,
    parentQuoteOfferingId,
  );
  // show custom price input if custom price products do not have a custom price
  const [showCustomPriceInput, setShowCustomPriceInput] =
    useState<boolean>(false);
  // show custom price input based on mouse actions
  const [toggleInput, setToggleInput] = useState<boolean>(false);
  const [focused, setFocused] = useState<boolean>(false);

  useEffect(() => {
    if (!!isCustomPrice && !item?.customPriceId) {
      setShowCustomPriceInput(true);
    } else {
      setShowCustomPriceInput(false);
    }
  }, [isCustomPrice, item]);

  const defaultValue = item?.amountWithoutDiscount || item?.amount;
  const renderCustomPriceInput = showCustomPriceInput || focused || toggleInput;
  const isUnitPriceBeforeDiscount = !!unitPriceBeforeDiscount.length;

  const shouldHidePPU = getShouldHidePPU(item, displayConfig);
  if (shouldHidePPU) {
    return <div />;
  }

  return (
    <MGrid
      data-testid="unit-price-cell"
      onMouseEnter={() => !isDisabled && isCustomPrice && setToggleInput(true)}
      onMouseLeave={() => !isDisabled && isCustomPrice && setToggleInput(false)}
      onMouseDown={() => !isDisabled && isCustomPrice && setFocused(true)}
      gridTemplateColumns="1fr"
      alignSelf="center"
      justifyItems="end"
      minH={isUnitPriceBeforeDiscount ? 8 : undefined} // TODO: find alternative ways instead of using min height
      {...rest}
    >
      <MFlex alignItems={'center'}>
        <MBox textAlign="right">
          {renderCustomPriceInput && (
            <CustomPriceInput
              errors={errors}
              control={control}
              index={index}
              defaultValue={defaultValue}
              focused={focused}
              isDisabled={isDisabled}
              handleChange={(diff, close) => {
                if (close) {
                  setFocused(false);
                  setToggleInput(false);
                  setShowCustomPriceInput(false);
                }
                if (diff) {
                  handleQuoteOfferingWithoutDirtyCheck &&
                    handleQuoteOfferingWithoutDirtyCheck();
                }
              }}
              item={item}
            />
          )}
          {!renderCustomPriceInput && (
            <>
              {isUnitPriceBeforeDiscount && (
                <MText
                  gridColumn="1"
                  gridRow="1"
                  textDecoration="line-through"
                  fontSize="xs"
                  alignSelf="start"
                  data-testid={`${item.id}-unitPriceBeforeDiscount`}
                  {...beforeDiscountTextProps}
                  {...styles}
                  pr="0"
                  color="tGray.darkPurple"
                >
                  {getPricePerUnitWithRateFrequencyShortCode({
                    priceStr: unitPriceBeforeDiscount,
                    offeringRate,
                    productType: productType,
                    isCustomPrice: isCustomPrice,
                    item,
                  })}
                </MText>
              )}
              <MText
                gridColumn="1"
                gridRow="1"
                alignSelf={!isUnitPriceBeforeDiscount ? 'center' : 'end'}
                data-testid={`${item.id}-unitPrice`}
                {...amountTextProps}
                {...styles}
                textDecoration="initial"
                color="tPurple.base"
                fontWeight="600"
              >
                {getPricePerUnitWithRateFrequencyShortCode({
                  priceStr: getUnitPriceAfterDiscount({
                    parentQuoteOfferingId,
                    item,
                    quotePrices,
                    currency: quote.currency,
                    offeringId: offeringRate?.offering.id,
                    rateId: offeringRate?.id!,
                  }),
                  offeringRate,
                  productType: productType,
                  isCustomPrice: isCustomPrice,
                  item,
                })}
              </MText>
            </>
          )}
        </MBox>
      </MFlex>
    </MGrid>
  );
};

export default UnitPrice;
