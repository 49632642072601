import { ColumnProps } from 'primereact/column';
import { DataTableExpandedRows } from 'primereact/datatable';
import React from 'react';
import { MDataTable } from '~app/components/Monetize';
import { ISubscriptionWithBillGroup } from '~app/hooks/AccountDetail';
import { IGetSubscriptionItemSchema } from '~app/types';
import { UsageListTable } from './UsageListTable';

interface UsageSubscriptionTableProps {
  accountId: string;
  loading: boolean;
  columns: ColumnProps[];
  expandedRows: DataTableExpandedRows;
  setExpandedRows: React.Dispatch<React.SetStateAction<DataTableExpandedRows>>;
  subscriptionsWithUsage: ISubscriptionWithBillGroup[] | undefined;
  usageConsumptions: Map<
    string,
    Record<string, { unitsConsumed: number; error: boolean }>
  >;
  billGroupId?: string;
  onRowClick: (id: string) => void;
  handleUsageUpload: (
    subscriptionId: string,
    subscriptionItem: IGetSubscriptionItemSchema,
  ) => void;
  handleViewTrendSingleProduct: (
    subscriptionId: string,
    productId: string,
  ) => void;
}

export const UsageSubscriptionTable = ({
  accountId,
  loading,
  columns,
  expandedRows,
  setExpandedRows,
  subscriptionsWithUsage,
  usageConsumptions,
  billGroupId,
  onRowClick,
  handleUsageUpload,
  handleViewTrendSingleProduct,
}: UsageSubscriptionTableProps) => {
  return (
    <MDataTable
      noBackgroundOnHover
      dataKey="id"
      tableStyle={{ tableLayout: 'fixed' }}
      loading={loading}
      columns={columns}
      expandedRows={expandedRows}
      value={subscriptionsWithUsage}
      totalRecords={subscriptionsWithUsage?.length}
      paginator={false}
      className="p-datatable-responsive"
      emptyProps={{
        mainMessage: `No Usage products on this ${
          billGroupId ? 'bill group' : 'account'
        }`,
        smallMessage: 'Usage products can be added on Quotes',
      }}
      onRowToggle={({ data }) => setExpandedRows(data as DataTableExpandedRows)}
      onRowClick={({ data: { id } }) => {
        onRowClick(id);
      }}
      rowExpansionTemplate={(data: ISubscriptionWithBillGroup) => {
        const unitsConsumed = usageConsumptions.get(data.id);

        return (
          <UsageListTable
            accountId={accountId}
            subscriptionId={data.id}
            subscriptionUsageProducts={data.subscriptionItems}
            unitsConsumed={unitsConsumed}
            billGroupId={billGroupId}
            handleUsageUpload={handleUsageUpload}
            handleViewTrendSingleProduct={handleViewTrendSingleProduct}
          />
        );
      }}
    />
  );
};
