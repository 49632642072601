import {
  CreditNoteRefundReasonEnum,
  CreditStatusEnum,
  CreditTypeEnum,
  IUpsertCreditSchema,
} from '~app/types';
import { objectToSelectOptions } from '~app/utils/misc';

export const CreditTypeEnumDisplay: {
  [key in CreditTypeEnum]: string;
} = {
  SERVICE: 'Service Credit',
  PREPAID: 'Prepaid Credit',
  CREDIT_NOTE: 'Credit Note',
};

export const CreditStatusEnumDisplay: {
  [key in CreditStatusEnum]: string;
} = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  CANCELED: 'Canceled',
  APPLIED: 'Applied',
  VOID: 'Voided',
};

export const INIT_NEW_CREDIT: IUpsertCreditSchema = {
  name: '',
  billGroupId: '',
  currency: '',
  amount: '' as any,
  expirationDate: '',
  reason: '',
  type: CreditTypeEnum.SERVICE,
  status: CreditStatusEnum.ACTIVE,
  refundable: false,
  customFields: null,
};

export const CREDIT_TYPE_OPTIONS = objectToSelectOptions(CreditTypeEnumDisplay);

export const CREDIT_NOTE_REFUND_REASON_DISPLAY: {
  [key in CreditNoteRefundReasonEnum]: string;
} = {
  ADJUSTMENT: 'Adjustment',
  BILLING_ERROR: 'Billing Error',
  CANCELLATION: 'Cancelation',
  SERVICE_ISSUE: 'Service Issue',
  OTHER: 'Other',
};

export const CREDIT_NOTE_REFUND_REASON_OPTIONS = objectToSelectOptions(
  CREDIT_NOTE_REFUND_REASON_DISPLAY,
);
