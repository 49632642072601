import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useGetQuoteStorageById } from '~app/api/cpqService';
import {
  MAccordion,
  MBox,
  MButton,
  MFlex,
  MPageLoader,
} from '~app/components/Monetize';
import { PreviewQuotePdfDrawerV2 } from '~app/components/Quotes/PreviewQuotePdfDrawerV2';
import { IQuoteRequestSchema } from '~app/types';
import { ensureBoolean } from '~app/utils';
import { useDocumentHead } from '~services/documentHead';
import { ReviewQuoteHtml } from '../../Quote/ReviewQuoteHtml';
import { QuoteConfetti } from '../../Quote/components/QuoteConfetti';
import { useQuoteContext } from '../../Quote/quoteContext';
import { QuoteBasic } from './QuoteBasic';

interface QuotePageCustomerProps {
  setContactDrawerOpen: (val: boolean) => void;
}
export const QuotePageCustomer = ({
  setContactDrawerOpen,
}: QuotePageCustomerProps) => {
  const {
    quoteData: { quote, displayConfig },
    setReviewQuoteDisplayWidth,
  } = useQuoteContext();
  const reviewQuoteRef = useRef<any>();
  const [searchParams] = useSearchParams();
  const isCelebrate = searchParams.get('celebrate');
  const { data: document } = useGetQuoteStorageById(
    {
      quoteId: quote?.id!,
      storageId: quote?.documentLocation!,
      params: { attachContent: true },
    },
    { enabled: !!quote?.id && !!quote?.documentLocation },
  );

  const { setValue } = useFormContext<IQuoteRequestSchema>();
  // NOTE: Leave these setDocTitle within the index.tsx and QuotePageCustomer.tsx
  const { setDocTitle } = useDocumentHead();
  useEffect(() => {
    setDocTitle(
      `Review Quote${quote?.description ? ` - ${quote.description}` : ''}`,
    );
  }, [quote?.description]);

  useEffect(() => {
    if (reviewQuoteRef.current) {
      // Calculating review quote display width and removing padding
      setReviewQuoteDisplayWidth(reviewQuoteRef.current.clientWidth - 64);
    }
  }, [reviewQuoteRef, document?.contentBase64Encoded]);

  if (!quote || !displayConfig) {
    return <MPageLoader />;
  }

  return (
    <>
      <QuoteConfetti active={ensureBoolean(isCelebrate)} />
      <MAccordion
        w="100%"
        mt={4}
        mb={2}
        allowToggle
        variant="outline"
        defaultIndex={[]}
      >
        <QuoteBasic
          onOpenContactDrawer={() => {
            setContactDrawerOpen(true);
          }}
        />
      </MAccordion>
      <MFlex alignItems="center" my="1" alignSelf="flex-end">
        <PreviewQuotePdfDrawerV2
          quote={quote}
          renderTrigger={({ handleOpen }) => (
            <MButton variant="tertiary" onClick={handleOpen} minW="auto">
              Preview PDF
            </MButton>
          )}
        />
      </MFlex>
      <MBox
        w="full"
        borderWidth={0.5}
        borderColor="tBlue.hover"
        background="tWhite.titanWhite"
        ref={reviewQuoteRef}
      >
        {!!quote && !!displayConfig && (
          <ReviewQuoteHtml quote={quote} displayConfig={displayConfig} />
        )}
      </MBox>
    </>
  );
};
