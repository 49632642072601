import { Buffer } from 'buffer';
import { saveAs } from 'file-saver';
import { MimeTypeEnum } from '~app/types';

export const downloadBlobAsFile = (
  resp: any,
  fileName: string,
  mimeType: string = 'application/octet-stream',
) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  const blob = new Blob([resp], { type: mimeType });
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};

export function saveFile(content: any, filename: string, type: MimeTypeEnum) {
  const blob = new Blob([content], { type });
  saveAs(blob, filename);
}

export function convertToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

// https://stackoverflow.com/questions/2805330/opening-pdf-string-in-new-window-with-javascript
export function showPdfInNewTab(base64Data: string, filename: string) {
  if (!base64Data || !filename) {
    return;
  }
  // Convert the base64 string in a data URL if not already in correct format
  if (!base64Data.includes('base64,')) {
    base64Data = `data:application/pdf;base64,${encodeURI(base64Data)}`;
  }

  const newWindow = window.open('');
  newWindow?.document.write(
    `<html>` +
      `<head><title>${filename}</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head><body>` +
      `<iframe title='${filename}' width='100%' height='100%' src='${base64Data}'></iframe>` +
      `</body></html>`,
  );
}

/***
 * Converts a dataUrl base64 image string into a File byte array
 * dataUrl example:
 * data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIsAAACLCAYAAABRGWr/AAAAAXNSR0IA...etc
 */
export function dataUrlToFile(
  dataUrl: string,
  filename: string,
): File | undefined {
  const arr = dataUrl.split(',');
  if (arr.length < 2) {
    return undefined;
  }
  const mimeArr = arr[0].match(/:(.*?);/);
  if (!mimeArr || mimeArr.length < 2) {
    return undefined;
  }
  const mime = mimeArr[1];
  const buff = Buffer.from(arr[1], 'base64');
  return new File([buff], filename, { type: mime });
}
export function arrayBufferToString(
  buffer: ArrayBuffer,
  encoding: string = 'UTF-8',
): Promise<string> {
  return new Promise((resolve) => {
    const blob = new Blob([buffer], { type: 'text/plain' });
    const reader = new FileReader();
    reader.onload = function (evt) {
      resolve((evt.target?.result as string) || '');
    };
    reader.readAsText(blob, encoding);
  });
}

export const isBase64Encoded = (str: string) => {
  return /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/.test(
    str,
  );
};

export function readArrayBufferAsDataURL(buffer: ArrayBuffer): Promise<string> {
  return new Promise((resolve) => {
    const blob = new Blob([buffer as unknown as any], { type: 'image/png' });

    const reader = new FileReader();
    reader.onload = function () {
      resolve(reader.result as string);
    };
    reader.readAsDataURL(blob);
  });
}
