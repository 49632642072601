import { useDisclosure } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useGetAccountById, useGetCreditNotesById } from '~api/accountsService';
import { PAPER_PAGE_MAX_WIDTH } from '~app/constants/quotes';
import { MBox, MFlex, MPageContainer, MPageHeader } from '~components/Monetize';
import { ROUTES } from '~constants';
import { getAccountCreditsRoute } from '~constants/routes';
import { useHandleApiError } from '~hooks/useHandleApiError';
import { ICustomFieldRecordSchema, TransactableSourceType } from '~types';
import { MEntityStatusBadge } from '../../../../components/Monetize/MEntityStatusBadge';
import { CreditStatusEnumDisplay } from '../../../../constants/credits';
import { CreditActions } from '../CreditActions';
import { CreditAdditionalFieldsBanner } from '../CreditAdditionalFieldsBanner';
import { CreditNoteFormEditModal } from './CreditNoteFormEditModal';
import { PreviewCreditNotePdfDrawer } from './PreviewCreditNotePdfDrawer';
import { ViewCreditNoteHtml } from './ViewCreditNoteHtml';

export const ViewCreditNote = () => {
  const navigate = useNavigate();
  const { creditNoteId, accountId = '' } = useParams();
  const { handleApiError } = useHandleApiError();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: accountDetails } = useGetAccountById(accountId, {
    enabled: !!accountId,
    onError: (err) =>
      handleApiError(err, ROUTES.getAccountCreditsRoute(accountId!)),
  });

  const { isLoading, data: creditNote } = useGetCreditNotesById(creditNoteId!, {
    onError: (err) =>
      handleApiError(err, ROUTES.getAccountCreditsRoute(accountId!)),
  });

  const [customFields, setCustomFields] = useState<ICustomFieldRecordSchema>(
    () => creditNote?.customFields || {},
  );

  useEffect(() => {
    if (creditNote?.customFields) {
      setCustomFields(creditNote.customFields);
    }
  }, [creditNote?.customFields]);

  const handleBackNavigation = () => {
    navigate(ROUTES.getAccountCreditsRoute(creditNote?.accountId!));
  };

  const handleOnCloseModal = (data: ICustomFieldRecordSchema) => {
    setCustomFields(data);
    onClose();
  };

  const isDetailBannerEditable =
    creditNote && creditNote?.voidReason !== null
      ? false
      : Object.keys(customFields).length > 0;

  return (
    <MPageContainer height="auto">
      <MPageHeader
        hasBackButton
        backButtonCallback={handleBackNavigation}
        title="View Credit Note"
        parentLink={getAccountCreditsRoute(
          creditNote ? creditNote?.accountId : '',
        )}
        parentLinkTitle={creditNote && creditNote.accountSummary.accountName}
        copyUrl
        id={creditNote?.id!}
        tag={
          creditNote && (
            <MEntityStatusBadge
              status={CreditStatusEnumDisplay[creditNote.status]}
            />
          )
        }
      >
        <MFlex
          alignContent="center"
          alignItems="center"
          columnGap={3}
          display={['none', 'none', 'flex']}
        >
          {!!creditNote && (
            <PreviewCreditNotePdfDrawer creditNoteId={creditNote.id} />
          )}
          {!!creditNote?.id && (
            <CreditActions
              sourceType={TransactableSourceType.creditNote}
              accountId={creditNote.accountId}
              applicationsSourceId={creditNote.credit.id}
              amount={creditNote.amount}
              appliedAmount={creditNote.credit.amountApplied}
              currency={creditNote.currency}
              billGroupId={creditNote.billGroupId}
              creditNoteId={creditNote.id}
              creditStatus={creditNote.status}
            />
          )}
        </MFlex>
      </MPageHeader>
      <MBox flex="1" w="full" pb={8}>
        <MBox mx="auto" mt={2.5} maxW={PAPER_PAGE_MAX_WIDTH} w="full" mb="2">
          <CreditAdditionalFieldsBanner
            billGroupId={creditNote?.billGroupId}
            isLoading={isLoading}
            customFields={customFields}
            isEditable={isDetailBannerEditable}
            reason={creditNote?.description || ''}
            onOpen={onOpen}
            voidReason={creditNote?.voidReason ?? ''}
          />
          <MBox
            w="full"
            mt={6}
            borderWidth={0.5}
            borderColor="tBlue.hover"
            background="tWhite.titanWhite"
          >
            {!!creditNote && (
              <ViewCreditNoteHtml
                creditNoteId={creditNote.id}
                modifyDate={creditNote?.modifyDate}
              />
            )}
          </MBox>
        </MBox>
      </MBox>
      {isOpen && accountDetails && creditNote && (
        <CreditNoteFormEditModal
          account={accountDetails}
          creditNote={creditNote}
          onClose={onClose}
          onSave={handleOnCloseModal}
        />
      )}
    </MPageContainer>
  );
};
