import { isBefore } from 'date-fns/isBefore';
import hasValue from 'lodash/has';
import { useInvoiceListByAccount } from '~app/api/accountsService';
import { handleApiErrorToast } from '~app/api/axios';
import {
  DEFAULT_PAGER,
  InvoiceStatusEnum,
  InvoiceSummaryResp,
} from '~app/types';
import { parseDateAsUtc } from '~app/utils/dates';
import { arrayToObjects } from '~app/utils/misc';

export const useAccountPastDueInformation = (accountId: string) => {
  const { isLoading, data: groupedAmountDue = {} } = useInvoiceListByAccount(
    {
      accountId,
      config: { ...DEFAULT_PAGER, rows: 300 },
      filters: {
        status: InvoiceStatusEnum.UNPAID,
        amount: {
          between: {
            gte: 0,
          },
        },
      },
    },
    {
      select: ({ content }) => {
        const pastDueContents = content.filter(({ dueDate }) =>
          isBefore(parseDateAsUtc(dueDate), new Date()),
        );
        const aggregatedData: Record<string, number> = {};
        const groupedData = arrayToObjects<InvoiceSummaryResp>(
          pastDueContents,
          'billGroupId',
        );
        for (const key in groupedData) {
          if (key in groupedData) {
            aggregatedData[key] = groupedData[key].reduce(
              (acc, obj) => acc + obj.amountDue,
              0,
            );
          }
        }
        return aggregatedData;
      },
      onError: (err) => handleApiErrorToast(err),
    },
  );

  const getDueAmountByBillGroupId = (billGroupId: string) => {
    return hasValue(groupedAmountDue, billGroupId)
      ? groupedAmountDue[billGroupId]
      : 0;
  };

  return {
    isLoading,
    groupedAmountDue,
    totalAmountDue: Object.values(groupedAmountDue).reduce(
      (acc, val) => acc + val,
      0,
    ),
    getDueAmountByBillGroupId,
  };
};
