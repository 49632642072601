import { useRecoilState } from 'recoil';
import { IQuoteRespSchema, QuoteTypeEnum } from '~app/types';
import { useFlags } from '../../../../services/launchDarkly';
import { QuoteStateDataTypes } from '../quoteContext';
import { QuoteBannerItem } from './QuoteBannerItem';
import { bannerDismissedSelector } from './quoteBanner.utils';

export function InactiveRateWarningBanner({
  quote,
  quoteStateData,
}: {
  quote: IQuoteRespSchema;
  quoteStateData: QuoteStateDataTypes;
}) {
  const { disableInactiveProductBanner } = useFlags();
  const [dismissed, setDismissed] = useRecoilState(
    bannerDismissedSelector([quote.id, 'InactiveRateWarningBanner']),
  );

  const { isSelectedOfferingAndRateIdsInactiveForInfo: shouldShowBanner } =
    quoteStateData;
  const hasQuoteOfferings = (quote?.quoteOfferings?.length || 0) > 0;

  if (
    !hasQuoteOfferings ||
    !shouldShowBanner ||
    disableInactiveProductBanner ||
    dismissed
  ) {
    return null;
  }

  return (
    <QuoteBannerItem onDismiss={() => setDismissed(true)} type="warning">
      {quote.type === QuoteTypeEnum.AMENDMENT &&
        'There are Rates and/or Offerings on this Contract that are no longer active. No action is required unless your Administrator has requested a change.'}
      {quote.type === QuoteTypeEnum.RENEWAL &&
        'There are Rates and/or Offerings on the Contract being renewed that are no longer active. No action is required unless your Administrator has requested a change.'}
    </QuoteBannerItem>
  );
}
