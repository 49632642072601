import { FC, Suspense } from 'react';
import {
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import AppLoading from '~app/components/App/AppLoading';
import AppLayout from '~layouts/AppLayout';

// Public
import Callback from './Auth/Callback';
import EmptyLogin from './Auth/EmptyLogin';
import Logout from './Auth/Logout';
import RefreshToken from './Auth/RefreshToken';

import { ROLES, ROUTES } from '~constants';
import { useAuthRouteInterceptor } from './routes';

// Dashboard
import Dashboard from './Dashboard/Dashboard';

// Onboard
import { CreateTenant } from './Auth/Onboard/CreateTenant';
import CreateUser from './Auth/Onboard/CreateUser';
import SelectTenant from './Auth/Onboard/SelectTenant';
import SwitchTenant from './Auth/Onboard/SwitchTenant';
import VerifyEmail from './Auth/Onboard/VerifyEmail';

// Accounts
import { AccountDetailsPage } from './AccountDetails/AccountDetailsPage';
import { SubscriptionOverview } from './AccountDetails/Subscriptions/SubscriptionOverview';
import Accounts from './Accounts/AccountsTable';
import { InvoicePage } from './Invoices/InvoicePage';

// Quotes
import { NewQuoteFormPage } from './Quotes/Quote/NewQuoteFormPage';
import { QuotePageWrapper } from './Quotes/Quote/QuotePageWrapper';
import SalesLanding from './Quotes/SalesLanding';

// Subscription
import { SubscriptionsPage } from './AccountDetails/Subscriptions/SubscriptionsPage';

// Product Catalog
import { DiscountForm } from './ProductCatalog/Discounts/DiscountForm';
import DiscountListPage from './ProductCatalog/Discounts/DiscountListPage';
import { OfferingForm } from './ProductCatalog/Offerings/OfferingForm';
import OfferingListPage from './ProductCatalog/Offerings/OfferingListPage';
import { ProductForm } from './ProductCatalog/Products/ProductForm';
import ProductListPage from './ProductCatalog/Products/ProductListPage';
import { UsageTypeFormPage } from './ProductCatalog/UsageTypes/UsageTypeFormPage';
import UsageTypeListPage from './ProductCatalog/UsageTypes/UsageTypeListPage';

// Tenant Manage
import { ManageUsers } from './Tenants/ManageUsers/ManageUsers';
import { TenantPage } from './Tenants/TenantPage';

// Settings
import GuidedQuoting from './Settings/Quoting/GuidedQuoting/GuidedQuotingPage';
import QuoteSettings from './Settings/Quoting/QuoteSettings/QuoteSettings';
import { TeamsPage } from './Settings/Quoting/Teams/TeamsPage';

// Settings -> Billing
import { BillingSettings } from './Settings/Billing/BillingSettings/BillingSettings';
import Dunning from './Settings/Billing/Dunning';
import EditDunning from './Settings/Billing/Dunning/EditDunning';
import { InvoiceSettings } from './Settings/Billing/Invoices/InvoiceSettings';
import { InvoiceTemplates } from './Settings/Billing/InvoiceTemplates/InvoiceTemplates';
import Pricing from './Settings/Billing/Pricing';
import Subscriptions from './Settings/Billing/Subscriptions';
import { TaxConnections } from './Settings/Billing/Tax/TaxConnections';

// Settings -> Integrations
import { CrmAuthCallback } from './Settings/Integration/Crm/CrmAuthCallback';
import Crm from './Settings/Integration/Crm/CrmConnections';
import EsignCallback from './Settings/Integration/Esign/Callback';
import PaymentProcessor from './Settings/Integration/PaymentProcessor/PaymentProcessor';
import EditWebhook from './Settings/Integration/Webhooks/EditWebhook';
import Webhooks from './Settings/Integration/Webhooks/Webhook';

// Settings -> Tenant
import CompanySetting from './Settings/Tenant/CompanySetting';
import { CustomFieldsPage } from './Settings/Tenant/CustomFields/CustomFieldsPage';
import { Notifications } from './Settings/Tenant/Notifications';

// CRM
import CrmAmendRenewContract from './Crm/CrmAmendRenewContract';

// Profile
import Profile from './Profile/Profile';

// Redirector
import Redirector from './Redirector';

import { AnonymousFeatureFlagWrapper } from '~app/components/App/AnonymousFeatureFlagWrapper';
import ContractModal from '~app/components/Contracts/ContractModal';
import { MFlex, MLink, MPageLoader, MText } from '~app/components/Monetize';
import PayInvoiceLayout from '~app/layouts/PayInvoiceLayout';
import { useAuth } from '~app/services/auth0';
import { useFlags } from '~app/services/launchDarkly';
import lazyWithPreload from '../components/App/LazyLoadRetry';
import AccessDenied from './AccessDenied';
import { BillGroupPage } from './AccountDetails/BillGroups/BillGroupPage';
import { ViewCreditNote } from './AccountDetails/Credits/CreditNotes/ViewCreditNote';
import { ViewCredit } from './AccountDetails/Credits/Credits/ViewCredit';
import { OneTimeInvoiceForm } from './AccountDetails/OneTimeInvoice/OneTimeInvoiceForm';
import { PaymentMethodsStripeCallback } from './AccountDetails/PaymentMethods/PaymentMethodsStripeCallback';
import { PaymentPage } from './AccountDetails/Payments/PaymentPage';
import { RefundPage } from './AccountDetails/Refunds/RefundPage';
import { SubscriptionUsageOverviewPage } from './AccountDetails/Subscriptions/Usage/SubscriptionUsageOverviewPage';
import { UsageEventsPage } from './AccountDetails/Usage/UsageEventsPage';
import { PrivateRoute } from './AppPrivateRoute';
import { BillingPage } from './Billing/BillingPage';
import ErrorPage from './ErrorPages/ErrroPage';
import { UnauthorizedPage } from './ErrorPages/UnauthorizedPage';
import IdPrefixNavigator from './IdPrefixNavigator';
import { InvoiceShareDetails } from './PayInvoice/InvoiceShareDetails';
import { StripePaymentCallback } from './PayInvoice/StripeCallback';
import NewGuidedQuoteFormPage from './Quotes/Quote/components/guidedQuote/NewGuidedQuoteFormPage';
import { QuoteBaseRedirector } from './Quotes/Quote/QuoteBaseRedirector';
import { ReportPage } from './Report/ReportPage';
import { RevRecPage } from './RevenueRecognition/RevRecPage';
import ApprovalRules from './Settings/Approvals/ApprovalRules';
import { Payments } from './Settings/Billing/Payments';
import ConditionalTermsPage from './Settings/ConditionalTerms/ConditionalTermsPage';
import { Accounting } from './Settings/Integration/Accounting/Accounting';
import Esign from './Settings/Integration/Esign/Esign';
import { SettingsLanding } from './Settings/LandingPage/SettingsLanding';
import { QuoteExpirationReminder } from './Settings/Quoting/Expiration/QuoteExpirationReminder';
import { GuidedQuotingForm } from './Settings/Quoting/GuidedQuoting/GuidedQuotingForm';
import QuoteTemplates from './Settings/Quoting/QuoteTemplates';
import { RevRecSettings } from './Settings/RevenueRecognition/RevRecSettings';
import { RuleTypeEnum } from './Settings/rules-v3/rules.types';
import { SettingsSideNav } from './Settings/SideNavBar/SettingsSideNav';
import { ApiKeysPage } from './Settings/Tenant/ApiKeys.tsx/ApiKeysPage';
import CurrencySettings from './Settings/Tenant/CurrencySettings';
import { CustomFieldsForm } from './Settings/Tenant/CustomFields/CustomFieldsForm';
import { LegalEntityForm } from './Settings/Tenant/LegalEntityForm';

const ValidationRules = lazyWithPreload(
  () => import('./Settings/Validations/ValidationRules'),
);

const Onboarding = lazyWithPreload(
  () => import('./Settings/Tenant/Onboarding/OnboardingPage'),
);

const RuleFormPageV3 = lazyWithPreload(
  () => import('./Settings/rules-v3/RuleFormPage.v3'),
);

const AuthOnlyRoute: FC = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth();

  if (!isAuthenticated) {
    loginWithRedirect();
  }

  return <Outlet />;
};

const AuthWrappedAppLayout = () => {
  const { isReady, nextRoute = '' } = useAuthRouteInterceptor();
  const { isLDLoading } = useAuth();

  if (!isReady) {
    return (
      <AppLoading>
        <MText my="4" fontSize="sm">
          You can also{' '}
          <MLink
            fontSize="sm"
            as={Link}
            to={ROUTES.AUTH_LOGOUT}
            textDecor="underline"
          >
            log out
          </MLink>
          .
        </MText>
      </AppLoading>
    );
  }

  if (nextRoute !== ROUTES.ROOT) {
    // Redirect to next routes
    return <Navigate to={nextRoute} />;
  }

  if (isLDLoading) {
    return (
      <AppLoading>
        <MText my="4" fontSize="sm">
          You can also{' '}
          <MLink
            fontSize="sm"
            as={Link}
            to={ROUTES.AUTH_LOGOUT}
            textDecor="underline"
          >
            log out
          </MLink>
          .
        </MText>
      </AppLoading>
    );
  }
  // If everything is good then go to the route
  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  );
};

const AuthRouteSettingsLayout = () => (
  <SettingsSideNav>
    <Outlet />
  </SettingsSideNav>
);

const AppRoutes: FC = () => {
  const navigate = useNavigate();
  const {
    billRun,
    metabaseReporting,
    revenueRecognition,
    onetimeInvoice,
    guidedOnboardingEnabled,
    guidedSelling,
  } = useFlags();

  return (
    <Suspense
      fallback={
        <MFlex justify="center" grow={1}>
          <MPageLoader />
        </MFlex>
      }
    >
      <Routes>
        <Route path={ROUTES.AUTH_LOGIN} element={<EmptyLogin />} />
        <Route path={ROUTES.AUTH_CALLBACK} element={<Callback />} />
        <Route path={ROUTES.AUTH_LOGOUT} element={<Logout />} />
        <Route path={ROUTES.AUTH_REFRESH_TOKEN} element={<RefreshToken />} />
        <Route path={ROUTES.REDIRECT} element={<Redirector />} />

        <Route path="/onboard" element={<AuthOnlyRoute />}>
          <Route path={ROUTES.ONBOARD_CREATE_USER} element={<CreateUser />} />
          <Route
            path={ROUTES.ONBOARD_CREATE_TENANT}
            element={<CreateTenant />}
          />
          <Route path={ROUTES.ONBOARD_VERIFY_EMAIL} element={<VerifyEmail />} />
          <Route
            path={ROUTES.ONBOARD_SWITCH_TENANT}
            element={<SwitchTenant />}
          />
          <Route
            path={ROUTES.ONBOARD_SELECT_TENANT}
            element={<SelectTenant />}
          />
        </Route>

        <Route
          path={ROUTES.INVOICE_SHARE}
          element={
            <AnonymousFeatureFlagWrapper>
              <PayInvoiceLayout>
                <InvoiceShareDetails />
              </PayInvoiceLayout>
            </AnonymousFeatureFlagWrapper>
          }
        />
        <Route
          path={ROUTES.INVOICE_SHARE_STRIPE_PAYMENT_CALLBACK}
          element={
            <AnonymousFeatureFlagWrapper>
              <StripePaymentCallback />
            </AnonymousFeatureFlagWrapper>
          }
        />

        <Route path="/" element={<AuthWrappedAppLayout />}>
          <Route
            path={ROUTES.ROOT}
            element={<Navigate to={ROUTES.DASHBOARD} />}
          />
          <Route path={`${ROUTES.DASHBOARD}/*`} element={<Dashboard />} />

          {/* Accounts */}

          {/*
            This component makes POST requests on load, so it cannot be placed
            anywhere where a parent is re-rendered multiple times on load.
          */}
          <Route
            path={ROUTES.ACCOUNT_PAYMENT_METHODS_CALLBACK}
            element={<PaymentMethodsStripeCallback />}
          />

          <Route
            path={ROUTES.ACCOUNT_LIST}
            element={
              <PrivateRoute
                acls={[['accounts', 'read']]}
                component={<Accounts />}
              />
            }
          />
          <Route
            path={`${ROUTES.ACCOUNT_DETAIL}/*`}
            element={<AccountDetailsPage />}
          />

          <Route
            path={ROUTES.ACCOUNT_CREDITS_VIEW}
            element={
              <PrivateRoute
                acls={[['billing', 'read']]}
                component={<ViewCredit />}
              />
            }
          />

          <Route
            path={ROUTES.ACCOUNT_CREDIT_NOTE_VIEW}
            element={
              <PrivateRoute
                acls={[['billing', 'read']]}
                component={<ViewCreditNote />}
              />
            }
          />

          {/* Accounts Subscriptions */}
          <Route
            path={ROUTES.SUBSCRIPTION_NEW}
            element={
              <PrivateRoute
                acls={[['billing', 'create']]}
                component={<SubscriptionsPage />}
              />
            }
          />
          <Route
            path={ROUTES.SUBSCRIPTION_EDIT}
            element={
              <PrivateRoute
                acls={[['billing', 'update']]}
                component={<SubscriptionsPage />}
              />
            }
          />
          <Route
            path={ROUTES.SUBSCRIPTION_OVERVIEW}
            element={
              <PrivateRoute
                acls={[['billing', 'read']]}
                component={<SubscriptionOverview />}
              />
            }
          />
          <Route
            path={`${ROUTES.BILL_GROUP_PAGE}/*`}
            element={
              <PrivateRoute
                acls={[['billing', 'read']]}
                component={<BillGroupPage />}
              />
            }
          />
          <Route
            path={ROUTES.SUBSCRIPTION_PRODUCT_USAGE}
            element={
              <PrivateRoute
                acls={[['billing', 'read']]}
                component={<SubscriptionUsageOverviewPage />}
              />
            }
          />

          {/* Usage */}
          <Route
            path={ROUTES.USAGE_EVENTS}
            element={
              <PrivateRoute
                acls={[['billing', 'read']]}
                component={<UsageEventsPage />}
              />
            }
          />

          {/* Payment */}
          <Route
            path={ROUTES.PAYMENTS_VIEW}
            element={
              <PrivateRoute
                acls={[['billing', 'read']]}
                component={<PaymentPage />}
              />
            }
          />

          <Route
            path={ROUTES.REFUND_VIEW}
            element={
              <PrivateRoute
                acls={[['billing', 'read']]}
                component={<RefundPage />}
              />
            }
          />

          {/* Invoice */}
          <Route
            path={ROUTES.INVOICE_DETAIL}
            element={
              <PrivateRoute
                acls={[['billing', 'read']]}
                component={<InvoicePage />}
              />
            }
          />

          <Route
            path={ROUTES.INVOICE_DETAIL_CONTRACTS_EDIT}
            element={
              <ContractModal
                isOpen
                onClose={(params, source) => {
                  if (params?.invoiceId) {
                    navigate(ROUTES.getInvoiceDetailRoute(params.invoiceId), {
                      state: { source },
                    });
                  }
                }}
              />
            }
          />

          {/* One Time Invoice */}
          {onetimeInvoice && (
            <Route
              path={ROUTES.ONE_TIME_INVOICE_CREATE}
              element={
                <PrivateRoute
                  acls={[['billing', 'create']]}
                  component={<OneTimeInvoiceForm />}
                />
              }
            />
          )}

          {/* Sales */}
          <Route
            path={`${ROUTES.SALES_LANDING}/*`}
            element={<SalesLanding />}
          />

          {/* Quotes */}
          <Route
            path={ROUTES.SALES_LANDING}
            element={
              <PrivateRoute
                roles={ROLES.QUOTE_LIST_ROLES}
                component={<SalesLanding />}
              />
            }
          />
          <Route
            path={ROUTES.QUOTE_BASE}
            element={
              <PrivateRoute
                acls={[['sales', 'read']]}
                component={<QuoteBaseRedirector />}
              />
            }
          />
          <Route
            path={ROUTES.QUOTE_EDIT}
            element={
              <PrivateRoute
                acls={[['sales', 'read']]}
                component={<QuotePageWrapper />}
              />
            }
          />
          <Route
            path={ROUTES.QUOTE_NEW}
            element={
              <PrivateRoute
                acls={[['sales', 'create']]}
                component={<NewQuoteFormPage />}
              />
            }
          />
          <Route
            path={ROUTES.QUOTE_NEW_GUIDED}
            element={
              <PrivateRoute
                acls={[['sales', 'create']]}
                component={<NewGuidedQuoteFormPage />}
              />
            }
          />
          <Route
            path={ROUTES.ACCOUNT_QUOTE_NEW}
            element={
              <PrivateRoute
                roles={ROLES.ACCOUNT_QUOTE_NEW_ROLES}
                component={<NewQuoteFormPage />}
              />
            }
          />
          <Route
            path={ROUTES.QUOTE_REVIEW}
            element={
              <PrivateRoute
                acls={[['sales', 'read']]}
                component={<QuotePageWrapper />}
              />
            }
          >
            <Route
              path=":contractId"
              element={
                <ContractModal
                  isOpen
                  onClose={(params) => {
                    if (params?.quoteId) {
                      navigate(ROUTES.getQuoteReviewRoute(params.quoteId), {
                        replace: true,
                      });
                    }
                  }}
                />
              }
            />
          </Route>

          {/* Products */}
          <Route
            path={ROUTES.PRODUCT_LIST}
            element={
              <PrivateRoute
                roles={ROLES.PRODUCT_LIST_ROLES}
                component={<ProductListPage />}
              />
            }
          />
          <Route
            path={ROUTES.PRODUCT_NEW}
            element={
              <PrivateRoute
                roles={ROLES.PRODUCT_NEW_ROLES}
                component={<ProductForm />}
              />
            }
          />
          <Route
            path={ROUTES.PRODUCT_EDIT}
            element={
              <PrivateRoute
                roles={ROLES.PRODUCT_EDIT_ROLES}
                component={<ProductForm />}
              />
            }
          />

          {/* Offerings */}
          <Route
            path={ROUTES.OFFERING_LIST}
            element={
              <PrivateRoute
                roles={ROLES.OFFERING_LIST_ROLES}
                component={<OfferingListPage />}
              />
            }
          />
          <Route
            path={ROUTES.OFFERING_NEW}
            element={
              <PrivateRoute
                roles={ROLES.OFFERING_NEW_ROLES}
                component={<OfferingForm />}
              />
            }
          />
          <Route
            path={ROUTES.OFFERING_EDIT}
            element={
              <PrivateRoute
                roles={ROLES.OFFERING_EDIT_ROLES}
                component={<OfferingForm />}
              />
            }
          />

          {/* Discount */}
          <Route
            path={ROUTES.DISCOUNT_LIST}
            element={
              <PrivateRoute
                roles={ROLES.DISCOUNT_LIST_ROLES}
                component={<DiscountListPage />}
              />
            }
          />
          <Route
            path={ROUTES.DISCOUNT_NEW}
            element={
              <PrivateRoute
                roles={ROLES.DISCOUNT_NEW_ROLES}
                component={<DiscountForm />}
              />
            }
          />
          <Route
            path={ROUTES.DISCOUNT_EDIT}
            element={
              <PrivateRoute
                roles={ROLES.DISCOUNT_EDIT_ROLES}
                component={<DiscountForm />}
              />
            }
          />

          {/* Tenants */}
          <Route path={ROUTES.TENANT_LIST} element={<TenantPage />} />

          {/* Usage Types */}
          <Route
            path={ROUTES.USAGE_TYPE_LIST}
            element={
              <PrivateRoute
                roles={ROLES.USAGE_TYPE_LIST_ROLE}
                component={<UsageTypeListPage />}
              />
            }
          />
          <Route path={ROUTES.USAGE_TYPE_NEW} element={<UsageTypeFormPage />} />
          <Route
            path={ROUTES.USAGE_TYPE_EDIT}
            element={<UsageTypeFormPage />}
          />

          {/* Profile */}
          <Route path={ROUTES.PROFILE} element={<Profile />} />

          {/* Settings */}
          <Route path={ROUTES.SETTINGS} element={<SettingsLanding />} />

          {/* Settings->Billings */}
          <Route path="/settings" element={<AuthRouteSettingsLayout />}>
            <Route
              path={ROUTES.SETTINGS_TAX}
              element={
                <PrivateRoute
                  roles={ROLES.SETTINGS_TAX_ROLES}
                  component={<TaxConnections />}
                />
              }
            />

            <Route
              path={ROUTES.SETTINGS_REV_REC}
              element={
                <PrivateRoute
                  roles={ROLES.SETTINGS_REV_REC_ROLES}
                  component={<RevRecSettings />}
                />
              }
            />
            <Route
              path={ROUTES.SETTINGS_DUNNING_LIST}
              element={
                <PrivateRoute
                  roles={ROLES.SETTINGS_DUNNING_ROLES}
                  component={<Dunning />}
                />
              }
            />
            <Route
              path={ROUTES.SETTINGS_DUNNING_NEW}
              element={
                <PrivateRoute
                  roles={ROLES.SETTINGS_DUNNING_ROLES}
                  component={<EditDunning />}
                />
              }
            />
            <Route
              path={ROUTES.SETTINGS_DUNNING_EDIT}
              element={
                <PrivateRoute
                  roles={ROLES.SETTINGS_DUNNING_ROLES}
                  component={<EditDunning />}
                />
              }
            />

            <Route
              path={ROUTES.SETTINGS_SUBSCRIPTIONS}
              element={
                <PrivateRoute
                  roles={ROLES.SETTINGS_SUBSCRIPTIONS_ROLE}
                  component={<Subscriptions />}
                />
              }
            />
            <Route
              path={ROUTES.SETTINGS_PRICING}
              element={
                <PrivateRoute
                  roles={ROLES.SETTINGS_PRICING_ROLE}
                  component={<Pricing />}
                />
              }
            />
            <Route
              path={ROUTES.SETTINGS_PAYMENTS}
              element={
                <PrivateRoute
                  roles={ROLES.SETTINGS_PAYMENT_ROLE}
                  component={<Payments />}
                />
              }
            />
            <Route
              path={ROUTES.SETTINGS_INVOICES}
              element={
                <PrivateRoute
                  roles={ROLES.SETTINGS_INVOICES_ROLE}
                  component={<InvoiceSettings />}
                />
              }
            />
            <Route
              path={ROUTES.SETTINGS_INVOICE_TEMPLATES}
              element={
                <PrivateRoute
                  roles={ROLES.SETTINGS_INVOICE_TEMPLATES_ROLE}
                  component={<InvoiceTemplates />}
                />
              }
            />
            <Route
              path={ROUTES.SETTINGS_BILLING_SETTINGS}
              element={
                <PrivateRoute
                  roles={ROLES.SETTINGS_INVOICE_TEMPLATES_ROLE}
                  component={<BillingSettings />}
                />
              }
            />

            {/* Settings->Quoting */}

            <Route
              path={ROUTES.SETTINGS_QUOTE_SETTINGS}
              element={
                <PrivateRoute
                  roles={ROLES.SETTINGS_QUOTE_ROLES}
                  component={<QuoteSettings />}
                />
              }
            />
            <Route
              path={ROUTES.SETTINGS_QUOTE_TEMPLATES}
              element={
                <PrivateRoute
                  roles={ROLES.SETTINGS_QUOTE_TEMPLATES_ROLES}
                  component={<QuoteTemplates />}
                />
              }
            />

            <Route
              path={ROUTES.SETTINGS_APPROVALS_V3}
              element={
                <PrivateRoute
                  roles={ROLES.SETTINGS_VALIDATIONS_ROLES}
                  component={<ApprovalRules V3 />}
                />
              }
            />
            <Route
              path={ROUTES.SETTINGS_APPROVALS_V3_CREATE}
              element={
                <PrivateRoute
                  roles={ROLES.SETTINGS_VALIDATIONS_ROLES}
                  component={<RuleFormPageV3 type={RuleTypeEnum.APPROVAL} />}
                />
              }
            />
            <Route
              path={ROUTES.SETTINGS_APPROVALS_V3_EDIT}
              element={
                <PrivateRoute
                  roles={ROLES.SETTINGS_VALIDATIONS_ROLES}
                  component={<RuleFormPageV3 type={RuleTypeEnum.APPROVAL} />}
                />
              }
            />

            <Route
              path={ROUTES.SETTINGS_CONDITIONAL_TERMS_V3}
              element={
                <PrivateRoute
                  roles={ROLES.SETTINGS_VALIDATIONS_ROLES}
                  component={<ConditionalTermsPage V3 />}
                />
              }
            />
            <Route
              path={ROUTES.SETTINGS_CONDITIONAL_TERMS_V3_CREATE}
              element={
                <PrivateRoute
                  roles={ROLES.SETTINGS_VALIDATIONS_ROLES}
                  component={
                    <RuleFormPageV3 type={RuleTypeEnum.CONDITIONAL_TERM} />
                  }
                />
              }
            />
            <Route
              path={ROUTES.SETTINGS_CONDITIONAL_TERMS_V3_EDIT}
              element={
                <PrivateRoute
                  roles={ROLES.SETTINGS_VALIDATIONS_ROLES}
                  component={
                    <RuleFormPageV3 type={RuleTypeEnum.CONDITIONAL_TERM} />
                  }
                />
              }
            />

            <Route
              path={ROUTES.SETTINGS_VALIDATIONS_V3}
              element={
                <PrivateRoute
                  roles={ROLES.SETTINGS_VALIDATIONS_ROLES}
                  component={<ValidationRules />}
                />
              }
            />
            <Route
              path={ROUTES.SETTINGS_VALIDATIONS_V3_CREATE}
              element={
                <PrivateRoute
                  roles={ROLES.SETTINGS_VALIDATIONS_ROLES}
                  component={<RuleFormPageV3 type={RuleTypeEnum.VALIDATION} />}
                />
              }
            />
            <Route
              path={ROUTES.SETTINGS_VALIDATIONS_V3_EDIT}
              element={
                <PrivateRoute
                  roles={ROLES.SETTINGS_VALIDATIONS_ROLES}
                  component={<RuleFormPageV3 type={RuleTypeEnum.VALIDATION} />}
                />
              }
            />

            <Route
              path={`${ROUTES.SETTINGS_TEAMS}/*`}
              element={
                <PrivateRoute
                  roles={ROLES.SETTINGS_TEAMS_ROLES}
                  component={<TeamsPage />}
                />
              }
            />
            <Route
              path={ROUTES.SETTINGS_EMAILS}
              element={
                <PrivateRoute
                  roles={ROLES.SETTINGS_EMAIL_REMINDERS}
                  component={<QuoteExpirationReminder />}
                />
              }
            />
            {guidedSelling && (
              <>
                <Route
                  path={ROUTES.SETTINGS_GUIDED_QUOTING}
                  element={
                    <PrivateRoute
                      roles={ROLES.ADMIN_ROLES}
                      component={<GuidedQuoting />}
                    />
                  }
                />
                <Route
                  path={`${ROUTES.SETTINGS_GUIDED_QUOTING_EDIT}/*`}
                  element={
                    <PrivateRoute
                      roles={ROLES.ADMIN_ROLES}
                      component={<GuidedQuotingForm />}
                    />
                  }
                />
                <Route
                  path={`${ROUTES.SETTINGS_GUIDED_QUOTING_CREATE}/*`}
                  element={
                    <PrivateRoute
                      roles={ROLES.ADMIN_ROLES}
                      component={<GuidedQuotingForm />}
                    />
                  }
                />
              </>
            )}

            {/* END of Settings->Quoting */}

            {/* Settings->Integrations */}
            <Route
              path={ROUTES.SETTINGS_PAYMENT_PROCESSOR}
              element={
                <PrivateRoute
                  roles={ROLES.SETTINGS_PAYMENT_PROCESSOR_ROLES}
                  component={<PaymentProcessor />}
                />
              }
            />
            <Route
              path={ROUTES.SETTINGS_ESIGN}
              element={
                <PrivateRoute
                  roles={ROLES.SETTINGS_E_SIGN_ROLES}
                  component={<Esign />}
                />
              }
            />
            <Route
              path={ROUTES.SETTINGS_ESIGN_CALLBACK}
              element={
                <PrivateRoute
                  roles={ROLES.SETTINGS_E_SIGN_ROLES}
                  component={<EsignCallback />}
                />
              }
            />

            <Route
              path={ROUTES.SETTINGS_CRM}
              element={
                <PrivateRoute
                  roles={ROLES.SETTINGS_CRM_ROLES}
                  component={<Crm />}
                />
              }
            />
            <Route
              path={ROUTES.SETTINGS_CRM_CALLBACK}
              element={
                <PrivateRoute
                  roles={ROLES.SETTINGS_CRM_ROLES}
                  component={<CrmAuthCallback />}
                />
              }
            />
            <Route
              path={ROUTES.SETTINGS_WEBHOOK_LIST}
              element={
                <PrivateRoute
                  roles={ROLES.SETTINGS_WEBHOOK_ROLES}
                  component={<Webhooks />}
                />
              }
            />
            <Route
              path={ROUTES.SETTINGS_WEBHOOK_NEW}
              element={
                <PrivateRoute
                  roles={ROLES.SETTINGS_WEBHOOK_ROLES}
                  component={<EditWebhook />}
                />
              }
            />
            <Route
              path={ROUTES.SETTINGS_WEBHOOK_EDIT}
              element={
                <PrivateRoute
                  roles={ROLES.SETTINGS_WEBHOOK_ROLES}
                  component={<EditWebhook />}
                />
              }
            />

            <Route
              path={ROUTES.SETTINGS_WEBHOOK_VIEW}
              element={
                <PrivateRoute
                  roles={ROLES.SETTINGS_WEBHOOK_ROLES}
                  component={<EditWebhook />}
                />
              }
            />

            <Route
              path={ROUTES.SETTINGS_ACCOUNTING}
              element={
                <PrivateRoute
                  roles={ROLES.SETTINGS_ACCOUNTING_ROLES}
                  component={<Accounting />}
                />
              }
            />

            {/* END of Settings->Integrations */}

            {/* Settings->Tenant */}
            <Route
              path={ROUTES.API_KEYS}
              element={
                <PrivateRoute
                  roles={ROLES.API_KEYS_ROLES}
                  component={<ApiKeysPage />}
                />
              }
            />
            <Route
              path={ROUTES.COMPANY_SETTINGS}
              element={
                <PrivateRoute
                  roles={ROLES.COMPANY_SETTINGS_ROLES}
                  component={<CompanySetting />}
                />
              }
            />
            {guidedOnboardingEnabled && (
              <Route
                path={ROUTES.ONBOARDING}
                element={
                  <PrivateRoute
                    roles={ROLES.BILLING_ADMIN_ROLES}
                    component={<Onboarding />}
                  />
                }
              />
            )}
            <Route
              path={ROUTES.LEGAL_ENTITY_NEW}
              element={
                <PrivateRoute
                  roles={ROLES.COMPANY_SETTINGS_ROLES}
                  component={<LegalEntityForm />}
                />
              }
            />
            <Route
              path={ROUTES.LEGAL_ENTITY_EDIT}
              element={
                <PrivateRoute
                  roles={ROLES.COMPANY_SETTINGS_ROLES}
                  component={<LegalEntityForm />}
                />
              }
            />

            {/* Settings->Tenant */}

            <Route
              path={ROUTES.CUSTOM_FIELDS_LIST}
              element={
                <PrivateRoute
                  acls={[['settings', 'read']]}
                  component={<CustomFieldsPage />}
                />
              }
            />
            <Route
              path={ROUTES.CUSTOM_FIELDS_EDIT}
              element={
                <PrivateRoute
                  acls={[['settings', 'create']]}
                  component={<CustomFieldsForm />}
                />
              }
            />
            <Route
              path={ROUTES.CUSTOM_FIELDS_NEW}
              element={
                <PrivateRoute
                  acls={[['settings', 'create']]}
                  component={<CustomFieldsForm />}
                />
              }
            />

            {/* Settings->Tenant user list */}
            <Route
              path={ROUTES.TENANT_DETAIL_USERS_LIST}
              element={<ManageUsers />}
            />

            <Route
              path={ROUTES.SETTINGS_NOTIFICATIONS}
              element={
                <PrivateRoute
                  roles={ROLES.EMAIL_NOTIFICATIONS_ROLES}
                  component={<Notifications />}
                />
              }
            />

            <Route
              path={ROUTES.SETTINGS_CURRENCIES}
              element={
                <PrivateRoute
                  roles={ROLES.CURRENCY_SETTINGS_ROLES}
                  component={<CurrencySettings />}
                />
              }
            />

            {/* END of Settings->Tenant */}
          </Route>

          {/* CRM routes */}
          <Route
            path={ROUTES.CRM_AMEND_RENEW}
            element={<CrmAmendRenewContract />}
          />

          {/* Reporting routes */}
          {metabaseReporting && (
            <Route path={`${ROUTES.REPORTING}`} element={<ReportPage />} />
          )}

          {/* Revenue routes */}
          {revenueRecognition && (
            <Route path={`${ROUTES.REVENUE}`} element={<RevRecPage />} />
          )}

          {billRun && (
            <Route
              path={`${ROUTES.BILLING}/*`}
              element={
                <PrivateRoute
                  roles={ROLES.BILLING_ADMIN_ROLES}
                  component={<BillingPage />}
                />
              }
            />
          )}
        </Route>

        <Route path={ROUTES.ACCESS_DENIED} element={<AccessDenied />} />
        <Route path="*" element={<IdPrefixNavigator />} />

        <Route path={ROUTES.ERROR_PAGE} element={<ErrorPage />} />
        <Route path={ROUTES.UNAUTHORIZED_PAGE} element={<UnauthorizedPage />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
