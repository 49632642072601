import { FunctionComponent as FC } from 'react';
import { IQuoteItemRespSchema } from '~app/types';
import { formatNumber, prorationLabel } from '~app/utils';
import { getProrationAndCredit } from '~app/utils/quotes';
import { MBox, MText, MTooltip } from '../Monetize';
import { MTextProps } from '../Monetize/MText';

interface AdditionalInfoCellProps {
  item: IQuoteItemRespSchema;
  currency: string;
  styles?: MTextProps;
  showProration?: boolean;
  showCredit?: boolean;
}
const AdditionalInfoCell: FC<AdditionalInfoCellProps> = ({
  item,
  styles,
  showProration = true,
  showCredit = true,
  currency,
}: AdditionalInfoCellProps) => {
  const { proration } = getProrationAndCredit(item);

  const formattedProration = proration ? formatNumber(proration) : '';

  return (
    <MBox data-testid="additional-info-cell" alignSelf="center" maxW={105}>
      {proration && showProration ? (
        <MText isTruncated {...styles} textDecoration="initial">
          {prorationLabel(proration)}:
          <MTooltip label={formattedProration} placement="bottom-end">
            <MText as="span" ml={0.5} {...styles} textDecoration="initial">
              {formattedProration}
            </MText>
          </MTooltip>
        </MText>
      ) : null}
    </MBox>
  );
};

export default AdditionalInfoCell;
