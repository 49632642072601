import { AccordionButton, AccordionItemProps, Icon } from '@chakra-ui/react';
import { FC } from 'react';
import { MdChevronRight, MdExpandMore } from 'react-icons/md';
import {
  MAccordionItem,
  MAccordionPanel,
  MFlex,
  MText,
} from '~app/components/Monetize';

interface QuoteBodyAccordionProps extends AccordionItemProps {
  title: string;
  /**
   * Hides the expand icon on right side of accordion button
   */
  hideExpandIcon?: boolean;
  /**
   * Add detail content on right side of accordion button
   */
  rightContent?: React.ReactNode;
  /**
   * Hides the panel content
   */
  hidePanel?: boolean;
  children?: React.ReactNode;
}

export const QuoteBodyAccordion: FC<QuoteBodyAccordionProps> = ({
  title,
  children,
  hideExpandIcon,
  rightContent,
  hidePanel,
  ...rest
}: QuoteBodyAccordionProps) => {
  return (
    <MAccordionItem border="none" {...rest}>
      {({ isExpanded }) => (
        <>
          <h2>
            <AccordionButton px={2} onClick={() => {}} minH="unset">
              <MFlex alignItems="center" alignContent="spread" w="full" py={2}>
                <MFlex alignItems="center">
                  {!hideExpandIcon && (
                    <MFlex alignItems="center">
                      {isExpanded ? (
                        <Icon boxSize="1.5rem" as={MdExpandMore} />
                      ) : (
                        <Icon boxSize="1.5rem" as={MdChevronRight} />
                      )}
                    </MFlex>
                  )}
                </MFlex>
                <MFlex textAlign="left" alignItems="center" py={1} flexGrow={1}>
                  <MText fontWeight="500" fontSize="lg" ml={2}>
                    {title}
                  </MText>
                </MFlex>
                {/* TODO: add back in the future if we need it */}
                {/* {rightContent} */}
              </MFlex>
            </AccordionButton>
          </h2>
          {!hidePanel && (
            <MAccordionPanel p="4" px="10">
              {children}
            </MAccordionPanel>
          )}
        </>
      )}
    </MAccordionItem>
  );
};
