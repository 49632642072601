import { Tooltip } from '@chakra-ui/react';
import { FunctionComponent as FC, Fragment } from 'react';
import { MdClose } from 'react-icons/md';
import {
  MBox,
  MCheckbox,
  MCustomIconButton,
  MDivider,
  MGrid,
  MText,
} from '~app/components/Monetize';
import { PRODUCT_TYPE_DISPLAY_SHORT } from '~app/constants/products';
import { OfferingTypesEnum, ProductResWithMandatory } from '~app/types';
import { sortByProductType } from '~app/utils';
import { useFlags } from '../../../../services/launchDarkly';
import { colors } from '../../../../styles/theme';
import BodyCell from './BodyCell';
import HeaderCellText from './HeaderCellText';
import OfferingHeaderCell from './OfferingHeaderCell';
import ProductSearch from './ProductSearch';

export const getRowColor = (index: number) =>
  index % 2 === 0 ? 'tWhite.titanWhite' : 'tWhite.base';

interface ProductsTableProps {
  isValid: boolean;
  products: ProductResWithMandatory[];
  isEditable: boolean;
  offeringType: OfferingTypesEnum;
  handleSelect: (product: ProductResWithMandatory) => void;
  handleRemove: (product: ProductResWithMandatory, i: number) => void;
  onMandatoryCheckboxChange: (product?: ProductResWithMandatory) => void;
  isReadOnly: boolean;
  isAllProductMandatory: boolean;
}

const ProductsTable: FC<ProductsTableProps> = ({
  isValid,
  products,
  isEditable,
  offeringType,
  handleSelect,
  handleRemove,
  onMandatoryCheckboxChange,
  isReadOnly,
  isAllProductMandatory,
}: ProductsTableProps) => {
  const { allowOptionalProducts } = useFlags();

  const isIndeterminate =
    products.some(({ isMandatory }) => isMandatory) && !isAllProductMandatory;

  const isDisabled = !isEditable || isReadOnly;

  return (
    <>
      <MText
        width="full"
        alignSelf="start"
        fontSize="lg"
        fontWeight="bold"
        color="tPurple.base"
        mb="3"
        ml={8}
      >
        Products
      </MText>

      <MBox w="full" data-testid="offering-form-product-list" pl={8}>
        <MBox
          border={`1px solid #C8C5D4`}
          borderRadius={8}
          background={colors.tWhite.base}
          py={4}
          px={6}
        >
          <MGrid
            gridTemplateColumns="120px 1fr"
            columnGap={2}
            w="full"
            alignItems="center"
          >
            <MText fontWeight="bold" fontSize="md">
              Add Products
            </MText>

            <ProductSearch
              isDisabled={isDisabled}
              onSelect={handleSelect}
              selected={products}
              offeringType={offeringType}
              mb={4}
            />
          </MGrid>

          {products.length > 0 && (
            <MGrid
              mt={4}
              gridTemplateColumns={
                allowOptionalProducts
                  ? '0.9fr 0.9fr 2fr 0.9fr 0.1fr'
                  : '0.9fr 0.9fr 2fr 1fr'
              }
              w="full"
              alignItems="center"
              data-testid="offering-product-list"
            >
              <MDivider gridColumn="1 / -1" mb={2} />
              <OfferingHeaderCell pr={4} pb={4} pt={4}>
                <HeaderCellText textProps={{ fontSize: 'md' }} pl={4}>
                  Products
                </HeaderCellText>
              </OfferingHeaderCell>
              <OfferingHeaderCell pr={4} pb={4} pt={4}>
                <HeaderCellText textProps={{ fontSize: 'md' }}>
                  Product Type
                </HeaderCellText>
              </OfferingHeaderCell>
              <OfferingHeaderCell pr={4} pb={4} pt={4}>
                <HeaderCellText textProps={{ fontSize: 'md' }}>
                  Description
                </HeaderCellText>
              </OfferingHeaderCell>
              {allowOptionalProducts && (
                <OfferingHeaderCell gridColumn="span" pb={4} pt={4}>
                  <HeaderCellText
                    textProps={{ fontSize: 'md' }}
                    tooltipLabel="Unchecked items are optional on quote."
                    additionalNode={
                      !isDisabled && (
                        <MCheckbox
                          isDisabled={isDisabled}
                          isChecked={isAllProductMandatory}
                          onChange={() => onMandatoryCheckboxChange()}
                          isIndeterminate={isIndeterminate}
                        >
                          <MBox as="span">Select All</MBox>
                        </MCheckbox>
                      )
                    }
                  >
                    Required
                  </HeaderCellText>
                </OfferingHeaderCell>
              )}

              <MDivider gridColumn="1 / -1" />
              {sortByProductType(products, 'name').map((product, index) => (
                <Fragment key={product.id}>
                  <BodyCell bgColor={getRowColor(index)}>
                    <MText
                      pl={4}
                      fontSize="sm"
                      color="tPurple.base"
                      fontWeight="regular"
                    >
                      {product.name}
                    </MText>
                  </BodyCell>
                  <BodyCell bgColor={getRowColor(index)}>
                    <MText color="tPurple.base" textTransform="capitalize">
                      {PRODUCT_TYPE_DISPLAY_SHORT[product.productType]}
                    </MText>
                  </BodyCell>
                  <BodyCell bgColor={getRowColor(index)}>
                    <MText color="tPurple.base">{product.description}</MText>
                  </BodyCell>
                  {allowOptionalProducts && (
                    <BodyCell bgColor={getRowColor(index)} align="center">
                      <MCheckbox
                        pl={8}
                        isDisabled={isDisabled}
                        isChecked={product.isMandatory}
                        onChange={() => onMandatoryCheckboxChange(product)}
                      ></MCheckbox>
                    </BodyCell>
                  )}
                  <BodyCell
                    justify="right"
                    bgColor={getRowColor(index)}
                    cursor="pointer"
                  >
                    {!isReadOnly && isEditable && products.length > 0 && (
                      <Tooltip label="Remove" placement="bottom-end">
                        <MCustomIconButton
                          mr={4}
                          title="Remove"
                          variant="icon"
                          icon={MdClose}
                          onClick={() => handleRemove(product, index)}
                        />
                      </Tooltip>
                    )}
                  </BodyCell>
                  <MDivider bgColor={getRowColor(index)} gridColumn="1 / -1" />
                </Fragment>
              ))}
            </MGrid>
          )}
        </MBox>
      </MBox>
    </>
  );
};

export default ProductsTable;
