import {
  AccordionButton,
  AccordionButtonProps,
  Icon,
  TextProps,
} from '@chakra-ui/react';
import React, { FunctionComponent as FC, HTMLAttributes } from 'react';
import { MdChevronRight, MdExpandMore } from 'react-icons/md';
import { MFlex, MTooltip } from './chakra';
import MText from './MText';

/**
 * This component is used as the content within an AccordionItem.
 * @example
 * ```
    <Accordion allowMultiple w="100%">
      <AccordionItem
        key={id}
        mt={4}
        borderWidth="1px"
        borderRadius="md"
        borderColor="tGray.back"
      >
        {({ isExpanded }) => (
          <>
            <MAccordionCustomButton isExpanded={isExpanded} label="Test">
              <MAccordionCustomButtonItem label="test:" value="test" />
              <MAccordionCustomButtonItem label="test:" value="test" />
            </MAccordionCustomButton>
            <AccordionPanel>test</AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
 * ```
 */

interface MAccordionCustomButtonProps
  extends HTMLAttributes<HTMLHeadingElement> {
  isExpanded: boolean;
  label: string;
  buttonProps?: AccordionButtonProps;
  children?: React.ReactNode;
  labelProps?: TextProps;
  testId?: string;
  customLabel?: React.ReactNode;
}

const MAccordionCustomButton: FC<MAccordionCustomButtonProps> = ({
  isExpanded,
  label,
  children,
  buttonProps,
  labelProps,
  testId,
  customLabel,
  ...rest
}: MAccordionCustomButtonProps) => {
  return (
    <h2 {...rest}>
      <AccordionButton py={0} pr={8} {...buttonProps} data-testid={testId}>
        <MFlex alignItems="center" alignContent="spread" w="full">
          <MFlex textAlign="left" alignItems="center" py={3} flexGrow={1}>
            <MTooltip label="Toggle" placement="top-start">
              <MFlex alignItems="center">
                {isExpanded ? (
                  <Icon boxSize="1.5rem" as={MdExpandMore} />
                ) : (
                  <Icon boxSize="1.5rem" as={MdChevronRight} />
                )}
              </MFlex>
            </MTooltip>
            {customLabel ? (
              customLabel
            ) : (
              <MText fontWeight="600" fontSize="lg" ml={1} {...labelProps}>
                {label}
              </MText>
            )}
          </MFlex>
          <MFlex alignItems="center">{children}</MFlex>
        </MFlex>
      </AccordionButton>
    </h2>
  );
};

export default MAccordionCustomButton;
