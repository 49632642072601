import isNumber from 'lodash/isNumber';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { MGrid, MGridItem, MText } from '~app/components/Monetize';
import { useFlags } from '../../../../../services/launchDarkly';
import {
  ContextProduct,
  GuidedQuotingContext,
  GuidedQuotingContextOffering,
} from '../../../../../types';
import { ProductQuantityTableRow } from './ProductQuantityTableRow';

interface ProductQuantityTableProps {
  questionId: string;
  offeringId: string;
  context: any;
  setContext: Dispatch<SetStateAction<GuidedQuotingContext>>;
}

export const ProductQuantityTable = ({
  questionId,
  offeringId,
  context,
  setContext,
}: ProductQuantityTableProps) => {
  const { salesDemoMedinsightTempGuidedQuotingSpecialConfig: specialConfig } =
    useFlags();
  /**
   * Super sketchy way to set initial values for quantity of specific products
   * based on the value from another question - driven by a feature flag
   */
  useEffect(() => {
    // Has special quantity mapping
    if (
      Array.isArray(context[questionId].value[offeringId]?.products) &&
      specialConfig?.quantityMapping?.questionId &&
      context[specialConfig.quantityMapping.questionId]
    ) {
      const {
        productIds: productIdsToInitQty,
        questionId: questionIdToGetQtyFrom,
      } = specialConfig?.quantityMapping || {};

      const allProducts = new Set(
        context[questionId].value[offeringId].products.map(
          (product: ContextProduct) => product.id,
        ),
      );

      const productsToSetQty = new Set(
        productIdsToInitQty.filter((id) => allProducts.has(id)) || [],
      );
      const { value, type } = context[questionIdToGetQtyFrom] || {};

      function getInitialQuantity(product: ContextProduct) {
        if (product.qty) {
          return product.qty;
        }
        // default from question answer
        if (productsToSetQty.has(product.id)) {
          return value;
        }
        // preselected number
        if (isNumber(specialConfig?.defaultQuantities?.[product.id])) {
          return specialConfig?.defaultQuantities?.[product.id]!;
        }
        // default value (likely '')
        return product.qty;
      }

      if (
        productsToSetQty.size > 0 &&
        type === 'NUMBER' &&
        /^[0-9]+$/.test(value)
      ) {
        setContext((prev) => {
          const newState = structuredClone(prev);

          const contextValue = (
            newState[questionId] as GuidedQuotingContextOffering
          ).value[offeringId];

          const previousProducts =
            (prev[questionId] as GuidedQuotingContextOffering).value[offeringId]
              .products ?? [];

          contextValue.products = previousProducts.map((product) => ({
            ...product,
            qty: getInitialQuantity(product),
          }));

          return newState;
        });
        // specialConfig.defaultQuantities handled above if user got into this case
        return;
      }
    }

    if (specialConfig?.defaultQuantities) {
      setContext((prev) => {
        const newState = structuredClone(prev);

        const contextValue = (
          newState[questionId] as GuidedQuotingContextOffering
        ).value[offeringId];

        const previousProducts =
          (prev[questionId] as GuidedQuotingContextOffering).value[offeringId]
            .products ?? [];

        contextValue.products = previousProducts.map(
          (product: ContextProduct) => ({
            ...product,
            qty: String(
              specialConfig.defaultQuantities?.[product.id] ?? product.qty,
            ),
          }),
        );

        return newState;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context[questionId].value[offeringId]?.products?.length]);

  function handleRowChange(
    row: ContextProduct,
    quantity: string,
    isSelected: boolean,
  ) {
    setContext((prev) => {
      const newState = structuredClone(prev);

      const contextValue = (
        newState[questionId] as GuidedQuotingContextOffering
      ).value[offeringId];

      const previousProducts =
        (prev[questionId] as GuidedQuotingContextOffering).value[offeringId]
          .products ?? [];

      contextValue.products = previousProducts.map(
        (product: ContextProduct) => ({
          ...product,
          isSelected: product.id === row.id ? isSelected : product.isSelected,
          qty: product.id === row.id ? quantity : product.qty,
        }),
      );

      return newState;
    });
  }

  if (!context[questionId].value[offeringId]) {
    return null;
  }

  const { name, products: allProducts } = context[questionId].value[offeringId];
  const products = allProducts.filter(
    (product: any) => product.type !== 'USAGE',
  );

  let areas = ``;
  let rows = ``;

  products.forEach((product: any) => {
    areas += `"name ${product.id} ${product.id}qty"`;
    rows += `35px `;
  });

  return (
    <MGrid
      templateAreas={areas}
      width="100%"
      columnGap="0px"
      templateColumns={'200px 1fr 100px'}
      templateRows={rows}
    >
      <MGridItem
        area={'name'}
        display="flex"
        alignItems="top"
        justifyContent="left"
        overflow="auto"
      >
        <MText key={offeringId}>{name}</MText>
      </MGridItem>
      {products.map((row: ContextProduct) => (
        <ProductQuantityTableRow
          key={row.id}
          row={row}
          onChange={(quantity, isSelected) =>
            handleRowChange(row, quantity, isSelected)
          }
        />
      ))}
    </MGrid>
  );
};
