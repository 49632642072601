import { Divider } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { BsDash, BsPlus } from 'react-icons/bs';
import { MCustomIconButton, MFlex, MText } from '~app/components/Monetize';
import { roundNumberToDecimal } from '../../../utils/numberUtils';

const STEP = 0.1;

interface PreviewPdfDrawerZoomProps {
  zoom: number;
  minZoom?: number;
  maxZoom?: number;
  onChange: (value: number) => void;
}

export const PreviewPdfDrawerZoom: FC<PreviewPdfDrawerZoomProps> = ({
  onChange,
  minZoom = 0.2,
  maxZoom = 5,
  zoom,
}) => {
  const displayValue = useMemo(
    () => `${roundNumberToDecimal(zoom * 100, 2)}%`,
    [zoom],
  );

  return (
    <MFlex alignItems="center">
      <MCustomIconButton
        variant="icon"
        containerSize="5"
        btnSize={5}
        fontSize="lg"
        _focus={{ background: 'tGray.support' }}
        icon={BsDash}
        onClick={() => onChange(Math.max(zoom - STEP, minZoom))}
        color="tPurple.dark"
        iconColorHover="tPurple.base"
        mr={2}
      />
      <Divider orientation="vertical" h="20px" border="1px solid #E3E8EC" />
      <MCustomIconButton
        variant="icon"
        containerSize="5"
        btnSize={5}
        fontSize="lg"
        _focus={{ background: 'tGray.support' }}
        icon={BsPlus}
        onClick={() => onChange(Math.min(zoom + STEP, maxZoom))}
        color="tPurple.dark"
        iconColorHover="tPurple.base"
        ml={2}
      />
      <MText color="tPurple.dark" fontWeight="normal" pl={2}>
        {displayValue}
      </MText>
    </MFlex>
  );
};
