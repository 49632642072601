import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  useGetEarliestManualPaymentDate,
  useUpdateEarliestManualPaymentDate,
} from '~app/api/settingsService';
import {
  MAccordion,
  MBox,
  MButton,
  MDivider,
  MFlex,
  MFormField,
  MPageContainer,
  MSettingsPageHeader,
} from '~app/components/Monetize';
import { DatePicker } from '~app/components/Monetize/DatePicker/DatePicker';
import { MSettingAccordionItem } from '~app/components/Monetize/MSettingAccordionItem';
import { useFlags } from '~app/services/launchDarkly';

export const BillingSettings = () => {
  const { enableBillingSettings } = useFlags();
  const { data: earliestPaymentDateSetttings, isLoading } =
    useGetEarliestManualPaymentDate();

  const { mutate: doUpdateEarliestPaymentDate, isLoading: isUpdateLoading } =
    useUpdateEarliestManualPaymentDate();

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm<{ earliestManualPaymentDate: string }>({
    mode: 'onChange',
    defaultValues: {
      earliestManualPaymentDate:
        earliestPaymentDateSetttings?.earliestManualPaymentDate,
    },
  });

  const currentEarliestPaymentDate = watch('earliestManualPaymentDate');

  const handleSubmitAutomationConfig = async (formData: any) => {
    doUpdateEarliestPaymentDate(formData);
  };

  useEffect(() => {
    if (earliestPaymentDateSetttings) {
      reset({
        earliestManualPaymentDate:
          earliestPaymentDateSetttings.earliestManualPaymentDate,
      });
    }
  }, [earliestPaymentDateSetttings, reset]);

  return (
    <MPageContainer alignItems="stretch">
      <MSettingsPageHeader title="Billing Settings" />
      <MBox maxW="600px" data-testid="billing-settings">
        <form
          onSubmit={handleSubmit(handleSubmitAutomationConfig)}
          data-testid="invoice-automation-settings-form"
        >
          <MAccordion defaultIndex={[0]} allowToggle reduceMotion>
            <MSettingAccordionItem
              description="Define settings for Accounting Periods."
              label="Accounting Periods"
            >
              <MFormField
                error={errors?.earliestManualPaymentDate}
                label="Last Accounting Period closed on"
                onClick={(ev) => ev.stopPropagation()}
                labelProps={{ mb: 2 }}
                tooltip="Impacts the ability to edit or take actions within a previously closed Accounting Period."
              >
                <Controller
                  name="earliestManualPaymentDate"
                  control={control}
                  render={({ field: { onChange, value, ...rest } }) => (
                    <DatePicker
                      value={value ?? currentEarliestPaymentDate}
                      placeholder={'Chose Date'}
                      isLoading={isLoading}
                      isDisabled={isLoading || !enableBillingSettings}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      {...rest}
                    />
                  )}
                />
              </MFormField>
              <MDivider my={2} />
              <MFlex justifyContent="flex-end">
                <MButton
                  type="submit"
                  isLoading={isUpdateLoading}
                  isDisabled={
                    isUpdateLoading ||
                    isLoading ||
                    !currentEarliestPaymentDate ||
                    earliestPaymentDateSetttings?.earliestManualPaymentDate ===
                      currentEarliestPaymentDate
                  }
                >
                  Save
                </MButton>
              </MFlex>
            </MSettingAccordionItem>
          </MAccordion>
        </form>
      </MBox>
    </MPageContainer>
  );
};
