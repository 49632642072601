import { MBox, MDivider, MText } from '~app/components/Monetize';

interface ContractSettingSectionProps {
  label?: string;
  children: React.ReactNode;
  showDivider?: boolean;
}

export const ContractSettingSection = ({
  label,
  children,
  showDivider = true,
}: ContractSettingSectionProps) => {
  return (
    <MBox w="100%">
      {label && (
        <MText fontSize="md" fontWeight="600" mb="4">
          {label}
        </MText>
      )}
      {children}
      {showDivider && <MDivider my="4" />}
    </MBox>
  );
};
