import { useEffect, useState } from 'react';
import { Route, Routes, matchPath, useLocation } from 'react-router-dom';
import { ROLES, ROUTES } from '~app/constants';
import {
  MCustomLinkTab,
  MFlex,
  MPageContainer,
  MTabList,
  MTabs,
} from '~components/Monetize';
import { PrivateRoute } from '../AppPrivateRoute';
import { ManualBillRun } from './BillRun/ManualBillRun';
import { InvoiceManagement } from './InvoiceManagement/InvoiceManagement';

const billingTabs = [
  {
    title: 'Bill Run',
    route: ROUTES.BILLING_BILL_RUN,
  },
  {
    title: 'Invoice Management',
    route: ROUTES.BILLING_INVOICe_MANAGEMENT,
  },
];

export const BillingPage = () => {
  const { pathname } = useLocation();
  const [activeTabIdx, setActiveTabIdx] = useState(0);
  useEffect(() => {
    setActiveTabIdx(
      billingTabs.findIndex(({ route }) => matchPath(route, pathname)),
    );
  }, [pathname]);

  return (
    <MPageContainer
      centerContent={false}
      enableAccountSearch
      maxWidth="unset"
      data-testid="billing-page"
    >
      <MFlex flex="1" alignSelf="stretch" flexDirection="column">
        <MTabs index={activeTabIdx} size="sm" isLazy mt={-1}>
          <MTabList alignItems="flex-end">
            {billingTabs?.map(({ route, title }, index) => (
              <MCustomLinkTab to={route} key={index}>
                {title}
              </MCustomLinkTab>
            ))}
          </MTabList>
        </MTabs>
      </MFlex>
      <Routes>
        <Route
          path="bill-run"
          element={
            <PrivateRoute
              roles={ROLES.BILLING_ADMIN_ROLES}
              component={<ManualBillRun />}
            />
          }
        />
        <Route
          path="invoice-management"
          element={
            <PrivateRoute
              roles={ROLES.BILLING_ADMIN_ROLES}
              component={<InvoiceManagement />}
            />
          }
        />
      </Routes>
    </MPageContainer>
  );
};

export default BillingPage;
