import React, { FunctionComponent as FC } from 'react';
import { IApprovalRespSchema, IQuoteRespSchema } from '~app/types';
import { MBox } from '../Monetize';
import { ApprovalSteps } from './ApprovalSteps';

interface ApprovalQuoteItemProps {
  quote: IQuoteRespSchema;
  approvals: IApprovalRespSchema[];
  reloadData: () => void;
}

const ApprovalQuoteItem: FC<ApprovalQuoteItemProps> = React.forwardRef<
  any,
  ApprovalQuoteItemProps
>((props: ApprovalQuoteItemProps, ref) => {
  const { quote, approvals, reloadData } = props;

  return (
    <MBox w="100%">
      <ApprovalSteps
        quote={quote}
        approvals={approvals}
        reloadData={reloadData}
      />
    </MBox>
  );
});

export default ApprovalQuoteItem;
