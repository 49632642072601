import {
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import React, { FunctionComponent as FC } from 'react';
import { MdClose, MdSearch } from 'react-icons/md';
import MCustomIconButton from '../../MCustomIconButton';

interface SearchInputProps {
  ref: any;
  count?: number;
  onKeyDown: any;
  value: string;
  onChange: (val: string) => void;
  children?: React.ReactNode;
}

const SearchInput: FC<SearchInputProps> = React.forwardRef<
  any,
  SearchInputProps
>(({ children, value, onChange, count = 0, onKeyDown, ...rest }, ref) => {
  const onSetEmpty = () => {
    onChange('');
  };

  return (
    <Flex mx="2" mt={2}>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <Icon as={MdSearch} />
        </InputLeftElement>

        <Input
          ref={ref}
          value={value}
          placeholder="Search..."
          onKeyDown={onKeyDown}
          isRequired={false}
          required={false}
          aria-required={false}
          onChange={(e: any) => onChange(e.target.value)}
        />

        <InputRightElement>
          {value && (
            <MCustomIconButton
              icon={MdClose}
              bg="none"
              _focus={{
                bg: 'tBlue.hover',
              }}
              onClick={onSetEmpty}
              onKeyDown={(ev) => ev.stopPropagation()}
            />
          )}
        </InputRightElement>
      </InputGroup>
    </Flex>
  );
});

export default SearchInput;
