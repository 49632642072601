import { zodResolver } from '@hookform/resolvers/zod';
import pick from 'lodash/pick';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { handleApiErrorToast } from '~app/api/axios';
import {
  MBox,
  MButton,
  MFormField,
  MGrid,
  MInput,
  MLink,
  MPageContainer,
  MPageLoader,
  MSettingsPageHeader,
  MText,
} from '~app/components/Monetize';
import { CustomSteps } from '~app/components/Monetize/CustomSteps/CustomSteps';
import { ROUTES } from '~app/constants';
import { defaultStepValue } from '~app/constants/dunnings';
import { usePrompt } from '~app/hooks/usePrompt';
import { useDocumentHead } from '~app/services/documentHead';
import {
  DunningCreateSchema,
  IDunningCreateSchema,
  IDunningStepCreateSchema,
  IDunnings,
} from '~app/types';
import { nullifyEmptyStrings } from '~app/utils/misc';
import {
  useCreateEntity,
  useGetById,
  useUpdateEntity,
} from '../../../../api/queryUtils';
import { logger } from '../../../../services/logger';
import DunningStep from './DunningStep';

const EditDunning = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { setDocTitle } = useDocumentHead();
  const dunningId = params.dunningId || '';

  const { data: dunning, isInitialLoading } = useGetById<IDunnings>(
    'dunnings',
    dunningId,
    {
      refetchOnWindowFocus: false,
      enabled: !!dunningId,
      onSuccess: (data) => {
        setExistingSteps(data.dunningSteps);
        setDocTitle('Settings', `Dunning - ${data.name}`);
      },
      onError: (error) => {
        handleApiErrorToast(error);
        if (!dunning) {
          navigate(ROUTES.SETTINGS_DUNNING_LIST);
        }
      },
    },
  );
  const [existingSteps, setExistingSteps] = useState<
    IDunningStepCreateSchema[]
  >(() => dunning?.dunningSteps || []);

  const { mutate: createDunningMutate, isLoading: createLoading } =
    useCreateEntity<IDunnings, IDunningCreateSchema>('dunnings', {
      onSuccess: (newData) => {
        newData.id && navigate(-1);
      },
      onError: (err) => handleApiErrorToast(err),
    });

  const { mutate: updateDunningMutate, isLoading: updateLoading } =
    useUpdateEntity<IDunnings, IDunningCreateSchema>('dunnings', {
      onSuccess: (newData) => {
        /** Using setTimeout here since it was triggering usePrompt hook because of the dirty fields on save */
        newData.id && setTimeout(() => navigate(-1));
      },
      onError: (err) => handleApiErrorToast(err),
    });

  const dunningData = dunning
    ? pick(nullifyEmptyStrings(dunning), Object.keys(DunningCreateSchema.shape))
    : undefined;

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    clearErrors,
    watch,
    formState: { errors, dirtyFields },
  } = useForm<IDunningCreateSchema>({
    resolver: zodResolver(DunningCreateSchema),
    mode: 'onSubmit',
    values: dunningData
      ? {
          ...dunningData,
          name: dunningData.name || '',
          dunningSteps:
            dunningData.dunningSteps?.map(({ internalCCEmails, ...rest }) => {
              return {
                ...rest,
                /** Since BE will send array, FE needs to turn it to string */
                internalCCEmails: Array.isArray(internalCCEmails)
                  ? internalCCEmails.join(', ')
                  : null,
              };
            }) || [],
        }
      : undefined,
  });

  usePrompt(
    'There are unsaved changes, do you want to discard these changes?',
    !!dunningId && Object.keys(dirtyFields).length > 0,
  );

  const { fields, append, replace } = useFieldArray({
    control,
    name: `dunningSteps`,
    keyName: 'sId',
  });

  useEffect(() => {
    if (fields.length === 0) {
      addNewDunningStep(1);
    }
  }, []);

  const onSubmit = async (data: IDunningCreateSchema) => {
    const finalSteps = data.dunningSteps.map(
      (step: IDunningStepCreateSchema) => {
        const { id, internalCCEmails, ...rest } = step;
        return {
          ...rest,
          /** Since BE accepts array, FE needs to turn it to array */
          internalCCEmails:
            internalCCEmails && internalCCEmails.length > 0
              ? (internalCCEmails as string)
                  .trim()
                  .split(',')
                  .map((item) => item.trim())
                  .filter(Boolean)
              : null,
        };
      },
    );

    const payload = { ...data, dunningSteps: finalSteps };

    createDunningMutate(nullifyEmptyStrings(payload));
  };

  const onUpdate = async (data: IDunningCreateSchema) => {
    const submittedSteps = data.dunningSteps;

    const finalSteps = submittedSteps.map((step: IDunningStepCreateSchema) => {
      const updatedStepsId = existingSteps?.find(
        (eStep: any) => eStep.id === step.id,
      );
      if (updatedStepsId) {
        return {
          ...step,
          /** Since BE accepts array, FE needs to turn it to array */
          internalCCEmails:
            step.internalCCEmails && step.internalCCEmails.length > 0
              ? (step.internalCCEmails as string)
                  .trim()
                  .split(',')
                  .map((item) => item.trim())
                  .filter(Boolean)
              : null,
        };
      }
      const { id, internalCCEmails, ...rest } = step;
      return {
        ...rest,
        /** Since BE accepts array, FE needs to turn it to array */
        internalCCEmails:
          internalCCEmails && internalCCEmails.length > 0
            ? (internalCCEmails as string)
                .trim()
                .split(',')
                .map((item) => item.trim())
                .filter(Boolean)
            : null,
      };
    });

    const finalPayload = { ...data, id: dunningId, dunningSteps: finalSteps };

    updateDunningMutate({
      id: dunningId,
      payload: nullifyEmptyStrings(finalPayload),
    });
  };

  const handleSubmitBtn = (data: IDunningCreateSchema, e: any) => {
    if (dunningId) {
      return onUpdate(nullifyEmptyStrings(data));
    }
    return onSubmit(nullifyEmptyStrings(data));
  };

  const addNewDunningStep = (stepSequence?: number | null): void => {
    if (fields.length === 0) {
      append({
        ...defaultStepValue,
        id: uuidv4(),
        stepSequence: !stepSequence ? fields.length + 1 : stepSequence,
      });
    } else {
      const {
        sendEmail,
        retryCollection,
        emailBillGroupShippingContact,
        emailBillGroupCCEmails,
        internalCCEmails,
      } = watch(`dunningSteps.${fields.length - 1}`);

      append({
        ...defaultStepValue,
        sendEmail,
        retryCollection,
        emailBillGroupShippingContact,
        emailBillGroupCCEmails,
        internalCCEmails,
        id: uuidv4(),
        stepSequence: !stepSequence ? fields.length + 1 : stepSequence,
      });
    }
  };

  const removeDunningStep = (id: number | string) => {
    clearErrors();
    const steps = getValues('dunningSteps');
    const filteredSteps = steps.filter((step) => step.id !== id);

    const updatedSteps = filteredSteps.map((step: any, index: number) => {
      const updatedStepId = { ...step, stepSequence: index + 1 };
      return updatedStepId;
    });

    replace(updatedSteps);
  };

  const swapSteps = (items: any, firstIndex: number, secondIndex: number) => {
    clearErrors();
    const results = items.slice();
    const firstItem = items[firstIndex];
    results[firstIndex] = items[secondIndex];
    results[secondIndex] = firstItem;

    return results;
  };

  const reOrderDunningStep = (data: any, orderType: string) => {
    clearErrors();
    const dunningSteps = getValues('dunningSteps');

    const selectedIndex = dunningSteps.findIndex(
      (step: any) => step.id === data.id,
    );

    const fromIndex = selectedIndex;

    let toIndex = 0;
    if (orderType === 'up') {
      toIndex = fromIndex - 1;
    }
    if (orderType === 'down') {
      toIndex = fromIndex + 1;
    }

    if (fromIndex !== toIndex) {
      const result = swapSteps(dunningSteps, fromIndex, toIndex);

      const updatedSteps = result.map((step: any, index: number) => {
        const updateStepSequence = { ...step, stepSequence: index + 1 };
        return updateStepSequence;
      });

      replace(updatedSteps);
    }
  };

  const onError = (error: any) => logger.error('Dunning save error', error);

  if (isInitialLoading) {
    return <MPageLoader />;
  }

  return (
    <MPageContainer
      alignItems="stretch"
      data-testid="dunning-form"
      overflowX="scroll"
    >
      <MSettingsPageHeader
        divider={false}
        hasBackButton
        backButtonTitle="Back to Dunning Processes"
        backButtonLink={ROUTES.SETTINGS_DUNNING_LIST}
        title={!dunningId ? 'New Dunning Process' : 'Edit Dunning Process'}
        id={dunningId}
      >
        <MButton
          data-testid="dunning-form-submit-btn"
          variant="primary"
          isLoading={createLoading || updateLoading}
          onClick={handleSubmit(handleSubmitBtn, onError)}
        >
          Save
        </MButton>
      </MSettingsPageHeader>
      <MBox>
        <MGrid
          maxWidth="750px"
          templateColumns="2fr 1fr 1fr"
          gap={4}
          mb={10}
          px={3.5}
        >
          <MFormField error={errors.name} label="Dunning Name" isRequired>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <MInput placeholder="Enter Name" {...field} />
              )}
            />
          </MFormField>
        </MGrid>
        <MBox mb={6} px={3.5}>
          <MText fontSize={18} fontWeight="bold">
            Dunning Steps
          </MText>
          <MText color="tGray.darkPurple">
            {
              "Configure the steps you'd like to take when a customer's payment method fails."
            }
          </MText>
        </MBox>

        <MBox ml={-4}>
          {fields.map((field, index) => (
            <CustomSteps
              key={field.sId}
              stepIndex={index}
              stepNumber={field.stepSequence}
              stepData={field}
              totalSteps={fields?.length}
              removable={fields?.length !== 1}
              removeStep={removeDunningStep}
              isOrderable={fields?.length !== 1}
              reOrderSteps={reOrderDunningStep}
            >
              <DunningStep
                errors={errors}
                index={index}
                control={control}
                setValue={setValue}
                clearErrors={clearErrors}
              />
            </CustomSteps>
          ))}
          <CustomSteps
            stepNumber={fields.length + 1}
            isLastStep
            stepData={defaultStepValue}
            removable={false}
            containerProps={fields.length > 1 ? { ml: '0.6rem', gap: 2.5 } : {}}
          >
            <MLink
              color="tIndigo.base"
              fontWeight="bold"
              textDecoration="underline"
              fontSize="sm"
              onClick={() => addNewDunningStep()}
            >
              + Add New Step
            </MLink>
          </CustomSteps>
        </MBox>
      </MBox>
    </MPageContainer>
  );
};

export default EditDunning;
