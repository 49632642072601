import { FunctionComponent as FC } from 'react';
import { MText } from '../Monetize';

const getAlignment = (label: string) => {
  if (label === 'Quantity') {
    return 'center';
  }
  if (label === 'Amount' || label === 'Price Per Unit') {
    return 'right';
  }
  return 'left';
};

const getAlignmentForReview = (label: string) => {
  if (label === 'Discount') {
    return 'center';
  }
  if (label === 'Cost' || label === 'Amount' || label === 'Price Per Unit') {
    return 'right';
  }
  return 'left';
};

const getPaddingRightForReview = (label: string) => {
  if (label === 'Cost' || label === 'Amount') {
    return 4;
  }
  return 0;
};

// FIXME: get rid of any
const HeaderCell: FC<any> = ({
  value,
  children,
  reviewQuote,
  ...rest
}: any) => {
  const alignment = !reviewQuote
    ? getAlignment(value)
    : getAlignmentForReview(value);
  const paddingRightForReview = getPaddingRightForReview(value);
  return (
    <MText
      key={value}
      fontWeight="bold"
      fontSize="md"
      textAlign={alignment}
      pr={reviewQuote ? paddingRightForReview : 0}
      {...rest}
    >
      {children}
    </MText>
  );
};

export default HeaderCell;
