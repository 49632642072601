import { Progress } from '@chakra-ui/react';
import { MBox } from '~components/Monetize';

interface BillingPageLoadingBarProps {
  isVisible: boolean;
  progress: number;
}

export const BillingPageLoadingBar = ({
  isVisible = false,
  progress,
}: BillingPageLoadingBarProps) => {
  return (
    <MBox minH="4px" width="100%" mt="-6" mb="6">
      {isVisible && (
        <Progress
          width="100%"
          size="xs"
          value={progress}
          colorScheme="blackAlpha"
        />
      )}
    </MBox>
  );
};
