import { Progress } from '@chakra-ui/react';
import { useCallback, useRef } from 'react';
import { useNavigate } from 'react-router';
import { MBox, MFlex, MSkeleton } from '~app/components/Monetize';
import { Maybe } from '~app/types';
import { logger } from '../../services/logger';

interface HtmlIFrameContentRendererProps {
  /**
   * Unique identifier for the iframe, this is used for testing and must be a unique and meaningful value
   */
  id: string;
  /**
   * If not provided, skeleton will be rendered instead
   */
  html?: Maybe<string>;
  /**
   * If true, skeleton will be rendered
   */
  isInitialLoading?: boolean;
  /**
   * Set to true if data is re-fetching and a progress bar will be shown
   */
  isReFetching?: boolean;
}

const IFRAME_SCROLL_HEIGHT_BUFFER = 100;

// Regex to match a URL without the path - used to turn absolute links into relative links
const urlPattern = /^(?:https?:\/\/)?(?:www\.)?[^/]+/;

/**
 * If a link is clicked in the iframe that matches any of these routes,
 * we will intercept the click and navigate within the app
 */
const LINK_INTERCEPTOR_ROUTES = [
  /^\/invoices\/invc_[\w-]+$/,
  /^\/accounts\/acct_[\w-]+\/payments\/pymt_[\w-]+\/view$/,
  /^\/accounts\/acct_[\w-]+\/credit\/crdt_[\w-]+\/view$/,
  /^\/accounts\/acct_[\w-]+\/credit-note\/crnt_[\w-]+\/view$/,
  /^\/accounts\/acct_[\w-]+\/refunds\/rfnd_[\w-]+\/view$/,
];

/**
 * Returns true if link should be disabled and made to not look like a link
 */
function isInternalLinkOnSharePage(link: string) {
  // If not on invoice share, then ignore
  if (!link || !window.location.pathname.startsWith('/share/')) {
    return false;
  }
  // ignore links that are not from MonetizeNow
  if (!link.includes('monetizeplatform') && !link.includes('localhost')) {
    return false;
  }
  // allow links that can be accessed anonymously
  if (link.includes('/share/')) {
    return false;
  }
  // This link should only be visible to MonetizeNow authenticated users
  return true;
}

export const HtmlIFrameContentRenderer = ({
  id,
  html,
  isInitialLoading,
  isReFetching,
}: HtmlIFrameContentRendererProps) => {
  const contentFrameRef = useRef<HTMLIFrameElement>(null);
  const linkRefs = useRef<HTMLAnchorElement[]>([]);

  const navigate = useNavigate();

  /**
   * Intercept links so we can navigate within the app
   */
  const handleLinkClick = useCallback((event: MouseEvent) => {
    try {
      let canHandle = true;
      const target = event.currentTarget as HTMLAnchorElement;
      const targetUrl = (target.getAttribute('href') || '').replace(
        urlPattern,
        '',
      );
      canHandle =
        !!targetUrl &&
        LINK_INTERCEPTOR_ROUTES.some((route) => route.test(targetUrl));
      if (canHandle) {
        event.preventDefault();
        navigate(targetUrl);
      }
    } catch (ex) {
      logger.error('Error intercepting event', ex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDisabledLinkClick = useCallback((event: MouseEvent) => {
    try {
      event.preventDefault();
    } catch (ex) {
      logger.error('Error intercepting event', ex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnLoad = useCallback(() => {
    try {
      const iframe = contentFrameRef?.current;
      if (iframe) {
        const iframeWindow = iframe.contentWindow;
        const iframeDocument = iframe.contentDocument ?? iframeWindow?.document;

        if (iframeWindow) {
          iframe.style.height = `${
            iframeWindow.document.body.scrollHeight +
            IFRAME_SCROLL_HEIGHT_BUFFER
          }px`;
        }
        if (iframeDocument) {
          iframeDocument
            .querySelectorAll('a')
            .forEach((link: HTMLAnchorElement) => {
              try {
                linkRefs.current.push(link);
                if (isInternalLinkOnSharePage(link.href)) {
                  // overwrite links so that they don't look like links and don't go anywhere
                  link.addEventListener('click', handleDisabledLinkClick);
                  link.href = '#';
                  link.style.color = '#3d3c65';
                  link.style.textDecoration = 'unset';
                  link.style.cursor = 'unset';
                } else {
                  // Add event listeners for quick navigation for internal links
                  link.addEventListener('click', handleLinkClick);
                }
              } catch (ex) {
                logger.error('Error adding event listener to link', ex);
              }
            });
        }
      }
    } catch (ex) {
      logger.error('iframe onload handler error', ex);
    }
    return () => {
      try {
        linkRefs.current.forEach((link) => {
          link.removeEventListener('click', handleLinkClick);
          link.removeEventListener('click', handleDisabledLinkClick);
        });
        linkRefs.current = [];
      } catch (ex) {
        logger.error(ex);
      }
    };
  }, [handleLinkClick]);

  if (!html || isInitialLoading) {
    return LOADING_SKELETON;
  }

  return (
    <MBox position="relative" w="100%">
      <MBox minH="4px">
        {isReFetching && (
          <Progress size="xs" isIndeterminate colorScheme="blackAlpha" />
        )}
      </MBox>
      <iframe
        id={id}
        ref={contentFrameRef}
        title="Preview Document"
        onLoad={handleOnLoad}
        srcDoc={`<html><head>
          <style>
            html {
              font-size: 14px !important;
            }
            body {
              min-height: 1200px;
            }
          </style>
          <base target="_parent">
          </head>
          ${html}
        `}
        allow=""
        sandbox="allow-popups allow-same-origin allow-top-navigation-by-user-activation"
        width="100%"
      ></iframe>
    </MBox>
  );
};

const LOADING_SKELETON = (
  <MBox padding="6" w="100%">
    <MSkeleton height="25px" width="20%" />
    <MSkeleton height="12px" width="10%" mt="1" />
    <MSkeleton height="12px" width="15%" mt="1" />
    <MFlex alignItems="center" justifyContent="space-between" mt="10" w="100%">
      <MBox w="50%">
        <MSkeleton height="15px" width="30%" />
        <MSkeleton height="15px" width="35%" mt="1" />
        <MSkeleton height="15px" width="20%" mt="1" />
        <MSkeleton height="15px" width="25%" mt="1" />
      </MBox>
      <MBox w="50%" textAlign="right" display="flex" justifyContent="flex-end">
        <MSkeleton height="80px" width="50%" />
      </MBox>
    </MFlex>
  </MBox>
);
