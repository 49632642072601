import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { handleApiErrorToast } from '~app/api/axios';
import {
  getOrderablesParams,
  productCatalogServiceQueryKeys,
  useOrderables,
} from '~app/api/productCatalogService';
import {
  CUSTOM_SELECT_SUBTITLE_TYPE,
  OPTION_TYPE_FIELD,
} from '~app/constants/customSelect';
import { OFFERING_TYPES_LABEL_DISPLAY } from '~app/constants/offerings';
import {
  OfferingDropdownSortingType,
  useFlags,
} from '~app/services/launchDarkly';
import {
  IOfferingRes,
  IOfferingResUI,
  IOrderablesRes,
  IRateResSchema,
  OfferingTypesEnum,
  OfferingTypesNameEnum,
  OrderableRecordsWithSequence,
} from '~app/types';
import {
  filterAndSortOfferings,
  sortAlphabetically,
  sortByOfferingType,
} from '~app/utils';
import { arrayToObjectOrdered } from '~app/utils/misc';

const DEFAULT_PRODUCT_OFFERINGS_OBJ = { orderedIds: [], byId: {} };

/**
 * Get offerings from the product catalog
 *
 * @param currency Rates for this currency will be returned
 * @param accountId Rates for the catalog AND this optional account will be returned
 * @param orderableStartDate Rates with a start date after this date will be returned
 * @param quotableOnly If true, only rates with quotable=true will be returned
 * @returns
 */
const useProductOfferings = (
  currency?: string,
  accountId?: string,
  orderableStartDate?: string | null,
  quotableOnly?: boolean,
) => {
  const queryClient = useQueryClient();
  const { offeringDropdownSorting } = useFlags();

  const [params, setParams] = useState(() =>
    getOrderablesParams({
      currency,
      accountId,
      quotableOnly,
      orderableStartDate,
    }),
  );

  useEffect(() => {
    setParams(
      getOrderablesParams({
        currency,
        accountId,
        quotableOnly,
        orderableStartDate,
      }),
    );
  }, [accountId, currency, orderableStartDate, quotableOnly]);

  const { data, isLoading } = useOrderables(params, {
    enabled: !!currency && !!accountId,
    onError: (error) => {
      handleApiErrorToast(error);
    },
    select: (data: IOrderablesRes) => {
      return sortOrderables(data, offeringDropdownSorting);
    },
  });

  const productOfferings = data?.productOfferings || [];
  const productOfferingsObj =
    data?.productOfferingsObj || DEFAULT_PRODUCT_OFFERINGS_OBJ;

  /**
   * Update query cache with the rate changes without waiting for changes from server
   * When a user creates or updates a rate on a quote, this allows the UI to update immediately
   */
  const addOrUpdateOfferingRate = (
    offeringId: string,
    rate: IRateResSchema,
  ) => {
    queryClient
      .getQueryCache()
      .findAll({
        queryKey: [...productCatalogServiceQueryKeys.orderables(), params],
      })
      .forEach((cache) => {
        const existingData = cache.state?.data as IOrderablesRes;
        // Update or add rate to prior oderables API response
        const newData = {
          ...existingData,
          offerings: existingData?.offerings?.map((offering) => {
            if (offering.id !== offeringId) {
              return offering;
            }
            // Add or replace rate in the response cache for the modified/created rate
            const hasRate = offering.rates.some(({ id }) => id === rate.id);
            return {
              ...offering,
              rates: hasRate
                ? offering.rates.map((oldRate) =>
                    oldRate.id === rate.id ? rate : oldRate,
                  )
                : [...offering.rates, rate],
            };
          }),
        };
        cache.setData(newData);
      });
  };

  return {
    loading: isLoading,
    productOfferings,
    productOfferingsObj,
    addOrUpdateOfferingRate,
  };
};

export function sortOrderables(
  data: IOrderablesRes,
  offeringDropdownSorting: OfferingDropdownSortingType,
) {
  const offerings = data.offerings.map((offering) => {
    const offer: IOfferingResUI = offering;
    // for UI only, displaying the offering type next to the offering name in the select box
    offer.rightLabel = OFFERING_TYPES_LABEL_DISPLAY[offer.type!];
    return offer;
  });

  const productOfferingsObj = arrayToObjectOrdered(
    data.offerings.map((offering) => ({
      ...offering,
      ratesObj: arrayToObjectOrdered(offering.rates, 'id'),
    })),
    'id',
  ) as OrderableRecordsWithSequence;

  switch (offeringDropdownSorting) {
    case 'alphabetical': {
      return {
        productOfferings: sortByOfferingType(
          offerings.filter(
            (offering: IOfferingResUI) => !!offering.rates?.length,
          ),
        ) as IOfferingRes[],
        productOfferingsObj,
      };
    }

    case 'customId': {
      return {
        productOfferings: offerings.sort(sortAlphabetically('customId')),
        productOfferingsObj,
      };
    }

    case 'groupByTypeNoHeader': {
      const oneTimes = filterAndSortOfferings(
        offerings,
        OfferingTypesEnum.ONETIME,
      );
      const subscriptions = filterAndSortOfferings(
        offerings,
        OfferingTypesEnum.SUBSCRIPTION,
      );
      const minCommits = filterAndSortOfferings(
        offerings,
        OfferingTypesEnum.MIN_COMMIT,
      );
      const percentOfTotals = filterAndSortOfferings(
        offerings,
        OfferingTypesEnum.CUSTOM_PERCENT_OF_TOTAL,
      );

      return {
        productOfferings: [
          ...minCommits,
          ...subscriptions,
          ...oneTimes,
          ...percentOfTotals,
        ] as IOfferingRes[],
        productOfferingsObj,
      };
    }

    case 'groupByType':
    default: {
      const groupedOfferings: any[] = [];
      const offeringTypes = [
        OfferingTypesEnum.MIN_COMMIT,
        OfferingTypesEnum.SUBSCRIPTION,
        OfferingTypesEnum.ONETIME,
        OfferingTypesEnum.CUSTOM_PERCENT_OF_TOTAL,
      ];

      offeringTypes.forEach((type) => {
        const filteredAndSorted = filterAndSortOfferings(offerings, type);
        if (filteredAndSorted.length > 0) {
          groupedOfferings.push(
            {
              name: OfferingTypesNameEnum[type],
              [OPTION_TYPE_FIELD]: CUSTOM_SELECT_SUBTITLE_TYPE,
            },
            ...filteredAndSorted,
          );
        }
      });

      return {
        productOfferings: groupedOfferings as IOfferingRes[],
        productOfferingsObj,
      };
    }
  }
}

export default useProductOfferings;
