import { useRecoilState } from 'recoil';
import { IQuoteRespSchema } from '~app/types';
import { useFlags } from '../../../../services/launchDarkly';
import { QuoteStateDataTypes } from '../quoteContext';
import { QuoteBannerItem } from './QuoteBannerItem';
import { bannerDismissedSelector } from './quoteBanner.utils';

export function InactiveRateInfoBanner({
  quote,
  quoteStateData,
}: {
  quote: IQuoteRespSchema;
  quoteStateData: QuoteStateDataTypes;
}) {
  const { disableInactiveProductBanner } = useFlags();
  const [dismissed, setDismissed] = useRecoilState(
    bannerDismissedSelector([quote.id, 'InactiveRateInfoBanner']),
  );

  const { isSelectedOfferingAndRateIdsInactiveForWarning: shouldShowBanner } =
    quoteStateData;
  const hasQuoteOfferings = (quote?.quoteOfferings?.length || 0) > 0;

  if (
    !hasQuoteOfferings ||
    !shouldShowBanner ||
    disableInactiveProductBanner ||
    dismissed
  ) {
    return null;
  }

  return (
    <QuoteBannerItem onDismiss={() => setDismissed(true)} type="warning">
      There are Rates and/or Offerings on this Quote that are no longer active.
      It is recommended to adjust to an active rate.
    </QuoteBannerItem>
  );
}
