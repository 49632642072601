import { MdEdit } from 'react-icons/md';
import {
  MBox,
  MCheckbox,
  MCustomIconButton,
  MDivider,
  MFlex,
  MLink,
  MSimpleGrid,
  MSpinner,
  MText,
} from '~app/components/Monetize';
import { useCustomFields } from '~app/hooks/useCustomFields';
import {
  CustomFieldEntityEnum,
  CustomFieldTypeEnum,
  ICustomFieldRecordSchema,
  ICustomFieldResSchema,
  IInvoiceRespSchema,
} from '~app/types';

type EditCustomFieldsSectionProps = {
  isLoading: boolean;
  isDraftInvoice?: boolean;
  invoice: IInvoiceRespSchema;
  customFiledValues?: ICustomFieldRecordSchema;
  onOpenEditInvoiceModal: () => void;
};

export const EditInvocieSection = ({
  isLoading,
  isDraftInvoice,
  invoice,
  customFiledValues,
  onOpenEditInvoiceModal,
}: EditCustomFieldsSectionProps) => {
  const { customFieldList, isLoading: isCustomFieldsLoading } = useCustomFields(
    CustomFieldEntityEnum.INVOICE,
  );

  const stripHtmlTags = (html: string | null) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html ?? 'Blank', 'text/html');

    return doc.body.textContent;
  };

  if (isLoading || isCustomFieldsLoading) {
    return (
      <MBox w="100%" display="flex" alignItems="center" justifyContent="center">
        <MSpinner size="sm" />
      </MBox>
    );
  }

  return (
    <MBox
      width="100%"
      border="1px"
      borderColor="tGray.lightPurple"
      borderRadius={8}
      p={6}
    >
      <MFlex justifyContent="space-between">
        <MText fontSize={16} fontWeight={600} mb={4}>
          Invoice Details
        </MText>
        <MCustomIconButton
          variant="icon"
          btnSize={4}
          iconColor="tPurple.base"
          icon={MdEdit}
          data-testid="edit-draft-invoice-btn"
          onClick={onOpenEditInvoiceModal}
        />
      </MFlex>
      <MDivider mb={6} />
      <MSimpleGrid
        columns={isDraftInvoice ? 5 : 4}
        gap={4}
        mb={6}
        alignItems="flex-start"
      >
        {isDraftInvoice && (
          <>
            <MBox>
              <MText color="tGray.darkPurple" fontWeight={400}>
                Billing Contact *
              </MText>
              <MText color="tPurple.200">
                {invoice.billingContact.fullName ?? 'Blank'}
              </MText>
            </MBox>
            <MBox>
              <MText color="tGray.darkPurple" fontWeight={400}>
                Billing Address *
              </MText>
              {invoice.billingAddress?.id ? (
                <>
                  {invoice.billingAddress.line1 && (
                    <MText>{invoice.billingAddress.line1}</MText>
                  )}
                  {invoice.billingAddress.line2 && (
                    <MText>{invoice.billingAddress.line2}</MText>
                  )}
                  <MText>
                    {invoice.billingAddress.city},{' '}
                    {invoice.billingAddress.country}
                  </MText>
                </>
              ) : (
                <MText>Blank</MText>
              )}
            </MBox>
            <MBox>
              <MText color="tGray.darkPurple" fontWeight={400}>
                Shipping Contact *
              </MText>
              <MText color="tPurple.200">
                {invoice.shippingContact.fullName ?? 'Blank'}
              </MText>
            </MBox>
            <MBox>
              <MText color="tGray.darkPurple" fontWeight={400}>
                Shipping Address *
              </MText>
              {invoice.shippingAddress?.id ? (
                <>
                  {invoice.shippingAddress.line1 && (
                    <MText>{invoice.shippingAddress.line1}</MText>
                  )}
                  {invoice.shippingAddress.line2 && (
                    <MText>{invoice.shippingAddress.line2}</MText>
                  )}
                  <MText>
                    {invoice.shippingAddress.city},{' '}
                    {invoice.shippingAddress.country}
                  </MText>
                </>
              ) : (
                <MText>Blank</MText>
              )}
            </MBox>
          </>
        )}

        <MBox>
          <MText color="tGray.darkPurple" fontWeight={400}>
            PO Number
          </MText>
          <MText color="tPurple.200">
            {invoice.purchaseOrderNumber ?? 'Blank'}
          </MText>
        </MBox>
        <MBox>
          <MText color="tGray.darkPurple" fontWeight={400}>
            Registration Number
          </MText>
          <MText color="tPurple.200">
            {invoice.registrationNumber ?? 'Blank'}
          </MText>
        </MBox>
        <MBox>
          <MText color="tGray.darkPurple" fontWeight={400}>
            VAT Number
          </MText>
          <MText color="tPurple.200">{invoice.vatNumber ?? 'Blank'}</MText>
        </MBox>
        <MBox>
          <MText color="tGray.darkPurple" fontWeight={400}>
            Invoice Memo
          </MText>
          <MText color="tPurple.200" isTruncated>
            {stripHtmlTags(invoice.invoiceMemo)}
          </MText>
        </MBox>
      </MSimpleGrid>

      {customFieldList?.length >= 5 && (
        <MBox>
          <MText color="tGray.darkPurple" fontWeight={400}>
            Additional Fields
          </MText>
          <MLink
            onClick={onOpenEditInvoiceModal}
            fontSize="sm"
            cursor="pointer"
          >
            View All in Edit screen
          </MLink>
        </MBox>
      )}

      {customFieldList?.length < 5 && (
        <MSimpleGrid columns={5} gap={4} mb={6} alignItems="flex-end">
          {customFieldList?.map((customField: ICustomFieldResSchema) => {
            const { key, displayLabel, type } = customField;
            const fieldValue = customFiledValues![key];

            switch (type) {
              case CustomFieldTypeEnum.CHECKBOX: {
                return (
                  <MBox>
                    <MCheckbox isChecked={!!fieldValue}>
                      {displayLabel}
                    </MCheckbox>
                  </MBox>
                );
              }
              default:
                return (
                  <MBox>
                    <MText color="tGray.darkPurple" fontWeight={400}>
                      {displayLabel}
                    </MText>
                    <MText color="tPurple.200">{fieldValue ?? 'Blank'}</MText>
                  </MBox>
                );
            }
          })}
        </MSimpleGrid>
      )}
    </MBox>
  );
};
