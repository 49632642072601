import React from 'react';

import { Navigate } from 'react-router-dom';
import { ROUTES } from '~app/constants';
import { useACL } from '~app/services/acl/acl';
import { ACLActionItemType } from '~app/services/acl/actions';

import { useAuth } from '~app/services/auth0';

interface IPrivateRouteProps {
  component: JSX.Element;
  path?: string;
  roles?: string[];
  acls?: ACLActionItemType[];
}

export const PrivateRoute: React.FC<IPrivateRouteProps> = ({
  component: RouteComponent,
  roles,
  acls,
}: IPrivateRouteProps) => {
  const { currentTenantUserHasRole } = useAuth();
  const { canDo } = useACL();

  if (roles && !currentTenantUserHasRole(roles)) {
    return <Navigate to={ROUTES.UNAUTHORIZED_PAGE} replace />;
  }
  if (acls && !canDo(acls)) {
    return <Navigate to={ROUTES.UNAUTHORIZED_PAGE} replace />;
  }

  return RouteComponent;
};
