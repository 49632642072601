import { Flex, NumberInputProps } from '@chakra-ui/react';
import React, { FunctionComponent as FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { MdCheck } from 'react-icons/md';
import {
  MCustomIconButton,
  MCustomNumberInput,
  MFormField,
} from '~app/components/Monetize';
import { IQuoteItemRespSchema, IQuoteOfferingReqSchema } from '~app/types';

interface CustomPriceInputProps extends NumberInputProps {
  item: IQuoteItemRespSchema;
  errors?: any;
  control?: Control<IQuoteOfferingReqSchema>;
  index?: number;
  defaultValue?: number;
  focused?: boolean;
  handleChange?: (diff: boolean, close: boolean) => void;
}

const CustomPriceInput: FC<CustomPriceInputProps> = React.forwardRef<
  any,
  CustomPriceInputProps
>(
  (
    {
      errors,
      control,
      index,
      defaultValue,
      focused,
      handleChange,
      item,
    }: CustomPriceInputProps,
    ref: any,
  ) => {
    const updateCustomPrice = (diff: boolean, close: boolean) => {
      handleChange && handleChange(diff, close);
    };

    // FIXME: breaking change
    // trigger addition on return/enter key press
    // useHotkeys('return', () => updateCustomPrice(true, true), {
    //   enableOnTags: ['INPUT'],
    // });

    if (typeof index === 'undefined' || typeof control === 'undefined') {
      return null;
    }
    const customPriceError = errors?.items?.[index]?.customPrice?.unitAmount;
    // if message exists then there is an error. Don't close input until fixed.
    const isValid = !customPriceError?.message;

    return (
      <MFormField error={customPriceError}>
        <Controller
          name={`items.${index}.customPrice.unitAmount`}
          control={control}
          defaultValue={defaultValue}
          render={({ field: { value, onBlur, onChange, ...rest } }) => {
            // Check for difference between value and item?.amount which is what we are updating.
            // if customPriceId is null always update.
            const parsedValue = parseFloat(String(value));
            const isValueDiff =
              !item?.customPriceId ||
              ((!!parsedValue || parsedValue === 0) &&
                parsedValue !== defaultValue);
            return (
              <Flex alignItems="center" justifyContent="flex-end">
                <MCustomNumberInput
                  value={value}
                  inputMode="decimal"
                  onChange={(valueAsString: string, valueAsNumber: number) =>
                    onChange(valueAsString)
                  }
                  onBlur={() => {
                    updateCustomPrice(isValueDiff, isValid);
                    onBlur();
                  }}
                  variant="quantity"
                  styleProps={{
                    maxWidth: 20,
                    textAlign: 'center',
                  }}
                  {...rest}
                />
                {focused && isValueDiff && isValid && (
                  <MCustomIconButton
                    bg="none"
                    btnSize={4}
                    p={2}
                    ml={2}
                    mr={-2}
                    _hover={{
                      background: 'none',
                    }}
                    iconColor="tIndigo.base"
                    icon={MdCheck}
                    onClick={() => updateCustomPrice(true, isValid)}
                  />
                )}
              </Flex>
            );
          }}
        />
      </MFormField>
    );
  },
);

export default CustomPriceInput;
