import { BoxProps, Icon, TextProps, useDisclosure } from '@chakra-ui/react';
import React, { FunctionComponent as FC } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { MdCheck, MdClose, MdRemove } from 'react-icons/md';
import { MBox, MFlex, MIcon, MText } from '../../Monetize';
import MPopoverWrapper from '../../Monetize/MPopoverWrapper';

interface QuoteProgressStepProps {
  stepTitle: string | React.ReactElement;
  stepDate: string | React.ReactElement;
  /**
   * If true, it will make the step indigo completed color.
   */
  isCompleted?: boolean;
  /**
   * Step Number to show within the circle
   */
  stepNumber: number;
  /**
   * If true, it will show - inside the step number circle. This indicate the step is in progress.
   */
  showInprogressStepNumber?: boolean;
  /**
   * If true, it will make grayed out step
   */
  isDisabled?: boolean;
  /**
   * If true, it indicates this is current step
   */
  isCurrent?: boolean;
  isError?: boolean;
  /**
   * If it's recover-able error state like EXPIRED, it should show light background
   */
  showLightBackground?: boolean;
  showHighlight?: boolean;
  showStepPopover?: boolean;
  popoverContent?: ({}: { onClose: () => void }) => React.ReactElement;
  containerProps?: BoxProps;
  textProps?: Pick<TextProps, 'color'>;
  popoverState: ReturnType<typeof useDisclosure>;
}

const STEP_HEIGHT = 40;
const DEFAULT_STYLES = {
  fontColor: 'tGray.disabledText',
  backgroundColor: 'tGray.disabledBack',
  circleBackColor: 'tWhite.base',

  containerBorderColor: 'none',
  containerBorderWidth: 'none',
  containerBoxShadow: 'none',
  zIndex: 'unset',
};
const COMPLETED_STYLES = {
  fontColor: 'tWhite.base',
  backgroundColor: 'tIndigo.base',
  circleBackColor: 'tIndigo.base',
};
const DISABLED_STYLES = DEFAULT_STYLES;
const CURRENT_STYLES = {
  fontColor: 'tIndigo.base',
  backgroundColor: 'tBlue.hover',
  circleBackColor: 'tWhite.base',
};
const ERROR_STYLES = {
  fontColor: 'tRed.hover',
  backgroundColor: 'tRed.cosmos',
  circleBackColor: 'tRed.cosmos',
};
const ERROR_COMPLETED_STYLES = {
  fontColor: 'tWhite.base',
  backgroundColor: 'tRed.hover',
  circleBackColor: 'tRed.hover',
};

const padding = 6;

export const QuoteProgressStep: FC<QuoteProgressStepProps> = React.forwardRef<
  any,
  QuoteProgressStepProps
>(
  (
    {
      stepTitle,
      stepDate,
      isCompleted,
      stepNumber,
      showInprogressStepNumber,
      isDisabled,
      isCurrent,
      isError,
      showLightBackground,
      showHighlight = false,
      showStepPopover,
      popoverContent,
      containerProps,
      textProps,
      popoverState,
    }: QuoteProgressStepProps,
    ref,
  ) => {
    let styles = { ...DEFAULT_STYLES };
    if (isCurrent) {
      styles = { ...DEFAULT_STYLES, ...CURRENT_STYLES };
    }
    if (isCompleted) {
      styles = { ...DEFAULT_STYLES, ...COMPLETED_STYLES };
    }
    if (isDisabled) {
      styles = { ...DEFAULT_STYLES, ...DISABLED_STYLES };
    }
    if (isError) {
      styles = !showLightBackground
        ? { ...DEFAULT_STYLES, ...ERROR_COMPLETED_STYLES }
        : { ...DEFAULT_STYLES, ...ERROR_STYLES };
    }
    if (showHighlight) {
      styles.containerBoxShadow = '0px 4px 10px 0px #4659DA66';
      styles.containerBorderWidth = '1px';
      styles.containerBorderColor = 'tBlue.light';
      styles.zIndex = '10';
    }

    return (
      <MFlex
        minWidth={[
          '288px',
          'initial',
          'initial',
          'initial',
          'initial',
          '288px',
        ]}
        borderRadius="27px"
        minHeight={`${STEP_HEIGHT}px`}
        backgroundColor={styles.backgroundColor}
        alignItems="center"
        px={`${padding}px`}
        pr="10"
        flex={['initial', 'initial', 'initial', '1', '1', '1']}
        {...containerProps}
        borderColor={styles.containerBorderColor}
        borderWidth={styles.containerBorderWidth}
        boxShadow={styles.containerBoxShadow}
        zIndex={styles.zIndex}
        transition={'all 0.3s ease'}
      >
        <MFlex alignItems="center">
          <MBox
            w={`${STEP_HEIGHT - padding * 2}px`}
            h={`${STEP_HEIGHT - padding * 2}px`}
            borderRadius="50%"
            borderWidth="2px"
            borderColor={styles.fontColor}
            backgroundColor={styles.circleBackColor}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {isError ? (
              <MIcon as={MdClose} color={styles.fontColor} />
            ) : showInprogressStepNumber ? (
              <MIcon as={MdRemove} color={styles.fontColor} />
            ) : isCompleted ? (
              <MIcon as={MdCheck} color={styles.fontColor} />
            ) : (
              <MText fontWeight="semibold" color={styles.fontColor}>
                {stepNumber}
              </MText>
            )}
          </MBox>
          <MPopoverWrapper
            placement="bottom-start"
            usePortal
            trigger="click"
            isOpen={popoverState.isOpen}
            onClose={popoverState.onClose}
            popoverProps={{ offset: [-50, 10] }}
            triggerContent={
              <MBox
                display="flex"
                flexDirection="column"
                justifyContent="center"
                ml="4"
                flex="1"
              >
                <MFlex
                  alignItems="center"
                  cursor={
                    showStepPopover && !isDisabled ? 'pointer' : undefined
                  }
                  onClick={showStepPopover ? popoverState.onToggle : () => {}}
                >
                  <MText
                    color={styles.fontColor}
                    fontSize={'sm'}
                    fontWeight={500}
                    {...textProps}
                  >
                    {stepTitle}
                  </MText>

                  {showStepPopover && !isDisabled && (
                    <MBox display="flex" alignItems="center" ml="2">
                      {!popoverState.isOpen ? (
                        <Icon
                          as={FiChevronDown}
                          zIndex="1"
                          color={styles.fontColor}
                          {...textProps}
                        />
                      ) : (
                        <Icon
                          as={FiChevronUp}
                          zIndex="1"
                          color={styles.fontColor}
                          {...textProps}
                        />
                      )}
                    </MBox>
                  )}
                </MFlex>
                <MText
                  fontSize="xs"
                  color={styles.fontColor}
                  lineHeight="1.2"
                  {...textProps}
                >
                  {stepDate}
                </MText>
              </MBox>
            }
            popoverContentProps={{
              display: 'flex',
              alignItems: 'center',
              border: 'none',
              bg: 'transparent',
              justifyContent: 'center',
              boxShadow: 'none',
            }}
            popoverBodyProps={{ w: '280px', p: 0 }}
            bodyContent={
              <MBox
                bg="white"
                w="100%"
                boxShadow={'0px 4px 25px rgba(180, 188, 239, 0.38)'}
              >
                {popoverContent &&
                  popoverContent({ onClose: popoverState.onClose })}
              </MBox>
            }
          />
        </MFlex>
      </MFlex>
    );
  },
);
