import { tagAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { theme as defaultTheme } from '@chakra-ui/theme';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tagAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    size: 'md',
    fontWeight: 'normal',
    borderRadius: '3',
    fontSize: 'sm',
  },
  closeButton: {
    h: '18px',
    w: '18px',
  },
});

const blueVariant = definePartsStyle({
  container: {
    color: 'tIndigo.base',
    bgColor: 'tPurple.linkWater',
  },
});

const grayVariant = definePartsStyle({
  container: {
    color: 'tGray.darkPurple',
    bgColor: 'tGray.back',
  },
});

const greenVariant = definePartsStyle({
  container: {
    color: 'tGreen.mDarkShade',
    bgColor: 'tGreen.light',
  },
});

const greenTextVariant = definePartsStyle({
  container: {
    color: 'tGreen.mDarkShade',
    bgColor: 'transparent',
  },
});

const neutralVariant = definePartsStyle({
  container: {
    color: 'tGray.darkPurple',
    bgColor: 'transparent',
  },
});

const neutralItalicVariant = definePartsStyle({
  container: {
    color: 'tGray.darkPurple',
    bgColor: 'transparent',
    fontStyle: 'italic',
  },
});

const orangeVariant = definePartsStyle({
  container: {
    color: 'tOrange.base',
    bgColor: 'tOrange.input',
  },
});

const purpleVariant = definePartsStyle({
  container: {
    color: 'tPurple.safety',
    bgColor: 'tPurple.inputBox',
  },
});

const redTextVariant = definePartsStyle({
  container: {
    color: 'tRed.base',
    bgColor: 'transparent',
  },
});

const lightBlueVariant = definePartsStyle({
  container: {
    color: 'tIndigo.base',
    bgColor: 'tBlue.hover',
  },
});

// TODO: if we use anywhere else, come up with a better name
const operandVariant = definePartsStyle({
  container: {
    p: 0,
    minW: '34px',
    textAlign: 'center',
    borderRadius: '3px',
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'uppercase',
    color: 'tPurple.dark',
    bg: 'tBlue.hover',
    justifyContent: 'center',
  },
});

const milestoneVariant = definePartsStyle({
  container: {
    w: '100%',
    flexGrow: 1,
    flexShrink: 0,
    maxWidth: 'fit-content',
    color: 'tGray.darkPurple',
    whiteSpace: 'nowrap',
    borderRadius: 12,
    border: '1px solid',
    borderColor: 'tGray.back',
  },
});

const uploadVariant = definePartsStyle({
  container: {
    background: 'tGray.support',
  },
  label: {
    textDecoration: 'underline',
  },
});

const outlineVariant = definePartsStyle((props) => ({
  ...defaultTheme.components.Tag.variants?.outline(props),
  container: {
    ...defaultTheme.components.Tag.variants?.outline(props).container,
    color: 'tPurple.dark',
    shadow: 'none',
    border: '1px solid',
    borderColor: 'tGray.back',
  },
}));

const sizes = {
  xs: definePartsStyle({
    container: {
      minH: '18px',
      px: 2,
    },
  }),
};

export const tagTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    size: 'xs',
    variant: 'gray',
  },
  variants: {
    blue: blueVariant,
    lightBlue: lightBlueVariant,
    gray: grayVariant,
    green: greenVariant,
    greenText: greenTextVariant,
    neutral: neutralVariant,
    neutralItalic: neutralItalicVariant,
    operand: operandVariant,
    orange: orangeVariant,
    purple: purpleVariant,
    red: redTextVariant,
    mileStone: milestoneVariant,
    upload: uploadVariant,
    outline: outlineVariant,
  },
});

export default tagTheme;
