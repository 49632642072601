import { CardBody } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import CurrencyIcon from '~app/assets/icons/CurrencyIcon';
import {
  MBox,
  MButton,
  MCard,
  MDivider,
  MDrawer,
  MDrawerBody,
  MDrawerCloseButton,
  MDrawerContent,
  MDrawerFooter,
  MDrawerHeader,
  MDrawerOverlay,
  MFlex,
  MPageLoader,
  MTab,
  MTabList,
  MTabPanel,
  MTabPanels,
  MTabs,
  MText,
} from '~app/components/Monetize';
import { useOfferingRate } from '~app/hooks';
import { ManageProductsFormWithCard } from '~app/routes/Quotes/Quote/components/quoteOffering/manageProducts/ManageProductsForm';
import { useConfirmModal } from '~app/services/confirmModal';
import { useFlags } from '~app/services/launchDarkly';
import {
  IAccount,
  IAccountRateCreateReqSchema,
  IAccountRateUpdateReqSchema,
  IOfferingRes,
  IOrderedObj,
  IPriceUnderProductSchemaUI,
  IQuoteItemRespSchema,
  IQuoteOfferingRespSchema,
  IRateResSchema,
  Maybe,
  OfferingTypesEnum,
  SelectedProductsWithinOfferingForm,
} from '~app/types';
import {
  CONFIRM_CLOSE_UNSAVED_DESC,
  CONFIRM_CLOSE_UNSAVED_TITLE,
  CONFIRM_SWITCH_UNSAVED_DESC,
  CONFIRM_TAB_SWITCH_TITLE,
} from '~app/utils/messages';
import { arrayToObjectOrdered } from '~app/utils/misc';
import { PricesDrawerBodyEdit } from '../PricesDrawer/PricesDrawerBodyEdit';
import { PricesDrawerBodyView } from '../PricesDrawer/PricesDrawerBodyView';

interface QuoteDrawerBaseProps {}

interface QuoteDrawerProps {
  offering?: IOfferingRes | null;
  account?: IAccount | Pick<IAccount, 'id' | 'accountName'>;
  // either (quoteOffering, offeringRate, orderedPricesUnderProduct) or (offeringId, rateId) should be passed in together
  quoteOffering?: IQuoteOfferingRespSchema | null;
  offeringRate?: IRateResSchema | null;
  providedOfferingRate?: IRateResSchema | null;
  orderedPricesUnderProduct?: IPriceUnderProductSchemaUI[];
  offeringType: OfferingTypesEnum;
  // These can be provided instead of the above optional properties and above data will be auto-fetched
  offeringId?: string;
  rateId?: Maybe<string>;
  allowPriceCustomization?: boolean;
  disabled?: boolean;
  isOfferingOpen?: boolean;
  onRateSaved?: (rate: IRateResSchema) => void;
  setOfferingRate?: (val: IRateResSchema | null) => void;
  setOrderedPricesUnderProduct?: (val: IPriceUnderProductSchemaUI[]) => void;
  onDrawerToggle?: (isOpen: boolean) => void;
  modalConfig: {
    isOpen: boolean;
    onClose: () => void;
    onOpen: () => void;
  };
  updateOfferingItems: (offering: SelectedProductsWithinOfferingForm) => void;
  offeringCreationLoading: boolean;
  areProductsOptional: boolean;
}

export const QuoteDrawer = ({
  offering,
  account,
  quoteOffering,
  offeringId = undefined,
  rateId = null,
  orderedPricesUnderProduct,
  offeringRate: providedOfferingRate,
  allowPriceCustomization,
  disabled,
  isOfferingOpen,
  offeringType,
  onRateSaved,
  setOfferingRate,
  setOrderedPricesUnderProduct,
  onDrawerToggle,
  modalConfig,
  updateOfferingItems,
  offeringCreationLoading,
  areProductsOptional,
  ...rest
}: QuoteDrawerProps) => {
  const xhrController = useRef(new AbortController());
  const pricesBtnRef = useRef<any>();
  const { isOpen, onOpen, onClose } = modalConfig;
  const [tabIndex, setTabIndex] = useState(1);
  const { allowOptionalProducts } = useFlags();

  const [productsObj, setProductsObj] = useState<
    IOrderedObj<IQuoteItemRespSchema>
  >({
    byId: {},
    orderedIds: [],
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [isProductListChanged, setIsProductListChanged] = useState(false);

  const {
    loading: loadingInitialData,
    offeringRate: offeringRateData,
    orderedPricesUnderProduct: orderedPricesUnderProductData,
    fetchOfferingRate,
    createOfferingRate,
    updateOfferingRate,
  } = useOfferingRate(offeringId);

  const { showConfirmModal, closeModal: closeConfirmationModal } =
    useConfirmModal();

  const offeringRate = providedOfferingRate || offeringRateData;

  const offeringName = offeringRate?.offering?.name || '';

  const getSelectedOrderedPricesUnderProduct = (
    productData: IPriceUnderProductSchemaUI[],
  ) => {
    return productData.filter((rate) => {
      const { id } = rate;
      return quoteOffering?.items.some(({ productId }) => id === productId);
    });
  };

  const productsWithPrices: IPriceUnderProductSchemaUI[] =
    orderedPricesUnderProduct || orderedPricesUnderProductData;

  const selectedProductsWithPrices: IPriceUnderProductSchemaUI[] =
    getSelectedOrderedPricesUnderProduct(
      orderedPricesUnderProduct || orderedPricesUnderProductData,
    );

  // Since we have one api and separate components to update RateName and Prices
  // the api request might not send the latest update when we try to update
  // both the RateName and Prices at the same time with the single api for that
  // reason we are keeping track of latest changes with latestPayload.
  const [latestPayload, setLatestPayload] = useState<
    IAccountRateCreateReqSchema | IAccountRateUpdateReqSchema
  >();

  useEffect(() => {
    const { current } = xhrController;
    return () => {
      current?.abort();
    };
  }, []);

  useEffect(() => {
    if (quoteOffering && quoteOffering?.items?.length > 0) {
      setProductsObj(arrayToObjectOrdered(quoteOffering.items, 'productId'));
    }
  }, [quoteOffering]);

  useEffect(() => {
    if (rateId && !loadingInitialData && !providedOfferingRate) {
      fetchOfferingRate(rateId);
    }
  }, [rateId, providedOfferingRate]);

  useEffect(() => {
    onDrawerToggle?.(isOpen);
    if (!isOpen) {
      setTabIndex(1);
    }
  }, [isOpen]);

  async function handleClose(skipEditWarning = false, submitting = false) {
    if (!skipEditWarning && isEditMode) {
      showConfirmModal({
        title: CONFIRM_CLOSE_UNSAVED_TITLE,
        description: CONFIRM_CLOSE_UNSAVED_DESC,
        onYes: () => {
          closeConfirmationModal();
          setIsEditMode(false);
          onClose();
        },
        yesBtnProps: {
          variant: 'primary',
        },
        noBtnProps: {
          variant: 'cancel',
        },
      });
    } else if (tabIndex === 0) {
      setIsEditMode(false);

      if (!submitting) {
        onClose();
      }
    }

    if (!skipEditWarning && isProductListChanged) {
      showConfirmModal({
        title: CONFIRM_CLOSE_UNSAVED_TITLE,
        description: CONFIRM_CLOSE_UNSAVED_DESC,
        onYes: () => {
          closeConfirmationModal();
          setIsProductListChanged(false);
          onClose();
        },
        yesBtnProps: {
          variant: 'primary',
        },
        noBtnProps: {
          variant: 'cancel',
        },
      });
    } else if (tabIndex === 1) {
      setIsProductListChanged(false);
      if (!submitting) {
        onClose();
      }
    }
    setTabIndex(1);
  }

  function cancelSaveCustomRates() {
    xhrController.current?.abort();
  }

  const handleDrawerToggle = () => {
    setTabIndex(0);
    onOpen();
  };

  const handleTabSwitch = (newTabIndex: number) => {
    if (isEditMode || isProductListChanged) {
      showConfirmModal({
        title: CONFIRM_TAB_SWITCH_TITLE,
        description: CONFIRM_SWITCH_UNSAVED_DESC,
        onYes: () => {
          closeConfirmationModal();
          setIsEditMode(false);
          setTabIndex(newTabIndex);
          setIsProductListChanged(false);
        },
        yesBtnProps: {
          variant: 'primary',
        },
        noBtnProps: {
          variant: 'cancel',
        },
      });
    } else {
      setTabIndex(newTabIndex);
    }
  };

  return (
    <MBox position="relative" {...rest}>
      <MButton
        leftIcon={<CurrencyIcon />}
        iconSpacing={isOfferingOpen ? 1 : 0}
        ref={pricesBtnRef}
        onClick={handleDrawerToggle}
        variant="tertiary"
        size="sm"
        isDisabled={disabled}
        data-testid="prices-btn"
      >
        {!!isOfferingOpen && 'Prices'}
      </MButton>

      {isOpen && (
        <MDrawer
          isOpen={isOpen}
          size="lg"
          placement="right"
          onClose={handleClose}
          finalFocusRef={tabIndex === 0 ? pricesBtnRef : undefined}
          blockScrollOnMount={false}
        >
          <MDrawerOverlay />
          <MDrawerContent>
            <MDrawerCloseButton />
            <MDrawerHeader
              fontSize="md"
              borderBottomColor="tGray.back"
              borderBottomWidth="1px"
              borderBottomStyle="solid"
            >
              {offeringName}
            </MDrawerHeader>

            <MDrawerBody p={0} backgroundColor="tGray.drawerSidebar">
              <MTabs
                mb={0}
                variant="line"
                size="sm"
                defaultIndex={tabIndex}
                index={tabIndex}
                onChange={(newTabIndex) => handleTabSwitch(newTabIndex)}
              >
                <MTabList backgroundColor="tWhite.base" py={4} px={6}>
                  <MTab key={0}>Prices</MTab>
                  {allowOptionalProducts && areProductsOptional && (
                    <MTab key={1}>Products</MTab>
                  )}
                </MTabList>
                <MTabPanels>
                  <MTabPanel>
                    {tabIndex === 0 && (
                      <MCard variant="borderless" mb="2">
                        <CardBody>
                          {loadingInitialData && !offeringRate && (
                            <MFlex justify="center" grow={1}>
                              <MPageLoader />
                            </MFlex>
                          )}
                          <MText>
                            You can change the prices of these products. Any
                            changes will only affect this Quote.
                          </MText>
                          {!allowPriceCustomization && (
                            <PricesDrawerBodyView
                              offeringType={offeringType}
                              currency={offeringRate?.currency || ''}
                              rateName={offeringRate?.name || ''}
                              billingFrequency={offeringRate?.billingFrequency}
                              billingFrequencyInMonths={
                                offeringRate?.billingFrequencyInMonths
                              }
                              productsWithPrices={selectedProductsWithPrices}
                              productsObj={productsObj}
                            />
                          )}
                          {allowPriceCustomization &&
                            offeringRate &&
                            providedOfferingRate &&
                            account && (
                              <MBox>
                                {/* Allow editing rates if not custom or if it is custom is is not locked */}
                                <PricesDrawerBodyEdit
                                  account={account}
                                  offeringRate={offeringRate}
                                  providedOfferingRate={providedOfferingRate}
                                  selectedProductsWithPrices={
                                    selectedProductsWithPrices
                                  }
                                  productsObj={productsObj}
                                  latestPayload={latestPayload}
                                  isEditMode={isEditMode}
                                  setIsEditMode={setIsEditMode}
                                  setLatestPayload={setLatestPayload}
                                  onRateSaved={onRateSaved}
                                  createOfferingRate={createOfferingRate}
                                  updateOfferingRate={updateOfferingRate}
                                  setOfferingRate={setOfferingRate}
                                  setOrderedPricesUnderProduct={
                                    setOrderedPricesUnderProduct
                                  }
                                  cancelSaveCustomRates={cancelSaveCustomRates}
                                  handleClose={handleClose}
                                  offeringType={offeringType}
                                  productsWithPrices={productsWithPrices}
                                />
                              </MBox>
                            )}
                        </CardBody>
                      </MCard>
                    )}
                  </MTabPanel>
                  <MTabPanel>
                    {tabIndex === 1 && (
                      <ManageProductsFormWithCard
                        isSource="drawer"
                        offering={offering}
                        quoteOffering={quoteOffering}
                        updateOfferingItems={updateOfferingItems}
                        onClose={() => {
                          handleClose(false, false);
                        }}
                        offeringCreationLoading={offeringCreationLoading}
                        setIsProductListChanged={setIsProductListChanged}
                      />
                    )}
                  </MTabPanel>
                </MTabPanels>
              </MTabs>
            </MDrawerBody>

            <MDivider />

            <MDrawerFooter p={4}>
              <MBox w="full">
                <MFlex justifyContent="center">
                  <MButton
                    type="button"
                    variant="cancel"
                    onClick={() => {
                      handleClose(false, false);
                      cancelSaveCustomRates();
                    }}
                  >
                    Close
                  </MButton>
                </MFlex>
              </MBox>
            </MDrawerFooter>
          </MDrawerContent>
        </MDrawer>
      )}
    </MBox>
  );
};
