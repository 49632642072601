import { addDays } from 'date-fns/addDays';
import { differenceInMonths } from 'date-fns/differenceInMonths';
import { parseISO } from 'date-fns/parseISO';
import clamp from 'lodash/clamp';
import isNaN from 'lodash/isNaN';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import React, { FC, ReactNode } from 'react';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import {
  MBox,
  MCustomIconButton,
  MFlex,
  MText,
} from '~app/components/Monetize';
import { RATE_BILLING_FREQUENCY_MAP } from '~app/constants/offerings';
import {
  DELATED_BILLING_QUOTE_START_SOURCE,
  QUOTE_GRID_TEMPLATE_COLUMNS,
  QUOTE_GRID_TEMPLATE_COLUMNS_WITH_ADDITIONAL_INFO,
  SCHEDULED_OFFERING_OPTIONS,
} from '~app/constants/quotes';
import {
  EffectiveDateType,
  IOfferingRes,
  IQuoteOfferingRespSchema,
  IQuoteRespSchema,
  IRateResBaseSchema,
  OfferingTypesEnum,
  ProductTypeEnum,
  QuoteAmendmentVersionEnum,
  QuoteItemAmendmentStatusEnum,
  QuoteOfferingProps,
  QuoteOfferingReqSchema,
  QuoteOfferingState,
  QuoteStatusEnum,
  QuoteTypeEnum,
  RateBillingFrequencyEnum,
  RateUsageBillingFrequencyEnum,
  ScheduleChangeState,
} from '~app/types';
import {
  formatCurrency,
  getQuoteOfferingAmendStatusIsRemoved,
} from '~app/utils';
import { toDateShort } from '~app/utils/dates';
import { getQuoteOfferingDiscountAvailability } from '~app/utils/quotes';
import { QuoteDataById } from '../../../../../hooks/useQuote';
import QuoteOfferingRateRightEl from './QuoteOfferingRateRightEl';

/**
 * Separator between an offering and rate
 */
export const ItemRateSep = ({ isDisabled }: { isDisabled?: boolean }) => {
  return (
    <MFlex
      alignItems="center"
      justifyContent="center"
      borderTop="1px"
      borderBottom="1px"
      width="1px"
      backgroundColor={isDisabled ? 'tGray.back' : 'transparent'}
      borderColor="tGray.lightPurple"
    >
      <MBox height="4" width="1px" backgroundColor="tGray.lightPurple" />
    </MFlex>
  );
};

export const getRateRightElement = ({
  value,
  offeringRatesById,
}: {
  value: string;
  offeringRatesById: Record<string, IRateResBaseSchema>;
}) => {
  const foundRate = offeringRatesById[value];

  if (!foundRate) {
    return null;
  }

  return {
    width: 12,
    content: <QuoteOfferingRateRightEl rate={foundRate} />,
  };
};

/**
 * Offering toggle button
 */
export const OfferingToggleButton = ({
  isOpen,
  setIsOpen,
  offeringIndex,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  offeringIndex: number;
}) => {
  return (
    <MCustomIconButton
      bg="none"
      btnSize={3}
      containerSize={8}
      _hover={{
        background: 'none',
      }}
      iconColor="tPurple.dark"
      icon={isOpen ? FaChevronDown : FaChevronRight}
      onClick={() => setIsOpen(!isOpen)}
      data-testid={`toggle-btn-${offeringIndex}`}
    />
  );
};

/**
 * Rate toggle button
 */
export const RateToggleButton = ({
  isOpen,
  setIsOpen,
  offeringIndex,
}: {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  offeringIndex: number;
}) => {
  return (
    <MCustomIconButton
      bg="none"
      btnSize={3}
      containerSize={6}
      minW="6"
      _hover={{
        background: 'none',
      }}
      iconColor="tPurple.dark"
      icon={isOpen ? FaChevronDown : FaChevronRight}
      onClick={() => setIsOpen(!isOpen)}
      data-testid={`toggle-btn-${offeringIndex}`}
    />
  );
};

/**
 * If collapsed, will show rate names and dates, otherwise will show child content
 * If removed, will render removed text
 * If open and active, will render offering text
 */
export const QuoteOfferingCollapsibleRate: FC<{
  isOpen: boolean;
  quoteOffering?: IQuoteOfferingRespSchema | null;
  rateName: string;
  isOfferingEndBeforeContract: boolean;
  offeringEndDate?: string;
  isRemoved: boolean;
  children: ReactNode;
  useQuoteEditV2: boolean;
}> = ({
  isOpen,
  quoteOffering,
  rateName,
  isOfferingEndBeforeContract,
  offeringEndDate,
  isRemoved,
  useQuoteEditV2,
  children,
}) => {
  if (isRemoved) {
    <MFlex minH="8" alignItems="center">
      {useQuoteEditV2 && isRemoved && (
        <MText color="#000" fontStyle="italic" mr="2">
          Removed
        </MText>
      )}
      <MText fontWeight="bold" mr={1} mt={1} noOfLines={1}>
        {quoteOffering?.offeringName}
        {quoteOffering?.rateName ? ' - ' : ''}
        {quoteOffering?.rateName}
      </MText>
    </MFlex>;
  }

  const isOfferingOnetime =
    quoteOffering?.billingFrequency === RateBillingFrequencyEnum.ONETIME;
  if (isOpen && quoteOffering && rateName) {
    return (
      <MFlex minH="8" alignItems="center">
        {useQuoteEditV2 && isRemoved && (
          <MText color="#000" fontStyle="italic" mr="2">
            Removed
          </MText>
        )}
        <MText fontWeight="600">{quoteOffering.offeringName}</MText>
        {isOfferingEndBeforeContract && !!offeringEndDate && (
          <>
            {isOfferingOnetime ? (
              <MText fontSize="xxs" color="tOrange.tangerine" ml="4">
                Existing One-time Offerings may not be Amended. If you need to
                remove it, consider issuing a Credit Note.
              </MText>
            ) : (
              <MText fontSize="xxs" color="tOrange.tangerine" ml="4">
                Deleted as of{' '}
                {toDateShort(addDays(parseISO(offeringEndDate), 1))}
              </MText>
            )}
          </>
        )}
      </MFlex>
    );
  }

  if (isOpen || !quoteOffering || !rateName) {
    return <>{children}</>;
  }
  return (
    <MBox pt="0.2rem">
      <MText fontSize="md" fontWeight={600}>
        {rateName} {toDateShort(quoteOffering.startDate)} -{' '}
        {toDateShort(quoteOffering.endDate)}
      </MText>
    </MBox>
  );
};

/**
 * Ensure that the provided value is within the provided range.
 * Otherwise, clamp value to lower or upper bound.
 * If value is not provided, default to lower.
 * If lower is not provided, default to 0.
 * If upper is not provided, default to Infinity (in which case value would always be returned).
 */
export function clampValueToRange(
  value: number,
  min: number = 0,
  max: number = Infinity,
) {
  min = isNumber(min) && !isNaN(min) ? min : 0;
  max = isNumber(max) && !isNaN(max) ? max : Infinity;
  value = value ?? min;
  value = isNumber(value) && !isNaN(value) ? value : min;

  return clamp(value, min, max);
}

/**
 *
 * @param data IQuoteOfferingRespSchema
 * @returns IQuoteOfferingReqSchema
 */
export const prepareQuoteOfferingRequestDataFromResponse = ({
  offeringId,
  description,
  rateId,
  startDate,
  items,
  parentQuoteOfferingId,
  discounts: offeringDiscounts,
}: IQuoteOfferingRespSchema) => {
  return QuoteOfferingReqSchema.parse({
    description,
    offeringId,
    rateId,
    discountIds: offeringDiscounts.map(({ id: discountId }) => discountId),
    startDate,
    items: items.map((item) => ({
      ...item,
      customPrice: item.customPriceId
        ? {
            id: item.customPriceId,
            unitAmount: item.unitPrice || 0,
          }
        : undefined,
      quantity: item.quantity,
      customDiscountAmountOrPercent: item.customDiscountAmountOrPercent,
      customDiscountType: item.customDiscountType,
    })),
    schedule: parentQuoteOfferingId
      ? {
          startDate: startDate,
          parentQuoteOfferingId: parentQuoteOfferingId,
        }
      : undefined,
  });
};

export const isQuoteOfferingChanged = (
  quoteOffering: IQuoteOfferingRespSchema,
  quoteDataOnInitialLoad: QuoteDataById,
) => {
  // If quote offering does not exist in initial data, it is newly added and is "changed" no matter what BE reports
  if (!quoteDataOnInitialLoad.quoteOfferings[quoteOffering.id]) {
    return true;
  }
  return quoteOffering.items.some((item) => {
    const dataWasChangedAtSomePoint = quoteDataOnInitialLoad.quoteItems[item.id]
      ? quoteDataOnInitialLoad.quoteItems[item.id].amendmentStatus !==
        QuoteItemAmendmentStatusEnum.NO_CHANGE
      : false;
    return (
      dataWasChangedAtSomePoint ||
      item.amendmentStatus !== QuoteItemAmendmentStatusEnum.NO_CHANGE
    );
  });
};

/**
 * Processes a quote offering and returns various indicators related to its state.
 *
 * @param quoteOffering - The offering associated with the quote.
 * @param index - The index of the offering within the quote.
 * @param childQuoteOfferings - An array of child offerings if the current offering has any.
 * @param quote - The full quote object containing all offerings and details.
 * @param isChildOffering - A boolean indicating if the current offering is a child offering.
 * @param parentQuoteOffering - A reference to the parent offering if the current offering is a child.
 * @param quoteDataOnInitialLoad - The initial data of the quote when it was first loaded.
 *
 * @returns {Object} An object with the following properties:
 *  - newlyAddedOffering: `boolean` - Indicates if the offering was added in the current quote.
 *  - isNewQuoteOfferingForm: `boolean` - Indicates if this is a new offering form.
 *  - isRemoved: `boolean` - Indicates if the offering has been removed.
 *  - isAmendment: `boolean` - Indicates if the quote represents an amendment.
 *  - isRenewal: `boolean` - Indicates if the offering is a renewal.
 */
export const getQuoteOfferingState = ({
  quoteOffering,
  index: offeringIndex,
  childQuoteOfferings = [],
  quote,
  isChildOffering,
  parentQuoteOffering,
  quoteDataOnInitialLoad,
}: QuoteOfferingProps & {
  quote?: IQuoteRespSchema | null;
  quoteDataOnInitialLoad: QuoteDataById;
}): QuoteOfferingState => {
  // TEMPORARY don't allow removal of a scheduled offering on amendments
  const newlyAddedOffering = !!quoteOffering?.items.some(
    ({ amendmentStatus }) =>
      amendmentStatus === QuoteItemAmendmentStatusEnum.ADDED,
  );
  const isNewQuoteOfferingForm = offeringIndex === -1;
  const isLastSegment = childQuoteOfferings.length
    ? offeringIndex === childQuoteOfferings.length
    : true;

  const isRemoved = getQuoteOfferingAmendStatusIsRemoved(
    isChildOffering ? parentQuoteOffering : quoteOffering,
  );
  const isAmendment = quote?.type === QuoteTypeEnum.AMENDMENT;
  const isRenewal = quote?.type === QuoteTypeEnum.RENEWAL;
  const isDraft = quote?.status === QuoteStatusEnum.DRAFT;

  const isLocked = !!quoteOffering?.locked;
  const isReadOnlyAndNotEmptyOffering = isLocked && !isNewQuoteOfferingForm;

  const allowPriceCustomization = !isLocked;

  let bgColor = 'tWhite.titanWhite';
  if (isRemoved || isReadOnlyAndNotEmptyOffering) {
    bgColor = 'tGray.disabled';
  } else if (isChildOffering) {
    bgColor = 'tWhite.titanWhite';
  }

  let bgColorV2 = 'tWhite.base';
  if (isRemoved || isReadOnlyAndNotEmptyOffering) {
    bgColorV2 = 'tWhite.base';
  } else if (isChildOffering) {
    bgColorV2 = 'tWhite.base';
  }
  // indicates the offering (quoteOffering) was carried over from the original quote to an amended/renewed quote -- details of the offering
  // can be edited (like rate), and it can be removed, but it cannot be replaced with another offering so the select is disabled
  const isOfferingUnselectable = !!(
    quoteOffering?.subscriptionId &&
    [QuoteTypeEnum.AMENDMENT, QuoteTypeEnum.RENEWAL].includes(quote?.type!)
  );

  const gridColumns =
    quote?.type === QuoteTypeEnum.NEW ||
    (quote?.type === QuoteTypeEnum.AMENDMENT &&
      quote?.amendmentVersion === QuoteAmendmentVersionEnum.v2)
      ? QUOTE_GRID_TEMPLATE_COLUMNS
      : QUOTE_GRID_TEMPLATE_COLUMNS_WITH_ADDITIONAL_INFO;

  const { isQuoteOfferingDiscountAvailable } =
    getQuoteOfferingDiscountAvailability(quoteOffering);

  const isOfferingOnetime =
    quoteOffering?.billingFrequency === RateBillingFrequencyEnum.ONETIME;

  const lastSegment =
    (childQuoteOfferings || []).length > 0
      ? childQuoteOfferings[childQuoteOfferings.length - 1]
      : quoteOffering;

  const isLastSegmentNoChange = !!lastSegment?.items.every(
    (item) => item.amendmentStatus === QuoteItemAmendmentStatusEnum.NO_CHANGE,
  );

  const allOfferings = [
    isChildOffering ? parentQuoteOffering : quoteOffering,
    ...(childQuoteOfferings || []),
  ].filter((offering) => !!offering) as IQuoteOfferingRespSchema[];

  let isNoOfferingChanged = allOfferings.every((offering) =>
    offering.items.every(
      (item) =>
        item.amendmentStatus === QuoteItemAmendmentStatusEnum.NO_CHANGE ||
        !item.amendmentStatusOverwrite ||
        item.amendmentStatusOverwrite ===
          QuoteItemAmendmentStatusEnum.NO_CHANGE,
    ),
  );

  // Check local state overrides for amendments to see if we can allow a scheduled change
  if (
    isNoOfferingChanged &&
    quote?.type === QuoteTypeEnum.AMENDMENT &&
    quote?.amendmentVersion === QuoteAmendmentVersionEnum.v2
  ) {
    // add additional checks to see if we can allow a scheduled change
    isNoOfferingChanged = allOfferings.every((offering) =>
      offering.items.every(
        (item) =>
          !quoteDataOnInitialLoad.quoteItems[item.id] ||
          quoteDataOnInitialLoad.quoteItems[item.id].amendmentStatus ===
            QuoteItemAmendmentStatusEnum.NO_CHANGE,
      ),
    );
  }

  const firstQuoteOffering = quoteOffering;
  const lastQuoteOffering =
    isChildOffering || childQuoteOfferings.length === 0
      ? quoteOffering
      : childQuoteOfferings[childQuoteOfferings.length - 1];

  const quoteOfferingGroupStartDate = firstQuoteOffering?.startDate;
  const quoteOfferingGroupEndDate = lastQuoteOffering?.endDate;

  const isUsingDelayedBilling =
    !!quote?.type &&
    quote?.type === QuoteTypeEnum.NEW &&
    !!quote?.startDateSource &&
    DELATED_BILLING_QUOTE_START_SOURCE.has(quote?.startDateSource);

  return {
    newlyAddedOffering,
    isNewQuoteOfferingForm,
    isLastSegment,
    isRemoved,
    isAmendment,
    isRenewal,
    isDraft,
    isLocked,
    isReadOnlyAndNotEmptyOffering,
    allowPriceCustomization,
    gridColumns,
    isQuoteOfferingDiscountAvailable,
    bgColor,
    bgColorV2,
    isOfferingUnselectable,
    isOfferingOnetime,
    isLastSegmentNoChange,
    isNoOfferingChanged,
    quoteOfferingGroupStartDate,
    quoteOfferingGroupEndDate,
    isUsingDelayedBilling,
  };
};

export const getScheduleChangeState = ({
  isChildOffering,
  quoteOffering,
  quote,
  offering,
}: {
  isChildOffering: boolean;
  quoteOffering?: IQuoteOfferingRespSchema | null;
  quote?: IQuoteRespSchema | null;
  offering?: IOfferingRes;
}): ScheduleChangeState => {
  const offeringRate = offering?.rates.find(
    (rate) => rate.id === quoteOffering?.rateId,
  );
  const scheduleChangeInitDate = isChildOffering
    ? parseISO(quoteOffering?.startDate || quote?.contractStartDate!)
    : parseISO(quoteOffering?.startDate || quote?.contractStartDate!);

  const scheduleChangeEndDate = isChildOffering
    ? parseISO(quoteOffering?.endDate || quote?.contractEndDate!)
    : parseISO(quoteOffering?.endDate || quote?.contractEndDate!);

  // How many months remain until the start of next schedule change or end of contract
  const remainingMonths = differenceInMonths(
    scheduleChangeEndDate,
    scheduleChangeInitDate,
  );

  let availableMonthSelectOptions: {
    title: string;
    value: EffectiveDateType;
  }[] = SCHEDULED_OFFERING_OPTIONS.filter(
    ({ value }) => isString(value) || value <= remainingMonths,
  );

  let monthInterval = 1;

  const billingFrequencyDisplay =
    offeringRate &&
    offeringRate.usageBillingFrequency &&
    RATE_BILLING_FREQUENCY_MAP[offeringRate.usageBillingFrequency]() &&
    RATE_BILLING_FREQUENCY_MAP[offeringRate.usageBillingFrequency]()
      ? RATE_BILLING_FREQUENCY_MAP[offeringRate.usageBillingFrequency]()
      : null;

  const isMinCommit = offering?.type === OfferingTypesEnum.MIN_COMMIT;
  const hasUsageProduct = offering?.products?.some(
    (product) => product.productType === ProductTypeEnum.USAGE,
  );

  if (isMinCommit || hasUsageProduct) {
    if (
      billingFrequencyDisplay?.month &&
      typeof billingFrequencyDisplay?.month === 'number'
    ) {
      monthInterval = billingFrequencyDisplay?.month;

      const intervalCount = Math.floor(remainingMonths / monthInterval);

      if (
        offeringRate?.usageBillingFrequency ===
        RateUsageBillingFrequencyEnum.ANNUALLY
      ) {
        availableMonthSelectOptions = new Array(intervalCount)
          .fill(0)
          .map((_, index) => ({
            title: `+ ${index + 1} Year`,
            value: (index + 1) * monthInterval,
          }));
      } else {
        availableMonthSelectOptions = new Array(intervalCount)
          .fill(0)
          .map((_, index) => ({
            title: `+ ${(index + 1) * monthInterval} Month`,
            value: (index + 1) * monthInterval,
          }));
      }
    }
  }

  return {
    availableMonthSelectOptions,
    monthInterval,
    scheduleChangeInitDate,
  };
};
export const getTotalValues = (
  quote: IQuoteRespSchema,
  variableAmountIndicator: string,
) => {
  if (!quote?.incrementalPrices) {
    return null;
  }

  const formatValue = (value?: number | null) => {
    if (typeof value !== 'number') {
      return '';
    }
    return (
      formatCurrency(value, {
        currency: quote?.currency,
      }) + variableAmountIndicator
    );
  };
  return {
    subtotal: {
      value: formatValue(quote?.incrementalPrices?.absolute?.amount),
      priorValue: formatValue(quote?.incrementalPrices?.prior?.amount),
      amendmentValue: formatValue(quote.incrementalPrices?.incremental?.amount),
    },
    discount: {
      value: formatValue(-quote?.incrementalPrices?.absolute?.discountAmount),
      priorValue: formatValue(-quote?.incrementalPrices?.prior?.discountAmount),
      amendmentValue: formatValue(
        -quote?.incrementalPrices?.incremental?.discountAmount,
      ),
    },
    total: {
      value: formatValue(
        quote?.incrementalPrices?.absolute.amountAfterDiscount,
      ),
      priorValue: formatValue(
        quote?.incrementalPrices?.prior?.amountAfterDiscount,
      ),
      amendmentValue: formatValue(
        quote?.incrementalPrices?.incremental?.amountAfterDiscount,
      ),
    },
  };
};
