import { Tooltip } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import {
  Control,
  Controller,
  FormState,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { useACL } from '~app/services/acl/acl';
import {
  countriesWithStates,
  statesByCountry,
} from '~app/services/countryState';
import {
  MButton,
  MCheckbox,
  MCustomSelect,
  MFormField,
  MGrid,
  MGridItem,
  MInput,
} from '~components/Monetize';
import { CustomFieldEntityEnum, IContactRequestSchema } from '~types';
import { CustomFieldDataForm } from '../CustomFields/CustomFieldDataForm';

interface ContactFormProps {
  formId: string;
  isDisabled?: boolean;
  isCompact?: boolean;
  isCompactForCcContact?: boolean;
  omitPrimary?: boolean;
  /** from useForm() */
  control: Control<IContactRequestSchema>;
  errors: FormState<IContactRequestSchema>['errors'];
  trigger: UseFormTrigger<IContactRequestSchema>;
  watch: UseFormWatch<IContactRequestSchema>;
  setValue: UseFormSetValue<IContactRequestSchema>;
  handleSubmit: React.FormEventHandler<HTMLFormElement>;
  children?: React.ReactNode;
  isReadOnly?: boolean;
  contactId?: string | null;
}

/**
 * This component is just the form and requires a parent component to manage the form state and submission
 *
 * You can use the formId to set this on a button outside the form for submission - assuming formId is `contact-form`
 * @example `e.x. <button form="contact-form">Submit</button>`
 */
export const ContactForm = React.forwardRef(
  (
    {
      formId,
      isDisabled = false,
      isCompact = false,
      isCompactForCcContact = false,
      omitPrimary,
      control,
      errors,
      trigger,
      watch,
      setValue,
      handleSubmit,
      children,
      isReadOnly: isReadOnlyProp,
      contactId,
    }: ContactFormProps,
    ref,
  ) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const formCountry = watch('address.country');
    const states = formCountry ? statesByCountry[formCountry] : [];

    // If there is a form error, clear error once the user satisfies issue prior to blur
    const emailError = !!errors.email;
    const fullNameError = !!errors.fullName;
    useEffect(() => {
      if (emailError || fullNameError) {
        const subscription = watch((value, { name, type }) => {
          if (name === 'fullName' && fullNameError) {
            trigger('fullName');
          } else if (name === 'email' && emailError) {
            trigger('email');
          }
        });
        return () => subscription.unsubscribe();
      }
    }, [emailError, fullNameError, trigger, watch]);

    const { canDo } = useACL();
    // Disable Edit contact only when contact is being edited
    const isReadOnlyWithFF =
      !canDo([['account_contacts', 'update']]) && !!contactId;
    const isReadOnly = isReadOnlyProp || isReadOnlyWithFF;
    const isReadOnlyProps = {
      isDisabled: isReadOnly || isDisabled,
      isReadOnly: isReadOnly,
      variant: isReadOnly ? 'readonly' : 'primary',
    };

    return (
      <form data-testid={formId} id={formId} onSubmit={handleSubmit}>
        <MGrid templateColumns="repeat(12, 1fr)" gap={4}>
          {isCompactForCcContact ? (
            <>
              <MGridItem colSpan={12}>
                <MFormField error={errors.fullName} label="Name" isRequired>
                  <Controller
                    name="fullName"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <MInput {...field} {...isReadOnlyProps} />
                    )}
                  />
                </MFormField>
              </MGridItem>

              <MGridItem colSpan={12}>
                <MFormField error={errors.email} label="Email" isRequired>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <MInput {...field} {...isReadOnlyProps} />
                    )}
                  />
                </MFormField>
              </MGridItem>
            </>
          ) : (
            <>
              <MGridItem colSpan={8}>
                <MFormField error={errors.fullName} label="Name" isRequired>
                  <Controller
                    name="fullName"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <MInput {...field} {...isReadOnlyProps} />
                    )}
                  />
                </MFormField>
              </MGridItem>
              <MGridItem colSpan={4}>
                <MFormField error={errors.title} label="Job Title">
                  <Controller
                    name="title"
                    control={control}
                    defaultValue=""
                    render={({ field: { value, ...rest } }) => (
                      <MInput
                        {...rest}
                        value={value || ''}
                        {...isReadOnlyProps}
                      />
                    )}
                  />
                </MFormField>
              </MGridItem>
              <MGridItem colSpan={7}>
                <MFormField error={errors.email} label="Email" isRequired>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <MInput {...field} {...isReadOnlyProps} />
                    )}
                  />
                </MFormField>
              </MGridItem>
              <MGridItem colSpan={5}>
                <MFormField error={errors.phone} label="Phone">
                  <Controller
                    name="phone"
                    control={control}
                    defaultValue=""
                    render={({ field: { value, ...rest } }) => (
                      <MInput
                        value={value || ''}
                        {...rest}
                        {...isReadOnlyProps}
                      />
                    )}
                  />
                </MFormField>
              </MGridItem>
            </>
          )}
          {((!isCompactForCcContact && !isCompact) || isExpanded) && (
            <>
              <MGridItem colSpan={12}>
                <MFormField
                  error={errors.address?.line1}
                  label="Address Line 1"
                >
                  <Controller
                    name="address.line1"
                    control={control}
                    defaultValue=""
                    render={({ field: { value, ...rest } }) => (
                      <MInput
                        value={value || ''}
                        {...rest}
                        {...isReadOnlyProps}
                        maxLength={40}
                      />
                    )}
                  />
                </MFormField>
              </MGridItem>
              <MGridItem colSpan={12}>
                <MFormField
                  error={errors.address?.line2}
                  label="Address Line 2"
                >
                  <Controller
                    name="address.line2"
                    control={control}
                    defaultValue=""
                    render={({ field: { value, ...rest } }) => (
                      <MInput
                        value={value || ''}
                        {...rest}
                        {...isReadOnlyProps}
                        maxLength={40}
                      />
                    )}
                  />
                </MFormField>
              </MGridItem>
              <MGridItem colSpan={4}>
                <MFormField error={errors.address?.city} label="City">
                  <Controller
                    name="address.city"
                    control={control}
                    defaultValue=""
                    render={({ field: { value, ...rest } }) => (
                      <MInput
                        value={value || ''}
                        {...rest}
                        {...isReadOnlyProps}
                      />
                    )}
                  />
                </MFormField>
              </MGridItem>
              <Tooltip
                isDisabled={!!formCountry}
                label="Choose a country to see available states"
              >
                <MGridItem colSpan={4}>
                  <MFormField error={errors.address?.state} label="State">
                    <Controller
                      name="address.state"
                      control={control}
                      defaultValue=""
                      render={({ field: { value, ...rest } }) => (
                        <MCustomSelect
                          showQueryInput
                          items={states}
                          itemTitle="name"
                          itemValue="isoCode"
                          value={value || ''}
                          clearable
                          useRawValue
                          {...rest}
                          {...isReadOnlyProps}
                          isDisabled={
                            isDisabled ||
                            isReadOnlyProps.isDisabled ||
                            !formCountry
                          }
                        />
                      )}
                    />
                  </MFormField>
                </MGridItem>
              </Tooltip>
              <MGridItem colSpan={4}>
                <MFormField error={errors?.address?.postalCode} label="Zip">
                  <Controller
                    name="address.postalCode"
                    control={control}
                    defaultValue=""
                    render={({ field: { value, ...rest } }) => (
                      <MInput
                        value={value || ''}
                        {...rest}
                        {...isReadOnlyProps}
                      />
                    )}
                  />
                </MFormField>
              </MGridItem>
              <MGridItem colSpan={12}>
                <MFormField error={errors.address?.country} label="Country">
                  <Controller
                    name="address.country"
                    control={control}
                    defaultValue=""
                    render={({ field: { value, onChange, ...rest } }) => (
                      <MCustomSelect
                        showQueryInput
                        items={countriesWithStates}
                        itemTitle="name"
                        itemValue="isoCode"
                        value={value || ''}
                        clearable
                        onChange={(ev) => {
                          setValue('address.state', '');
                          onChange(ev);
                        }}
                        useRawValue
                        {...rest}
                        {...isReadOnlyProps}
                      />
                    )}
                  />
                </MFormField>

                {!omitPrimary && (
                  <MFormField label="" skipLabel>
                    <Controller
                      name="primary"
                      control={control}
                      defaultValue
                      render={({ field: { value, ...rest } }) => (
                        <MCheckbox
                          isChecked={value as any}
                          {...rest}
                          {...isReadOnlyProps}
                        >
                          Set as Primary Contact
                        </MCheckbox>
                      )}
                    />
                  </MFormField>
                )}

                <MGridItem colSpan={12}>
                  <Controller
                    name="customFields"
                    control={control}
                    defaultValue={{}}
                    render={({ field: { value, onChange } }) => (
                      <CustomFieldDataForm
                        entity={CustomFieldEntityEnum.CONTACT}
                        value={value}
                        setValue={(val) => {
                          onChange(val);
                        }}
                        fieldColSpan={6}
                        mode="modal"
                        mt="4"
                        showDivider
                        {...isReadOnlyProps}
                      />
                    )}
                  />
                </MGridItem>
              </MGridItem>
            </>
          )}
          {/* children allows parent to embed something, like a button, into the bottom of the form */}
          {isCompact && (
            <>
              <MGridItem colSpan={6}>
                <MButton
                  data-testid="compact-toggle-btn"
                  variant="tertiary"
                  size="sm"
                  onClick={() => setIsExpanded(!isExpanded)}
                >
                  {isExpanded ? 'Less Fields' : 'More Fields'}
                </MButton>
              </MGridItem>
              <MGridItem colSpan={6} justifySelf="right">
                {children}
              </MGridItem>
            </>
          )}
          {!isCompact && (
            <MGridItem
              colSpan={12}
              textAlign={isCompactForCcContact ? 'right' : 'left'}
            >
              {children}
            </MGridItem>
          )}
        </MGrid>
      </form>
    );
  },
);
