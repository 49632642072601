import { BoxProps, ButtonProps, TextProps } from '@chakra-ui/react';
import React, { ElementType } from 'react';
import { z } from 'zod';
import { ApiListResponse, DEFAULT_PAGER, GetListApiFilter } from './apiTypes';
import { FilterType } from './miscTypes';

export type SortOrder = 1 | 0 | -1 | null | undefined;

type Pager = TDataTablePager;

export type UseEntityDataResponseType<T> = {
  isLoading: boolean;
  pager: Pager;
  invoices?: ApiListResponse<T>;
  setPager?: React.Dispatch<React.SetStateAction<Pager>>;
  loadData: (params?: GetListApiFilter | undefined) => void;
  filters?: FilterType[];
  setFilters?: (arg0: FilterType[]) => void;
  onResetFilter?: () => void;
  searchTerm?: string;
  setSearchTerm?: (term: string) => void;
};

export const DataTablePagerSchema = z.object({
  first: z.number().default(DEFAULT_PAGER.first),
  rows: z.number().default(DEFAULT_PAGER.rows),
  page: z.number().default(DEFAULT_PAGER.page),
  pageCount: z.number().nullish(),
  sortField: z.string().nullish(),
  sortOrder: z.number().nullish(),
  multiSortMeta: z.array(z.any()).nullish(),
});

export type TDataTablePager<T = string> = {
  first: number;
  rows: number;
  page: number;
  pageCount?: number;
  sortField?: T;
  sortOrder?: SortOrder;
  multiSortMeta?: any[];
};

export type MEmptyPlaceholderProps = {
  renderEmptyPlaceholder?: null | (() => React.ReactElement);
  mainMessage?: string | null;
  smallMessage?: string | null;
  btnLabel?: string | null;
  alternateBtnLabel?: string | null;
  onClick?: () => void;
  to?: string;
  alternateTo?: string;
  as?: ElementType;
  boxProps?: BoxProps;
  mainMessageProps?: TextProps;
  secondaryBtnLabel?: string | null;
  onClickSecondaryBtn?: () => void;
  btnProps?: Omit<ButtonProps, 'variant' | 'as' | 'to' | 'onClick'> & {
    'data-testid': string;
  };
  alternateBtnProps?: Omit<ButtonProps, 'variant' | 'as' | 'to' | 'onClick'> & {
    'data-testid': string;
  };
};

export type MEmptyFilterResultsProps = {
  mainMessage?: string;
  smallMessage?: string | null;
  btnLabel?: string | null;
  to?: string;
  secondaryBtnLabel?: string | null;
};

export type MDataTableErrorProps = {
  isFetchingError: boolean;
  error: any;
  onRefetchData: () => void;
};
