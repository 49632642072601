import { useEffect } from 'react';

import { Accordion } from '@chakra-ui/react';
import {
  useConfigQuoteTemplate,
  useGetConfigQuoteTemplate,
} from '~app/api/settingsService';
import {
  MAccordionCustomButton,
  MButton,
  MFlex,
  MPageContainer,
  MPageLoader,
  MSettingsPageHeader,
  MStack,
} from '~app/components/Monetize';

import { useForm } from 'react-hook-form';
import { MSettingAccordionItem } from '~app/components/Monetize/MSettingAccordionItem';
import { QuoteTemplateForm } from '~app/components/Quotes/QuoteTemplates/QuoteTemplateForm';
import { useDocumentHead } from '~app/services/documentHead';
import { IQuoteTemplateConfigSchema } from '~app/types';

const QuoteTemplates = () => {
  const { setDocTitle } = useDocumentHead();
  const { data: quoteTemplate, isLoading: isDataLoading } =
    useGetConfigQuoteTemplate();
  const { mutate: updateQuoteTemplateConfig, isLoading: isUpdateLoading } =
    useConfigQuoteTemplate();

  const {
    handleSubmit,
    control,
    formState: { isDirty, errors },
    reset,
  } = useForm<IQuoteTemplateConfigSchema>({
    mode: 'onChange',
  });

  useEffect(() => {
    if (quoteTemplate) {
      reset({
        defaultTemplate: 'QUOTE_DEFAULT_1', // FALLBACK TO DEFAULT TEMPLATE UNTIL BE HAS THIS DATA
        ...quoteTemplate,
      });
    }
  }, [quoteTemplate]);

  useEffect(() => {
    setDocTitle('Settings', 'Quote Templates');
  }, []);

  const onSubmit = (payload: IQuoteTemplateConfigSchema) => {
    delete payload.showOfferingDescription;
    updateQuoteTemplateConfig(payload);
  };

  return (
    <MPageContainer data-testid="settings-quote-templates">
      <MSettingsPageHeader title="Quote Templates" />
      <MStack w="100%" overflow="auto" data-testid="quoteTemplates">
        {isDataLoading ? (
          <MPageLoader height="450px" />
        ) : (
          <Accordion allowMultiple maxW="600px">
            <MSettingAccordionItem
              data-testid="qTemplate-accordion"
              description="Configure the default customizations settings for Quotes."
              renderAccordionButton={({ isExpanded }) => (
                <MAccordionCustomButton
                  isExpanded={isExpanded}
                  label="Default Customization"
                  data-testid="quote-template-mAccordionBtn"
                />
              )}
            >
              <QuoteTemplateForm
                source="tenant"
                errors={errors}
                control={control}
                handleSubmit={handleSubmit(onSubmit)}
                isAmendmentV2Quote={true}
              />

              <MFlex justifyContent="flex-end">
                <MButton
                  type="submit"
                  data-testid="submit-quoteTemplate-form"
                  variant="primary"
                  mt={8}
                  alignSelf="self-end"
                  isDisabled={isUpdateLoading || !isDirty}
                  isLoading={isUpdateLoading}
                  onClick={handleSubmit(onSubmit)}
                >
                  Save
                </MButton>
              </MFlex>
            </MSettingAccordionItem>
          </Accordion>
        )}
      </MStack>
    </MPageContainer>
  );
};

export default QuoteTemplates;
