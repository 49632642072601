import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  MBox,
  MBreadcrumb,
  MButton,
  MDivider,
  MFlex,
  MPageTopNavContainer,
} from '~app/components/Monetize';
import MEditableInput from '~app/components/Monetize/MEditableInput';
import { QuoteActionsV2 } from '~app/components/Quotes/QuoteActionsV2';
import { QuoteHeaderSummary } from '~app/components/Quotes/QuoteHeaderSummary';
import { QuoteProgressBar } from '~app/components/Quotes/QuoteProgress.tsx/QuoteProgressBar';
import { QuoteTagsV2 } from '~app/components/Quotes/QuoteTagsV2';
import { ROUTES } from '~app/constants';
import { IQuoteRequestSchema, QuoteStatusEnum } from '~app/types';
import { ReviewQuoteActionButton } from '../../Quote/components/reviewQuote/ReviewQuoteActionButton';
import { QuoteAlerts } from '../../Quote/QuoteAlerts';
import { useQuoteContext } from '../../Quote/quoteContext';

interface QuotePageHeaderProps {
  hasValidRole: boolean;
  setContactDrawerOpen: (val: boolean) => void;
  canEditQuote?: boolean;
  editQuoteLoading?: boolean;
  handleEditQuoteClick?: () => void;
  onOpenManualAcceptanceModal: () => void;
}

const DIVIDER = (
  <MDivider
    orientation="vertical"
    mx="3"
    borderColor="tBlack.200"
    height="28px"
  />
);
export const QuotePageHeader = ({
  hasValidRole,
  canEditQuote = false,

  editQuoteLoading = false,
  handleEditQuoteClick,

  onOpenManualAcceptanceModal,
}: QuotePageHeaderProps) => {
  const {
    isReadOnly,
    quoteData,
    quoteStateData,
    methods,
    reviewQuoteModalData,
    manualRenewalFormMethods,
    actionRunning,
    isEsignEnabled,
    handleSendQuote,
    handleRecreateQuote,
    handleBackButton,
    handleSubmitButton,
  } = useQuoteContext();
  const { loading: quoteLoading, quote, setQuote, fetchQuote } = quoteData;
  const isDraft = quote?.status === QuoteStatusEnum.DRAFT;
  const isActionLoading = actionRunning || quoteLoading;

  const { control } = useFormContext<IQuoteRequestSchema>();

  const { offeringLoadingState } = quoteStateData;
  const anyOfferingLoading = Object.values(offeringLoadingState).some(
    (item) => item.isLoading,
  );

  const [displaySticky, setDisplaySticky] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener('scroll', handleStickyMenu);

    return () => {
      window.removeEventListener('scroll', handleStickyMenu);
    };
  }, []);

  const handleStickyMenu = () => {
    if (window !== undefined) {
      window.scrollY > 20 ? setDisplaySticky(true) : setDisplaySticky(false);
    }
  };

  if (!quote) {
    return null;
  }

  return (
    <MPageTopNavContainer
      position="sticky"
      top="0"
      background="tWhite.300"
      zIndex="4"
      boxShadow={displaySticky ? '22px 0px 56px 0px #00000021' : 'none'}
    >
      <MBox maxWidth="1440px" margin="0 auto">
        <MFlex
          alignItems="center"
          justifyContent="space-between"
          py={1.5}
          maxH="64px"
        >
          <MFlex alignItems="center" justifyContent="space-between">
            <MBreadcrumb
              items={[
                {
                  label: quote.accountName,
                  route: ROUTES.getAccountDetailRoute(quote.accountId),
                },
                {
                  label:
                    quote?.status === QuoteStatusEnum.DRAFT
                      ? 'New Quote'
                      : 'Review Quote',
                  isCurrentPage: true,
                  renderItem: () => {
                    return (
                      <Controller
                        defaultValue=""
                        name="description"
                        control={control}
                        render={({
                          field: { onChange, value, onBlur, ref, ...rest },
                        }) => (
                          <MEditableInput
                            aria-label="description"
                            value={value || ''}
                            onSubmit={(newValue) => {
                              onChange(newValue);
                              onBlur();
                              value && handleSubmitButton();
                            }}
                            submitOnBlur
                            isDisabled={isReadOnly}
                            onChange={onChange}
                            onBlur={onBlur}
                            {...rest}
                            previewProps={{
                              fontSize: 'md',
                              fontWeight: '600',
                              px: 1,
                            }}
                            inputProps={{
                              value: value || '',
                              fontSize: 'md',
                              fontWeight: '600',
                              'data-testid': 'input-description',
                              ...rest,
                            }}
                          />
                        )}
                      />
                    );
                  },
                },
              ]}
              backButtonProps={{
                title: 'Go Back',
                onClick: handleBackButton,
              }}
              containerStyles={{ mr: '2', ml: -1 }}
            />
            <QuoteTagsV2
              quote={quote}
              isReadOnly={isReadOnly}
              setQuote={setQuote}
              manualRenewalFormMethods={manualRenewalFormMethods}
              mt={0.5}
            />
          </MFlex>

          <QuoteHeaderSummary />
        </MFlex>
        <MFlex w="100%">
          <QuoteProgressBar
            quote={quote}
            approvals={quote.approvals}
            reloadData={() => {
              quote?.id && fetchQuote(quote.id);
            }}
          />
        </MFlex>
        <MFlex flexDir="row" justifyContent="end" w="100%" pt="3" pb="3">
          <MFlex align="center">
            {quote.validations.length > 0 && (
              <>
                <QuoteAlerts
                  validations={quote.validations}
                  showDivider={false}
                />
                {DIVIDER}
              </>
            )}

            {canEditQuote && handleEditQuoteClick && (
              <MButton
                mr="2"
                type="button"
                variant="tertiary"
                size="sm"
                isDisabled={isActionLoading || editQuoteLoading}
                onClick={() => handleEditQuoteClick()}
              >
                Edit Quote
              </MButton>
            )}

            <ReviewQuoteActionButton
              isReadOnly={isReadOnly}
              hasValidRole={hasValidRole}
              isActionLoading={isActionLoading}
              isEsignEnabled={isEsignEnabled}
              quote={quote}
              quoteStateData={quoteStateData}
              quoteFormMethods={methods}
              reviewQuoteModalData={reviewQuoteModalData}
              anyOfferingLoading={anyOfferingLoading}
              onAcceptQuote={onOpenManualAcceptanceModal}
              onRecreateQuote={handleRecreateQuote}
              onSendQuote={handleSendQuote}
            />
            {quote.id && hasValidRole && (
              <QuoteActionsV2
                quote={quote}
                setQuote={setQuote}
                loadData={() => quote.id && fetchQuote(quote.id)}
                manualRenewalFormMethods={manualRenewalFormMethods}
                isReadOnly={isReadOnly}
                handleEditQuoteClick={handleEditQuoteClick}
                canEditQuote={canEditQuote}
                editQuoteLoading={editQuoteLoading}
              />
            )}
          </MFlex>
        </MFlex>
      </MBox>
    </MPageTopNavContainer>
  );
};
