import { Accordion, Switch } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { MdStar, MdStarOutline } from 'react-icons/md';
import {
  MAccordionCustomButton,
  MAccordionCustomButtonItem,
  MBox,
  MButton,
  MCustomIconButton,
  MFlex,
  MFormField,
  MStack,
  MText,
  MTooltip,
} from '~app/components/Monetize';
import { MSettingAccordionItem } from '~app/components/Monetize/MSettingAccordionItem';
import {
  INetTerm,
  INetTerms,
  INetTermSchema,
  INetTermSchemaUI,
  NET_TERMS_DISPLAY,
  NetTermsEnum,
  NetTermsStatusEnum,
} from '~app/types';

interface NetTermsProps {
  netTerms: INetTerms;
  onSave: (netTerms: INetTerms) => Promise<void>;
}

const NetTerms = ({ netTerms, onSave }: NetTermsProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    getValues,
    setValue,
    reset,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<INetTermSchemaUI>({
    resolver: zodResolver(INetTermSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (netTerms) {
      const orgNetTerms: INetTerm[] = Object.entries(netTerms).map(
        ([key, { status }]) => ({
          // Ensure UI is not broken if BE adds a new net term before FE code is updated
          name:
            NET_TERMS_DISPLAY[key as NetTermsEnum] ||
            (key || '').replace('NET_', 'Net '),
          value: key as NetTermsEnum,
          active:
            status === NetTermsStatusEnum.ACTIVE ||
            status === NetTermsStatusEnum.PRIMARY,
          primary: status === NetTermsStatusEnum.PRIMARY,
        }),
      );

      reset({ netTerms: orgNetTerms });
    }
  }, [netTerms]);

  const netTermValues = getValues().netTerms;
  const primaryNetTerm = netTermValues?.find(({ primary }) => primary);

  const onSubmit = async (data: INetTermSchemaUI) => {
    try {
      setIsLoading(true);
      const payload = data.netTerms.reduce((output: any, item) => {
        output[item.value] = {
          status: item.primary
            ? NetTermsStatusEnum.PRIMARY
            : item.active
            ? NetTermsStatusEnum.ACTIVE
            : NetTermsStatusEnum.INACTIVE,
        };
        return output;
      }, {});

      await onSave({ ...payload });
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeStatus = (index: number) => {
    const active = getValues(`netTerms.${index}.active`);
    setValue(`netTerms.${index}.active`, !active, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const onChangeDefaultTerm = (index: number) => {
    netTermValues.forEach((netTerm, i) => {
      if (i === index) {
        setValue(`netTerms.${index}.primary`, true, {
          shouldDirty: true,
          shouldValidate: true,
        });
      } else {
        setValue(`netTerms.${i}.primary`, false, {
          shouldDirty: true,
          shouldValidate: true,
        });
      }
    });
  };

  return (
    <MStack w="100%" data-testid="netTermsConfig">
      <Accordion allowMultiple w="full">
        <MSettingAccordionItem
          description="
            The number of days from invoice generation until payment is due.
            Configure which Net Terms options are available in Quoting and Billing as well as the default for New Quotes.
          "
          renderAccordionButton={({ isExpanded }: { isExpanded: boolean }) => (
            <MAccordionCustomButton
              isExpanded={isExpanded}
              label="Net Terms"
              data-testid="netTerms-accord-cusBtn"
            >
              <MAccordionCustomButtonItem
                align="left"
                label="Default:"
                value={primaryNetTerm?.name || ''}
                isExpanded={isExpanded}
                hideWhenExpanded
                w="70px"
              />
            </MAccordionCustomButton>
          )}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            {netTermValues?.map((item, index) => (
              <MFlex
                key={index}
                justifyContent="space-between"
                alignItems="center"
                borderBottom="1px"
                borderBottomColor="tGray.back"
                py={3}
                h={57}
                role="group"
              >
                <MText py={0} m={0}>
                  {item.name}
                </MText>
                <MStack spacing={5} direction="row">
                  <MTooltip
                    label="Set as Default Net Term"
                    placement="bottom-end"
                  >
                    <MBox>
                      {item.active && (
                        <Controller
                          name={`netTerms.${index}.primary`}
                          control={control}
                          render={({ field: { onChange, value, ...rest } }) => (
                            <MCustomIconButton
                              data-testid={
                                item.primary
                                  ? 'primary-netTerm'
                                  : 'active-netTerm'
                              }
                              variant="icon"
                              btnSize={5}
                              padding={0}
                              containerSize={8}
                              iconProps={{
                                _active: { color: 'unset' },
                                _focus: { color: 'unset' },
                              }}
                              icon={value ? MdStar : MdStarOutline}
                              onClick={() => {
                                onChangeDefaultTerm(index);
                              }}
                              {...rest}
                            />
                          )}
                        />
                      )}
                    </MBox>
                  </MTooltip>
                  <MBox>
                    <MFormField error={`errors.netTerms.${index}.active`}>
                      <Controller
                        name={`netTerms.${index}.active`}
                        control={control}
                        render={({ field: { onChange, value, ...rest } }) => (
                          <Switch
                            data-testid={`netTerm-switch-${
                              item.active ? 'active' : 'inactive'
                            }`}
                            isChecked={value}
                            onChange={() => onChangeStatus(index)}
                            {...rest}
                            isDisabled={item.primary}
                          />
                        )}
                      />
                    </MFormField>
                  </MBox>
                </MStack>
              </MFlex>
            ))}
            <MFlex justifyContent="flex-end">
              <MButton
                type="submit"
                data-testid="save-nTerms-config"
                variant="primary"
                mt={8}
                alignSelf="self-end"
                isDisabled={isLoading || !isDirty}
                isLoading={isLoading}
                onClick={handleSubmit(onSubmit)}
              >
                Save
              </MButton>
            </MFlex>
          </form>
        </MSettingAccordionItem>
      </Accordion>
    </MStack>
  );
};

export default NetTerms;
