import { FormControlProps, Spinner, Switch } from '@chakra-ui/react';
import { forwardRef } from 'react';
import { ComingSoonIcon } from '~app/assets/icons/ComingSoonIcon';
import {
  MBox,
  MFormControl,
  MFormLabel,
  MIcon,
  MText,
} from '~app/components/Monetize';
import { MTooltip } from '~app/components/Monetize/chakra';

interface SettingsToggleProps {
  name: string;
  heading: string;
  subheading: string;
  fetchLoading?: boolean;
  value: boolean;
  isDisabled?: boolean;
  comingSoon?: boolean;
  formControlProps?: FormControlProps;
  onChange: (value: boolean) => void | Promise<unknown>;
}

export const SettingsToggle = forwardRef(
  (
    {
      name,
      heading,
      subheading,
      fetchLoading = false,
      value,
      formControlProps,
      isDisabled = false,
      comingSoon = false,
      onChange,
    }: SettingsToggleProps,
    ref,
  ) => {
    return (
      <MFormControl
        display="flex"
        width="full"
        justifyContent="space-between"
        alignItems="center"
        gap="4"
        {...formControlProps}
      >
        <MBox>
          <MFormLabel htmlFor={name} fontSize="md" fontWeight="600">
            {heading}
            {comingSoon && (
              <MTooltip label="Coming Soon" shouldWrapChildren>
                <MIcon
                  ml="2"
                  as={ComingSoonIcon}
                  color="tGray.lightPurple"
                  cursor="pointer"
                  mb="-0.5"
                />
              </MTooltip>
            )}
          </MFormLabel>
          <MText id={`${name}-subheading`}>{subheading}</MText>
        </MBox>
        {fetchLoading ? (
          <Spinner size="md" />
        ) : (
          <Switch
            id={name}
            aria-describedby={`${name}-subheading`}
            isDisabled={isDisabled}
            isChecked={value}
            onChange={(event) => onChange(event.target.checked)}
          />
        )}
      </MFormControl>
    );
  },
);
