import { parseISO } from 'date-fns/parseISO';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { MBox, MFlex } from '~app/components/Monetize';
import { DatePicker } from '~app/components/Monetize/DatePicker/DatePicker';
import { ChangeOwnerV2 } from '~app/components/Quotes/ChangeOwnerV2';
import { QuoteBasicInfoLine } from '~app/components/Quotes/QuoteBasicInfoLine';
import QuoteBasicOpportunity from '~app/components/Quotes/QuoteBasicOpportunity';
import { useQuoteSettings } from '~app/hooks';
import { IQuoteRequestSchema } from '~app/types';
import { getQuoteType } from '~app/utils';
import { toDateShort } from '~app/utils/dates';
import { AddPoNumberSectionV2 } from '../../../../components/Quotes/AddPoNumberSectionV2';
import { useQuoteContext } from '../../Quote/quoteContext';

interface QuoteBasicDetailsProps {}

export const QuoteBasicDetails: FC<
  QuoteBasicDetailsProps
> = ({}: QuoteBasicDetailsProps) => {
  const {
    handleSubmitButtonWithoutDirtyCheck,
    isReadOnly,
    quoteData: { quote, setQuote },
    useAmendmentV2,
    opportunityLinkData,
    handleChangeOwner,
    setIsQuoteSaving,
  } = useQuoteContext();
  const { isAmendment } = getQuoteType(quote);
  const { control } = useFormContext<IQuoteRequestSchema>();
  const { quoteSettings } = useQuoteSettings();

  if (!quote) {
    return null;
  }
  return (
    <MBox>
      <QuoteBasicInfoLine title="Owner" inputId="quote-header-owner">
        <MBox mr={isReadOnly ? 0 : '-3'}>
          <ChangeOwnerV2
            inputId="quote-header-owner"
            isReadOnly={isReadOnly}
            value={quote?.owner}
            ownerName={quote?.ownerName}
            onChange={(e) => handleChangeOwner(String(e))}
          />
        </MBox>
      </QuoteBasicInfoLine>

      <QuoteBasicInfoLine title="Opportunity">
        {opportunityLinkData && (
          <QuoteBasicOpportunity {...opportunityLinkData} />
        )}
      </QuoteBasicInfoLine>

      {isAmendment && !useAmendmentV2 && (
        <QuoteBasicInfoLine title="Amendment Date">
          <MBox mr="-3">
            <Controller
              name="contractAmendmentDate"
              control={control}
              render={({ field: { onChange, ...rest } }) => (
                <DatePicker
                  {...rest}
                  triggerType="button"
                  btnText={toDateShort(quote?.contractAmendmentDate || null)}
                  minDate={
                    quote?.contractStartDate
                      ? parseISO(quote.contractStartDate)
                      : undefined
                  }
                  maxDate={
                    quote?.contractEndDate
                      ? parseISO(quote.contractEndDate)
                      : undefined
                  }
                  onChange={(date) => {
                    onChange(date);
                    handleSubmitButtonWithoutDirtyCheck();
                  }}
                  isDisabled={!quote}
                  isReadOnly={isReadOnly}
                  btnProps={{
                    my: 0,
                    'data-testid': 'contractStartDateBtn',
                  }}
                  readonlyContentProps={{ pb: 0, pt: 1, mr: 2 }}
                />
              )}
            />
          </MBox>
        </QuoteBasicInfoLine>
      )}

      <QuoteBasicInfoLine title="Quote Expiration Date">
        <MFlex align="center" mr="-3">
          <Controller
            name="expirationDate"
            control={control}
            render={({ field: { onChange, ...rest } }) => (
              <DatePicker
                data-testid="date-picker-expirationDate"
                triggerType="button"
                btnText={toDateShort(quote?.expirationDate || null)}
                {...rest}
                isDisabled={isReadOnly}
                isReadOnly={isReadOnly}
                minDate={new Date()}
                placeholder="Select End Date"
                onChange={(date) => {
                  onChange(date);
                  handleSubmitButtonWithoutDirtyCheck();
                }}
                readonlyContentProps={{ pb: 0, pt: 1.5, mr: 2 }}
                btnProps={{ my: 0 }}
              />
            )}
          />
        </MFlex>
      </QuoteBasicInfoLine>

      {quoteSettings?.allowPONumberOnQuotes && (
        <QuoteBasicInfoLine title="PO Number">
          <MBox mr="-3">
            <AddPoNumberSectionV2
              quote={quote}
              setQuote={setQuote}
              setIsQuoteSaving={setIsQuoteSaving}
              buttonProps={{ ml: '-3' }}
              previewPropsNonEdit={{
                justifyContent: 'flex-start',
              }}
              inputProps={{
                maxW: '100px',
              }}
            />
          </MBox>
        </QuoteBasicInfoLine>
      )}
    </MBox>
  );
};
