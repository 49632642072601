import { CardBody, GridItem } from '@chakra-ui/react';
import { useState } from 'react';
import {
  MBox,
  MButton,
  MCard,
  MCheckbox,
  MDivider,
  MFlex,
  MGrid,
  MGridItem,
  MSearchInput,
  MSkeleton,
  MStack,
  MText,
} from '~app/components/Monetize';
import { MTooltip } from '~app/components/Monetize/MTooltip';
import {
  IOfferingRes,
  IQuoteOfferingRespSchema,
  SelectedProductsWithinOffering,
  SelectedProductsWithinOfferingForm,
} from '~app/types';
import { formatInteger } from '~app/utils';
import { useProductSelection } from '../../../../../../hooks';
import { ManageProductsRow } from './ManageProductsRow';

interface ManageProductsFormProps {
  offering?: IOfferingRes | null;
  quoteOffering?: IQuoteOfferingRespSchema | null;
  updateOfferingItems: (offering: SelectedProductsWithinOfferingForm) => void;
  onClose: () => void;
  isSource?: 'modal' | 'drawer';
  isDirty?: boolean;
  offeringCreationLoading?: boolean;
  setIsProductListChanged?: (val: boolean) => void;
}

export const ManageProductsForm = ({
  offering,
  quoteOffering,
  updateOfferingItems,
  onClose,
  isSource,
  offeringCreationLoading = false,
  setIsProductListChanged,
}: ManageProductsFormProps) => {
  const {
    selectedProductCount,
    rootProductValue,
    isAnyProductSelected,
    isAnyChangesMade,
    searchTerm,
    visibleRows,
    isAllProductSelected,
    isIndeterminate,
    isLoading,
    isSearching,
    handleCheckboxChange,
    setSearchTerm,
    handleSelectAll,
    onSubmit,
  } = useProductSelection({
    offering: offering!,
    quoteOffering,
    onSave: updateOfferingItems,
    onDrawerClose: onClose,
    setIsProductListChanged,
  });

  const [isSubmitClicked, setIsSubmitClicked] = useState(
    offeringCreationLoading,
  );

  const isDrawer = isSource === 'drawer';

  const saveButtonToolTipLabel = !isAnyProductSelected
    ? 'At least one product must be selected'
    : undefined;
  return (
    <>
      <MBox px={!isDrawer ? 4 : 0} pt={!isDrawer ? 4 : 0}>
        {!isLoading && (
          <MGrid
            gridTemplateColumns={isDrawer ? '1fr' : '2fr 1fr'}
            rowGap={4}
            w="full"
            alignItems="center"
            pb={4}
          >
            <MGridItem colSpan={2}>
              <MSearchInput
                placeholder="Search Products"
                omitCount
                value={searchTerm}
                onChange={setSearchTerm}
                _placeholder={{
                  color: 'tPurple.lightPurple',
                  fontWeight: '400',
                }}
              />
            </MGridItem>

            <GridItem alignItems={'center'}>
              <MFlex alignItems="center">
                <MCheckbox
                  isDisabled={visibleRows.length === 0}
                  isChecked={isAllProductSelected}
                  onChange={(value) => handleSelectAll(value.target.checked)}
                  isIndeterminate={isIndeterminate}
                >
                  <MText ml={1}>
                    Select All ({formatInteger(selectedProductCount)}/
                    {formatInteger(rootProductValue.length)})
                  </MText>
                </MCheckbox>
              </MFlex>
            </GridItem>

            <MGridItem colSpan={2}>
              <MDivider />
            </MGridItem>
          </MGrid>
        )}

        {visibleRows.length === 0 && isSearching && (
          <MText fontSize="md" fontWeight="bold" textAlign="center">
            No products found
          </MText>
        )}

        {(isLoading || (!isSearching && visibleRows.length < 1)) && (
          <MStack>
            <MSkeleton size="xs" h={4} />
            <MSkeleton size="xs" h={4} />
            <MSkeleton size="xs" h={4} />
          </MStack>
        )}

        {!isLoading && (
          <MBox
            style={{
              height: isDrawer ? 'calc(100vh - 400px)' : 'calc(80vh - 360px)',
              overflowY: 'auto',
            }}
          >
            <MGrid
              gridTemplateColumns={'1fr'}
              rowGap={4}
              w="full"
              alignItems="center"
            >
              {visibleRows.map(
                (row: SelectedProductsWithinOffering, index: number) => (
                  <ManageProductsRow
                    key={row.id}
                    row={row}
                    index={index}
                    value={row.isSelected}
                    isDrawer={isDrawer}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                ),
              )}
            </MGrid>
          </MBox>
        )}

        {isDrawer && (
          <>
            <MDivider />

            <MFlex justify={'end'}>
              <MTooltip label={saveButtonToolTipLabel} placement="left">
                <MButton
                  mt={2}
                  isDisabled={!isAnyChangesMade || !isAnyProductSelected}
                  isLoading={isSubmitClicked}
                  onClick={() => {
                    onSubmit();
                    setIsSubmitClicked(true);
                  }}
                >
                  Save
                </MButton>
              </MTooltip>
            </MFlex>
          </>
        )}
      </MBox>
      {!isDrawer && (
        <MStack
          p={4}
          style={{
            borderRadius: '0px 0px 4px 4px',
            background: 'tWhite.base',
            boxShadow: '0px -3px 14px 0px rgba(24, 36, 60, 0.08)',
          }}
          spacing={4}
          direction="row"
          align="center"
          justify="end"
          flex={1}
          minH={18}
        >
          <MButton onClick={onClose} variant="cancel" minW="80px">
            Cancel
          </MButton>
          <MTooltip label={saveButtonToolTipLabel}>
            <MButton
              form="product-selection-form"
              type="submit"
              minW="80px"
              size="md"
              onClick={onSubmit}
              isDisabled={visibleRows.length === 0 || !isAnyProductSelected}
            >
              Save
            </MButton>
          </MTooltip>
        </MStack>
      )}
    </>
  );
};

export const ManageProductsFormWithCard = (props: ManageProductsFormProps) => {
  return (
    <MCard variant="borderless" mb="2">
      <CardBody>
        <ManageProductsForm {...props} />
      </CardBody>
    </MCard>
  );
};
