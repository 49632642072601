import { useDisclosure } from '@chakra-ui/react';
import React from 'react';
import {
  IApprovalRespSchema,
  IQuoteRespSchema,
  QuoteStatusEnum,
} from '~app/types';
import { QuoteProgressStep } from './QuoteProgressStep';

interface QuoteStepProcessProps {
  quote: IQuoteRespSchema;
  approvals: IApprovalRespSchema[];
  reloadData: () => void;
  stepMessage: {
    stepDate: string;
    stepTitle: string;
    isError: boolean;
    showLightBackground: boolean;
  } | null;
}

export const QuoteStepProcess = React.forwardRef<any, QuoteStepProcessProps>(
  ({ quote, stepMessage }: QuoteStepProcessProps, ref) => {
    let stepTitle = 'Process Quote';
    let stepDate = '';
    const isCompleted = quote.status === QuoteStatusEnum.PROCESSED;

    const showInprogressStepNumber = false;
    const isCurrent =
      quote.status === QuoteStatusEnum.ACCEPTED && !!quote.acceptedAt;
    const isDisabled = false;

    if (isCompleted) {
      stepTitle = 'Processed Quote';
    }
    const popoverState = useDisclosure();

    if (stepMessage) {
      stepTitle = stepMessage.stepTitle;
      stepDate = stepMessage.stepDate;
    }

    return (
      <QuoteProgressStep
        key="quote-progress-process"
        stepTitle={stepTitle}
        stepDate={stepDate}
        stepNumber={5}
        isCompleted={isCompleted}
        showInprogressStepNumber={showInprogressStepNumber}
        isDisabled={isDisabled}
        isCurrent={isCurrent}
        containerProps={{ ml: -8 }}
        isError={stepMessage?.isError}
        showLightBackground={stepMessage?.showLightBackground}
        popoverState={popoverState}
      />
    );
  },
);
