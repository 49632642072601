import { Accordion } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import isNumber from 'lodash/isNumber';
import { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { MdClose } from 'react-icons/md';
import { handleApiErrorToast } from '~app/api/axios';
import {
  useGetQuoteExpirationReminder,
  useSaveQuoteExpirationReminder,
} from '~app/api/settingsService';
import {
  MBox,
  MButton,
  MCustomIconButton,
  MCustomNumberInput,
  MFlex,
  MFormField,
  MLink,
  MPageContainer,
  MPageLoader,
  MSettingsPageHeader,
  MVStack,
} from '~app/components/Monetize';
import { MSettingAccordionItem } from '~app/components/Monetize/MSettingAccordionItem';
import {
  defaultQuoteExpirationReminder,
  EXPIRATION_REMINDER_TYPE_DISPLAY,
} from '~app/constants/quoteExpirationReminder';
import { useDocumentHead } from '~app/services/documentHead';
import {
  QuoteExpirationReminderForm,
  QuoteExpirationReminderSchema,
  ReminderIntervalSchema,
} from '~app/types';

export const QuoteExpirationReminder = () => {
  const { setDocTitle } = useDocumentHead();

  const {
    control,
    formState: { isDirty, errors },
    setError,
    getValues,
    clearErrors,
    handleSubmit,
    setValue,
    reset,
    watch,
  } = useForm({
    resolver: zodResolver(QuoteExpirationReminderForm),
    defaultValues: {
      quoteExpirationReminders: [defaultQuoteExpirationReminder[0]],
    },
  });
  const isValid = Object.keys(errors).length === 0;
  const numberOfEmails = getValues('quoteExpirationReminders').length;

  const firstDayWatch = watch(`quoteExpirationReminders.0.days`);
  const secondDayWatch = watch(`quoteExpirationReminders.1.days`);
  const lastDayWatch = watch(`quoteExpirationReminders.2.days`);

  const { fields, append } = useFieldArray({
    control,
    name: 'quoteExpirationReminders',
    keyName: 'fieldArrayKey',
  });

  const { isLoading: isFetchLoading, data: quoteExpirationReminders } =
    useGetQuoteExpirationReminder();

  const { mutateAsync: mutateQuoteExpirationEmails, isLoading: saveLoading } =
    useSaveQuoteExpirationReminder();

  useEffect(() => {
    if (quoteExpirationReminders?.length) {
      const controlledFields = defaultQuoteExpirationReminder
        .filter((defaultReminder) =>
          quoteExpirationReminders.find(
            (reminder) => reminder.type === defaultReminder.type,
          ),
        )
        .map((defaultReminder) => {
          const apiField: ReminderIntervalSchema | undefined =
            quoteExpirationReminders.find(
              (reminder) => reminder.type === defaultReminder.type,
            );
          return { ...defaultReminder, days: apiField?.days.toString() || '' };
        });
      reset({
        quoteExpirationReminders: [...controlledFields],
      });
    }
  }, [quoteExpirationReminders]);

  useEffect(() => {
    if (!isValid && (firstDayWatch || secondDayWatch || lastDayWatch)) {
      customValidate();
    }
  }, [isValid, firstDayWatch, secondDayWatch, lastDayWatch]);

  useEffect(() => {
    setDocTitle('Settings', 'Email Reminders');
  }, []);

  const handleBlur = () => {
    customValidate();
  };

  const appendRow = () => {
    const tempId = numberOfEmails;
    append({
      ...defaultQuoteExpirationReminder[tempId],
      tempId,
    });
  };

  const removeRow = (tempId: number) => {
    const reminders = getValues('quoteExpirationReminders');
    // remove all reminders after and including the selected reminder.
    // If we remove the second reminder we want to remove the last reminder if it exists
    reminders.splice(tempId);
    setValue('quoteExpirationReminders', reminders, { shouldDirty: true });
  };

  const customValidate = () => {
    const emailReminderSettings = getValues().quoteExpirationReminders;
    emailReminderSettings.forEach((reminder, i) => {
      const day = Number(reminder.days);
      // don't check "previousDay" if "day" isFirstDay
      const isFirstDay = i === 0;
      const previousDay =
        !isFirstDay && Number(emailReminderSettings[i - 1].days);
      if (!day) {
        return;
      } else if (!isFirstDay && !previousDay) {
        // ex. if we remove the 1st days value it doesn't make sense to have a 2nd days value
        setError(`quoteExpirationReminders.${i - 1}.days`, {
          type: 'custom',
          message:
            'Please enter a value for this day or delete the following days value',
        });
      } else if (!isFirstDay && isNumber(previousDay) && !(day < previousDay)) {
        // a days value cannot be more/earlier than the previous
        setError(`quoteExpirationReminders.${i}.days`, {
          type: 'custom',
          message: 'Value must be less than the previous day',
        });
      } else if (day < 1 || day > 30) {
        // a days value must be between 1 & 30
        setError(`quoteExpirationReminders.${i}.days`, {
          type: 'custom',
          message: 'Value must be between 1 and 30 days',
        });
      } else if (errors?.quoteExpirationReminders?.[i]?.days) {
        // clear errors
        clearErrors(`quoteExpirationReminders.${i}.days`);
      }
    });
  };

  const onSubmit = async () => {
    try {
      const formValues = getValues().quoteExpirationReminders;
      const data: QuoteExpirationReminderSchema = formValues.map((value) => ({
        days: value.days,
        type: value.type,
      }));

      await mutateQuoteExpirationEmails(data);
    } catch (error) {
      handleApiErrorToast(error);
    }
  };

  return (
    <MPageContainer alignItems="stretch">
      <MBox>
        <MSettingsPageHeader divider title="Email Reminders" />
      </MBox>

      <Accordion allowMultiple maxW="600px">
        <MSettingAccordionItem
          description="The number of days (1-30) before a quote expires that we will send a reminder email. We will always send an email when a quote expires"
          label="Quote Expiration"
        >
          <MBox w="100%" pb={4}>
            {isFetchLoading && <MPageLoader />}
            {!isFetchLoading && (
              <form>
                <MVStack alignItems="start" my={6} spacing={4}>
                  {fields.map((reminder) => {
                    const { tempId, type } = reminder;
                    return (
                      <MFormField
                        key={type}
                        error={errors?.quoteExpirationReminders?.[tempId]?.days}
                        label={EXPIRATION_REMINDER_TYPE_DISPLAY[type]}
                      >
                        <Controller
                          name={`quoteExpirationReminders.${tempId}.days`}
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, ...rest },
                          }) => (
                            <MFlex alignItems="center">
                              <MCustomNumberInput
                                w="30%"
                                value={value}
                                inputMode="numeric"
                                placeholder="Enter Days"
                                onChange={(
                                  valueAsString: string,
                                  valueAsNumber: number,
                                ) => {
                                  onChange(valueAsString);
                                }}
                                onBlur={() => {
                                  onBlur();
                                  handleBlur();
                                }}
                                {...rest}
                              />
                              {tempId > 0 && (
                                <MCustomIconButton
                                  variant="icon"
                                  btnSize={4}
                                  justifySelf="self-end"
                                  color="tPurple.base"
                                  containerSize={6}
                                  iconProps={{
                                    _active: { color: 'unset' },
                                    _focus: { color: 'unset' },
                                  }}
                                  ml={4}
                                  icon={MdClose}
                                  onClick={() => removeRow(tempId)}
                                />
                              )}
                            </MFlex>
                          )}
                        />
                      </MFormField>
                    );
                  })}
                  {numberOfEmails < 3 && (
                    <MLink
                      mt={0}
                      color="tIndigo.base"
                      fontWeight="bold"
                      textDecoration="underline"
                      fontSize="sm"
                      onClick={() => appendRow()}
                    >
                      + Add Reminder
                    </MLink>
                  )}
                </MVStack>
              </form>
            )}
          </MBox>

          <MFlex justifyContent="flex-end">
            <MButton
              variant="primary"
              onClick={handleSubmit(onSubmit)}
              isDisabled={!isDirty || !isValid}
              isLoading={saveLoading}
            >
              Save
            </MButton>
          </MFlex>
        </MSettingAccordionItem>
      </Accordion>
    </MPageContainer>
  );
};
