import { useCallback, useEffect, useState } from 'react';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import {
  doAmendContract,
  doGetContract,
  doRenewContract,
} from '~app/api/cpqService';
import AppLoading from '~app/components/App/AppLoading';
import {
  MAlert,
  MBox,
  MFlex,
  MPageContainer,
  MText,
} from '~app/components/Monetize';
import { getQuoteEditRoute, SALES_LANDING } from '~app/constants/routes';
import { logger } from '~app/services/logger';
import { IOpportunity } from '~app/types';
import { canAmendContract, canRenewContract } from '~app/utils/contracts';

type ContractAction = 'amend' | 'renew';

const ERROR_INVALID_CONTRACT_ID = 'The provided contract id is invalid.';
const ERROR_INVALID_CONTRACT_STATUS_AMEND =
  'The provided contract is not eligible to be amended.';
const ERROR_INVALID_CONTRACT_STATUS_RENEW =
  'The provided contract is not eligible to be renewed.';
const ERROR_INVALID_ACTION = 'The provided action is invalid.';
const ERROR_UNKNOWN = 'There was a problem processing your request.';

// Confirms that the params are in a valid format and that the contract is eligible for the action.
async function isValid(
  contractId: string,
  action: ContractAction,
): Promise<string | null> {
  logger.log({ contractId, action });
  if (!contractId || !contractId.startsWith('ctrct')) {
    return ERROR_INVALID_CONTRACT_ID;
  }
  if (action !== 'amend' && action !== 'renew') {
    return ERROR_INVALID_ACTION;
  }

  // Confirm contract exists and has a correct status before attempting to take action
  try {
    const contract = await doGetContract(contractId);
    if (action === 'amend' && !canAmendContract(contract)) {
      return ERROR_INVALID_CONTRACT_STATUS_AMEND;
    } else if (action === 'renew' && !canRenewContract(contract)) {
      return ERROR_INVALID_CONTRACT_STATUS_RENEW;
    }
  } catch (ex) {
    logger.warn('[ERROR] looking up contract', ex);
    return ERROR_INVALID_CONTRACT_ID;
  }

  return null;
}

/**
 * This component produces a page that amends or renews a contract or shows an error message.
 * Once completed, the user will be redirected to the newly created quote, and this page removed from history.
 */
const CrmAmendRenewContract = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loadingMessage, setLoadingMessage] = useState<string | null>(null);

  const amendOrRenewContract = useCallback(
    async (
      contractId: string,
      action: ContractAction,
      opportunity?: Partial<Pick<IOpportunity, 'id' | 'name' | 'customId'>>,
    ) => {
      try {
        setErrorMessage(null);
        setIsLoading(true);

        const isValidErrMessage = await isValid(contractId, action);
        if (isValidErrMessage) {
          setErrorMessage(isValidErrMessage);
          setIsLoading(false);
          return;
        }

        setLoadingMessage(`Your contract is being ${action}ed.`);

        const quote = await (action === 'amend'
          ? doAmendContract(contractId, opportunity)
          : doRenewContract(contractId, opportunity));

        quote?.id &&
          navigate(
            {
              pathname: getQuoteEditRoute(quote.id),
            },
            { replace: true },
          );
      } catch (ex) {
        logger.warn('Error amending or renewing contract', contractId, action);
        setErrorMessage(ERROR_UNKNOWN);
        setIsLoading(false);
      }
    },
    [navigate],
  );

  useEffect(() => {
    if (params?.contractId && params?.action) {
      const opportunityCustomId = searchParams.get('opportunityCustomId');
      const opportunityName = searchParams.get('opportunityName');
      amendOrRenewContract(
        params.contractId,
        params.action as ContractAction,
        opportunityCustomId && opportunityName
          ? {
              customId: opportunityCustomId,
              name: opportunityName,
            }
          : undefined,
      );
    }
  }, [params.contractId, params.action, searchParams, amendOrRenewContract]);

  return (
    <MPageContainer>
      {isLoading && (
        <AppLoading>
          <MText my="4" fontSize="sm">
            {loadingMessage}
          </MText>
        </AppLoading>
      )}
      {errorMessage && (
        <MAlert type="error" title="Error">
          <MFlex direction="column">
            <MText>{errorMessage}</MText>
            <MBox>
              <Link to={SALES_LANDING}>
                <MText mt={2} textDecor="underline" fontSize="sm">
                  Go to Sales Dashboard
                </MText>
              </Link>
            </MBox>
          </MFlex>
        </MAlert>
      )}
    </MPageContainer>
  );
};

export default CrmAmendRenewContract;
