export const amountFilter = [
  { id: 1, label: '$0 - $19,999', bt: '0,20000' },
  {
    id: 2,
    label: '$20,000 - $39,999',
    bt: '20000,40000',
  },
  {
    id: 3,
    label: '$40,000 - $59,999',
    bt: '40000,60000',
  },
  {
    id: 4,
    label: '$60,000 - $79,999',
    bt: '60000,80000',
  },
  {
    id: 5,
    label: '$80,000 - $99,999',
    bt: '80000,100000',
  },
  { id: 6, label: '$100,000+', gte: 100000 },
];

// for MCustomNumberInput
export const intNumRegex = /^\d*$/;
export const intNumWithNegativeRegex = /^[-]?\d*$/;
export const decimalRegex = /^\d+\.?\d*$/;
export const decimalWithNegativeRegex = /^[-]?\d+\.?\d*$/;
// when addition is enabled
export const hasAdditionRegex = /^[0-9]*\++[0-9]*$/;
export const additionRegex = /^\+?[0-9]+$/;
export const specialCharacterRegex = /[^\w\s]/gi;
export const SAFE_FILENAME = /[/?<>\\:*|"]/g;
export const NOT_NUMERIC = /[^0-9]/g;

export const ISO_COMPATIBLE_DATE =
  /[0-9]{4}-[0-9]{2}-[0-9]{2}($|T[0-9]{2}| [0-9]{2}(\+[0-9]{2}:[0-9]{2}|z|-[0-9]{4}|:[0-9]{2}:[0-9]{2}\.[0-9]{3}\+[0-9]{2}($|:[0-9]{2})|$|:[0-9]{2}($|:[0-9]{2}($|.[0-9]{3}))))/;

export const TWENTY_FOUR_HR_TIME = /^[0-9]{1,2}:[0-9]{1,2}:[0-9]{1,2}$/;
export const TWELVE_HR_TIME = /^[0-9]{1,2}:[0-9]{1,2} (A|P)M$/;

export enum DateFormatEnum {
  MM_DD_YYYY = 'MM_DD_YYYY',
  DD_MM_YYYY = 'DD_MM_YYYY',
  YYYY_MM_DD = 'YYYY_MM_DD',
}
