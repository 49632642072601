import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosHeaders } from 'axios';
import { apiGet, apiPost } from '~api/axios';
import {
  ISharedInvoicePaymentReq,
  IShareInvoiceGenerateSetupIntentResp,
  IShareInvoiceRespSchema,
  Maybe,
  PaymentMethodSummaryResponse,
  SharedInvoicePaymentExistingMethod,
} from '~types';
import { invoiceServiceQueryKeys } from './invoiceService';

export const shareQueryKeys = {
  base: (secretId: string) => ['share', secretId] as const,
  invoice: (secretId: string) =>
    [...shareQueryKeys.base(secretId), 'invoice'] as const,
  paymentMethods: (secretId: string) =>
    [...shareQueryKeys.base(secretId), 'paymentMethods'] as const,
};

export function useGetInvoiceByShareId(
  secretId: string,
  tenantId: string,
  options: {
    onSuccess?: (data: IShareInvoiceRespSchema) => void;
    onError?: (err: unknown) => void;
  } = {},
) {
  return useQuery([...shareQueryKeys.invoice(secretId)], {
    queryFn: () =>
      apiGet<IShareInvoiceRespSchema>(`/api/share/invoices/${secretId}`, {
        axiosConfig: {
          excludeUserIdFromHeader: true,
          excludeTenantIdFromHeader: true,
          customXTenantId: tenantId,
        },
      }).then((res) => res.data),
    ...options,
    refetchOnWindowFocus: false,
  });
}

export function useGetPaymentMethodsByInvoiceShareId<
  SelectData = Array<PaymentMethodSummaryResponse>,
>(
  { invoiceSecretId, tenantId }: { invoiceSecretId: string; tenantId: string },
  options: Partial<
    UseQueryOptions<Array<PaymentMethodSummaryResponse>, unknown, SelectData>
  > = {},
) {
  return useQuery<Array<PaymentMethodSummaryResponse>, unknown, SelectData>(
    [...shareQueryKeys.paymentMethods(invoiceSecretId)],
    {
      queryFn: () =>
        apiGet<Array<PaymentMethodSummaryResponse>>(
          `/api/share/invoices/${invoiceSecretId}/paymentMethods`,
          {
            axiosConfig: {
              excludeUserIdFromHeader: true,
              excludeTenantIdFromHeader: true,
              customXTenantId: tenantId,
            },
          },
        ).then((res) => res.data),
      ...options,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
}

export function useSetupIntentForInvoiceShare(
  options: {
    onSuccess?: (data: IShareInvoiceGenerateSetupIntentResp) => void;
    onError?: (err: unknown) => void;
  } = {},
) {
  return useMutation<
    IShareInvoiceGenerateSetupIntentResp,
    unknown,
    { invoiceSecretId: string; tenantId: string }
  >(
    ({ invoiceSecretId, tenantId }) => {
      return apiPost<IShareInvoiceGenerateSetupIntentResp>(
        `/api/share/invoices/${invoiceSecretId}/paymentMethods/token`,
        null,
        {
          axiosConfig: {
            excludeUserIdFromHeader: true,
            excludeTenantIdFromHeader: true,
            customXTenantId: tenantId,
          },
        },
      ).then((res) => res.data);
    },
    {
      ...options,
    },
  );
}

export const doPrintInvoicePdfForInvoiceShare = async (
  tenantId: string,
  invoiceSecretId: string,
  invoiceId: string,
  invoiceNumber: Maybe<string>,
) => {
  const res = await apiGet<any>(
    `/api/share/invoices/${invoiceSecretId}/print`,
    {
      responseType: 'arraybuffer',
      axiosConfig: {
        excludeUserIdFromHeader: true,
        excludeTenantIdFromHeader: true,
        customXTenantId: tenantId,
      },
      headers: new AxiosHeaders({
        accept: 'application/pdf',
      }),
    },
  );
  return {
    data: res.data,
    fileName: `invoice-${invoiceNumber || invoiceId}.pdf`,
  };
};

export const usePrintInvoiceToHtmlForInvoiceShare = (
  {
    invoiceSecretId,
    lastModifiedTimestamp,
    tenantId,
  }: {
    invoiceSecretId: string;
    /** Used for caching response */
    lastModifiedTimestamp?: string;
    tenantId: string;
  },
  options: {
    enabled?: boolean;
    onSuccess?: (data: string) => void;
    onError?: (error: unknown) => void;
  } = {},
) => {
  return useQuery(
    [
      ...invoiceServiceQueryKeys.htmlTemplate(invoiceSecretId),
      lastModifiedTimestamp,
      tenantId,
    ],
    {
      queryFn: () =>
        apiGet<string>(`/api/share/invoices/${invoiceSecretId}/print`, {
          responseType: 'text',
          headers: new AxiosHeaders({
            accept: 'text/html',
          }),
          axiosConfig: {
            excludeUserIdFromHeader: true,
            excludeTenantIdFromHeader: true,
            customXTenantId: tenantId,
          },
        }).then((res) => res.data),
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
      ...options,
    },
  );
};

export function useInvoicePaymentForInvoiceShare(
  options: {
    onSuccess?: (data: void) => void;
    onError?: (err: unknown) => void;
  } = {},
) {
  const queryClient = useQueryClient();
  return useMutation<
    void,
    unknown,
    {
      invoiceSecretId: string;
      tenantId: string;
      body: ISharedInvoicePaymentReq;
    }
  >(
    ({ invoiceSecretId, tenantId, body }) => {
      return apiPost(
        `/api/share/invoices/${invoiceSecretId}/startPayment`,
        body,
        {
          axiosConfig: {
            excludeUserIdFromHeader: true,
            excludeTenantIdFromHeader: true,
            customXTenantId: tenantId,
          },
        },
      ).then((res) => {
        queryClient.invalidateQueries(shareQueryKeys.invoice(invoiceSecretId));
        queryClient.invalidateQueries(
          invoiceServiceQueryKeys.htmlTemplate(invoiceSecretId),
        );
        return res.data;
      });
    },
    {
      ...options,
    },
  );
}

/**
 * Create payment method for invoice share
 * this is used when the payment method has additional action required
 * such as verifying micro-deposits
 */
export function useCreateInvoicePaymentMethodForInvoiceShare(
  options: {
    onSuccess?: (data: void) => void;
    onError?: (err: unknown) => void;
  } = {},
) {
  const queryClient = useQueryClient();

  return useMutation<
    void,
    unknown,
    {
      invoiceSecretId: string;
      tenantId: string;
      body: ISharedInvoicePaymentReq;
    }
  >(
    ({ invoiceSecretId, tenantId, body }) => {
      return apiPost(
        `/api/share/invoices/${invoiceSecretId}/paymentMethods`,
        body,
        {
          axiosConfig: {
            excludeUserIdFromHeader: true,
            excludeTenantIdFromHeader: true,
            customXTenantId: tenantId,
          },
        },
      ).then((res) => {
        queryClient.invalidateQueries(shareQueryKeys.invoice(invoiceSecretId));
        queryClient.invalidateQueries(
          invoiceServiceQueryKeys.htmlTemplate(invoiceSecretId),
        );
        return res.data;
      });
    },
    {
      ...options,
    },
  );
}

/**
 * Pay with existing payment method
 */
export function usePayInvoiceWithPaymentMethodForInvoiceShare(
  options: {
    onSuccess?: (data: void) => void;
    onError?: (err: unknown) => void;
  } = {},
) {
  const queryClient = useQueryClient();

  return useMutation<
    void,
    unknown,
    {
      invoiceSecretId: string;
      tenantId: string;
      body: SharedInvoicePaymentExistingMethod;
    }
  >(
    ({ invoiceSecretId, tenantId, body }) => {
      return apiPost(`/api/share/invoices/${invoiceSecretId}/payment`, body, {
        axiosConfig: {
          excludeUserIdFromHeader: true,
          excludeTenantIdFromHeader: true,
          customXTenantId: tenantId,
        },
      }).then((res) => {
        queryClient.invalidateQueries(shareQueryKeys.invoice(invoiceSecretId));
        queryClient.invalidateQueries(
          invoiceServiceQueryKeys.htmlTemplate(invoiceSecretId),
        );
        return res.data;
      });
    },
    {
      ...options,
    },
  );
}
