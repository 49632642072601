import { CardBody } from '@chakra-ui/react';
import { forwardRef, ReactNode, useState } from 'react';
import {
  BsCreditCard2Front,
  BsCreditCard2FrontFill,
  BsPen,
  BsPenFill,
} from 'react-icons/bs';
import { MdStar, MdStarOutline } from 'react-icons/md';
import { ContactAddress } from '~app/components/Contacts/ContactAddress';
import {
  MAlertTag,
  MBox,
  MButton,
  MCard,
  MCloseButton,
  MFlex,
  MText,
  MTooltip,
} from '~app/components/Monetize';
import { ContactTypes, ContactWithTypes, IContactRespSchema } from '~app/types';
import { LegalEntityAddressFormatEnum } from '~app/types/legalEntityTypes';
import { QuoteContactForm } from './QuoteContactForm';
import ContactItemItemIcon from './QuoteContactItemIcon';

export interface QuoteContactItemProps {
  isDisabled?: boolean;
  isDisabledPrimaryBilling?: boolean;
  isEsignEnabled: boolean;
  contact: ContactWithTypes;
  isInternal?: boolean;
  onRemove: (contact: ContactWithTypes, isInternal: boolean) => void;
  onUpdated: (contact: ContactWithTypes, isInternal: boolean) => void;
  onToggleType: (
    contact: ContactWithTypes,
    type: ContactTypes,
    value: boolean,
    isInternal: boolean,
  ) => void;
  children?: ReactNode;
  addressFormat?: LegalEntityAddressFormatEnum | null;
  canUpdateContact: boolean;
  showAddress?: boolean;
}

const QuoteContactItem = forwardRef<any, QuoteContactItemProps>(
  (
    {
      isDisabled,
      isDisabledPrimaryBilling,
      isEsignEnabled,
      contact: contactWithType,
      isInternal = false,
      onUpdated,
      onRemove,
      onToggleType,
      children,
      addressFormat,
      canUpdateContact,
      showAddress = true,
    }: QuoteContactItemProps,
    ref,
  ) => {
    const { billing, contact, esign, primary } = contactWithType;
    const [isEditMode, setIsEditMode] = useState(false);

    const omitProperties: (keyof IContactRespSchema)[] = showAddress
      ? ['fullName']
      : ['fullName', 'address'];

    function handleCloseEdit(updatedContact?: IContactRespSchema) {
      setIsEditMode(false);
      if (updatedContact) {
        onUpdated(
          {
            ...contactWithType,
            contact: updatedContact,
          },
          isInternal,
        );
      }
    }

    return (
      <>
        <MCard data-testid="contact-list-item" variant="borderless">
          <CardBody py="2">
            <MFlex direction="column" grow={1}>
              {isEditMode ? (
                <QuoteContactForm
                  accountId={contact.accountId}
                  isInternal={isInternal}
                  existingContact={contact}
                  onClose={handleCloseEdit}
                />
              ) : (
                <>
                  <MFlex justifyContent="spread" alignItems="center">
                    <MText fontSize="md" isTruncated w="100%" mb={2}>
                      {contact.fullName}
                    </MText>
                    {!isDisabled && (
                      <MTooltip label="Remove Contact" placement="bottom-end">
                        <MCloseButton
                          aria-label="Remove Contact"
                          onClick={() => onRemove(contactWithType, isInternal)}
                        />
                      </MTooltip>
                    )}
                  </MFlex>
                  <MFlex grow={1}>
                    <MFlex direction="column" grow={1}>
                      <ContactAddress
                        textProps={{ color: 'tGray.darkPurple' }}
                        contact={contact}
                        omitProperties={omitProperties}
                        addressFormat={addressFormat}
                      />
                      {/* Tenant configured contact is not allowed to be edited on the quote */}
                      {!isDisabled &&
                        (!isInternal || contact.accountId) &&
                        canUpdateContact && (
                          <MBox>
                            <MButton
                              size="xs"
                              ml={-2}
                              p={2}
                              variant="tertiary"
                              onClick={() => setIsEditMode(true)}
                            >
                              Edit
                            </MButton>
                          </MBox>
                        )}
                    </MFlex>
                    <MFlex
                      alignItems="end"
                      justifyContent="space-between"
                      flexDirection="column"
                    >
                      <MFlex>
                        {!isInternal && (
                          <ContactItemItemIcon
                            disabled={isDisabledPrimaryBilling}
                            tooltip="Shipping Contact"
                            contact={contactWithType}
                            type="primary"
                            isActive={primary}
                            inactiveIcon={MdStarOutline}
                            activeIcon={MdStar}
                            onToggleType={onToggleType}
                          />
                        )}

                        {!isInternal && (
                          <ContactItemItemIcon
                            disabled={isDisabledPrimaryBilling}
                            tooltip="Billing Contact"
                            contact={contactWithType}
                            type="billing"
                            isActive={billing}
                            inactiveIcon={BsCreditCard2Front}
                            activeIcon={BsCreditCard2FrontFill}
                            onToggleType={onToggleType}
                          />
                        )}

                        {isEsignEnabled && (
                          <ContactItemItemIcon
                            tooltip="eSign Contact"
                            contact={contactWithType}
                            isInternal={isInternal}
                            type="esign"
                            isActive={esign}
                            disabled={isDisabled}
                            allowDeselect={!isInternal}
                            inactiveIcon={BsPen}
                            activeIcon={BsPenFill}
                            onToggleType={onToggleType}
                          />
                        )}
                      </MFlex>
                      {!primary && !billing && !esign && (
                        <MAlertTag>Assign a contact type</MAlertTag>
                      )}
                    </MFlex>
                  </MFlex>
                </>
              )}
            </MFlex>
          </CardBody>
        </MCard>
        {children}
      </>
    );
  },
);

export default QuoteContactItem;
