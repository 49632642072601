import { Switch } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { handleApiErrorToast } from '~app/api/axios';
import {
  MAccordion,
  MAccordionCustomButton,
  MBox,
  MButton,
  MFlex,
  MFormField,
  MGrid,
  MGridItem,
  MStack,
  MText,
} from '~app/components/Monetize';
import MEditor from '~app/components/Monetize/MEditor';
import { MSettingAccordionItem } from '~app/components/Monetize/MSettingAccordionItem';
import {
  IQuoteSettingApprovalSchema,
  QuoteSettingApprovalSchema,
} from '~app/types/quoteSettingsTypes';

interface QuoteSettingApprovalsProps {
  approvalData: IQuoteSettingApprovalSchema;
  onSubmit: (data: IQuoteSettingApprovalSchema) => Promise<void>;
}

export const QuoteSettingApprovals = ({
  approvalData,
  onSubmit,
}: QuoteSettingApprovalsProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
    reset,
    watch,
  } = useForm<IQuoteSettingApprovalSchema>({
    resolver: zodResolver(QuoteSettingApprovalSchema),
    mode: 'onChange',
    defaultValues: {
      quoteApprovalNotes: {
        status: true,
        approvalNote: '',
      },
    },
  });

  useEffect(() => {
    if (approvalData) {
      reset({
        ...approvalData,
        quoteApprovalNotes: {
          ...approvalData.quoteApprovalNotes,
          approvalNote: approvalData.quoteApprovalNotes.approvalNote || '',
        },
      });
    }
  }, [approvalData]);

  const handleSubmitNote = async (data: IQuoteSettingApprovalSchema) => {
    try {
      setIsLoading(true);
      await onSubmit(data);
    } catch (err: any) {
      handleApiErrorToast(err);
    } finally {
      setIsLoading(false);
    }
  };

  const formApprovalStatus = watch('quoteApprovalNotes.status');

  return (
    <MStack w="100%" data-testid="approval-notes-config">
      <form
        onSubmit={handleSubmit(handleSubmitNote)}
        data-testid="quote-setting-form"
      >
        <MAccordion allowMultiple>
          <MSettingAccordionItem
            renderAccordionButton={({
              isExpanded,
            }: {
              isExpanded: boolean;
            }) => (
              <MAccordionCustomButton
                isExpanded={isExpanded}
                label="Approval"
              />
            )}
          >
            <MBox mt="6">
              <MFlex
                justify="space-between"
                w="100%"
                mt="4"
                data-testid="approval-notes-check"
              >
                <MBox>
                  <MText fontSize="md" fontWeight="600">
                    Approval Notes
                  </MText>
                  <MText fontSize="sm" mt="2">
                    This message will show up before a quote goes to the review
                    screen
                  </MText>
                </MBox>
                <MFormField
                  error={errors?.quoteApprovalNotes?.status}
                  onClick={(ev) => ev.stopPropagation()}
                  w="auto"
                >
                  <Controller
                    name="quoteApprovalNotes.status"
                    control={control}
                    render={({ field: { value, onChange, ...rest } }) => (
                      <Switch
                        isChecked={value}
                        size="md"
                        onChange={(ev) => {
                          onChange(ev.target.checked);
                          handleSubmit(onSubmit)();
                        }}
                        {...rest}
                      />
                    )}
                  />
                </MFormField>
              </MFlex>
              <MGrid templateColumns="repeat(12, 1fr)" gap={4}>
                <MGridItem colSpan={12}>
                  <MFormField error={errors?.quoteApprovalNotes?.approvalNote}>
                    <Controller
                      name="quoteApprovalNotes.approvalNote"
                      control={control}
                      render={({ field: { onChange, value, ...rest } }) => (
                        <MEditor
                          autoScroll
                          handleEditorChange={onChange}
                          boxProps={{ pb: 0, minHeight: 125 }}
                          init={{ height: 125 }}
                          disabled={!formApprovalStatus}
                          value={value || ''}
                          {...rest}
                        />
                      )}
                    />
                  </MFormField>
                </MGridItem>
              </MGrid>
              <MGridItem colSpan={12} mt="4">
                <MFlex justify="end">
                  <MButton
                    variant="primary"
                    onClick={handleSubmit(handleSubmitNote)}
                    isDisabled={
                      !isDirty || !isValid || !formApprovalStatus || isLoading
                    }
                    isLoading={isLoading}
                  >
                    Save
                  </MButton>
                </MFlex>
              </MGridItem>
            </MBox>
          </MSettingAccordionItem>
        </MAccordion>
      </form>
    </MStack>
  );
};
