import { zodResolver } from '@hookform/resolvers/zod';
import isString from 'lodash/isString';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { handleApiErrorToast } from '~app/api/axios';
import {
  useCreateEntity,
  useDeleteEntity,
  useGetById,
  useGetListData,
  useUpdateEntity,
} from '~app/api/queryUtils';
import {
  MBox,
  MButton,
  MFormField,
  MGrid,
  MHStack,
  MInput,
  MLink,
  MPageContainer,
  MPageLoader,
  MSettingsPageHeader,
  MText,
} from '~app/components/Monetize';
import { CustomSteps } from '~app/components/Monetize/CustomSteps/CustomSteps';
import { ROUTES } from '~app/constants';
import { usePrompt } from '~app/hooks/usePrompt';
import { useDocumentHead } from '~app/services/documentHead';
import {
  CustomFieldEntityEnum,
  DEFAULT_PAGER,
  GuidedQuotingReq,
  GuidedQuotingReqSchema,
  ICustomFieldResSchema,
  IGuidedQuoting,
  IGuidedQuotingRespSchema,
  IQuestion,
  QUESTION_TEMP_PREFIX,
  QuestionFilterByEnum,
  QuestionReq,
} from '~app/types';
import { useFlags } from '../../../../services/launchDarkly';
import { logger } from '../../../../services/logger';
import { getFirstValue } from '../../../../utils';
import { orderObjectsBy } from '../../../../utils/misc';
import {
  convertResponseToRequestData,
  getEmptyQuestion,
} from './guidedQuoting.utils';
import { GuidedQuotingQuestion } from './questions/GuidedQuotingQuestion';
import { GuidedQuotingQuoteFields } from './quoteFields/GuidedQuotingQuoteFields';

export const GuidedQuotingForm = () => {
  const { guidedQuotingId } = useParams();
  const navigate = useNavigate();
  const { setDocTitle } = useDocumentHead();
  const { guidedSellingViaRulesService } = useFlags();

  const { mutateAsync: doCreateGuidedQuoting } = useCreateEntity<
    IGuidedQuoting,
    GuidedQuotingReq
  >(guidedSellingViaRulesService ? 'guidedQuoting_v2' : 'guidedQuoting');

  const { mutateAsync: doUpdateGuidedQuoting } = useUpdateEntity<
    IGuidedQuoting,
    GuidedQuotingReq
  >(guidedSellingViaRulesService ? 'guidedQuoting_v2' : 'guidedQuoting');

  /** @deprecated with guidedQuoting_v2 */
  const { mutateAsync: doCreateQuestion } = useCreateEntity<
    IQuestion,
    Omit<QuestionReq, 'id'> & { id?: null }
  >('questions');

  /** @deprecated with guidedQuoting_v2 */
  const { mutateAsync: doUpdateQuestion } = useUpdateEntity<
    IQuestion,
    QuestionReq
  >('questions');

  /** @deprecated with guidedQuoting_v2 */
  const { mutateAsync: doDeleteQuestionById } = useDeleteEntity('questions', {
    onError: (error) => handleApiErrorToast(error),
  });

  const { data: customFieldsData, isLoading: customFieldsLoading } =
    useGetListData<
      ICustomFieldResSchema,
      {
        customFields: ICustomFieldResSchema[];
        quoteCustomFields: ICustomFieldResSchema[];
      }
    >(
      'customFields',
      {
        config: DEFAULT_PAGER,
        filters: {
          sort: 'entity:asc',
        },
      },
      {
        isGetAll: true,
        refetchOnWindowFocus: false,
        select: ({ content }) => {
          const fields = orderObjectsBy(content || [], ['displayLabel']);
          return {
            customFields: fields.map((field) => ({
              ...field,
              values: orderObjectsBy(field.values || [], ['order']).filter(
                ({ enabled }) => enabled,
              ),
            })),
            quoteCustomFields: fields.filter(
              ({ entity }) => entity === CustomFieldEntityEnum.QUOTE,
            ),
          };
        },
      },
    );

  const { customFields, quoteCustomFields } = customFieldsData || {
    dropdownCustomFields: [],
    quoteCustomFields: [],
  };

  const { data: guidedQuotingData, isInitialLoading } =
    useGetById<IGuidedQuotingRespSchema>(
      guidedSellingViaRulesService ? 'guidedQuoting_v2' : 'guidedQuoting',
      guidedQuotingId || '',
      {
        refetchOnWindowFocus: false,
        enabled: !!guidedQuotingId,
        onSuccess: (data) => {
          reset(convertResponseToRequestData(data, quoteCustomFields));
          setInitialQuestionIds(new Set(data.questions.map((item) => item.id)));
          setDocTitle('Settings', `Guided Quoting - ${data.name}`);
        },
        onError: (error) => {
          handleApiErrorToast(error);
          navigate(ROUTES.SETTINGS_GUIDED_QUOTING);
        },
      },
    );

  useEffect(() => {
    if (
      !isInitialLoading &&
      !customFieldsLoading &&
      (guidedQuotingData || quoteCustomFields)
    ) {
      reset(convertResponseToRequestData(guidedQuotingData, quoteCustomFields));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isInitialLoading,
    guidedQuotingData,
    quoteCustomFields,
    customFieldsLoading,
  ]);

  const [initialQuestionIds, setInitialQuestionIds] = useState(
    () => new Set(guidedQuotingData?.questions.map((item) => item.id)),
  );
  const [isSaving, setIsSaving] = useState(false);

  const formValues = useForm<GuidedQuotingReq>({
    resolver: zodResolver(GuidedQuotingReqSchema),
    mode: 'onSubmit',
    defaultValues: convertResponseToRequestData(guidedQuotingData),
  });

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    clearErrors,
    reset,
    watch,
    formState: { errors: errors, isDirty },
  } = formValues;

  const quoteSourceFields = watch('quoteSourceFields') || [];
  const questions = watch('questions') || [];
  const questionsOverallError = (errors as any)?.['questions-custom']?.message;

  const { fields, append, replace, remove } = useFieldArray({
    control: control,
    name: 'questions',
  });

  const addNewQuestion = () => {
    append(getEmptyQuestion(fields.length + 1, guidedQuotingData?.id));
  };

  const swapQuestions = (
    items: any,
    firstIndex: number,
    secondIndex: number,
  ) => {
    if (getFirstValue(items[firstIndex].compareTo) === items[secondIndex].id) {
      items[firstIndex].compareTo = null;
    }

    if (getFirstValue(items[secondIndex].compareTo) === items[firstIndex].id) {
      items[secondIndex].compareTo = null;
    }

    const results = items.slice();
    const firstItem = items[firstIndex];
    results[firstIndex] = items[secondIndex];
    results[secondIndex] = firstItem;

    return results;
  };

  const reOrderQuestion = (data: any, orderType: string) => {
    clearErrors();
    const questions = getValues('questions');

    const selectedIndex = questions.findIndex(
      (step: any) => step.id === data.id,
    );

    const fromIndex = selectedIndex;

    let toIndex = 0;
    if (orderType === 'up') {
      toIndex = fromIndex - 1;
    }
    if (orderType === 'down') {
      toIndex = fromIndex + 1;
    }

    if (fromIndex !== toIndex) {
      const result = swapQuestions(questions, fromIndex, toIndex);

      const updatedQuestions = result.map((q: any, index: number) => {
        const updateQuestion = { ...q, questionNumber: index + 1 };
        return updateQuestion;
      });

      replace(updatedQuestions);
    }
  };

  const removeQuestion = (id: string | number) => {
    clearErrors();
    const indexToRemove = getValues('questions').findIndex(
      (question) => question.id === id,
    );
    if (indexToRemove === -1) {
      return;
    }

    remove(indexToRemove);

    const updatedQuestions = questions
      .filter((question) => question.id !== id)
      .map((question, index) => {
        const updateQuestion = {
          ...question,
          questionNumber: index + 1,
          compareTo:
            getFirstValue(question.compareTo) === id
              ? null
              : question.compareTo,
        };
        return updateQuestion;
      });

    setValue('questions', updatedQuestions);
  };

  const onSubmit = async (data: GuidedQuotingReq) => {
    try {
      setIsSaving(true);

      // ensure all questions have a correct question number
      data.questions.forEach(
        (question, index) => (question.questionNumber = index + 1),
      );

      if (guidedSellingViaRulesService) {
        if (data.id) {
          await doUpdateGuidedQuoting({
            payload: data,
            id: data.id,
          });
        } else {
          await doCreateGuidedQuoting(data);
        }
      } else {
        await onSubmitViaBillingPlatform(data);
      }

      navigate(ROUTES.SETTINGS_GUIDED_QUOTING);
    } catch (ex) {
      handleApiErrorToast(ex);
    } finally {
      setIsSaving(false);
    }
  };

  /**
   * @deprecated with guidedQuoting_v2
   */
  const onSubmitViaBillingPlatform = async (data: GuidedQuotingReq) => {
    const questionIdsToDelete = new Set(initialQuestionIds);
    data.questions.forEach(({ id }) => questionIdsToDelete.delete(id || ''));

    for (const id of Array.from(questionIdsToDelete)) {
      await doDeleteQuestionById({ id });
    }

    const updatedGuidedQuotingData = await (data.id
      ? doUpdateGuidedQuoting({
          payload: {
            ...data,
            quoteSourceFields: [], // don't include because there are likely temp ids - update at end
          },
          id: data.id,
        })
      : doCreateGuidedQuoting({
          ...data,
          quoteSourceFields: [], // don't include because there are likely temp ids - update at end
        }));

    const tempToRecordMap: Record<string, string> = {};
    const questionsById: Record<string, IQuestion> = {};

    for (let i = 0; i < data.questions.length; i++) {
      const question = data.questions[i];

      // replace all temp ids with real ids
      // TODO: this assumes that user has configured things correctly
      Object.entries(question).forEach(([key, value]) => {
        if (isString(value) && tempToRecordMap[value]) {
          (question as any)[key] = tempToRecordMap[value] as any;
        }
      });

      if (!question.id || question.id.startsWith(QUESTION_TEMP_PREFIX)) {
        const createdQuestion = await doCreateQuestion({
          ...question,
          id: undefined,
          // ensure this is always set
          guidedQuotingId: updatedGuidedQuotingData.id,
          compareTo: Array.isArray(question.compareTo)
            ? question.compareTo.join(',')
            : null,
        });

        questionsById[createdQuestion.id] = createdQuestion;

        if (question.id.startsWith(QUESTION_TEMP_PREFIX)) {
          tempToRecordMap[question.id] = createdQuestion.id;
        }

        setValue(`questions.${i}`, createdQuestion);

        const questionReferenceIdx = data.quoteSourceFields.findIndex(
          ({ type, value }) =>
            type === QuestionFilterByEnum.QUESTION && value === question.id,
        );
        if (questionReferenceIdx >= 0) {
          data.quoteSourceFields[questionReferenceIdx].value =
            createdQuestion.id;
          // alternatively could just re-fetch at the end and re-update form
          setValue(
            `quoteSourceFields.${questionReferenceIdx}.value`,
            createdQuestion.id,
          );
        }
      } else if (question.id) {
        const updatedQuestion = await doUpdateQuestion({
          id: question.id,
          payload: {
            id: question.id,
            guidedQuotingId: updatedGuidedQuotingData.id,
            questionText: question.questionText,
            questionNumber: question.questionNumber,
            type: question.type,
            filterBy: question.filterBy,
            compareFrom: question.compareFrom,
            customField: question.customField,
            comparator: question.comparator,
            compareTo: Array.isArray(question.compareTo)
              ? question.compareTo.join(',')
              : null,
          },
        });
        questionsById[updatedQuestion.id] = updatedQuestion;
      }
    }

    // re-update questions will
    await doUpdateGuidedQuoting({
      id: updatedGuidedQuotingData.id,
      payload: {
        ...data,
        questions: [],
      },
    });
  };

  const onError = (error: any) => {
    logger.error(error);
    setIsSaving(false);
  };

  usePrompt(
    'There are unsaved changes, do you want to discard these changes?',
    isDirty && !isSaving,
  );

  if (isInitialLoading || customFieldsLoading) {
    return <MPageLoader />;
  }

  return (
    <MPageContainer alignItems="stretch">
      <MSettingsPageHeader
        divider={false}
        hasBackButton
        backButtonTitle="Back to Guided Quoting List"
        backButtonLink={ROUTES.SETTINGS_GUIDED_QUOTING}
        title={
          !guidedQuotingId
            ? 'New Guided Quoting Configuration'
            : 'Edit Guided Quoting Configuration'
        }
        id={guidedQuotingId}
      >
        <MButton
          variant="primary"
          isLoading={isSaving}
          onClick={handleSubmit(onSubmit, onError)}
          isDisabled={!isDirty}
        >
          Save
        </MButton>
      </MSettingsPageHeader>
      <MBox>
        <MGrid
          maxWidth="750px"
          templateColumns="2fr 1fr 1fr"
          gap={4}
          mb={10}
          px={3.5}
        >
          <MFormField
            error={errors.name}
            label="Guided Quoting Configuration Name"
            isRequired
          >
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <MInput placeholder="Enter Name" {...field} />
              )}
            />
          </MFormField>
        </MGrid>
        <MBox mb={6} px={3.5}>
          <MText fontSize={18} fontWeight="bold">
            Guided Quoting Questions
          </MText>
          <MText color="tGray.darkPurple">
            Configure the questions to answer to generate a quote.
          </MText>
          {questionsOverallError && (
            <MText color="tRed.base">{questionsOverallError}</MText>
          )}
        </MBox>
        <MHStack alignItems="flex-start">
          <MBox
            ml={-4}
            w="50%"
            minW="400px"
            justifyContent="center"
            h="calc(100vh - 300px)"
            overflowY="auto"
            className="custom-scroll-bar-v1"
          >
            {fields.map((question, index) => (
              <CustomSteps
                key={question.id ?? index}
                stepIndex={index}
                stepNumber={question.questionNumber}
                // not using question because react hook form overwrites the id!
                stepData={questions[index]}
                totalSteps={fields?.length}
                removable={fields?.length !== 1}
                removeStep={removeQuestion}
                isOrderable={fields?.length !== 1}
                reOrderSteps={reOrderQuestion}
              >
                <GuidedQuotingQuestion
                  errors={errors}
                  index={index}
                  control={control}
                  customFields={customFields ?? []}
                  setValue={setValue}
                  clearErrors={clearErrors}
                />
              </CustomSteps>
            ))}
            <CustomSteps
              stepNumber={fields.length + 1}
              isLastStep
              removable={false}
              containerProps={
                fields.length > 1 ? { ml: '1.3rem', gap: 2.5 } : {}
              }
            >
              <MLink
                color="tIndigo.base"
                fontWeight="bold"
                textDecoration="underline"
                fontSize="sm"
                onClick={() => addNewQuestion()}
                ml={2}
              >
                + Add New Question
              </MLink>
            </CustomSteps>
          </MBox>
          <MBox w="50%">
            <GuidedQuotingQuoteFields
              formValues={formValues}
              quoteSourceFields={quoteSourceFields}
              questions={questions}
              quoteCustomFields={quoteCustomFields}
            />
          </MBox>
        </MHStack>
      </MBox>
    </MPageContainer>
  );
};

export default GuidedQuotingForm;
