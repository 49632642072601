import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(accordionAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    borderColor: 'tGray.lightPurple',
    borderRadius: '0.25rem',
    borderWidth: '1px',
  },
  panel: {
    pt: 0,
    mx: 7,
  },
  button: {
    height: '3.125rem',
    px: 2,
    py: 1,
  },
});

const defaultStyle = definePartsStyle({});
const primaryStyle = definePartsStyle({
  container: {
    borderColor: 'tGray.lightPurple',
    borderRadius: '0.25rem',
    borderWidth: '1px',
  },
  panel: {
    p: 0,
    mx: 0,
  },
  button: {
    py: 0,
    px: '4',
    minH: '64px',
    borderColor: 'tWhite.400',
    borderBottomWidth: '1px',
    backgroundColor: 'tBlue.hover',
    _hover: {
      backgroundColor: 'tBlue.100',
    },
  },
});

const outlineStyle = definePartsStyle({
  root: {
    borderColor: 'tGray.lightPurple',
    borderRadius: '0.25rem',
    borderWidth: '1px',
  },
  container: {},
  panel: {
    p: 0,
    mx: 0,
  },
  button: {
    py: 0,
    px: '4',
    minH: '64px',
    _expanded: {
      borderColor: 'tGray.lightPurple',
      borderBottomWidth: '1px',
    },
  },
});

const bottomBorderStyle = definePartsStyle({
  container: {},
  panel: {
    p: 0,
    mx: 0,
  },
  button: {
    py: 0,
    px: '4',
    minH: '64px',
    borderColor: 'tGray.lightPurple',
    borderBottomWidth: '1px',
  },
});

export const accordionTheme = defineMultiStyleConfig({
  variants: {
    base: baseStyle,
    default: defaultStyle,
    primary: primaryStyle,
    bottomBorder: bottomBorderStyle,
    outline: outlineStyle,
  },
  defaultProps: {
    variant: 'base',
  },
});

export default accordionTheme;
