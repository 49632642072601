import { Control, Controller, FormState } from 'react-hook-form';
import {
  MCustomMultiSelect,
  MFormField,
  MStack,
} from '~app/components/Monetize';
import { IBulkQuoteOfferingReqSchema, IOfferingRes } from '~app/types';
import {
  ImportQuoteOfferingsModal,
  ImportQuoteOfferingsModalProps,
} from '../ImportQuoteOfferingsModal';

interface BulkOfferingSelectionProps extends ImportQuoteOfferingsModalProps {
  errors: FormState<IBulkQuoteOfferingReqSchema>['errors'];
  control: Control<IBulkQuoteOfferingReqSchema>;
  items: IOfferingRes[];
  disabled?: boolean;
  displayImportOffering: boolean;
}

export const BulkOfferingSelection = ({
  displayImportOffering,
  onImported,
  quote,
  errors,
  control,
  items,
  disabled,
}: BulkOfferingSelectionProps) => {
  return (
    <MStack direction={'row'} alignItems={'end'}>
      <MFormField error={errors.offerings} isRequired isDisabled={disabled}>
        <Controller
          control={control}
          name={'offerings'}
          render={({ field: { onChange, value, ...rest } }) => {
            return (
              <MCustomMultiSelect
                isDisabled={disabled}
                value={value}
                onChange={(val) => {
                  onChange(val);
                }}
                onAdd={(val, allItems) => {
                  onChange(allItems);
                }}
                onRemove={(val, allItems) => {
                  onChange(allItems);
                }}
                items={items}
                itemTitle="name"
                itemValue="id"
                multiple
                checkboxDisplay
                showAllSelect
                returnItem
                rightLabel
                showQueryInput
              />
            );
          }}
        ></Controller>
      </MFormField>
      {displayImportOffering && (
        <ImportQuoteOfferingsModal quote={quote} onImported={onImported} />
      )}
    </MStack>
  );
};
