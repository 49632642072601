import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useCreateTeam, useUpdateTeam } from '~app/api/settingsService';
import { useGetUsersListByTenantId } from '~app/api/usersService';
import {
  MAvatar,
  MButton,
  MCenterModal,
  MCustomMultiSelect,
  MCustomSelect,
  MFlex,
  MFormField,
  MGrid,
  MGridItem,
  MInput,
  MStack,
} from '~app/components/Monetize';
import { UserAvatarAndName } from '~app/components/Monetize/MCustomSelect/components/UserAvatarAndName';
import { ROUTES } from '~app/constants';
import { TEAM_TYPE_DISPLAY_OPTIONS } from '~app/constants/teams';
import { useAuth } from '~app/services/auth0';
import {
  DEFAULT_PAGER,
  ITeam,
  ITeamSchema,
  IUser,
  TeamSchema,
  UserStatusEnum,
} from '~app/types';
import { logger } from '../../../../../services/logger';
import { MemberLeftElementContent } from '../components/MemberLeftElementContent';

export const TeamForm: FC = () => {
  const { id = '' } = useParams();
  const { tenantId } = useAuth();
  const navigate = useNavigate();
  const [ownerQuery, setOwnerQuery] = useState<string>('');

  const {
    control,
    formState: { isValid, isDirty, errors },
    watch,
    getValues,
    setValue,
    handleSubmit,
  } = useForm<ITeamSchema>({
    resolver: zodResolver(TeamSchema),
    mode: 'onChange',
  });

  const watchOwner = watch('owner');
  const { mutate: doCreateTeam, isLoading: createLoading } = useCreateTeam();

  const { mutate: doUpdateTeam, isLoading: updateLoading } = useUpdateTeam({
    teamId: id,
  });

  const usersValue = getValues('users') || [];

  const { data: userList } = useGetUsersListByTenantId<IUser>({
    tenantId,
    config: { ...DEFAULT_PAGER, rows: 100000 },
    filters: {
      status: {
        in: [UserStatusEnum.ACTIVE, UserStatusEnum.INVITED],
      },
      name: { contains: ownerQuery },
    },
  });
  const users = userList?.content || [];

  const onSubmit = async (submitData: ITeam) => {
    const members = submitData.users as IUser[];
    const memberIds = members?.map(({ id: memberId }) => memberId) || [];
    const finalData = {
      name: submitData.name,
      type: submitData.type,
      userIds: [...[submitData.owner], ...memberIds],
      owner: submitData.owner,
    };

    finalData.owner = finalData.owner || finalData.userIds?.[0];

    if (id) {
      await doUpdateTeam(finalData, {
        onSuccess: () => navigate(ROUTES.getTeamsDetailsRoute(id)),
      });
      return;
    }
    doCreateTeam(finalData, {
      onSuccess: (data) => navigate(ROUTES.getTeamsDetailsRoute(data.id)),
    });
  };

  const onError = (error: any, e: any) => {
    logger.error(error, e);
  };

  const onChangeOwnerQuery = (query: string) => {
    if (query !== ownerQuery) {
      setOwnerQuery(query);
    }
  };

  const renderInputLeftContent = () => {
    const ownerValue = getValues('owner');
    if (ownerValue) {
      return (
        <MAvatar
          name={users?.find(({ id: userId }) => userId === ownerValue)?.name}
          mr={2}
        />
      );
    }
  };

  const saveLoading = createLoading || updateLoading;
  const isDisabledSubmit = !isValid || !isDirty;

  return (
    <MCenterModal
      modalContentProps={{ 'data-testid': 'team-modal' } as any}
      modalBodyProps={{ overflow: 'visible' }}
      size="lg"
      isOpen
      closeOnOverlayClick={false}
      onClose={() => navigate(-1)}
      modalTitle={id ? 'Update Team' : 'Create New Team'}
      renderFooter={() => (
        <MStack
          spacing={4}
          direction="row"
          align="center"
          justify="right"
          flex={1}
        >
          <MButton variant="cancel" onClick={() => navigate(-1)} minW="auto">
            Cancel
          </MButton>
          <MButton
            data-testid="teams-submit-btn"
            type="submit"
            variant="primary"
            isLoading={saveLoading}
            isDisabled={isDisabledSubmit}
            onClick={handleSubmit(onSubmit, onError)}
            minW="auto"
          >
            Create
          </MButton>
        </MStack>
      )}
    >
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <MGrid gap={4} mb={4} templateColumns="8fr 4fr">
          <MGridItem>
            <MFormField error={errors.name} label="Team Name" isRequired>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <MInput placeholder="Enter Team Name" {...field} />
                )}
              />
            </MFormField>
          </MGridItem>

          <MGridItem>
            <MFormField label="Team Type" isRequired error={errors.type}>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <MCustomSelect
                    placeholder="Select"
                    items={TEAM_TYPE_DISPLAY_OPTIONS}
                    {...field}
                    isDisabled={!!id}
                  />
                )}
              />
            </MFormField>
          </MGridItem>
        </MGrid>

        <MFormField label="Choose Owner" isRequired mb={4} error={errors.owner}>
          <Controller
            name="owner"
            control={control}
            render={({ field }) => (
              <MCustomSelect
                placeholder="Select"
                clearable
                items={users}
                itemTitle="name"
                itemValue="id"
                displayAvatar
                renderItemContent={({ title }) => (
                  <UserAvatarAndName userName={title} />
                )}
                showQueryInput
                useExternalQuery
                externalQuery={ownerQuery}
                onChangeQuery={(value) => onChangeOwnerQuery(value)}
                renderInputLeftElement
                inputLeftElementContent={renderInputLeftContent()}
                {...field}
              />
            )}
          />
        </MFormField>

        <MFormField label="Add Member">
          <Controller
            name="users"
            control={control}
            render={({ field: { value, onChange, ref, ...rest } }) => (
              <MCustomMultiSelect
                placeholder={value?.length ? '' : 'Select'}
                returnItem
                itemValue="id"
                itemTitle="name"
                showQueryInput
                isSelectOpen={false}
                items={
                  watchOwner
                    ? users.filter(
                        ({ id: memberId }) => memberId !== watchOwner,
                      )
                    : users
                }
                value={value}
                onChange={(val: IUser[]) =>
                  setValue('users', val, {
                    shouldValidate: true,
                    shouldDirty: true,
                  })
                }
                renderItemContent={({ title }) => (
                  <MFlex>
                    <UserAvatarAndName
                      userName={title}
                      textProps={{ fontSize: 'sm' }}
                    />
                  </MFlex>
                )}
                renderInputLeftElement={!!value?.length && value.length === 1}
                inputLeftElementContent={
                  <MemberLeftElementContent value={value as IUser[]} />
                }
                showCheckCount
                closeButtonText="Apply"
                {...rest}
              />
            )}
          />
        </MFormField>
      </form>
    </MCenterModal>
  );
};
