import { useDisclosure } from '@chakra-ui/react';
import { isBefore } from 'date-fns/isBefore';
import { isEqual } from 'date-fns/isEqual';
import { parseISO } from 'date-fns/parseISO';
import { useId, useState } from 'react';
import { MdEdit } from 'react-icons/md';
import { useGetEarliestManualPaymentDate } from '~app/api/settingsService';
import { UpdatePaymentModal } from '~app/routes/Invoices/createPayment/UpdatePaymentModal';
import { useACL } from '~app/services/acl/acl';
import { IPayment, PaymentMethodTypeEnum, PaymentStatusEnum } from '~app/types';
import { toDateTimeShort } from '~app/utils/dates';
import { colors } from '~styles/theme';
import {
  MBox,
  MCard,
  MCustomIconButton,
  MFlex,
  MHeading,
  MText,
} from '../Monetize';
import MPopoverWrapper from '../Monetize/MPopoverWrapper';

interface KeyValueDetailProps {
  label: string;
  value: string;
}

const KeyValueDetail = ({ label, value }: KeyValueDetailProps) => {
  const KeyValueDetailId = useId();

  return (
    <MFlex direction="column" gap={2} pt={2}>
      <MText id={KeyValueDetailId} color={colors.tGray.darkPurple}>
        {label}
      </MText>
      <MText aria-labelledby={KeyValueDetailId}>{value}</MText>
    </MFlex>
  );
};

interface PaymentDetailsProps {
  payment: IPayment;
  accountId: string;
  refetch: () => void;
}

export const PaymentDetails = ({
  payment,
  accountId,
  refetch,
}: PaymentDetailsProps) => {
  const [showPopover, setShowPopover] = useState(false);
  const {
    createDate,
    modifyDate,
    paymentType,
    description,
    paymentDate,
    gatewayCode,
    gatewayReference,
    gatewayMessage,
    referenceNo,
    refundDate,
    paymentStatus,
  } = payment;
  const updatePaymentModalState = useDisclosure();
  const { canDo } = useACL();
  const hasBillingUpdatePermissions = canDo([['billing', 'update']]);

  const { data: earliestManualPaymentSettings } =
    useGetEarliestManualPaymentDate();

  const parsedEarliestDate = parseISO(
    earliestManualPaymentSettings?.earliestManualPaymentDate ?? '',
  );
  const parsedPaymentDate = parseISO(payment?.paymentDate ?? '');
  const shouldDisablePaymentEdit =
    isBefore(parsedPaymentDate, parsedEarliestDate) ||
    isEqual(parsedPaymentDate, parsedEarliestDate);

  const { onOpen, onClose } = useDisclosure();

  const handleEditIconMouseEnter = () => {
    if (shouldDisablePaymentEdit) {
      setShowPopover(true);
    }
  };

  const handleEditIconMouseLeave = () => {
    if (shouldDisablePaymentEdit) {
      setShowPopover(false);
    }
  };

  return (
    <MCard
      width={'100%'}
      p={6}
      borderRadius="lg"
      borderColor={colors.tGray.lightPurple}
    >
      <MFlex
        borderBottom="2px solid"
        borderColor={colors.tWhite.titanWhite}
        py={2}
        justifyContent="space-between"
      >
        <MHeading color="tPurple.dark" fontSize={16} fontWeight={600}>
          Payment Details
        </MHeading>
        {hasBillingUpdatePermissions &&
          !refundDate &&
          paymentStatus === PaymentStatusEnum.SUCCESS &&
          paymentType === PaymentMethodTypeEnum.MANUAL && (
            <MBox
              onMouseEnter={handleEditIconMouseEnter}
              onMouseLeave={handleEditIconMouseLeave}
            >
              <MPopoverWrapper
                usePortal
                placement="bottom-start"
                isOpen={showPopover}
                onOpen={onOpen}
                onClose={onClose}
                triggerContent={
                  <MCustomIconButton
                    variant="icon"
                    btnSize={4}
                    iconColor="tPurple.base"
                    icon={MdEdit}
                    data-testid="edit-payment-btn"
                    onClick={updatePaymentModalState.onOpen}
                    isDisabled={shouldDisablePaymentEdit}
                  />
                }
                popoverContentProps={{
                  display: 'flex',
                  alignItems: 'center',
                  border: 'none',
                  bg: 'transparent',
                  zIndex: 200,
                  maxW: '200px',
                }}
                popoverBodyProps={{
                  boxShadow: '0px 4px 25px 0px #7D7C8C80',
                  bg: 'tPurple.base',
                  borderRadius: 2,
                }}
                showArrow
                bodyContent={
                  <MText color="#fff" fontSize="xs">
                    This payment is in a closed Accounting Period. To make
                    changes, review Billing Settings or contact your
                    Administrator.
                  </MText>
                }
              />
            </MBox>
          )}
      </MFlex>

      <MFlex flexWrap="wrap" justifyContent="space-between" pt="4">
        <KeyValueDetail
          label={'Created Date'}
          value={toDateTimeShort(createDate ?? '')}
        />
        <KeyValueDetail
          label={'Last Modified Date'}
          value={toDateTimeShort(modifyDate ?? '')}
        />
        <KeyValueDetail
          label={'Payment Date'}
          value={toDateTimeShort(
            (paymentType === PaymentMethodTypeEnum.MANUAL
              ? paymentDate
              : createDate) ?? '',
          )}
        />
        {gatewayReference && (
          <KeyValueDetail
            label={'Gateway Reference No.'}
            value={gatewayReference ?? ''}
          />
        )}
        {gatewayCode && (
          <KeyValueDetail label={'Gateway Code'} value={gatewayCode ?? ''} />
        )}
        {gatewayMessage && (
          <KeyValueDetail label={'Gateway Message'} value={gatewayMessage} />
        )}
        {referenceNo && (
          <KeyValueDetail label={'Reference No.'} value={referenceNo ?? ''} />
        )}

        {description && (
          <KeyValueDetail label={'Description'} value={description} />
        )}
      </MFlex>
      {updatePaymentModalState.isOpen && (
        <UpdatePaymentModal
          onClose={(reload?: boolean) => {
            reload && refetch();
            updatePaymentModalState.onClose();
          }}
          accountId={accountId}
          payment={payment}
        />
      )}
    </MCard>
  );
};
