import React, { FunctionComponent as FC } from 'react';

import { Text, TextProps } from '@chakra-ui/react';
import { MdContentCopy } from 'react-icons/md';
import { MFlex, MIcon } from './chakra';
import { MClipboardCopyBox } from './MClipboardCopyBox';

export interface MIDCopyBoxProps extends TextProps {
  copyValue: string;
  fontSize?: string;
  isDisabled?: boolean;
  tooltipMessage?: string;
  displayIcon?: boolean;
}

export const MIDCopyBox: FC<MIDCopyBoxProps> = React.forwardRef<
  any,
  MIDCopyBoxProps
>(
  (
    {
      copyValue,
      fontSize = 'sm',
      isDisabled = false,
      tooltipMessage = 'Copy to Clipboard',
      displayIcon = true,
      ...rest
    }: MIDCopyBoxProps,
    ref,
  ) => {
    const renderContent = () => {
      return (
        <MFlex alignItems="center">
          <Text
            fontSize={fontSize}
            fontWeight="normal"
            color={isDisabled ? 'tGray.lightPurple' : 'tPurple.dark'}
            cursor="pointer"
            {...rest}
          >
            {copyValue}
          </Text>
          {displayIcon && (
            <MIcon
              as={MdContentCopy}
              boxSize={3.5}
              mx={1}
              color="tPurple.dark"
              cursor="pointer"
            />
          )}
        </MFlex>
      );
    };

    return (
      <MClipboardCopyBox
        {...rest}
        copyValue={copyValue}
        renderContent={renderContent}
      />
    );
  },
);
