import { StackProps } from '@chakra-ui/react';
import React, { FunctionComponent as FC } from 'react';
import { MStack } from './chakra';
import MText, { MTextProps } from './MText';

/**
 * This component is used as the content within an AccordionItem.
 * @example see @{MAccordionCustomButton}
 */

interface MAccordionCustomButtonItemProps extends StackProps {
  align?: 'left' | 'right'; // default is left
  label: React.ReactNode;
  value: React.ReactNode;
  isExpanded?: boolean;
  hideWhenExpanded?: boolean;
  valueTextProps?: MTextProps;
  labelTextProps?: MTextProps;
}

const MAccordionCustomButtonItem: FC<MAccordionCustomButtonItemProps> = ({
  align = 'left',
  label,
  value,
  isExpanded,
  hideWhenExpanded,
  labelTextProps,
  valueTextProps,
  ...rest
}: MAccordionCustomButtonItemProps) => {
  if (isExpanded && hideWhenExpanded) {
    return null;
  }
  return (
    <MStack spacing="0" ml={12} {...rest}>
      <MText
        py={0}
        m={0}
        fontWeight="600"
        textAlign={align}
        fontSize="md"
        {...labelTextProps}
      >
        {label}
      </MText>{' '}
      <MText py={0} m={0} textAlign={align} fontSize="sm" {...valueTextProps}>
        {value}
      </MText>
    </MStack>
  );
};

export default MAccordionCustomButtonItem;
