import { Accordion } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MdClear, MdStar, MdStarOutline } from 'react-icons/md';
import { handleApiErrorToast } from '~app/api/axios';
import {
  useChangeDefaultEsigner,
  useGetEsignContacts,
} from '~app/api/contactsService';
import { useCreateEntity, usePerformEntityAction } from '~app/api/queryUtils';
import {
  MBox,
  MButton,
  MCustomIconButton,
  MFlex,
  MFormField,
  MGrid,
  MGridItem,
  MInput,
  MStack,
  MText,
  MTooltip,
} from '~app/components/Monetize';
import { MSettingAccordionItem } from '~app/components/Monetize/MSettingAccordionItem';
import { useACL } from '~app/services/acl/acl';
import { useConfirmModal } from '~app/services/confirmModal';
import { logger } from '~app/services/logger';
import {
  ContactRequestSchema,
  ContactStatusEnum,
  IContactRequestSchema,
  IContactRespSchema,
} from '~app/types';
import { sortAlphabetically } from '~app/utils';
import { nullifyEmptyStrings } from '~app/utils/misc';

const DEFAULT_CONTACT = {
  status: ContactStatusEnum.ACTIVE,
  internal: true,
  esigner: true,
  customFields: {},
} as const;

export const DefaultSigningContacts = () => {
  const [isAddingNew, setIsAddingNew] = useState(false);
  // NOTE: This will track the clicked item and show the loading state of the clicked item
  // not the previous default contact item
  // ISSUE: https://jam.dev/c/049e0ec2-0529-4ca6-92d7-f9b74f250293
  const [internalDefaultContactId, setInternalDefaultContactId] = useState<
    string | undefined
  >();
  const { showConfirmModal, setModalLoading, closeModal } = useConfirmModal();
  const { canDo } = useACL();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm<IContactRequestSchema>({
    resolver: zodResolver(ContactRequestSchema),
    mode: 'onSubmit',
    defaultValues: DEFAULT_CONTACT,
  });

  const {
    allContacts,
    defaultContact,
    isLoading: isFetchLoading,
  } = useGetEsignContacts({
    select: ({ content, ...rest }) => {
      return {
        ...rest,
        content: content.sort((a, b) => sortAlphabetically('fullName')(a, b)),
      };
    },
  });

  const defaultContactId = defaultContact?.id;

  const { mutateAsync: createESignContactMutate, isLoading: createLoading } =
    useCreateEntity<IContactRespSchema, IContactRequestSchema>('contacts', {
      onSuccess: () => {
        reset(DEFAULT_CONTACT);
        setIsAddingNew(false);
      },
      onError: (err: any) => handleApiErrorToast(err),
    });

  const { mutateAsync: doChangeDefaultEsigner, isLoading: configureLoading } =
    useChangeDefaultEsigner(defaultContactId, {
      onSuccess: () => {
        setInternalDefaultContactId(defaultContactId);
      },
      onError: (err: any) => handleApiErrorToast(err),
    });

  const { mutateAsync: doContactAction, isLoading: removeLoading } =
    usePerformEntityAction<IContactRespSchema>('contacts', {
      onError: (err: any) => handleApiErrorToast(err),
    });

  const onSubmit = (payload: IContactRequestSchema) => {
    createESignContactMutate(nullifyEmptyStrings(payload));
  };

  const onError = (err: any) => {
    logger.warn(err);
  };

  const handleDeleteESignContact = (contactId: string, fullName: string) => {
    const onYes = async () => {
      try {
        setModalLoading(true);
        await doContactAction({ id: contactId, action: 'archive' });
        closeModal();
      } catch (err) {
        handleApiErrorToast(err);
      } finally {
        setModalLoading(false);
      }
    };

    showConfirmModal({
      title: `Are you sure you want to delete "${fullName}"?`,
      description:
        'Once removed, the signing contact will no longer be available.',
      onYes,
      yesBtnProps: {
        variant: 'delete' as any,
      },
      noBtnProps: {
        variant: 'cancel' as any,
      },
    });
  };

  const handleChangeDefaultEsigner = (contactId: string) => {
    if (contactId === defaultContactId) {
      return;
    }
    setInternalDefaultContactId(contactId);
    doChangeDefaultEsigner({ id: contactId });
  };

  return (
    <MStack w="100%" data-testid="defaultSigningContactForm">
      <Accordion allowMultiple w="full">
        <MSettingAccordionItem
          description="Make these contacts available to be used when eSigning Quotes."
          label="Default Signing Contacts"
        >
          {allContacts.map(
            ({ id: contactId, fullName, email, defaultESigner }, index) => (
              <MFlex
                key={index}
                justifyContent="space-between"
                alignItems="center"
                borderBottom={index !== 4 ? '1px' : 'none'}
                borderBottomColor="tGray.back"
                py={3}
                h={57}
              >
                <MBox>
                  <MText py={0} m={0} fontSize="md">
                    {fullName}
                  </MText>
                  <MText py={0} m={0} fontSize="sm" color="tGray.darkPurple">
                    {email}
                  </MText>
                </MBox>
                <MStack spacing={4} direction="row" alignItems="center">
                  <MTooltip
                    label={
                      defaultESigner
                        ? 'Default signing contact'
                        : 'Set as default signing contact'
                    }
                    placement="bottom-end"
                  >
                    <MBox>
                      <MCustomIconButton
                        isDisabled={configureLoading}
                        isLoading={
                          contactId === internalDefaultContactId &&
                          configureLoading
                        }
                        variant="icon"
                        btnSize={5}
                        padding={0}
                        containerSize={8}
                        iconProps={{
                          _active: { color: 'unset' },
                          _focus: { color: 'unset' },
                        }}
                        icon={defaultESigner ? MdStar : MdStarOutline}
                        onClick={() => handleChangeDefaultEsigner(contactId)}
                      />
                    </MBox>
                  </MTooltip>
                  <MTooltip
                    label={defaultESigner ? '' : 'Remove'}
                    placement="bottom-end"
                  >
                    <MBox>
                      <MCustomIconButton
                        isLoading={
                          contactId === defaultContactId ? removeLoading : false
                        }
                        isDisabled={defaultESigner}
                        variant="icon"
                        btnSize={3}
                        padding={0}
                        containerSize={6}
                        iconProps={{
                          _active: { color: 'unset' },
                          _focus: { color: 'unset' },
                        }}
                        icon={MdClear}
                        onClick={() => {
                          handleDeleteESignContact(contactId, fullName);
                        }}
                      />
                    </MBox>
                  </MTooltip>
                </MStack>
              </MFlex>
            ),
          )}
          <MBox mt={4} />

          {(!allContacts?.length || isAddingNew) && (
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <MGrid
                templateColumns=".9fr .7fr 1fr .3fr .1fr"
                columnGap={4}
                mb={4}
              >
                <MGridItem>
                  <MFormField error={errors.fullName} label="Name" isRequired>
                    <Controller
                      name="fullName"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <MInput
                          maxLength={40}
                          {...field}
                          isDisabled={createLoading}
                        />
                      )}
                    />
                  </MFormField>
                </MGridItem>

                <MGridItem>
                  <MFormField error={errors.title} label="Job Title">
                    <Controller
                      name="title"
                      control={control}
                      defaultValue=""
                      render={({ field: { value, ...rest } }) => (
                        <MInput
                          maxLength={40}
                          value={value || ''}
                          {...rest}
                          isDisabled={createLoading}
                        />
                      )}
                    />
                  </MFormField>
                </MGridItem>

                <MGridItem>
                  <MFormField error={errors.email} label="Email" isRequired>
                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <MInput {...field} isDisabled={createLoading} />
                      )}
                    />
                  </MFormField>
                </MGridItem>

                <MGridItem>
                  <MButton
                    w="77px"
                    size="sm"
                    type="submit"
                    variant="primary"
                    mt={7}
                    alignSelf="self-end"
                    isDisabled={!isDirty || createLoading}
                    isLoading={createLoading}
                  >
                    Add
                  </MButton>
                </MGridItem>

                {allContacts.length !== 0 && (
                  <MGridItem>
                    <MTooltip label="Close">
                      <MBox>
                        <MCustomIconButton
                          variant="icon"
                          btnSize={3.5}
                          padding={0}
                          containerSize={7}
                          icon={MdClear}
                          mt="30"
                          onClick={() => {
                            setIsAddingNew(false);
                            reset(DEFAULT_CONTACT);
                          }}
                        />
                      </MBox>
                    </MTooltip>
                  </MGridItem>
                )}
              </MGrid>
            </form>
          )}

          {allContacts?.length &&
          allContacts?.length < 50 &&
          !isAddingNew &&
          canDo([['account_contacts', 'create']], true) ? (
            <MButton
              variant="tertiary"
              fontSize="sm"
              onClick={() => setIsAddingNew(true)}
              p={0}
              m={0}
              mb={3.5}
            >
              + Add Contact
            </MButton>
          ) : null}
        </MSettingAccordionItem>
      </Accordion>
    </MStack>
  );
};
