import { Tab } from '@chakra-ui/react';
import useResizeObserver from '@react-hook/resize-observer';
import type { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';
import React, {
  FC,
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { MdOutlineFileDownload } from 'react-icons/md';
import { Document, Page, pdfjs } from 'react-pdf';
import { handleApiErrorToast } from '~app/api/axios';
import {
  MCustomIconButton,
  MDivider,
  MDrawer,
  MDrawerBody,
  MDrawerCloseButton,
  MDrawerContent,
  MDrawerHeader,
  MDrawerOverlay,
  MFlex,
  MPageLoader,
  MTabs,
  MText,
} from '~app/components/Monetize';
import { getSafeFilename } from '~app/utils';
import { downloadBlobAsFile } from '~app/utils/download';
import { PreviewPdfDrawerZoom } from './PreviewPdfDrawerZoom';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js';

const DEFAULT_ZOOM = 0.9;

/**
 * Highlights text in PDF
 *
 * https://github.com/wojtekmaj/react-pdf/wiki/Recipes#highlight-text-on-the-page
 * https://gist.github.com/wojtekmaj/f265f55e89ccb4ce70fbd2f8c7b1d95d
 *
 * @param text
 * @param pattern
 * @returns
 */
function highlightPattern(text: string, pattern: string | RegExp): any {
  const splitText = text.split(pattern);
  if (splitText.length <= 1) {
    return text;
  }

  const matches = text.match(pattern);
  return splitText.reduce((arr: any[], element, index) => {
    return matches && matches[index]
      ? [...arr, element, <mark key={index}>{matches[index]}</mark>]
      : [...arr, element];
  }, []);
}

interface PreviewPdfDrawerProps {
  /** Filename to use when pdf is downloaded */
  filename: string;
  /**
   * Highlight text that matches provided regex
   * /Docusign tags: /\/m[0-9a-z]{3}\//i
   * https://monetizenow.atlassian.net/wiki/spaces/PE/pages/1159004161/Docusign+Tabs
   */
  highlightTextPattern?: string | RegExp;
  isOpen: boolean;
  onCloseFocusRef?: RefObject<any>;
  /** Optional list of tabs to show below drawer header */
  tabs?: { label: string; value: string }[];
  /** Defaults to 0, can set if the initially active tab should be different */
  defaultTab?: number;
  /**
   * Optional content to show above PDF document
   * This will only render if the PDF document is loaded
   */
  children?: React.ReactNode;
  /** Fetch PDF document ArrayBuffer. If tabs are used, the active tab will be provided */
  fetchDocument: (tab?: string) => Promise<ArrayBuffer | null>;
  onClose: () => void;
}

/**
 * Side drawer for previewing PDF documents
 */
export const PreviewPdfDrawer: FC<PreviewPdfDrawerProps> = ({
  filename,
  isOpen,
  onCloseFocusRef,
  highlightTextPattern,
  tabs = [],
  defaultTab = 0,
  children,
  fetchDocument,
  onClose,
}) => {
  const drawerBodyRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState<{
    [key: string]: ArrayBuffer | null;
  }>({});
  const [numPages, setNumPages] = useState<number | null>(null);
  const [size, setSize] = useState<DOMRect>();
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [tabIndex, setTabIndex] = useState(defaultTab);
  // Ensure we do not encounter issues with React PDF rendering the same document since it is transferred to the worker and cannot be accessed again
  const [renderKey, setRenderKey] = useState(() => new Date().getTime());
  const [currentPdfFile, setCurrentPdfFile] = useState<
    ArrayBuffer | Uint8Array | Blob | null
  >();

  // If browser is resized, the PDF will be adjusted to fit the new size
  useResizeObserver(drawerBodyRef, (entry) => setSize(entry.contentRect));

  useLayoutEffect(() => {
    if (drawerBodyRef.current) {
      const roundingRect = drawerBodyRef.current.getBoundingClientRect();
      roundingRect && setSize(roundingRect);
    }
  }, [drawerBodyRef]);
  const currentTabValue = tabs?.length ? tabs[tabIndex].value : 'blank';

  // react-pdf throws an error if the same array buffer is used more than once
  // since it transfers ownership to a worker thread
  useEffect(() => {
    const document = documents[currentTabValue];
    if (document) {
      // react-pdf transfers the array buffer to the worker, se we need to clone it so we can continue to use it
      setCurrentPdfFile(null);
      setRenderKey(new Date().getTime());
      setLoading(true);
      setTimeout(() => {
        setCurrentPdfFile(new Blob([document]));
        setLoading(false);
      }, 300);
    }
  }, [currentTabValue, documents]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    (async () => {
      // Cache document response
      if (documents[currentTabValue]) {
        return;
      }

      try {
        setLoading(true);
        const pdfDocument = await fetchDocument(currentTabValue);
        setDocuments({
          ...documents,
          [currentTabValue]: pdfDocument || null,
        });
      } catch (ex) {
        handleApiErrorToast(ex);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, tabIndex]);

  /**
   * Allows highlighting of text within PDF
   * https://github.com/wojtekmaj/react-pdf/wiki/Recipes#highlight-text-on-the-page
   */
  const textRenderer = useCallback(
    (
      textItem: {
        pageIndex: number;
        pageNumber: number;
        itemIndex: number;
      } & { str: string },
    ) => {
      if (!highlightTextPattern) {
        return textItem.str;
      }
      return highlightPattern(textItem.str, highlightTextPattern);
    },
    [highlightTextPattern],
  );

  const onDocumentLoadSuccess = (pdf: PDFDocumentProxy) => {
    setNumPages(pdf?.numPages || 0);
  };

  const downloadPdf = async () => {
    try {
      const document = documents[currentTabValue];
      if (document) {
        downloadBlobAsFile(document, `${getSafeFilename(filename)}.pdf`);
      }
    } catch (err) {
      handleApiErrorToast(err);
    }
  };

  function handleTabChange(newTabIndex: number) {
    setCurrentPdfFile(null);
    setZoom(DEFAULT_ZOOM);
    setTabIndex(newTabIndex);
    setNumPages(null);
  }

  function handleClose() {
    setZoom(DEFAULT_ZOOM);
    setLoading(false);
    setDocuments({});
    onClose();
  }

  return (
    <MDrawer
      isOpen={isOpen}
      size="xl"
      placement="right"
      onClose={handleClose}
      finalFocusRef={onCloseFocusRef}
    >
      <MDrawerOverlay />
      <MDrawerContent>
        <MDrawerCloseButton />
        <MDrawerHeader borderBottomWidth="1px">
          <MFlex justify="space-between" alignItems="center">
            <MText fontSize="inherit" fontWeight="inherit">
              Preview
            </MText>
            <PreviewPdfDrawerZoom zoom={zoom} onChange={setZoom} />
            <MFlex>
              <MCustomIconButton
                variant="icon"
                containerSize="6"
                btnSize={5}
                fontSize="lg"
                _focus={{ background: 'tGray.support' }}
                icon={MdOutlineFileDownload}
                onClick={() => downloadPdf()}
                color="tPurple.dark"
                iconColorHover="tPurple.base"
                mr={6}
                isDisabled={!document}
              />
            </MFlex>
          </MFlex>
        </MDrawerHeader>

        {!!tabs?.length && (
          <MTabs
            variant="centered"
            size="sm"
            defaultIndex={defaultTab}
            onChange={handleTabChange}
          >
            {tabs.map(({ label, value }) => (
              <Tab key={value}>{label}</Tab>
            ))}
          </MTabs>
        )}

        <MDrawerBody
          ref={drawerBodyRef}
          backgroundColor="tGray.sidebarDark"
          overflowX="scroll"
          key={renderKey}
        >
          {(loading || !currentPdfFile) && (
            <MFlex justify="center" grow={1}>
              <MPageLoader />
            </MFlex>
          )}
          {!loading && !!currentPdfFile && (
            <MFlex w="full" flexDir="column">
              {children}
              <Document
                loading={
                  <MFlex justify="center" grow={1}>
                    <MPageLoader />
                  </MFlex>
                }
                file={currentPdfFile}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <MFlex key={`page_${index + 1}`} p={1} justify="center">
                    <Page
                      customTextRenderer={textRenderer}
                      pageNumber={index + 1}
                      scale={zoom}
                      className="pdf-page"
                      width={size?.width || 1105}
                    />
                  </MFlex>
                ))}
              </Document>
            </MFlex>
          )}
        </MDrawerBody>
        <MDivider />
      </MDrawerContent>
    </MDrawer>
  );
};

export default PreviewPdfDrawer;
