import { MBox, MFlex, MPageLoader } from '~app/components/Monetize';
import { TAX_ERROR_CAN_RESOLVE_STATUSES } from '~app/constants/invoice';
import { PAPER_PAGE_MAX_WIDTH } from '~app/constants/quotes';
import { IInvoiceRespSchema, InvoiceDisplayProps } from '~types';
import MEmptyDataPlaceholder from '../Monetize/MEmptyDataPlaceholder';
import { InvoiceHtml } from './InvoiceHtml';
import { InvoiceHtmlPublic } from './InvoiceHtmlPublic';
import { InvoiceTaxCalculationError } from './InvoiceTaxCalculationError';

const InvoiceDisplay = ({
  invoice,
  loadingInvoice,
  invoiceSettings,
  isInReview,
  canPayInvoice,
  isInvoiceSharePage,
  unpaidInvoices,
  onPayInvoice,
  companyLogoData,
  handleOpenEditContactModal,
  tenantId,
  invoiceSecretId,
}: InvoiceDisplayProps & {
  invoice?: IInvoiceRespSchema | null;
  handleOpenEditContactModal?: () => void;
}) => {
  if (loadingInvoice) {
    return <MPageLoader />;
  }

  if (!invoice && isInReview) {
    return (
      <MFlex justifyContent="center">
        <MEmptyDataPlaceholder
          mainMessage="No Invoice To Preview"
          smallMessage="The changes you have made don't result in an invoice, click save to continue with your changes."
        />
      </MFlex>
    );
  }
  if (!invoice) {
    return (
      <MFlex justifyContent="center">
        <MEmptyDataPlaceholder mainMessage="No Invoice To Preview" />
      </MFlex>
    );
  }

  return (
    <MBox maxW={PAPER_PAGE_MAX_WIDTH} w="full" pt="3">
      {/* Never show tax calculation error to end-user */}
      {!isInvoiceSharePage && (
        <InvoiceTaxCalculationError
          taxError={invoice.taxError}
          showReviewAction={TAX_ERROR_CAN_RESOLVE_STATUSES.has(invoice.status)}
          onOpenEditContactModal={handleOpenEditContactModal}
        />
      )}

      <MBox
        bgColor="tWhite.titanWhite"
        borderWidth="thin"
        borderColor="tBlue.hover"
      >
        {!isInvoiceSharePage ? (
          <InvoiceHtml invoice={invoice} />
        ) : (
          <InvoiceHtmlPublic
            invoice={invoice}
            tenantId={tenantId}
            invoiceSecretId={invoiceSecretId}
          />
        )}
      </MBox>
    </MBox>
  );
};

export default InvoiceDisplay;
