import { chakra, useMultiStyleConfig, useTab } from '@chakra-ui/react';
import React, { FunctionComponent as FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import './styles.scss';

type MCustomLinkTabProps = LinkProps;
const CLink = chakra(Link);
interface RoleProps {
  roles?: string[];
}

const MCustomLinkTab: FC<MCustomLinkTabProps & RoleProps> = React.forwardRef<
  any,
  MCustomLinkTabProps
>(
  (
    { roles = [], children, to, ...props }: MCustomLinkTabProps & RoleProps,
    ref,
  ) => {
    const tabProps = useTab({ ...props, ref });
    const styles = useMultiStyleConfig('Tabs', tabProps);

    return (
      <CLink
        __css={styles.tab}
        {...tabProps}
        type={undefined}
        to={to}
        data-content={children}
        className="boldContentHidden"
      >
        {children}
      </CLink>
    );
  },
);

export default MCustomLinkTab;
