import { AccordionProps } from '@chakra-ui/react';
import { Controller, useFormContext, useFormState } from 'react-hook-form';
import {
  MAccordion,
  MAccordionCustomButton,
  MAccordionItem,
  MAccordionPanel,
  MFormField,
} from '~app/components/Monetize';
import MEditor from '~app/components/Monetize/MEditor';
import { QuoteStatusEnum } from '~app/types';
import { useQuoteContext } from '../../Quote/quoteContext';
import { QuoteConditionalTerms } from './QuoteConditionalTerms';

export interface QuoteTermsProps extends AccordionProps {}

export const QuoteTerms = ({ ...rest }: QuoteTermsProps) => {
  const {
    quoteData: { quote },
    handleSubmitButtonWithoutDirtyCheck,
    isReadOnly,
  } = useQuoteContext();

  const { control } = useFormContext();
  const { errors } = useFormState({
    control,
  });

  return (
    <MAccordion allowToggle defaultIndex={0} {...rest}>
      <MAccordionItem>
        {({ isExpanded }) => (
          <>
            <MAccordionCustomButton
              isExpanded={isExpanded}
              label="Terms and Conditions"
            ></MAccordionCustomButton>

            <MAccordionPanel p="4" m="0">
              <MFormField isDisabled={!quote} error={errors.contractTerms}>
                <Controller
                  name="contractTerms"
                  control={control}
                  render={({ field: { onChange, onBlur, ...fields } }) => (
                    <MEditor
                      autoScroll
                      disabled={
                        !quote ||
                        isReadOnly ||
                        quote.status !== QuoteStatusEnum.DRAFT
                      }
                      handleEditorChange={onChange}
                      onBlur={() => {
                        handleSubmitButtonWithoutDirtyCheck();
                        onBlur();
                      }}
                      boxProps={{
                        pb: 0,
                        minH: 200,
                        w: '100%',
                      }}
                      {...fields}
                    />
                  )}
                />
              </MFormField>

              <QuoteConditionalTerms />
            </MAccordionPanel>
          </>
        )}
      </MAccordionItem>
    </MAccordion>
  );
};
