import { ColumnProps } from 'primereact/column';
import { DataTableRowClickEvent } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useGetOpportunities } from '~app/api/cpqService';
import {
  MDataTable,
  MFlex,
  MHStack,
  MPageContentContainer,
  MPageHeader,
  MPageLoader,
  MText,
} from '~app/components/Monetize';
import {
  AccountIdTableFilterOptionContent,
  MDataTableFilter,
} from '~app/components/Monetize/DataTable';
import { ExportTableButton } from '~app/components/Monetize/ExportEntityButton';
import { ROUTES } from '~app/constants';
import { useDocumentHead } from '~app/services/documentHead';
import {
  tableFilterSelector,
  tablePagerSelector,
} from '~app/store/global.store';
import { FilterStateKeys } from '~app/store/store.types';
import {
  FilterType,
  FilterTypeOperator,
  GetListApiFilter,
  IOpportunity,
  RenderTableFilterOptionProps,
} from '~app/types';
import {
  buildFilterParamsRequestObject,
  getFiltersApplied,
  getIsTrulyEmptyList,
} from '~app/utils';
import {
  currencyBodyTemplate,
  dateTimeBodyTemplate,
  idBodyTemplate,
  textBodyTemplate,
} from '~app/utils/tableUtils';

type FilterKey =
  | FilterStateKeys.OPPORTUNITY_LIST
  | FilterStateKeys.OPPORTUNITY_DASHBOARD_LIST;

export const useOpportunitiesTableData = (
  filterKey: FilterKey = FilterStateKeys.OPPORTUNITY_LIST,
) => {
  const navigate = useNavigate();
  const [initialFilters, persistTableFilter] = useRecoilState(
    tableFilterSelector(filterKey),
  );

  const [pager, setPager] = useRecoilState(tablePagerSelector(filterKey));
  const [filters, setFilters] = useState<FilterType[]>(initialFilters);
  const [searchKey] = useState<string>('description');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filterParams, setFilterParams] = useState<GetListApiFilter>(() =>
    buildFilterParamsRequestObject(filters, searchTerm, searchKey),
  );

  const { setDocTitle } = useDocumentHead();
  useEffect(() => {
    setDocTitle('Opportunities');
  }, []);

  useEffect(() => {
    setFilterParams(
      buildFilterParamsRequestObject(filters, searchTerm, searchKey),
    );
  }, [pager, searchTerm, filters, searchKey]);

  useEffect(() => {
    persistTableFilter(filters);
  }, [filters, persistTableFilter]);

  const {
    isLoading,
    isRefetching,
    isFetched,
    data: opportunityList,
  } = useGetOpportunities({
    config: pager,
    filters: filterParams,
  });
  const handleOnRowClick = (e: DataTableRowClickEvent) => {
    handleEditOpportunity(e.data?.id);
  };

  // primaryQuote is object type data in IOpportunity
  const quoteNameBodyTemplate = (rowData: IOpportunity) => (
    <MText noOfLines={1} title={rowData.primaryQuote?.name || ''}>
      {rowData.primaryQuote?.name}
    </MText>
  );

  const onResetFilter = () => {
    setFilters([]);
  };

  const handleEditOpportunity = (opportunityId: string) => {
    navigate(ROUTES.getSalesOpportunityEditRoute(opportunityId));
  };

  const columns: ColumnProps[] = [
    {
      className: 'overflow-hidden',
      field: 'id',
      header: 'ID',
      body: idBodyTemplate<IOpportunity>('id'),
      style: { width: '10em' },
    },
    {
      field: 'Name',
      header: 'Name',
      body: textBodyTemplate<IOpportunity>('name'),
      className: 'table-cell-lg',
    },
    {
      field: 'quoteName',
      header: 'Primary Quote',
      body: quoteNameBodyTemplate,
      className: 'table-cell-lg',
    },
    {
      field: 'modifyDate',
      header: 'Updated',
      body: dateTimeBodyTemplate<IOpportunity>('modifyDate'),
      sortable: true,
    },
    {
      field: 'amount',
      header: 'Amount',
      sortable: false,
      body: currencyBodyTemplate<IOpportunity>('amount', 'currency'),
      style: { textAlign: 'right' },
    },
  ];

  return {
    opportunityList: opportunityList,
    loading: isLoading || (isRefetching && !isFetched),
    searchTerm,
    setSearchTerm,
    pager,
    setPager,
    filters,
    setFilters,
    onResetFilter,
    handleOnRowClick,
    columns,
  };
};

const OpportunityListMain = ({
  renderTableHeader,
}: {
  renderTableHeader?: () => any;
}) => {
  const {
    opportunityList,
    loading,
    searchTerm,
    setSearchTerm,
    pager,
    setPager,
    filters,
    setFilters,
    onResetFilter,
    handleOnRowClick,
    columns,
  } = useOpportunitiesTableData();

  const filterComponentReset = React.useRef<any>(null);
  const searchComponentReset = React.useRef<any>(null);

  const filterOptions = [
    {
      title: 'Account',
      key: 'accountId',
      operator: FilterTypeOperator.EQUAL,
      renderOptionContent: (props: RenderTableFilterOptionProps) => (
        <AccountIdTableFilterOptionContent {...props} />
      ),
    },
  ];

  if (loading || !opportunityList) {
    return <MPageLoader />;
  }

  const isTrulyEmptyList = getIsTrulyEmptyList({
    loading,
    totalElements: opportunityList.totalElements,
    filters,
    searchTerm,
    page: pager.page,
  });

  return (
    <MPageContentContainer>
      {renderTableHeader ? (
        <MPageHeader title="Opportunities">{renderTableHeader()}</MPageHeader>
      ) : (
        <MPageHeader size="md" title="Opportunities">
          {!isTrulyEmptyList && (
            <MFlex>
              <MHStack spacing="2">
                <MDataTableFilter
                  filters={filters}
                  filterOptions={filterOptions}
                  setFilters={setFilters}
                  onResetFilter={onResetFilter}
                  resetFilter={filterComponentReset}
                />
                <ExportTableButton
                  entity="opportunities"
                  filters={filters}
                  sortField={pager.sortField}
                  sortOrder={pager.sortOrder}
                />
              </MHStack>
            </MFlex>
          )}
        </MPageHeader>
      )}
      <MDataTable
        value={opportunityList?.content}
        totalRecords={opportunityList?.totalElements}
        totalPages={opportunityList?.totalPages}
        pager={pager}
        setPager={setPager}
        className="p-datatable-responsive"
        emptyProps={{
          mainMessage: 'Looks like there are no opportunities here.',
        }}
        loading={loading}
        columns={columns}
        filtersApplied={getFiltersApplied(filters) > 0 || !!searchTerm}
        resetFilter={() => {
          filterComponentReset.current && filterComponentReset.current();
          searchComponentReset.current?.();
        }}
        onRowClick={handleOnRowClick}
      />
      <Outlet />
    </MPageContentContainer>
  );
};

export default OpportunityListMain;
