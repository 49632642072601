import { useDisclosure } from '@chakra-ui/react';
import { handleApiErrorToast } from '../../../api/axios';
import { usePerformEntityAction } from '../../../api/queryUtils';
import DataTableActions, {
  ActionType,
} from '../../../components/Monetize/DataTable/MDataTableActions';
import { TransactionApplicationModal } from '../../../components/TransactionApplication/TransactionApplicationModal';
import { useACL } from '../../../services/acl/acl';
import { useFlags } from '../../../services/launchDarkly';
import {
  CreditNoteStatusEnum,
  CreditStatusEnum,
  IGetCreditSchema,
  TransactableSourceType,
  TransactableTypeDisplay,
} from '../../../types';
import { VoidModal } from './VoidModal/VoidModal';

const confirmProps = {
  title: `Are you sure you want to deactivate this credit?`,
  description:
    'Once deactivated, this credit will not longer apply to future invoices.',
  yesButton: 'Deactivate',
  noButton: 'Cancel',
};

interface CreditActionsProps {
  sourceType: TransactableSourceType;
  accountId: string;
  applicationsSourceId: string;
  appliedAmount: number;
  amount: number;
  currency: string;
  billGroupId: string;
  creditStatus?: CreditStatusEnum | CreditNoteStatusEnum;
  creditNoteId?: string | null;
}

export const CreditActions = ({
  sourceType,
  accountId,
  applicationsSourceId,
  amount,
  appliedAmount,
  currency,
  billGroupId,
  creditStatus,
  creditNoteId,
}: CreditActionsProps) => {
  const { canDo } = useACL();
  const { newApplicationOfPaymentCredit, allowCreditAndCreditNoteVoid } =
    useFlags();

  const applyEntityToInvoicesModalState = useDisclosure();
  const creditVoidModalState = useDisclosure();

  const canApplyCredit = canDo([['billing', 'create']]);
  const canUpdateCredit = canDo([['billing', 'update']]);
  const sourceTypeLabel = TransactableTypeDisplay.credit;

  const { mutateAsync: doActivateDeactivate } =
    usePerformEntityAction<IGetCreditSchema>('accountCredits', {
      endpointArgs: { accountId, skipNestedEndpoint: true },
      onError: (err) => handleApiErrorToast(err),
    });

  const actions: ActionType[] = [
    {
      title: appliedAmount ? 'Edit Credit Applications' : 'Apply Credit',
      enabled:
        newApplicationOfPaymentCredit &&
        creditStatus !== CreditStatusEnum.INACTIVE &&
        canApplyCredit &&
        creditStatus !== CreditStatusEnum.VOID,
      action: () => {
        applyEntityToInvoicesModalState.onOpen();
      },
    },
    // TODO:
    // {
    //   title: 'Save to PDF',
    //   enabled: true,
    //   action: () => {
    //     return downloadCreditPDF(credit.id);
    //   },
    // },
  ];

  if ((sourceType === TransactableSourceType.credit, canUpdateCredit)) {
    actions.push(
      ...[
        {
          title: 'Activate',
          enabled: creditStatus === CreditStatusEnum.INACTIVE,
          action: () =>
            doActivateDeactivate({
              id: applicationsSourceId,
              action: 'activate',
            }),
        },
        {
          title: 'Deactivate',
          color: 'danger',
          confirmProps,
          enabled: creditStatus === CreditStatusEnum.ACTIVE,
          action: () =>
            doActivateDeactivate({
              id: applicationsSourceId,
              action: 'deactivate',
            }),
        },
      ],
    );
  }

  if (
    canUpdateCredit &&
    allowCreditAndCreditNoteVoid &&
    creditStatus !== CreditStatusEnum.VOID
  ) {
    actions.push({
      title: 'Void',
      color: 'danger',
      enabled: true,
      action: () => creditVoidModalState.onOpen(),
    });
  }

  return (
    <>
      <DataTableActions actions={actions} />
      {applyEntityToInvoicesModalState.isOpen && (
        <TransactionApplicationModal
          modalTitle={`Apply ${sourceTypeLabel} to Invoice`}
          accountId={accountId}
          sourceId={applicationsSourceId}
          sourceType={sourceType}
          sourceOriginalAmount={amount}
          currency={currency}
          billGroupId={billGroupId}
          onClose={() => applyEntityToInvoicesModalState.onClose()}
        />
      )}
      {creditVoidModalState.isOpen && (
        <VoidModal
          onClose={creditVoidModalState.onClose}
          creditId={applicationsSourceId}
          amount={amount}
          currency={currency}
          sourceType={sourceType}
          billGroupId={billGroupId}
          accountId={accountId}
          creditNoteId={creditNoteId}
        />
      )}
    </>
  );
};
