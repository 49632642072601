import {
  ButtonGroup,
  Editable,
  EditableInputProps,
  EditablePreviewProps,
  EditableProps,
  Tooltip,
  useEditable,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { MdCheck, MdClose, MdEdit } from 'react-icons/md';
import { pluralize } from '~app/utils';
import { MFlex, MInput } from './chakra';
import MCustomIconButton from './MCustomIconButton';
import MText from './MText';

interface MEditableInputProps extends EditableProps {
  inputProps?: EditableInputProps & { 'data-testid'?: string };

  previewProps?: EditablePreviewProps;
  tooltip?: string;
  showCancel?: boolean;
  showSave?: boolean;
  showEdit?: boolean;
  unitName?: string;
}

type EditableControlsProps = Pick<
  MEditableInputProps,
  'showCancel' | 'showSave' | 'showEdit'
> & {
  isEditing: boolean;
  getSubmitButtonProps: ReturnType<typeof useEditable>['getSubmitButtonProps'];
  getCancelButtonProps: ReturnType<typeof useEditable>['getCancelButtonProps'];
  getEditButtonProps: ReturnType<typeof useEditable>['getEditButtonProps'];
};

const EditableControls: FC<EditableControlsProps> = ({
  showCancel,
  showSave,
  showEdit,
  isEditing,
  getSubmitButtonProps,
  getCancelButtonProps,
  getEditButtonProps,
}: EditableControlsProps) => {
  return isEditing && (showCancel || showSave) ? (
    <ButtonGroup justifyContent="end" size="sm" w="full" spacing={1}>
      {showCancel && (
        <MCustomIconButton
          variant="icon"
          btnSize={4}
          ml={2}
          iconColor="tPurple.base"
          icon={MdClose}
          {...getCancelButtonProps()}
        />
      )}
      {showSave && (
        <MCustomIconButton
          variant="icon"
          btnSize={4}
          ml={2}
          iconColor="tPurple.base"
          icon={MdCheck}
          {...getSubmitButtonProps()}
        />
      )}
    </ButtonGroup>
  ) : (
    <ButtonGroup justifyContent="end" size="sm" w="full" spacing={1}>
      {showEdit && (
        <MCustomIconButton
          variant="icon"
          btnSize={4}
          iconColor="tPurple.base"
          icon={MdEdit}
          {...getEditButtonProps()}
        />
      )}
    </ButtonGroup>
  );
};

const MEditableInput: FC<MEditableInputProps> = React.forwardRef<
  any,
  MEditableInputProps
>(
  (
    {
      inputProps,
      previewProps,
      tooltip,
      showCancel,
      showSave,
      showEdit,
      unitName,
      isDisabled,
      ...rest
    }: MEditableInputProps,
    ref,
  ) => {
    const {
      value,
      isEditing,
      getPreviewProps,
      getInputProps,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditable({
      isPreviewFocusable: true,
      selectAllOnFocus: true,
      submitOnBlur: false,
      isDisabled,
      ...rest,
    });

    const unitToDisplay = unitName && pluralize(unitName, Number(value));

    return (
      <Editable display="flex" alignItems="center">
        <Tooltip label={tooltip}>
          <MText
            display="flex"
            alignItems="center"
            minH="8"
            as="span"
            color="tPurple.base"
            px={2}
            w="100%"
            border="1px solid"
            borderRadius="3px"
            borderColor="transparent"
            cursor="text"
            _hover={
              isDisabled
                ? {}
                : {
                    background: 'tGray.support',
                    border: '1px solid',
                    borderColor: 'tGray.lightPurple',
                    borderRadius: '3px',
                  }
            }
            {...getPreviewProps()}
            {...previewProps}
          >
            {value} {unitToDisplay}
          </MText>
        </Tooltip>
        <MFlex alignItems="center">
          <MInput
            flexGrow={1}
            flexShrink={0}
            py={2}
            autoFocus={false}
            {...getInputProps()}
            {...(inputProps as any)}
          />
          <EditableControls
            isEditing={isEditing}
            getSubmitButtonProps={getSubmitButtonProps}
            getCancelButtonProps={getCancelButtonProps}
            getEditButtonProps={getEditButtonProps}
            showCancel={showCancel}
            showSave={showSave}
            showEdit={showEdit}
          />
        </MFlex>
      </Editable>
    );
  },
);

export default MEditableInput;
