import { Column, ColumnProps } from 'primereact/column';
import React, { FunctionComponent as FC } from 'react';

interface MDataTableColumnProps extends ColumnProps {
  children?: React.ReactNode;
}

const MDataTableColumn: FC<MDataTableColumnProps> = ({
  children,
  ...rest
}: MDataTableColumnProps) => {
  return <Column {...rest}>{children}</Column>;
};

export default MDataTableColumn;
