import {
  AccountingFieldMatch,
  FieldMappingBaseFieldType,
  FieldMappingBatchUpsertPayloadMappingFieldType,
  FieldMappingBatchUpsertPayloadType,
  FieldMappingCreateReqType,
  FieldMappingObjectType,
  FieldMappingsResType,
  FieldMappingSystemType,
  FieldMappingType,
} from '../types/fieldMappingTypes';
import {
  convertToField,
  stringifyDisplayLabel,
  transformToDisplayLabelLookupRecord,
} from './crmFieldMapping';

interface MapMatchingNetsuiteFields {
  creditToCreditMemo?: AccountingFieldMatch;
}
export const mapMatchingNetsuiteFields = (
  accountingFields?: FieldMappingType,
  monetizeNowFields?: FieldMappingType,
): MapMatchingNetsuiteFields => {
  if (!accountingFields || !monetizeNowFields) {
    return {};
  }
  let response: Partial<MapMatchingNetsuiteFields> = {};
  if ('CreditMemo' in accountingFields && 'CreditNote' in monetizeNowFields) {
    response = {
      ...response,
      creditToCreditMemo: {
        accounting: accountingFields.CreditMemo || [],
        monetize: monetizeNowFields.CreditNote || [],
      },
    };
  }

  return response;
};

export const transformAccountingFields = (
  matchedFields: AccountingFieldMatch,
  objectType: 'accounting' | 'monetize',
): FieldMappingBaseFieldType[] => {
  return (matchedFields[objectType]
    ?.map(({ displayLabel, fieldApiName, dataType }) => ({
      displayLabel: stringifyDisplayLabel(displayLabel),
      fieldApiName,
      dataType,
    }))
    .sort((a, b) => a.displayLabel.localeCompare(b.displayLabel)) ??
    []) satisfies FieldMappingBaseFieldType[];
};

export const transformAccToFieldMappingBatchUpsertPayloadType = (
  targetSystem: FieldMappingSystemType,
  sourceEntity: FieldMappingObjectType,
  targetEntity: FieldMappingObjectType,
  payloads: FieldMappingCreateReqType[],
): FieldMappingBatchUpsertPayloadType => {
  return payloads.reduce(
    (result, field) => {
      let mappingField = {
        source: {
          fieldApiName: field.source.fieldApiName,
        },
        target: {
          fieldApiName: field.target.fieldApiName,
        },
        mode: field.mode,
      } as FieldMappingBatchUpsertPayloadMappingFieldType;

      result.mappings.push(mappingField);
      return result;
    },
    {
      sourceSystem: 'monetizenow',
      sourceEntity,
      targetSystem,
      targetEntity,
      mappings: [],
    } as FieldMappingBatchUpsertPayloadType,
  );
};

export const mapExistingFieldMappingFromMn = (
  data?: FieldMappingsResType,
  accountingFields?: FieldMappingType,
  monetizeNowFields?: FieldMappingType,
) => {
  const response = {
    userDefinedCreditNotesToCreditMemo: [] as FieldMappingCreateReqType[],
  };
  if (!data) {
    return response;
  }

  const displayLabelLookupRecord = transformToDisplayLabelLookupRecord(
    accountingFields,
    monetizeNowFields,
  );

  return data.fromMn.reduce((result, field) => {
    const convertToFieldResult = convertToField(
      field,
      displayLabelLookupRecord,
    );

    if (
      field.source.objectType === 'CreditNote' &&
      field.target.objectType === 'CreditMemo'
    ) {
      result.userDefinedCreditNotesToCreditMemo.push(convertToFieldResult);
    }
    return result;
  }, response);
};
