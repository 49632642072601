import { FormControl, FormLabel, Switch } from '@chakra-ui/react';
import { Controller, UseFormReturn } from 'react-hook-form';
import {
  MBox,
  MFlex,
  MFormField,
  MInput,
  MRadio,
  MRadioGroup,
  MStack,
  MText,
  RadioStyleButtonGroup,
} from '~app/components/Monetize';
import { logger } from '~app/services/logger';
import {
  ContractEndActionEnum,
  IQuoteSettingContractSchema,
  RenewalTermLengthEnum,
} from '~app/types';
import { QUOTE_CUSTOM_CONTRACT_LENGTHS } from '../../../../../constants/quotes';
import { ContractSettingSection } from './ContractSettingSection';

const customContractOptions = QUOTE_CUSTOM_CONTRACT_LENGTHS.map((value) => ({
  label: `${value}`,
  value: `${value}`,
}));
interface ContractRenewalTermsFormProps {
  contractSettingForm: UseFormReturn<IQuoteSettingContractSchema>;
}
export const ContractRenewalTermsForm = ({
  contractSettingForm,
}: ContractRenewalTermsFormProps) => {
  const {
    handleSubmit,
    setValue,
    watch,
    control,
    clearErrors,
    reset,
    formState: { errors, isDirty },
  } = contractSettingForm;

  const renewalTermLengthType = watch(
    'contractRenewalTerms.renewalTermLength.type',
  );
  const watchRenewalLength = watch(
    'contractRenewalTerms.renewalTermLength.months',
  );

  const onError = (err: any) => logger.error(err);

  return (
    <>
      <ContractSettingSection label="End Action">
        <MFormField
          mt="2"
          mb="4"
          error={errors.contractRenewalTerms?.contractEndAction}
        >
          <Controller
            name="contractRenewalTerms.contractEndAction"
            control={control}
            render={({ field: { name, value, onChange, ...rest } }) => (
              <FormControl
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <FormLabel htmlFor={name} fontWeight="400" mb="0">
                  Auto Renew Contract
                </FormLabel>

                <Switch
                  size="md"
                  id={name}
                  aria-describedby={`${name}-subheading`}
                  isChecked={value === ContractEndActionEnum.RENEW}
                  onChange={(event) =>
                    onChange(
                      event.target.checked
                        ? ContractEndActionEnum.RENEW
                        : ContractEndActionEnum.CANCEL,
                    )
                  }
                />
              </FormControl>
            )}
          />
        </MFormField>
      </ContractSettingSection>

      <ContractSettingSection label="Renewal Configuration">
        <MBox>
          <MFormField
            mt="2"
            mb="4"
            error={
              errors.contractRenewalTerms?.renewalTermLength?.type ||
              errors.contractRenewalTerms?.renewalTermLength?.months
            }
          >
            <MFlex alignItems="flex-end" gap="2">
              <Controller
                name="contractRenewalTerms.renewalTermLength.type"
                control={control}
                render={({ field: { onChange, ...rest } }) => (
                  <MRadioGroup
                    onChange={(val) => {
                      onChange(val);

                      if (val === RenewalTermLengthEnum.ContractLength) {
                        setValue(
                          'contractRenewalTerms.renewalTermLength.months',
                          null,
                          {
                            shouldDirty: true,
                            shouldValidate: true,
                          },
                        );
                        if (
                          errors.contractRenewalTerms?.renewalTermLength?.months
                        ) {
                          clearErrors(
                            'contractRenewalTerms.renewalTermLength.months',
                          );
                        }
                      }
                    }}
                    {...rest}
                  >
                    <MStack>
                      <MRadio
                        isInvalid={
                          !!errors.contractRenewalTerms?.renewalTermLength?.type
                        }
                        value={RenewalTermLengthEnum.ContractLength}
                        fontWeight="bold"
                      >
                        Same as Contract Length
                      </MRadio>
                      <MRadio
                        value={RenewalTermLengthEnum.FixedMonths}
                        fontWeight="bold"
                        minH={8}
                      >
                        Custom Length in Months
                      </MRadio>
                    </MStack>
                  </MRadioGroup>
                )}
              />
            </MFlex>

            {renewalTermLengthType === RenewalTermLengthEnum.FixedMonths && (
              <MBox pl="5">
                <Controller
                  control={control}
                  name="contractRenewalTerms.renewalTermLength.months"
                  render={({ field: { value, ...rest } }) => (
                    <MInput w="10rem" value={value || ''} {...rest} />
                  )}
                />

                <RadioStyleButtonGroup
                  name="renewalLength"
                  containerProps={{ maxW: '160px', mt: 2 }}
                  options={customContractOptions}
                  value={`${watchRenewalLength || ''}` || undefined}
                  defaultValue={`${watchRenewalLength || ''}` || undefined}
                  onChange={(value: string) => {
                    setValue(
                      'contractRenewalTerms.renewalTermLength.months',
                      Number(value),
                      {
                        shouldDirty: true,
                        shouldValidate: true,
                      },
                    );
                  }}
                />
              </MBox>
            )}
          </MFormField>

          <MFormField
            mt="4"
            label="Notice Period (in days)"
            isRequired
            labelProps={{
              display: 'block',
            }}
            tooltip="Notice of non-renewal by this number of days before Contract ends"
            error={errors.contractRenewalTerms?.autoRenewalNoticePeriod}
          >
            <Controller
              control={control}
              name="contractRenewalTerms.autoRenewalNoticePeriod"
              render={({ field: { value, ...field } }) => (
                <MInput maxW="11.25rem" value={value || ''} {...field} />
              )}
            />
          </MFormField>
          <MText mt="2" fontWeight="400" color="tGray.acGray">
            Example: If Contract with auto-renewal ends on July 15th with notice
            period of 10 days, notice of non-renewal must be received by July
            5th
          </MText>
        </MBox>
      </ContractSettingSection>
    </>
  );
};
