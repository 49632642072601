import { object, string, z } from 'zod';

export type Maybe<T> = T | null | undefined;

export type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

export interface StrKeyObj {
  [x: string]: any;
}
export interface IOrderedObj<T> {
  orderedIds: string[];
  byId: { [x: string]: T };
}
export interface StrValueObj {
  [x: string]: string;
}
export interface StrOrNumValueObj {
  [x: string]: string | number;
}
export interface AmountFilterType {
  id: number | string;
  gte: number | string;
  lt?: number | string;
  label: string;
}

// properties common to most API responses:
export const BaseResponseSchema = object({
  createdBy: string(),
  createDate: string(),
  lastModifiedBy: string(),
  modifyDate: string(),
  id: string(),
});
export type IBaseResponse = z.infer<typeof BaseResponseSchema>;

// for responses that sometimes return records the backend hasn't saved yet:
export const BaseResponseOptionalSchema = object({
  createdBy: string().nullish(),
  createDate: string().nullish(),
  lastModifiedBy: string().nullish(),
  modifyDate: string().nullish(),
  id: string().nullish(),
});

// This is here an not in utils to avoid circular dependency
export const convertEmailsStringToList = (value: Maybe<string>) =>
  (value ?? '')
    .trim()
    .replace(/[,\r\n\s]/g, ',')
    .split(',')
    .filter(Boolean);

// used in common filter component
export enum FilterTypeOperator {
  IN = 'in',
  CONTAINS = 'contains',
  BETWEEN = 'between',
  // gte + lte
  GLTE = 'glte',
  EQUAL = 'equal',
  NOT_EQUAL = 'not_equal',
  TOGGLE = 'toggle',
}

export type FilterOptionItemType = {
  label: string;
  value?: string;
  id?: string | number;
};

export interface RenderTableFilterOptionProps {
  filter: FilterType;
  filterOption: FilterOptionType;
  handleFilterChange: (
    val: string[] | { [key: string]: string } | string | boolean,
    filterObject: FilterOptionType,
  ) => void;
}

export type FilterOptionType = {
  title: string;
  key: string;
  operator: FilterTypeOperator;
  items?: FilterOptionItemType[];
  renderOptionContent?: (
    props: RenderTableFilterOptionProps,
  ) => React.ReactElement;
  // Only availalbe for FilterTypeOperator.TOGGLE
  options?: {
    trueValue?: string;
    falseValue?: string;
  };
};

export type FilterTypeIn = {
  key: string;
  operator: FilterTypeOperator.IN;
  value: string[];
};
export type FilterTypeContains = {
  key: string;
  operator: FilterTypeOperator.CONTAINS;
  value: string;
};
export type FilterTypeBetween = {
  key: string;
  operator: FilterTypeOperator.BETWEEN;
  value: { [key: string]: string };
};
export type FilterTypeGlte = {
  key: string;
  operator: FilterTypeOperator.GLTE;
  value: { min: string; max: string };
};
export type FilterTypeToggle = {
  key: string;
  operator: FilterTypeOperator.TOGGLE;
  value: boolean;
  options?: {
    trueValue?: string;
    falseValue?: string;
  };
};
export type FilterTypeEqual = {
  key: string;
  operator: FilterTypeOperator.EQUAL;
  value: string;
};
export type FilterTypeNotEqual = {
  key: string;
  operator: FilterTypeOperator.NOT_EQUAL;
  value: string;
};

export type FilterType =
  | FilterTypeIn
  | FilterTypeContains
  | FilterTypeBetween
  | FilterTypeGlte
  | FilterTypeEqual
  | FilterTypeToggle
  | FilterTypeNotEqual;

// MCustomNumberInput type
export const MCustomNumberTypeRequired = (message: string) =>
  z
    .union([z.string(), z.number()])
    .refine((val) => {
      return !!val || val === 0;
    }, message)
    .transform((val) => Number(val));

export const MCustomNumberTypeNullish = z
  .union([z.string().nullish(), z.number().nullish()])
  .transform((val) => (val === null ? null : Number(val)));

// default schema for options used by MCustomSelect
export interface MCustomSelectOption {
  title: string;
  value: string;
}

export enum NumberStyleEnum {
  DECIMAL = 'decimal',
  CURRENCY = 'currency',
  PERCENT = 'percent',
  UNIT = 'unit',
}
export interface FormatNumberOptions {
  style?: NumberStyleEnum;
  zeroStr?: string;
  currency?: string;
  currencySign?: 'standard' | 'accounting';
  minimumFractionDigits?: number;
  maximumFractionDigits?: 'max' | number; // JS max is 20
}

export enum MimeTypeEnum {
  PLAIN_TEXT = 'text/plain;charset=utf-8',
  CSV = 'text/csv;charset=utf-8',
  OCTET_STREAM = 'application/octet-stream;charset=utf-8',
  ZIP = 'application/zip;charset=utf-8',
  JSON = 'application/json;charset=utf-8',
  XML = 'text/xml;charset=utf-8',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

export enum DraggableItem {
  QUOTE_OFFERING = 'QUOTE_OFFERING',
}

export const EmailTextAreaTypeSchema = z
  .string()
  .refine(
    (value: string) => {
      const emails = convertEmailsStringToList(value);

      const isAllEmailValid = emails.every(
        (email: string) => z.string().email().safeParse(email).success,
      );

      return isAllEmailValid;
    },
    () => ({
      message: 'Contains invalid email address',
    }),
  )
  .nullish();

/**
 * Transform a string that has a list of email addresses into an array of email addresses
 * The email addresses are separated by commas, new lines, or white spaces and are normalized and validated
 */
export const EmailStringToEmailListSchema = EmailTextAreaTypeSchema.transform(
  (data) => convertEmailsStringToList(data),
);

export type BillGroupTabTypes =
  | 'details'
  | 'billing-schedule'
  | 'subscriptions'
  | 'payments'
  | 'credits'
  | 'quotes'
  | 'contracts'
  | 'invoices';

export type AccountTabTypes =
  | 'overview'
  | 'contacts'
  | 'bill-groups'
  | 'subscriptions'
  | 'payment-methods'
  | 'payments'
  | 'credits'
  | 'quotes'
  | 'contracts'
  | 'invoices';
