import { useDisclosure } from '@chakra-ui/react';
import { useState } from 'react';
import { Outlet } from 'react-router';
import { MPageContainer, MPageLoader } from '~app/components/Monetize';
import { ROLES } from '~app/constants';
import { useACL } from '~app/services/acl/acl';
import { useAuth } from '~app/services/auth0';
import { CollaborationAccessLevelEnum } from '~app/types';
import { QUOTE_EDIT_ACTIONABLE_STATUSES } from '../../../constants/quotes';
import QuoteContactsDrawer from '../Quote/components/contacts/QuoteContactsDrawer';
import { ManualQuoteAcceptanceModal } from '../Quote/ManualQuoteAcceptanceModal';
import { useQuoteContext } from '../Quote/quoteContext';
import { QuotePageCustomer } from './components/QuotePageCustomer';
import { QuotePageHeader } from './components/QuotePageHeader';
import { QuotePageInternal } from './components/QuotePageInternal';

export const QuotePageV2 = () => {
  const { canDo } = useACL();
  const { isReadOnly, quoteData, isInternalView, handleEdit, loadData } =
    useQuoteContext();
  const manualAcceptanceModalState = useDisclosure();
  const {
    onOpen: onOpenManualAcceptanceModal,
    onClose: onCloseManualAcceptanceModal,
    isOpen: isOpenManualAcceptanceModal,
  } = manualAcceptanceModalState;
  const {
    quote,
    fetchQuote,
    evaluateQuoteRules,
    updateQuoteSigningOrder,
    signingOrderUI,
    displayConfig,
  } = quoteData;
  const handleCloseContactDrawer = (didChange?: boolean) => {
    setContactDrawerOpen(false);
    didChange && quote?.id && evaluateQuoteRules(quote.id);
    didChange && quote?.id && fetchQuote(quote.id, true);
  };

  const [contactDrawerOpen, setContactDrawerOpen] = useState(false);
  const { currentTenantUserHasRole } = useAuth();
  const hasValidRole = currentTenantUserHasRole(ROLES.QUOTES_ROLES);
  const [editQuoteLoading, setEditQuoteLoading] = useState(false);
  const handleEditQuoteClick = async () => {
    try {
      setEditQuoteLoading(true);
      await handleEdit();
    } catch (ex) {
      // toast triggered from context
    } finally {
      setEditQuoteLoading(false);
    }
  };

  if (!quote || !displayConfig) {
    return <MPageLoader />;
  }

  const canUpdateSales = canDo([['sales', 'update']]);
  const hasEditAccess =
    canUpdateSales || quote.accessLevel === CollaborationAccessLevelEnum.READ;

  const canEditQuote =
    hasEditAccess &&
    QUOTE_EDIT_ACTIONABLE_STATUSES.has(quote.status) &&
    hasValidRole;

  return (
    <>
      <QuotePageHeader
        handleEditQuoteClick={handleEditQuoteClick}
        editQuoteLoading={editQuoteLoading}
        canEditQuote={canEditQuote}
        hasValidRole={hasValidRole}
        onOpenManualAcceptanceModal={onOpenManualAcceptanceModal}
        setContactDrawerOpen={setContactDrawerOpen}
      />

      <MPageContainer
        data-testid="quote-page"
        background="tGray.SidebarDark"
        px="0"
        pt="0"
        pb="10"
      >
        {isInternalView && (
          <QuotePageInternal setContactDrawerOpen={setContactDrawerOpen} />
        )}
        {!isInternalView && (
          <QuotePageCustomer setContactDrawerOpen={setContactDrawerOpen} />
        )}
      </MPageContainer>

      {quote?.contacts && contactDrawerOpen && (
        <QuoteContactsDrawer
          isReadOnly={isReadOnly}
          quote={quote}
          accountId={quote.accountId!}
          quoteContacts={quote.contacts}
          onClose={handleCloseContactDrawer}
          updateQuoteSigningOrder={updateQuoteSigningOrder}
          signingOrderUI={signingOrderUI}
        />
      )}
      <ManualQuoteAcceptanceModal
        isOpen={isOpenManualAcceptanceModal}
        quoteId={quote.id}
        onClose={() => {
          onCloseManualAcceptanceModal();
          loadData();
        }}
      />
      <Outlet />
    </>
  );
};
