import { chakra } from '@chakra-ui/react';
import { FunctionComponent as FC } from 'react';

import { Link, LinkProps, useMatch, useResolvedPath } from 'react-router-dom';

interface MCustomLinkProps extends LinkProps {
  activeStyle: any;
  inactiveStyle: any;
}

const CLink = chakra(Link);

const MCustomLink: FC<MCustomLinkProps> = ({
  children,
  to,
  activeStyle,
  inactiveStyle,
  ...props
}: MCustomLinkProps) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  const computedStyle = match ? activeStyle : inactiveStyle;

  return (
    <CLink to={to} {...props} {...computedStyle}>
      {children}
    </CLink>
  );
};

export default MCustomLink;
