import { useDisclosure } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Outlet } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { handleApiErrorToast } from '~app/api/axios';
import {
  doUpdateQuoteCollaborationAccess,
  useGetQuoteStorageById,
} from '~app/api/cpqService';
import ApprovalQuoteItem from '~app/components/ApprovalSteps/ApprovalQuoteItem';
import {
  MBox,
  MPageContainer,
  MPageHeader,
  MPageLoader,
} from '~app/components/Monetize';
import { ChangeOwner } from '~app/components/Monetize/ChangeOwner';
import { ROLES } from '~app/constants';
import {
  PAPER_PAGE_MAX_WIDTH,
  QUOTE_EDIT_ACTIONABLE_STATUSES,
} from '~app/constants/quotes';
import { getAccountDetailRoute } from '~app/constants/routes';
import { useAuth } from '~app/services/auth0';
import { useFlags } from '~app/services/launchDarkly';
import {
  CollaborationAccessLevelEnum,
  IQuoteRequestSchema,
  getQuoteRequestSchema,
} from '~app/types';
import { ensureBoolean } from '~app/utils';
import { getQuoteRequestFromQuoteResponse } from '~app/utils/quotes';
import { useDocumentHead } from '~services/documentHead';
import { useACL } from '../../../services/acl/acl';
import { ManualQuoteAcceptanceModal } from './ManualQuoteAcceptanceModal';
import { useOpportunityLinkData } from './OpportunityLink';
import { ReviewQuoteHtml } from './ReviewQuoteHtml';
import { QuoteCollaborationPopover } from './components/QuoteCollaborationPopover';
import { QuoteConfetti } from './components/QuoteConfetti';
import { QuotePageActions } from './components/QuotePageActions';
import { QuotePrimaryBadge } from './components/QuotePrimaryBadge';
import { QuoteTags } from './components/QuoteTags';
import QuoteContactsDrawer from './components/contacts/QuoteContactsDrawer';
import QuoteAccountSection from './components/reviewQuote/QuoteAccountSection';
import { useQuoteContext } from './quoteContext';

export const ReviewQuote = () => {
  const { canDo } = useACL();
  const {
    isReadOnly,
    quoteData: {
      quote,
      displayConfig,
      loading: quoteLoading,
      setQuote,
      fetchQuote,
      updateQuote,
      updateQuoteSigningOrder,
      signingOrderUI,
    },
    actionRunning,
    isEsignEnabled,
    manualRenewalFormMethods,
    showPrimaryContactRequiredBg,
    handleChangeOwner,
    setReviewQuoteDisplayWidth,
    loadData,
    handleBackButtonPress,
    handleEdit,
    handleSendQuote,
    handleRecreateQuote,
    isInternalView,
    setIsInternalView,
    quoteStateData,
    methods: quoteFormMethods,
    reviewQuoteModalData,
  } = useQuoteContext();

  const { quoteInternalExternalView } = useFlags();
  const reviewQuoteRef = useRef<any>();
  const { currentTenantUserHasRole, currentTenant } = useAuth();
  const [contactDrawerOpen, setContactDrawerOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const [editQuoteLoading, setEditQuoteLoading] = useState(false);
  const isCelebrate = searchParams.get('celebrate');

  const {
    onOpen: onOpenManualAcceptanceModal,
    onClose: onCloseManualAcceptanceModal,
    isOpen: isOpenManualAcceptanceModal,
  } = useDisclosure();

  const { data: document } = useGetQuoteStorageById(
    {
      quoteId: quote?.id!,
      storageId: quote?.documentLocation!,
      params: { attachContent: true },
    },
    { enabled: !!quote?.id && !!quote?.documentLocation },
  );

  // Quote Object dependant hooks
  const opportunityLinkData = useOpportunityLinkData({ quote, loadData });

  const { control, getValues } = useForm<IQuoteRequestSchema>({
    mode: 'onChange',
    resolver: getQuoteRequestSchema(quote),
    defaultValues: quote ? getQuoteRequestFromQuoteResponse(quote) : {},
  });

  const isActionLoading = actionRunning || quoteLoading;

  // NOTE: Leave these setDocTitle within the index.tsx and ReviewQuote.tsx
  const { setDocTitle } = useDocumentHead();
  useEffect(() => {
    setDocTitle(
      `Review Quote${quote?.description ? ` - ${quote.description}` : ''}`,
    );
  }, [quote?.description]);

  useEffect(() => {
    if (reviewQuoteRef.current) {
      // Calculating review quote display width and removing padding
      setReviewQuoteDisplayWidth(reviewQuoteRef.current.clientWidth - 64);
    }
  }, [reviewQuoteRef, document?.contentBase64Encoded]);

  if (!quote || !displayConfig) {
    return <MPageLoader />;
  }

  const hasValidRole = currentTenantUserHasRole(ROLES.QUOTES_ROLES);

  const handleCloseContactDrawer = (didChange?: boolean) => {
    setContactDrawerOpen(false);
    didChange && quote?.id && fetchQuote(quote.id);
  };

  const handleEditQuoteClick = async () => {
    try {
      setEditQuoteLoading(true);
      await handleEdit();
    } catch (ex) {
      // toast triggered from context
    } finally {
      setEditQuoteLoading(false);
    }
  };
  const canUpdateSales = canDo([['sales', 'update']]);
  const hasEditAccess =
    canUpdateSales || quote.accessLevel === CollaborationAccessLevelEnum.READ;
  const canEditQuote =
    hasEditAccess &&
    QUOTE_EDIT_ACTIONABLE_STATUSES.has(quote.status) &&
    hasValidRole;

  return (
    <MPageContainer>
      <QuoteConfetti active={ensureBoolean(isCelebrate)} />
      <form style={{ width: '100%' }}>
        <MPageHeader
          title="Review Quote"
          tag={
            <QuoteTags
              quote={quote}
              isReadOnly={isReadOnly}
              setQuote={setQuote}
              manualRenewalFormMethods={manualRenewalFormMethods}
            />
          }
          hasBackButton
          backButtonTitle="Go Back"
          backButtonCallback={handleBackButtonPress}
          parentLink={getAccountDetailRoute(quote.accountId)}
          parentLinkTitle={quote.accountName}
          copyUrl
          id={quote?.id}
          customIdCopyBox={
            <QuoteCollaborationPopover
              quoteId={quote.id}
              quoteStatus={quote.status}
              collaborationAccess={quote.collaborationAccess}
              accessLevel={quote.accessLevel}
              onChange={async (collaborationAccess) => {
                try {
                  await doUpdateQuoteCollaborationAccess(quote.id, {
                    collaborationAccess,
                  });
                  setQuote({ ...quote, collaborationAccess });
                } catch (e) {
                  handleApiErrorToast(e);
                }
              }}
            />
          }
          subTitle={
            <ChangeOwner
              isReadOnly={isReadOnly}
              value={quote.owner}
              ownerName={quote.ownerName}
              onChange={(e) => handleChangeOwner(String(e))}
            />
          }
          extraSubtitleParts={
            quote.id === quote.opportunity?.primaryQuoteId
              ? [<QuotePrimaryBadge />]
              : []
          }
        >
          <QuotePageActions
            hasValidRole={hasValidRole}
            fetchQuote={fetchQuote}
            isLoading={quoteLoading}
            isReadOnly={isReadOnly}
            manualRenewalFormMethods={manualRenewalFormMethods}
            opportunityLinkData={opportunityLinkData}
            quote={quote}
            quoteInternalExternalView={quoteInternalExternalView}
            setContactDrawerOpen={setContactDrawerOpen}
            setQuote={setQuote}
            showPrimaryContactRequiredBg={showPrimaryContactRequiredBg}
            isEsignEnabled={isEsignEnabled}
            canEditQuote={canEditQuote}
            isActionLoading={isActionLoading}
            editQuoteLoading={editQuoteLoading}
            handleEditQuoteClick={handleEditQuoteClick}
            onOpenManualAcceptanceModal={onOpenManualAcceptanceModal}
            handleRecreateQuote={handleRecreateQuote}
            handleSendQuote={handleSendQuote}
            isInternalView={isInternalView}
            setIsInternalView={setIsInternalView}
            quoteStateData={quoteStateData}
            quoteFormMethods={quoteFormMethods}
            reviewQuoteModalData={reviewQuoteModalData}
          />
        </MPageHeader>
      </form>

      <QuoteAccountSection
        quote={quote}
        isReadOnly={isReadOnly}
        setQuote={setQuote}
        fetchQuote={fetchQuote}
        updateQuote={updateQuote}
      />

      <MBox flex="1" pl="10" w="full" pb={8}>
        <MBox mx="auto" mt={2.5} maxW={PAPER_PAGE_MAX_WIDTH} w="full" mb="2">
          {quote && (
            <ApprovalQuoteItem
              quote={quote}
              approvals={(quote as any).approvals}
              reloadData={loadData}
            />
          )}
          <MBox
            w="full"
            mt={6}
            borderWidth={0.5}
            borderColor="tBlue.hover"
            background="tWhite.titanWhite"
            ref={reviewQuoteRef}
          >
            {!!quote && !!displayConfig && (
              <ReviewQuoteHtml quote={quote} displayConfig={displayConfig} />
            )}
          </MBox>
        </MBox>
      </MBox>

      {quote?.contacts && contactDrawerOpen && (
        <QuoteContactsDrawer
          isReadOnly={isReadOnly}
          quote={quote}
          accountId={quote.accountId!}
          quoteContacts={quote.contacts}
          onClose={handleCloseContactDrawer}
          updateQuoteSigningOrder={updateQuoteSigningOrder}
          signingOrderUI={signingOrderUI}
        />
      )}
      <ManualQuoteAcceptanceModal
        isOpen={isOpenManualAcceptanceModal}
        quoteId={quote.id}
        onClose={() => {
          onCloseManualAcceptanceModal();
          loadData();
        }}
      />
      <Outlet />
    </MPageContainer>
  );
};
