import { Text, TextProps, useMergeRefs } from '@chakra-ui/react';
import React, { FC } from 'react';

export interface MTextProps extends TextProps {
  disabled?: boolean;
  externalRef?: React.RefObject<any>;
}

const MText: FC<MTextProps> = React.forwardRef<any, MTextProps>(
  (
    { children, disabled, fontWeight, color, externalRef, ...rest }: MTextProps,
    ref,
  ) => {
    const textColor = disabled ? 'tGray.darkPurple' : 'tPurple.base';
    // NOTE: Text props omit the `ref` prop that why I have to use this hook
    // TextProps -> Omit<PropsOf<T>, "ref" | keyof StyleProps>
    const refs = useMergeRefs(ref, externalRef);
    return (
      <Text
        ref={refs}
        fontSize="sm"
        color={color || textColor}
        fontWeight={fontWeight}
        {...rest}
      >
        {children}
      </Text>
    );
  },
);

export default MText;
