import { useMemo } from 'react';
import { DynamicLabelValueBanner } from '~app/components/DynamicLabelValueBanner/DynamicLabelValueBanner';
import { useCustomFields } from '~app/hooks/useCustomFields';
import { handleCustomFieldToDisplayBannerFormat } from '~app/utils/customFields';
import { MBox, MSpinner } from '~components/Monetize';
import {
  CustomFieldEntityEnum,
  IBillGroupResp,
  ICustomFieldRecordSchema,
} from '~types';
import { useGetById } from '../../../api/queryUtils';

interface CreditAdditionalFieldsBannerProps {
  billGroupId?: string;
  isLoading: boolean;
  customFields: ICustomFieldRecordSchema;
  reason: string;
  isEditable?: boolean;
  additionalValues?: {
    label: string;
    value: string;
  }[];
  onOpen: () => void;
  voidReason: string;
}

/**
 * This banner works for Credits and Credit Notes
 */
export const CreditAdditionalFieldsBanner = ({
  billGroupId,
  customFields,
  isLoading,
  reason,
  isEditable = true,
  additionalValues,
  voidReason = '',
  onOpen,
}: CreditAdditionalFieldsBannerProps) => {
  const { data: billGroup } = useGetById<IBillGroupResp>(
    'billGroups',
    billGroupId!,
    {
      enabled: !!billGroupId,
    },
  );

  const { customFieldList, isLoading: isCustomFieldLoading } = useCustomFields(
    CustomFieldEntityEnum.CREDIT_NOTE,
  );

  const data = useMemo(() => {
    const values = additionalValues || [];
    if (billGroup) {
      values.push({
        label: 'Bill Group',
        value: billGroup.name,
      });
    }
    if (reason) {
      values.push({
        label: 'Credit Reason',
        value: reason,
      });
    }
    if (voidReason) {
      values.push({
        label: 'Void Reason',
        value: voidReason,
      });
    }
    return [
      ...values,
      ...handleCustomFieldToDisplayBannerFormat(customFields, customFieldList),
    ];
  }, [
    additionalValues,
    billGroup,
    customFieldList,
    customFields,
    reason,
    voidReason,
  ]);

  if (isCustomFieldLoading && isLoading) {
    return (
      <MBox w="100%" display="flex" alignItems="center" justifyContent="center">
        <MSpinner size="sm" />
      </MBox>
    );
  }

  return (
    <DynamicLabelValueBanner
      title="Additional Fields"
      data={data}
      isEditable={isEditable}
      onEditClick={onOpen}
    />
  );
};
